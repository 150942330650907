import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';

import {
    Avatar, Button, ButtonGroup,
    Card,
    CardActions,
    CardContent, CardHeader,
    CardMedia,
    Checkbox,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText, Grid,
    ListItem,
    ListItemAvatar, Menu, MenuItem, MenuList, TextField
} from "@mui/material";
import Badge from "@mui/material/Badge";
import {DatePicker, TimePicker} from "@mui/x-date-pickers";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import currencyList from "../../../sampleData/currencyList";
import Box from "@mui/material/Box";
import './activities.css';
import ListItemIcon from "@mui/material/ListItemIcon";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot, TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
import {editChipStyle} from "../../ButtonStyle/Edit";
import {addChipStyle} from "../../ButtonStyle/Add";

dayjs.extend(duration);


const CustomTimelineDot = ({ beginTime }) => {
    // Ensure the beginTime is valid before rendering
    if (!beginTime) return null;

    // Display the begin_time in a rectangle box
    return (
        <Box
            sx={{
                width: 'auto', // Set width to auto to accommodate the time length
                height: 40, // Adjust height as needed
                px: 1, // Add horizontal padding for a rectangular look
                borderRadius: 4, // Small border radius for slightly rounded corners (or remove for a full rectangle)
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="body2" noWrap>
                {beginTime} {/* Show the provided begin_time */}
            </Typography>
        </Box>
    );
};





function ActivitiesCard(props){

    const [anchorChangeCurrencyEl, setAnchorChangeCurrencyEl] = useState(null);

    const { dataArray, addPlanningAttendee } = useContext(PAContext);
    const { Planning, Attendees } = dataArray;

    const [eventActivity, setEventActivity] = useState(props.activity);
    const [selectedAction, setSelectedAction] = useState("");
    const [activityTabSelected, setActivityTabSelected] = useState("");

    const [showCalendarSelectBeginDate, setShowCalendarSelectBeginDate] = useState(false);
    const [showCalendarSelectEndDate, setShowCalendarSelectEndDate] = useState(false);
    const [showClockSelectBeginTime, setShowClockSelectBeginTime] = useState(false);
    const [showClockSelectEndTime, setShowClockSelectEndTime] = useState(false);

    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [checkShowPriceChanges, setCheckShowPriceChanges] = useState(false);
    const [showPriceChangesUpdateButton, setShowPriceChangesUpdateButton] = useState(false);


    useEffect(()=> {
        console.log("props.activity : ");
        setEventActivity(props.activity);
    }, [props.activity]);

    useEffect(() => {
        console.log("eventActivity has been updated --- : ");
        console.log(JSON.stringify(eventActivity, null, '\t'));
        console.log("--------------------------------");

//        props.updateActivityDetails(eventActivity);

    }, [eventActivity]);

    useEffect(()=> {

        if (Attendees !== null){
            Attendees.sort((a, b) => a.lname.localeCompare(b.lname));

            // Move activity attendees to the top of the list
            for (let i = eventActivity.attendees.length - 1; i >= 0; i--) {
                const activityAttendee = eventActivity.attendees[i];
                const index = Attendees.findIndex(attendee => attendee.id === activityAttendee.id);
                if (index !== -1) {
                    const removedAttendee = Attendees.splice(index, 1)[0];
                    Attendees.unshift(removedAttendee);
                }
            }

        }

    }, [Attendees]);


    useEffect(()=> {
        console.log("..... checkShowPriceChanges");

        /*
            compare new pricing to see if anything needs to be saved
            this can be a change to the total price or the price breakdown
            for each Attendee
         */

        console.log("comparing props.activity[amount_total] : "+props.activity["amount_total"]+" and "+eventActivity["amount_total"]);
        if (props.activity["amount_total"] !== eventActivity["amount_total"] || props.activity["currency"] !== eventActivity["currency"]){
            //turn on the button

            console.log("the total cost or currency has changed");

            setShowPriceChangesUpdateButton(true);

        } else if (
            eventActivity["attendees"].some((_attendee, index) => {
                const initialAttendee = props.activity["attendees"].find(a => a.id === _attendee.id);

                console.log("comparing attendee costs : "+_attendee.cost);
                console.log("comparing initialAttendee costs : "+initialAttendee["cost"]);

                return initialAttendee && parseFloat(_attendee.cost) !== parseFloat(initialAttendee.cost);
            })) {
            //turn on the button

            console.log("an attendee cost has changed");

            setShowPriceChangesUpdateButton(true);
        } else {
            //no changes have been made
            console.log("no pricing changes to save");

            setShowPriceChangesUpdateButton(false);
        }

        setCheckShowPriceChanges(false);

    }, [checkShowPriceChanges]);


    const formatName = (name) => {
        // Capitalize the first letter and make the rest lowercase
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                boxShadow: `0 0 5px ${stringToColor(name)}`,
                border: `1px solid ${stringToColor(name)}`,
                borderRadius: '10px',
                textAlign: 'center',
                fontWeight: '700',
                backgroundColor: '#FFF',
                color: 'black', // Text color (adjust as needed)
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function handlePromptShowActivityBeginDateCalendar(){
        //toggle the button

        //post the update
        if (showCalendarSelectBeginDate){
            props.updateActivityDetails(eventActivity);
        }

        setShowCalendarSelectBeginDate(!showCalendarSelectBeginDate);
    }
    function handlePromptShowActivityEndDateCalendar(){
        //toggle the button

        //post the update
        if (showCalendarSelectEndDate){
            props.updateActivityDetails(eventActivity);
        }

        setShowCalendarSelectEndDate(!showCalendarSelectEndDate);
    }

    function handleBeginDateChange(begin_date){

        const newBeginDate = begin_date;

        setEventActivity(prevState => ({
            ...prevState,
            begin_date: dayjs(newBeginDate).format('MM/DD/YYYY')
        }));

        /*
            see if the Event dates need to be adjusted
         */

        const _timetable_dates = eventActivity["time_table"].dates;
        if (_timetable_dates.length > 0){
            _timetable_dates.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
            const minDate = dayjs(_timetable_dates[0].date);
            const maxDate = dayjs(_timetable_dates[_timetable_dates.length - 1].date);

            // Convert the target date to a Day.js object
            const targetDate = dayjs(newBeginDate);

            // Check if the target date is before the minimum date or after the maximum date
            if (targetDate.isBefore(minDate) || targetDate.isAfter(maxDate)) {
                setOpenDialogDateRange(true);
            }
        }

    }
    function handleEndDateChange(end_date){

        const newEndDate = end_date;

        setEventActivity(prevState => ({
            ...prevState,
            end_date: dayjs(newEndDate).format('MM/DD/YYYY')
        }));

        /*
            see if the Event dates need to be adjusted
         */

        const _timetable_dates = eventActivity["time_table"].dates;
        if (_timetable_dates.length > 0){
            _timetable_dates.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
            const minDate = dayjs(_timetable_dates[0].date);
            const maxDate = dayjs(_timetable_dates[_timetable_dates.length - 1].date);

            // Convert the target date to a Day.js object
            const targetDate = dayjs(newEndDate);

            // Check if the target date is before the minimum date or after the maximum date
            if (targetDate.isBefore(minDate) || targetDate.isAfter(maxDate)) {
                setOpenDialogDateRange(true);
            }
        }

    }


    function handlePromptShowActivityBeginTime(){
        //toggle the button

        //post the update
        if (showClockSelectBeginTime){
            props.updateActivityDetails(eventActivity);
        }

        //change button back to 'Edit'
        setShowClockSelectBeginTime(!showClockSelectBeginTime);
    }
    function handlePromptShowActivityEndTime(){
        //toggle the button

        //post the update
        if (showClockSelectEndTime){
            props.updateActivityDetails(eventActivity);
        }

        //change button back to 'Edit'
        setShowClockSelectEndTime(!showClockSelectEndTime);
    }

    function handleBeginTimeChange(begin_time){
        console.log("begin_time : "+dayjs(begin_time).format('h:mm A'));
        setEventActivity(prevState => ({
            ...prevState,
            begin_time: dayjs(begin_time).format('h:mm A')
        }));

    }
    function handleEndTimeChange(end_time){
        setEventActivity(prevState => ({
            ...prevState,
            end_time:  dayjs(end_time).format('h:mm A')
        }));

    }



    const handleParticipantsToggle = (_attendee_id) => () => {
        console.log("_attendee_id : "+_attendee_id);

        //see if this Attendee needs to be added or removed
        const index = eventActivity["attendees"].findIndex(item => item.id === _attendee_id);

        let updatedList = [...eventActivity["attendees"]];

        if (index !== -1) {
            // Remove attendee from activity list
            updatedList.splice(index, 1);
        } else {

            //need to see if this Attendee has already been added to the Event
            //add if necessary
            const _att_index = Planning["attendees"].findIndex(att => att.id === _attendee_id);
            if (_att_index === -1){
                //need to add Attendee to the Event first
                addPlanningAttendee(Attendees.find((_att) => _att.id === _attendee_id));
            }

            // Add attendee to activity list
            const _att_with_cost = { id: _attendee_id, cost: 0 };
            updatedList = [...eventActivity["attendees"], _att_with_cost];
        }

        if (eventActivity["assign_equal"]) {

            const activityCost = parseFloat(eventActivity["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
            const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

        }

        setEventActivity(prevState => ({
            ...prevState,
            attendees: updatedList
        }));

    };

    function handleCostOverride(event) {
        const valueToAdd = parseFloat(event.target.value);

        setEventActivity(prevState => ({
            ...prevState,
            amount_total: valueToAdd
        }));

    }
    function toggleShowOverride(){
        console.log("toggleShowOverride");

        if (showPriceOverride){
            //they are toggling to off so update Attendee costs

            if (eventActivity["assign_equal"]) {
                //update the amount assigned to each Occupant

                let updatedList = [...eventActivity["attendees"]];
                let activityCost = parseFloat(eventActivity["amount_total"]);
                const numberOfAttendees = updatedList.length;
                const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("participants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                //go through and update the per Attendee cost
                updatedList.forEach((attendee, i) => {
                    // Add the base cost to the attendee
                    attendee.cost = baseCostPerAttendee;

                    // Distribute any remainder to the last attendee
                    if (i === numberOfAttendees - 1) {
                        attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                    }

                });

                props.updateActivityDetails(eventActivity);

            }
        }

        setShowPriceOverride(!showPriceOverride);
    }

    const openChangeCurrency = Boolean(anchorChangeCurrencyEl);
    const handleOpenChangeCurrency = (event) => {
        setAnchorChangeCurrencyEl(event.currentTarget);
    };
    const handleChangeCurrencyClick = (event, code) => {
        console.log("changing currency to : "+code);
        const _new_currency_code = code;
        setEventActivity(prevState => ({
            ...prevState,
            currency: _new_currency_code
        }));

        //recalculate to see if that changes the Attendees total breakdown
        setCheckShowPriceChanges(true);


        handleChangeCurrencyClose();
    };
    const handleChangeCurrencyClose = () => {
        setAnchorChangeCurrencyEl(null);
    };

    function handleToggleAssignEqual(){
        console.log("handleToggleAssignEqual");

        if (eventActivity["assign_equal"]){
            //they are changing to custom
            console.log("selecting custom costs");

            setEventActivity(prevState => ({
                ...prevState,
                assign_equal: false
            }));

        } else {
            //they are changing to equal
            console.log("selecting equal costs");

            let updatedList = [...eventActivity["attendees"]];

            const activityCost = parseFloat(eventActivity["amount_total"]);
            const numberOfAttendees = updatedList.length;
            const baseCostPerAttendee = parseFloat(Math.floor((activityCost / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((activityCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            //go through and update the per Attendee cost
            updatedList.forEach((attendee, i) => {
                // Add the base cost to the attendee
                attendee.cost = baseCostPerAttendee;

                // Distribute any remainder to the last attendee
                if (i === numberOfAttendees - 1) {
                    attendee.cost = (parseFloat(remainder) + parseFloat(baseCostPerAttendee)).toFixed(2);
                }
            });

            setEventActivity(prevState => ({
                ...prevState,
                attendees: updatedList,
                assign_equal: true
            }));

        }

        setCheckShowPriceChanges(true);

    }

    function handleCustomOccupantCost(_attendee_id, e){
        console.log("handleCustomOccupantCost : "+_attendee_id+" : "+e.target.value);


        //update this state variable
        setEventActivity(prevState => ({
            ...prevState,
            attendees: prevState.attendees.map(attendee => {
                if (attendee.id === _attendee_id) {
                    // Update the cost of the specific attendee
                    console.log("setting custom cost jjhgjhgjgjh : "+ (isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)));
                    return { ...attendee, cost: e.target.value };
                }
                return attendee;
            })
        }));

        console.log("check to see if any pricing changes were made");
        setCheckShowPriceChanges(true);
    }

    function handleSaveActivityCostsAssignment(event){

        props.updateActivityDetails(eventActivity);

        setShowPriceChangesUpdateButton(false);

    }


    function handleBookedThroughChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            booked_through: _newValue
        }));
    }
    function handleConfirmationNumberChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            confirmation_number: _newValue
        }));
    }
    function handleReservationNameChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            reservation_name: _newValue
        }));
    }
    function handleReservationNumberChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            reservation_number: _newValue
        }));
    }
    function handleNotesChange(event){
        const _newValue = event.target.value;
        setEventActivity(prevState => ({
            ...prevState,
            notes: _newValue
        }));
    }

    const handleFooterItemClick = (_item) => {
        setSelectedAction(_item);
        activityTabSelected === _item ? setActivityTabSelected("") : setActivityTabSelected(_item);
    };

    const [openDialogDateRange, setOpenDialogDateRange] = useState(false);
    function handleDialogDateRangeClose() {
        setOpenDialogDateRange(false);
    }
    function handleDialogDateRangeOK(){
        setOpenDialogDateRange(false);
    }


    const calculateElapsedTime = (startDate, startTime, endDate, endTime) => {
        // Use 'YYYY-MM-DD' format for dates and 'h:mm A' for times
        const start = dayjs(`${startDate} ${startTime}`, 'YYYY-MM-DD h:mm A');
        const end = dayjs(`${endDate} ${endTime}`, 'YYYY-MM-DD h:mm A');

        // Check if the start and end times are valid
        if (!start.isValid() || !end.isValid()) {
            return 'Invalid time format';
        }

        const totalDays = end.diff(start, 'day');  // Get total number of days between start and end
        const elapsed = dayjs.duration(end.diff(start));

        const hours = elapsed.hours();
        const minutes = elapsed.minutes();

        const elapsedTimeComponents = [];
        if (totalDays > 0) elapsedTimeComponents.push(`${totalDays} d`);
        if (hours > 0) elapsedTimeComponents.push(`${hours} h`);
        if (minutes > 0) elapsedTimeComponents.push(`${minutes} m`);

        return elapsedTimeComponents.join(' ') || '0 m';  // If no time difference, return '0m'
    };





    const [anchorActivityListItemMenu, setAnchorActivityListItemMenu] = useState(null);
    const handleActivityListItemMenuOpenMenu = (event) => {
        setAnchorActivityListItemMenu(event.currentTarget);
    };
    const handleActivityListItemMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorActivityListItemMenu(event.currentTarget);

    };
    const handleActivityListItemMenuClose = () => {
        setAnchorActivityListItemMenu(null);
    };
    
    
    
    
    return (


        <div>

            <Card
                sx={{
                    borderRadius: '5px',
                    border: '1px solid #f09637'
                }}
            >

                {/* add any photos if they exist */}
                {
                    eventActivity['photos'] && eventActivity['photos'].length >= 0 ? (
                        <div className="activities-suggestions-add-list-item-photos">
                            {
                                eventActivity['photos'].map((photo, index) => (
                                    <CardMedia
                                        key={index}
                                        component="img"
                                        alt={`Attribution ${index + 1}`}
                                        image={photo} // Use the attribution as the image source
                                    />
                                ))
                            }
                        </div>
                    ) : (
                        <></>
                    )
                }

                <CardHeader
                    title={
                        (() => {
                            const { time_table } = eventActivity;

                            if (time_table?.dates?.length > 0) {
                                const startDate = dayjs(time_table.dates[0].date, "YYYY-MM-DD").format('dddd');
                                const endDate = time_table.dates.length > 1
                                    ? dayjs(time_table.dates[time_table.dates.length - 1].date, "YYYY-MM-DD").format('ddd')
                                    : startDate; // If only one date, use startDate for both
                                return time_table.dates.length > 1 ? `${startDate} - ${endDate}` : startDate;

                            } else if (time_table?.days?.length > 0) {
                                const startDay = Object.keys(time_table.days[0])[0]; // Get the first day key
                                const endDay = time_table.days.length > 1
                                    ? Object.keys(time_table.days[time_table.days.length - 1])[0]
                                    : startDay; // If only one day, use startDay for both
                                return time_table.days.length > 1 ? `${startDay} - ${endDay}` : startDay;
                            }

                            return ''; // Return an empty string if no dates or days are available
                        })()
                    }
                    action={
                        <IconButton
                            aria-label="settings"
                            onClick={handleActivityListItemMenuOpenMenu}
                        >
                            <i
                                className="icon-menu2"
                                style={{fontSize: '16px'}}
                            />
                        </IconButton>
                    }
                    sx={{paddingBottom:'0'}}
                    titleTypographyProps={{ fontSize: '18px' }}
                />
                <Menu
                    sx={{ width: 320, maxWidth: '100%' }}
                    anchorEl={anchorActivityListItemMenu}
                    id="activity-item-menu"
                    open={Boolean(anchorActivityListItemMenu)}
                    onClose={handleActivityListItemMenuClose}

                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuList
                        dense
                        sx={{ width: 320, maxWidth: '100%' }}
                    >
                        <MenuItem
                            data-id="delete"
                            onClick={() => handleFooterItemClick('Delete')}
                        >
                            <ListItemIcon sx={{color:'#F00'}}>
                                <i className="icon-trash" title="Delete"/>
                            </ListItemIcon>
                            <ListItemText>Delete</ListItemText>
                        </MenuItem>
                    </MenuList>
                </Menu>

                {/* Dates (beneath CardHeader) */}
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: '0 16px 16px 16px' }}>
                    {/* Left-aligned: Dates */}
                    <Typography variant="body2" color="textSecondary">
                        {(() => {
                            const { time_table } = eventActivity;

                            if (time_table?.dates?.length > 0) {
                                // Extract the first and last date from time_table.dates
                                const beginDate = dayjs(time_table.dates[0].date, "YYYY-MM-DD");
                                const endDate = dayjs(time_table.dates[time_table.dates.length - 1].date, "YYYY-MM-DD");

                                // Format the dates, e.g., "Aug 3 - Aug 6"
                                const formattedBeginDate = beginDate.format('MMM D');
                                const formattedEndDate = endDate.isSame(beginDate, 'day') ? '' : ` - ${endDate.format('MMM D')}`;

                                return `${formattedBeginDate}${formattedEndDate}`;

                            } else if (time_table?.days?.length > 0) {
                                // If dates don't exist, show days from time_table.days
                                const startDay = Object.keys(time_table.days[0])[0];
                                const endDay = time_table.days.length > 1
                                    ? Object.keys(time_table.days[time_table.days.length - 1])[0]
                                    : startDay;

                                return startDay === endDay ? startDay : `${startDay} - ${endDay}`;
                            }

                            return ''; // If no valid dates or days
                        })()}
                    </Typography>

                    {/* Right-aligned: Activity total time */}
                    <Typography variant="body2" color="primary" sx={{ textAlign: 'right' }}>
                        {(() => {
                            const { time_table } = eventActivity;

                            if (time_table?.dates?.length > 0) {
                                const firstEntry = time_table.dates[0];
                                const lastEntry = time_table.dates[time_table.dates.length - 1];

                                // If all-day is true, return 'All Day'
                                if (firstEntry['all-day']) {
                                    return 'All Day';
                                }

                                // Calculate the total time using the calculateElapsedTime function
                                return calculateElapsedTime(
                                    firstEntry.date,
                                    firstEntry['begin_time'],
                                    lastEntry.date,
                                    lastEntry['end_time']
                                );
                            } else if (time_table?.days?.length > 0) {
                                // If no dates, calculate based on days (fallback to 'All Day' for simplicity here)
                                const firstDayEntry = time_table.days[0];
                                if (firstDayEntry['all-day']) {
                                    return 'All Day';
                                }

                                // If no specific all-day flag, return the number of days as fallback
                                const totalDays = time_table.days.length;
                                return totalDays === 1 ? '1 Day' : `${totalDays} Days`;
                            }

                            return ''; // If no valid dates or days
                        })()}
                    </Typography>

                </Box>

                {/* show the Activity timeline */}
                <CardContent sx={{padding:0}}>

                    <Timeline
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                            margin: 0
                        }}
                    >
                        <TimelineItem sx={{marginTop:0}}>
                            <TimelineSeparator>
                                {
                                    eventActivity.time_table?.dates?.length === 0 && eventActivity.time_table?.days?.length === 0 ? (
                                        <TimelineDot color="primary" />
                                    ) : (
                                        eventActivity.time_table?.dates?.[0]?.date !== "" && !eventActivity.time_table?.dates?.[0]?.['all-day']
                                        || eventActivity.time_table?.days?.length > 0  && !eventActivity.time_table?.days?.[0]?.['all-day'] ? (
                                            <TimelineDot
                                                sx={{
                                                    border: '1px solid #cbe0f7',
                                                    borderRadius: '5px',
                                                    padding: 0,
                                                    bgcolor: 'transparent',
                                                    color: '#151269'
                                                }}
                                            >
                                                <CustomTimelineDot
                                                    beginTime={
                                                        eventActivity.time_table?.dates?.[0]?.begin_time || // Check dates first
                                                        eventActivity.time_table?.days?.[0]?.['begin_time'] || // Fallback to days
                                                        null // If none, return null (which will result in an empty dot)
                                                    }
                                                />

                                            </TimelineDot>
                                        ) : (
                                            <TimelineDot color="primary" />
                                        )
                                    )
                                }
                                <TimelineConnector sx={{ bgcolor: '#cbe0f7' }}/>
                            </TimelineSeparator>

                            <TimelineContent sx={{py: '12px', px: 2}}>
                                <Typography sx={{ mb: 1.5}} variant="body2">
                                    {eventActivity['title']}
                                </Typography>
                                <Typography sx={{ fontSize:'12px' }} color="text.secondary">
                                    {eventActivity['location'].name}
                                </Typography>
                                <Typography sx={{ fontSize:'12px' }} color="text.secondary">
                                    {eventActivity['location'].address}
                                </Typography>
                                <Typography sx={{ mb: 1.5, fontSize:'12px' }} color="text.secondary">
                                    {eventActivity['location'].city} {eventActivity['location'].state} {eventActivity['location'].zip}
                                </Typography>
                            </TimelineContent>

                        </TimelineItem>

                        <TimelineItem>
                            <TimelineSeparator>
                                {
                                    eventActivity.time_table?.dates?.length === 0 && eventActivity.time_table?.days?.length === 0 ? (
                                        <TimelineDot color="primary" />
                                    ) : (
                                        eventActivity.time_table?.dates?.[0]?.date !== "" && !eventActivity.time_table?.dates?.[0]?.['all-day']
                                        || eventActivity.time_table?.days?.length > 0  && !eventActivity.time_table?.days?.[0]?.['all-day'] ? (
                                            <TimelineDot
                                                sx={{
                                                    border: '1px solid #cbe0f7',
                                                    borderRadius: '5px',
                                                    padding: 0,
                                                    bgcolor: 'transparent',
                                                    color: '#151269'
                                                }}
                                            >
                                                <CustomTimelineDot
                                                    beginTime={
                                                        eventActivity.time_table?.dates?.[0]?.end_time || // Check dates first
                                                        eventActivity.time_table?.days?.[0]?.['end_time'] || // Fallback to days
                                                        null // If none, return null (which will result in an empty dot)
                                                    }
                                                />
                                            </TimelineDot>
                                        ) : (
                                            <TimelineDot color="primary" />
                                        )
                                    )
                                }
                            </TimelineSeparator>
                            <TimelineContent sx={{py: '12px', px: 2}}>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>

                </CardContent>

                <CardActions>
                    <div className="activities-suggestions-add-list-item-footer-container">
                        {/* add the activity date */}
                        <div
                            style={{backgroundColor: selectedAction === "Dates" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-dates-container"
                            onClick={() => handleFooterItemClick('Dates')}
                        >
                            <i className="icon-calendar3" title="Dates"/>
                        </div>
                        {/* add the activity location */}
                        <div
                            style={{backgroundColor: selectedAction === "Location" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-location-container"
                            onClick={() => handleFooterItemClick('Location')}
                        >
                            <i className="icon-map-marker" title="Location"/>
                        </div>
                        {/* add the activity participants */}
                        <div
                            style={{backgroundColor: selectedAction === "Participants" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-occupants-container"
                            onClick={() => handleFooterItemClick('Participants')}
                        >
                            <i className="icon-user-24" title="Participants"/>
                        </div>
                        {/* add the activity costs */}
                        <div
                            style={{backgroundColor: selectedAction === "Costs" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-costs-container"
                            onClick={()=>handleFooterItemClick('Costs')}
                        >
                            <i className="icon-wallet"  title="Costs"/>
                        </div>
                        {/* add the activity details */}
                        <div
                            style={{backgroundColor: selectedAction === "Details" ? '#EDEDED' : 'initial'}}
                            className="activities-suggestions-add-list-item-footer-details-container"
                            onClick={()=>handleFooterItemClick('Details')}
                        >
                            <Badge
                                badgeContent={0}
                                color="badge"
                            >
                                <i className="icon-settings-icons"  title="Details"/>
                            </Badge>
                        </div>
                    </div>
                </CardActions>

                <CardContent sx={{padding:'20px 0', display:activityTabSelected?'block':'none'}}>
                    {
                        selectedAction === "Dates" ? (
                            <div className="activities-suggestions-add-list-item-footer-dates-holder">
                                <div
                                    className="activities-suggestions-add-list-item-footer-dates-checkin"
                                >
                                    Begin Date :&nbsp;
                                    {
                                        dayjs(eventActivity['begin_date'], 'MM/DD/YYYY').format('D MMM, YYYY')
                                    }
                                    <Chip
                                        sx={editChipStyle}
                                        label={showCalendarSelectBeginDate?'Done':'Edit'}
                                        size="small"
                                        onClick={handlePromptShowActivityBeginDateCalendar}
                                    />
                                </div>
                                <div
                                    className="activities-suggestions-add-list-item-footer-dates-checkin-input"
                                    style={{display: showCalendarSelectBeginDate ? 'block' : 'none'}}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        label="Begin Date"
                                        value={dayjs(eventActivity['begin_date'], 'MM/DD/YYYY')}
                                        onChange={handleBeginDateChange}
                                    />
                                </div>

                                {/* for the begin time */}
                                <div
                                    className="activities-suggestions-add-list-item-footer-times-begin"
                                >
                                    Time :&nbsp;
                                    {
                                        eventActivity['begin_time']
                                    }
                                    <Chip
                                        sx={editChipStyle}
                                        label={showClockSelectBeginTime?'Done':'Edit'}
                                        size="small"
                                        onClick={handlePromptShowActivityBeginTime}
                                    />
                                </div>
                                <div
                                    className="activities-suggestions-add-list-item-footer-times-begin-input"
                                    style={{display: showClockSelectBeginTime ? 'block' : 'none'}}>
                                    <TimePicker
                                        sx={{ width: '100%' }}
                                        label="Begin Time"
                                        value={dayjs(eventActivity['begin_time'], 'h:mm A')}
                                        onChange={handleBeginTimeChange}
                                    />
                                </div>


                                <div
                                    className="activities-suggestions-add-list-item-footer-dates-checkout"
                                >
                                    End Date :&nbsp;
                                    {
                                        dayjs(eventActivity['end_date'], 'MM/DD/YYYY').format('D MMM, YYYY')
                                    }
                                    <Chip
                                        sx={editChipStyle}
                                        label={showCalendarSelectEndDate?'Done':'Edit'}
                                        size="small"
                                        onClick={handlePromptShowActivityEndDateCalendar}
                                    />
                                </div>
                                <div
                                    className="activities-suggestions-add-list-item-footer-dates-checkout-input"
                                    style={{display: showCalendarSelectEndDate ? 'block' : 'none'}}>
                                    <DatePicker
                                        sx={{ width: '100%' }}
                                        label="End Date"
                                        value={dayjs(eventActivity['end_date'], 'MM/DD/YYYY')}
                                        minDate={dayjs(eventActivity['begin_date'], 'MM/DD/YYYY')}
                                        onChange={handleEndDateChange}
                                    />
                                </div>

                                {/* for the end time */}
                                <div
                                    className="activities-suggestions-add-list-item-footer-times-end"
                                >
                                    Time :&nbsp;
                                    {
                                        eventActivity['end_time']
                                    }
                                    <Chip
                                        sx={editChipStyle}
                                        label={showClockSelectEndTime?'Done':'Edit'}
                                        size="small"
                                        onClick={handlePromptShowActivityEndTime}
                                    />
                                </div>
                                <div
                                    className="activities-suggestions-add-list-item-footer-times-end-input"
                                    style={{display: showClockSelectBeginTime ? 'block' : 'none'}}>
                                    <TimePicker
                                        sx={{ width: '100%' }}
                                        label="End Time"
                                        value={dayjs(eventActivity['end_time'], 'h:mm A')}
                                        onChange={handleEndTimeChange}
                                    />
                                </div>


                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Location" ? (
                            <div className="activities-suggestions-add-list-item-footer-location-holder">
                                <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                    <Grid item style={{ flex: '1' }}>
                                        <Grid container spacing={2} direction="column">
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_name"
                                                    label="Name"
                                                    value={eventActivity['location'].name}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_address"
                                                    label="Address"
                                                    value={eventActivity['location'].address}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_city"
                                                    label="City"
                                                    value={eventActivity['location'].city}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_state"
                                                    label="State"
                                                    value={eventActivity['location'].state}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_zip"
                                                    label="Zip"
                                                    value={eventActivity['location'].zip}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_country"
                                                    label="Country"
                                                    value={eventActivity['location'].country}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    fullWidth
                                                    id="new_activity_location_phone"
                                                    label="Phone"
                                                    value={eventActivity['location'].phone}
                                                    onChange={handleBookedThroughChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : (
                            <></>
                        )
                    }

                    {
                        selectedAction === "Participants" && (
                            Attendees.length > 0 ? (
                                <List dense sx={{ width: '100%' }} >
                                    {/* Filter out activity attendees and sort by name */}
                                    {Attendees.filter(_attendee => Planning['attendees'].some(_att => _att.id === _attendee.id && _att.status !== 4))
                                        .sort((a, b) => `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(`${formatName(b.lname)}, ${formatName(b.fname)}`))
                                        .map((_attendee, index) => (
                                            <div key={index}>
                                                <ListItem
                                                    secondaryAction={
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={handleParticipantsToggle(_attendee.id)}
                                                            checked={eventActivity['attendees'].some(_att => _att.id === _attendee.id)}
                                                            inputProps={{ 'aria-labelledby': index }}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            id={index}
                                                            primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        ))
                                    }

                                    <Box mt={6} mb={1}>
                                        <Divider />
                                    </Box>

                                    {/* Filter out non-activity attendees and sort by name */}
                                    {Attendees.filter(_attendee => !Planning['attendees'].some(_att => (_att.id === _attendee.id && _att.status !== 4)))
                                        .sort((a, b) => `${formatName(a.lname)}, ${formatName(a.fname)}`.localeCompare(`${formatName(b.lname)}, ${formatName(b.fname)}`))
                                        .map((_attendee, index) => (
                                            <div key={index}>
                                                <ListItem
                                                    secondaryAction={
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={handleParticipantsToggle(_attendee.id)}
                                                            checked={eventActivity['attendees'].some(_att => _att.id === _attendee.id)}
                                                            inputProps={{ 'aria-labelledby': index }}
                                                        />
                                                    }
                                                    disablePadding
                                                >
                                                    <ListItemButton>
                                                        <ListItemAvatar>
                                                            <Avatar {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            id={index}
                                                            primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        ))
                                    }
                                </List>
                            ) : (
                                <div style={{ fontSize: '12px', textAlign: 'center' }}>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                        <i className="icon-warning" style={{ color: '#FF0000' }} />
                                        Add Attendees to Event first
                                    </Typography>
                                </div>
                            )
                        )
                    }

                    {
                        selectedAction === "Costs" ? (
                            <div className="activities-suggestions-add-list-item-footer-costs-holder">
                                {/* Cost FlightSummary */}
                                <Typography variant="body2">
                                    Cost Summary
                                </Typography>
                                <div
                                    className="activities-suggestions-add-list-item-footer-costs-total-holder"
                                >
                                    <div style={{cursor:'pointer'}} onClick={toggleShowOverride}>
                                        <Chip
                                            sx={editChipStyle}
                                            label={showPriceOverride?'Done':'Edit'}
                                            size="small"
                                        />
                                    </div>
                                    <div className="activities-suggestions-add-list-item-footer-costs-total">
                                        Total:&nbsp;
                                        {parseFloat(eventActivity['amount_total']).toFixed(2)}
                                        <IconButton
                                            sx={{fontSize: '12px', color:'initial'}}
                                            aria-label="more"
                                            id="long-button"
                                            aria-controls={openChangeCurrency ? 'long-menu' : undefined}
                                            aria-expanded={openChangeCurrency ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleOpenChangeCurrency}
                                        >
                                            {eventActivity['currency']}
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorChangeCurrencyEl}
                                            open={openChangeCurrency}
                                            onClose={handleChangeCurrencyClose}
                                        >
                                            {currencyList.map((option) => (
                                                <MenuItem sx={{fontSize:'12px'}} key={option.code} selected={option.code === eventActivity['currency']} onClick={(e)=>handleChangeCurrencyClick(e, option.code)}>
                                                    {option.code} : {option.name}
                                                </MenuItem>
                                            ))}
                                        </Menu>

                                    </div>
                                </div>
                                <div
                                    className="activities-suggestions-add-list-item-footer-costs-override-holder"
                                    style={{display:showPriceOverride?'block':'none'}}
                                >
                                    <div
                                        className="activities-suggestions-add-list-item-footer-costs-override-input-holder"
                                    >
                                        <TextField
                                            type="number"
                                            label="Activity Total"
                                            value={eventActivity["amount_total"]}
                                            onChange={handleCostOverride}
                                        />
                                    </div>
                                </div>

                                <Divider />

                                {/* _new_activity is the Assignment area */}
                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-holder" >
                                    <Typography variant="body2">
                                        Cost Assignment
                                    </Typography>
                                    {
                                        Planning.attendees.length === 0 ? (
                                            <div
                                                className="activities-suggestions-add-list-item-footer-cost-assignment-no-attendees"
                                            >
                                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                    <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                </Typography>
                                            </div>
                                        ) : (

                                            <div className="activities-suggestions-add-list-item-footer-cost-assignment-holder">
                                                {/* _new_activity is the toggle section */}
                                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-toggle-holder">
                                                    <ButtonGroup variant="text" aria-label="outlined button group">
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: eventActivity['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Divide Evenly
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                fontSize: '11px',
                                                                backgroundColor: !eventActivity['assign_equal']
                                                                    ? 'rgba(198, 226, 255, 0.8)'
                                                                    : 'initial'
                                                            }}
                                                            onClick={handleToggleAssignEqual}
                                                        >
                                                            Custom
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>

                                                {/* _new_activity is the attendee section */}
                                                <List dense sx={{ width: '100%'}} >
                                                    {
                                                        eventActivity['attendees']
                                                            .sort((a, b) => {
                                                                const _a = Attendees.find((_att)=>_att.id === a.id);
                                                                const _b = Attendees.find((_att)=>_att.id === b.id);
                                                                return _a.lname.localeCompare(_b.lname);
                                                            })
                                                            .map((_attendee, index) => (
                                                                <div
                                                                    className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder"
                                                                    key={index}
                                                                >
                                                                    <ListItem
                                                                        className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                        secondaryAction={
                                                                            eventActivity['assign_equal'] ? (
                                                                                <span className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-cost">
                                                                                {_attendee.cost}&nbsp;{eventActivity['currency']}
                                                                            </span>
                                                                            ) : (
                                                                                <span className="activities-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost">
                                                                                <TextField
                                                                                    label={
                                                                                        formatName(Attendees.find((_att)=>_att.id === _attendee.id).lname)
                                                                                        + ', ' +
                                                                                        formatName(Attendees.find((_att)=>_att.id === _attendee.id).fname)
                                                                                    }
                                                                                    value={_attendee.cost}
                                                                                    onChange={(e)=>handleCustomOccupantCost(_attendee.id, e)}
                                                                                    variant="standard"
                                                                                />
                                                                            </span>
                                                                            )
                                                                        }
                                                                        disablePadding
                                                                    >
                                                                        <ListItemAvatar>
                                                                            {Attendees.find((_att) => _att.id === _attendee.id) && ( // Check if the Attendee is found
                                                                                <Avatar {...stringAvatar(`${formatName(Attendees.find((_att) => _att.id === _attendee.id).fname)}, ${formatName(Attendees.find((_att) => _att.id === _attendee.id).lname)}`)} />
                                                                            )}
                                                                        </ListItemAvatar>

                                                                        <ListItemText
                                                                            sx={{ display: eventActivity['assign_equal'] ? 'block' : 'none' }}
                                                                            id={index}
                                                                            primary={Attendees.find((_att) => _att.id === _attendee.id) ? // Check if Attendee is found
                                                                                `${formatName(Attendees.find((_att) => _att.id === _attendee.id).lname)}, ${formatName(Attendees.find((_att) => _att.id === _attendee.id).fname)}`
                                                                                : '' // Return empty string if Attendee is not found
                                                                            }
                                                                        />

                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>

                                                            ))
                                                    }
                                                </List>

                                                {/* show the unassigned */}
                                                <div className="activities-suggestions-add-list-item-footer-cost-assignment-unassigned-holder">
                                                    <Typography sx={{ mb: 1.5, flex:'1' }}  variant="body2">
                                                        Unassigned Amount
                                                    </Typography>
                                                    <span className="activities-suggestions-add-list-item-footer-cost-assignment-unassigned-amount">
                                                    {
                                                        (parseFloat(eventActivity['amount_total']) - eventActivity['attendees'].reduce((total, attendee) => {
                                                            const cost = parseFloat(attendee['cost']);
                                                            // If cost is NaN (including when it's an empty string), use 0 instead
                                                            return total + (isNaN(cost) ? 0 : cost);
                                                        }, 0)).toFixed(2)
                                                    }
                                                        &nbsp;
                                                        {
                                                            eventActivity['currency']
                                                        }
                                                    </span>

                                                </div>

                                                {/* show the update button if changes were made */}

                                                {
                                                    showPriceChangesUpdateButton ? (
                                                        <div style={{cursor: 'pointer'}} onClick={handleSaveActivityCostsAssignment}>
                                                            <Chip
                                                                sx={addChipStyle}
                                                                label='Save Changes'
                                                                size="small"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }


                                            </div>

                                        )
                                    }

                                </div>

                            </div>
                        ) : (
                            <></>
                        )
                    }
                    {
                        selectedAction === "Details" ? (
                            <Grid container spacing={2} direction="column" sx={{ marginTop: '0' }}>
                                <Grid item style={{ flex: '1' }}>
                                    <Grid container spacing={2} direction="column">
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_booked_through"
                                                label="Booked Through"
                                                value={eventActivity['booked_through']}
                                                onChange={handleBookedThroughChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_confirmation_number"
                                                label="Confirmation Number"
                                                value={eventActivity['confirmation_number']}
                                                onChange={handleConfirmationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_name"
                                                label="Reservation Name"
                                                value={eventActivity['reservation_name']}
                                                onChange={handleReservationNameChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_reservation_number"
                                                label="Reservation Number"
                                                value={eventActivity['reservation_number']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_website"
                                                label="Website"
                                                value={eventActivity['website']}
                                                onChange={handleReservationNumberChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_bring"
                                                label="Bring"
                                                multiline
                                                rows={4}
                                                value={eventActivity['bring']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_details"
                                                label="Details"
                                                multiline
                                                rows={4}
                                                value={eventActivity['details']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                fullWidth
                                                id="new_activity_notes"
                                                label="Notes"
                                                multiline
                                                rows={4}
                                                value={eventActivity['notes']}
                                                onChange={handleNotesChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ) : (
                            <></>
                        )
                    }
                </CardContent>

            </Card>

            <Dialog
                open={openDialogDateRange}
                onClose={handleDialogDateRangeClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        The date for the Activity is outside of the date range for the Event.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDateRangeOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    )
}

export default ActivitiesCard;
