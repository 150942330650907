import PAAttendee from "./Attendee";
import TimeTable from "./TimeTable";

class Flight {
    constructor(){

        const _temp = PAAttendee.generateRandomString(10);
        this.temp_id = _temp;
        this.id = _temp;

        this.time_table = new TimeTable();

        this.flight_group_id = 0;
        this.ident = "";
        this.actual_ident = "";
        this.flight_number = "";

        this.airline_code = "";
        this.airline_name = "";

        this.flight_duration = "";

        this.depart_airport_code = "";
        this.depart_airport_name = "";
        this.depart_airport_city = "";
        this.depart_airport_latitude = 0;
        this.depart_airport_longitude = 0;
        this.depart_timestamp = 0;
        this.depart_timezone = "";
        this.depart_timezone_offset = 0;

        this.arrive_airport_code = "";
        this.arrive_airport_name = "";
        this.arrive_airport_city = "";
        this.arrive_airport_latitude = 0;
        this.arrive_airport_longitude = 0;
        this.arrive_timestamp = 0;
        this.arrive_timezone = "";
        this.arrive_timezone_offset = 0;

        this.day = "";

        this.attendees = [];

        this.amount_total = 0.00;
        this.currency = "USD";

        this.price_watch = false;
        this.price_history = [];

        this.Duffel = [];

        this.notes = "";
        this.status = 0;
    }

}

export default Flight;
