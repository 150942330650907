import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Avatar,
    Button,
    Card, CardActions, CardContent,
    CardHeader,
    Dialog,
    DialogContent,
    DialogTitle, FormControl,
    InputAdornment, InputLabel, Menu, MenuItem,
    OutlinedInput, Select, TextField,
    Tooltip
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import {capitalizeFirstLetter, formatName, stringAvatar, stringToColor} from "../Helpers/TextStyling";
import dayjs from "dayjs";
import {PAContext} from "../../Services/PAContext";
import Papa from "papaparse";
import * as XLSX from 'xlsx';
import {DataGrid} from "@mui/x-data-grid";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import country_codes from "../../sampleData/PhonePrefixes";



function MasterAttendees({dialogRefAttendees, isAttendeesDialogOpen, closeAttendeesDialog, isEventDialogOpen}){

    const { dataArray } = useContext(PAContext);
    const {Attendees } = dataArray;


    const [sortedAttendees, setSortedAttendees] = useState(Attendees);
    const [sortOrderAttendees, setSortOrderAttendees] = useState('asc');

    useEffect(() => {
        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = (a.lname || '').toLowerCase();
            const lnameB = (b.lname || '').toLowerCase();

            if (lnameA < lnameB) return -1;
            if (lnameA > lnameB) return 1;
            return 0;
        });
        setSortedAttendees(sortedAttendees);
    }, [Attendees]);

    const [searchTerm, setSearchTerm] = useState(''); // Unified search term
    const handleSearchChange = (event) => {
        setIsUploadMode(false);
        console.log("Search term: " + event.target.value);
        setSearchTerm(event.target.value); // Update search term
    };
    useEffect(() => {
        console.log("Search term changed");

        const normalizedSearchTerm = searchTerm.toLowerCase();

        const filteredAttendees = Attendees.filter((att) => {
            return att.fname.toLowerCase().startsWith(normalizedSearchTerm) ||
                att.lname.toLowerCase().startsWith(normalizedSearchTerm);
        });

        setSortedAttendees(filteredAttendees);

    }, [searchTerm]);


    const handleAttendeeSort = () => {
        setIsUploadMode(false);

        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = a.lname.toLowerCase();
            const lnameB = b.lname.toLowerCase();
            const fnameA = a.fname.toLowerCase();
            const fnameB = b.fname.toLowerCase();

            if (sortOrderAttendees === 'asc') {
                if (lnameA < lnameB) return -1;
                if (lnameA > lnameB) return 1;
                // If last names are equal, compare first names
                if (fnameA < fnameB) return -1;
                if (fnameA > fnameB) return 1;
                return 0;
            } else {
                if (lnameA > lnameB) return -1;
                if (lnameA < lnameB) return 1;
                // If last names are equal, compare first names
                if (fnameA > fnameB) return -1;
                if (fnameA < fnameB) return 1;
                return 0;
            }
        });

        setSortedAttendees(sortedAttendees);
        setSortOrderAttendees((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    };
    const handleAttendeeReset = () => {
        setIsUploadMode(false);
        const sortedAttendees = [...Attendees].sort((a, b) => {
            const lnameA = a.lname.toLowerCase();
            const lnameB = b.lname.toLowerCase();

            if (lnameA < lnameB) return -1;
            if (lnameA > lnameB) return 1;
            return 0;
        });
        setSortedAttendees(sortedAttendees);
    }


    const [isUploadMode, setIsUploadMode] = useState(false);
    const fileInputRef = useRef(null);
    const [originalFileData, setOriginalFileData] = useState([]);
    const [fileDataWithIds, setFileDataWithIds] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedEntries, setSelectedEntries] = useState([]); // To store the selected entries from file
    const [duplicateEmails, setDuplicateEmails] = useState([]);

    const handleUploadClick = () => {
        if (!isUploadMode){
            //they are toggling ON; clear everything
            handleAttendeeFileReset();
        }

        setIsUploadMode(!isUploadMode);
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setSelectedFile(file);

        const reader = new FileReader();
        const fileType = file.name.split('.').pop().toLowerCase();

        if (fileType === 'csv') {
            // Handle CSV Parsing
            reader.onload = (e) => {
                const text = e.target.result;
                const parsedData = Papa.parse(text, { header: true });

                /*
                    if there isn't a column labeled as id then we need to male one and
                    populate it with just numbered data
                 */
                const dataWithIds = parsedData.data
                    .filter(row => Object.values(row).some(value => value !== '' && value !== null && value !== undefined)) // Filter out empty rows
                    .map((row, index) => {
                        const idValue = row.id || row.ID || row.Id || index; // Use existing id if it exists, otherwise use index
                        const displayId = index + 1;
                        return {
                            id: idValue,  // Ensure the `id` field is always present
                            displayId,
                            ...row  // Spread the rest of the row's data
                        };
                    });

                setFileDataWithIds(dataWithIds);

                setOriginalFileData(parsedData.data);  // Store the original data
                setColumns(Object.keys(parsedData.data[0]).map((key) => ({ field: key, headerName: key, width: 150 })));
            };
            reader.readAsText(file);
        } else if (fileType === 'xls' || fileType === 'xlsx') {
            // Handle XLS/X Parsing
            reader.onload = (e) => {
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });

                // First row is header
                const headers = sheet[0];
                const rows = sheet.slice(1).map((row) => {
                    const rowData = {};
                    headers.forEach((header, index) => {
                        rowData[header] = row[index];
                    });
                    return rowData;
                });

                const dataWithIds = rows
                    .filter(row => Object.values(row).some(value => value !== '' && value !== null && value !== undefined)) // Filter out empty rows
                    .map((row, index) => {
                        const idValue = row.id || row.ID || row.Id || index; // Use existing id if it exists, otherwise use index
                        const displayId = index + 1;
                        return {
                            id: idValue,  // Ensure the `id` field is always present
                            displayId,
                            ...row  // Spread the rest of the row's data
                        };
                    });

                setFileDataWithIds(dataWithIds);
                setOriginalFileData(rows);  // Store the original data
                setColumns(headers.map((header) => ({ field: header, headerName: header, width: 150 })));

            };
            reader.readAsArrayBuffer(file);
        } else {
            alert('Unsupported file format. Please upload a CSV or Excel file.');
        }
    };
    const highlightDuplicates = (duplicates) => {
        const updatedRows = fileDataWithIds.map((row) => {
            // Dynamically find the email field (case-insensitive check for 'email')
            const emailKey = Object.keys(row).find(key => key.toLowerCase().includes('email'));
            return {
                ...row,
                isDuplicate: duplicates.includes(row[emailKey]?.toLowerCase()) // Mark duplicates
            };
        });

        console.log("updatedRows: "+JSON.stringify(updatedRows));

        // Sort: move duplicates to the top
        const sortedData = updatedRows.sort((a, b) => {
            if (a.isDuplicate && !b.isDuplicate) return -1;
            if (!a.isDuplicate && b.isDuplicate) return 1;
            return 0;
        });

        // Update the grid data with sorted and marked rows (this updates dataWithIds)
        setFileDataWithIds(sortedData);  // Update the state for DataGrid to re-render
    };
    const handleButtonSubmit = () => {
        console.log("selectedEntries : ", selectedEntries);

        // Extract emails from the selectedEntries (case-insensitive lookup for any variation of 'email')
        const selectedEmails = selectedEntries.map((row) => {
            // Find a key that contains 'email' (case-insensitive)
            const emailKey = Object.keys(row).find(key => key.toLowerCase().includes('email'));
            // Return the email value if found, otherwise return null
            return row[emailKey]?.toLowerCase() || null;
        }).filter(Boolean);  // Filter out any null values

        // Extract emails from the Attendees array
        const attendeeEmails = Attendees.map((attendee) => attendee.email?.toLowerCase());

        // Find duplicates between selected emails and Attendees' emails
        const duplicates = selectedEmails.filter((email) => attendeeEmails.includes(email));
        console.log("duplicates : ", duplicates);

        // Show the alert if duplicates are found and highlight them
        if (duplicates.length > 0) {
            setDuplicateEmails(duplicates);  // Display duplicates as an alert or some other UI
            highlightDuplicates(duplicates); // Highlight duplicates in the DataGrid
        } else {
            setDuplicateEmails([]);  // Clear duplicates
            // Proceed with the upload logic if no duplicates
            console.log('No duplicates found. Proceeding with submission...');
        }
    };
    const handleAttendeeFileReset = () => {

        // Reset the file input to remove any selected file
        setSelectedFile(null);

        // Clear file data states to reset the uploaded data
        setFileDataWithIds([]); // If using dataWithIds as a processed version
        setColumns([]);

        // Clear duplicate alerts or state flags related to duplicates
        setDuplicateEmails([]);
        setSelectedEntries([]);

        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Clear the displayed file name
        }

    }


    // Dynamically calculate the card width based on the dialog width
    const [dialogWidth, setDialogWidth] = useState(0);
    // Get the width of the Dialog once it's rendered
    useEffect(() => {
        if (isAttendeesDialogOpen && dialogRefAttendees.current) {
            const width = dialogRefAttendees.current.offsetWidth;
            console.log("&&&&&&&& setting dialogWidth : "+width);
            setDialogWidth(width);
        }
    }, [isEventDialogOpen]);
    const calculateCardWidth = () => {
        const cardsPerRow = 3;  // You can adjust the number of cards per row here
        const spacing = 16;  // Space between cards (as defined by Grid spacing)
        const totalSpacing = (cardsPerRow - 1) * spacing;
        const calculatedWidth = (dialogWidth - totalSpacing) / cardsPerRow;

        // Ensure the card width does not exceed 250px
        return Math.min(calculatedWidth, 250);
    };


    const [anchorAttendeeElements, setAnchorAttendeeElements] = useState(sortedAttendees.map(() => null));
    const handleOpenAttendeeMenu = (event, index) => {
        const updatedAnchors = [...anchorAttendeeElements];
        updatedAnchors[index] = event.currentTarget;
        setAnchorAttendeeElements(updatedAnchors);
    };

    // Function to handle closing the menu for a specific attendee.
    const handleCloseAttendeeMenu = (index) => {
        const updatedAnchors = [...anchorAttendeeElements];
        updatedAnchors[index] = null;
        setAnchorAttendeeElements(updatedAnchors);
    };


    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [attendeeToDeleteId, setAttendeeToDeleteId] = useState("");
    const [attendeeToDeleteName, setAttendeeToDeleteName] = useState("");

    function handleDialogDeleteOpen(index, attendee_id) {
        console.log("handleDialogDeleteOpen : "+attendee_id);

        handleCloseAttendeeMenu(index);

        setAttendeeToDeleteId(attendee_id);

        const foundAttendee = Attendees.find((_att) => _att.id === attendee_id);
        setAttendeeToDeleteName(foundAttendee.fname + ' ' + foundAttendee.lname);

        setOpenDialogDelete(true);

    }
    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }
    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

    }

    // Function to handle file input click on avatar
    const handleAvatarClick = (id) => {
        document.getElementById(`file-input-${id}`).click();
    };

    // Function to handle file change and set the image
    const handleImageChange = (event, id) => {
        const file = event.target.files[0];

        console.log("file : "+file);

        if (file) {
            const imageUrl = URL.createObjectURL(file); // Create a URL for the selected image


            // Optionally save the file to the attendee's profile
            setSortedAttendees(prevAttendees =>
                prevAttendees.map(attendee =>
                    attendee.id === id
                        ? { ...attendee, image: imageUrl } // Update the image for the matched attendee
                        : attendee
                )
            );
        }
    };


    const [activeSection, setActiveSection] = useState(null); // Keeps track of active section
    const [selectedAttendeeId, setSelectedAttendeeId] = useState(null);
    const handleEditAttendee = (attendeeId, index) => {

        const _selected_attendee = Attendees.find(attendee => attendee.id === attendeeId);

        console.log("attendee : "+JSON.stringify(_selected_attendee, null, '\t'));

        if (_selected_attendee){
            setGroupedAttendees([]); // Clear grouped attendees
            setSelectedAttendeeId(attendeeId); // Set the selected attendee's ID

            setFname(_selected_attendee.fname);
            setLname(_selected_attendee.lname);
            setNickname(_selected_attendee.nickname);

            setAddress(_selected_attendee.address);
            setCity(_selected_attendee.city);
            setState(_selected_attendee.state);
            setZip(_selected_attendee.zip);
            setCountry(_selected_attendee.country);

            setEmail(_selected_attendee.email);
            setPhonePrefix(_selected_attendee.phone_prefix);
            setPhone(_selected_attendee.phone);
            setDOB(_selected_attendee.dob);
            setLanguage(_selected_attendee.language);

            setActiveSection('edit'); // Set active section to 'edit'
            handleCloseAttendeeMenu(index); // Close the menu
        }

    };
    const handleSendEmail = (attendeeId, index) => {
        setGroupedAttendees([]); // Clear grouped attendees
        setSelectedAttendeeId(attendeeId); // Set the selected attendee's ID
        setActiveSection('email'); // Set active section to 'email'
        handleCloseAttendeeMenu(index); // Close the menu
    };
    const handleAddToEvent = (attendeeId, index) => {
        setGroupedAttendees([]); // Clear grouped attendees
        setSelectedAttendeeId(attendeeId); // Set the selected attendee's ID
        setActiveSection('add'); // Set active section to 'add'
        handleCloseAttendeeMenu(index); // Close the menu
    };
    const handleSendCoupon = (attendeeId, index) => {
        setGroupedAttendees([]); // Clear grouped attendees
        setSelectedAttendeeId(attendeeId); // Set the selected attendee's ID
        setActiveSection('coupon'); // Set active section to 'coupon'
        handleCloseAttendeeMenu(index); // Close the menu
    };
    function clearMenuSelections() {
        setGroupedAttendees([]);
        setSelectedAttendeeId(null);
        setActiveSection(null);
    }

    const [groupedAttendees, setGroupedAttendees] = useState([]); // State to hold grouped attendees
    useEffect(() => {
        if (groupedAttendees.length > 0) {
            setActiveSection('grouped');
        } else if (activeSection === 'grouped') {
            setActiveSection(null); // Clear active section if groupedAttendees is empty
        }
    }, [groupedAttendees]);
    // Toggle attendee in and out of the group array
    const handleGroupToggle = (attendeeId) => {
        setGroupedAttendees(prevGrouped =>
            prevGrouped.includes(attendeeId)
                ? prevGrouped.filter(id => id !== attendeeId) // Remove if already in group
                : [...prevGrouped, attendeeId] // Add if not in group
        );

        // Set activeSection to 'grouped' if there are any grouped attendees, else clear it
        setActiveSection(groupedAttendees.length > 0 ? 'grouped' : null);
        setSelectedAttendeeId(null);
    };


    //for editing Attendee info
    const [fname,setFname] = useState("");
    const [lname,setLname] = useState("");
    const [nickname,setNickname] = useState("");
    const [address,setAddress] = useState("");
    const [city,setCity] = useState("");
    const [state,setState] = useState("");
    const [zip,setZip] = useState("");
    const [country,setCountry] = useState("");
    const [email,setEmail] = useState("");
    const [phonePrefix,setPhonePrefix] = useState("");
    const [phone,setPhone] = useState("");
    const [dob,setDOB] = useState("");
    const [language,setLanguage] = useState("");
    const [preferredAirport,setPreferredAirport] = useState("");

    function handleFnameChange(event){
        const newValue = event.target.value;
        const capitalizedValue = newValue
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        setFname(capitalizedValue);
        setNickname(capitalizedValue);
    }
    function handleLnameChange(event){
        const newValue = event.target.value;
        const capitalizedValue = newValue
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        setLname(capitalizedValue);

    }
    function handleNicknameChange(event){
        const newValue = event.target.value;
        const capitalizedValue = newValue
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        setNickname(capitalizedValue);

    }

    function handleAddressChange(event){
        setAddress(event.target.value);
    }
    function handleCityChange(event){
        setCity(event.target.value);
    }
    function handleStateChange(event){
        setState(event.target.value);
    }
    function handleZipChange(event){
        setZip(event.target.value);
    }
    function handleCountryChange(event){
        setCountry(event.target.value);
    }

    function handleEmailChange(event){
        setEmail(event.target.value);
    }

    function handlePhonePrefixChange(event){
        console.log("setting phone prefix to : "+event.target.value);
        setPhonePrefix(event.target.value);
    }
    function handlePhoneChange(event){
        console.log("setting phone prefix to : "+event.target.value);

        if (phonePrefix === "+1"){
            //format for area code
            const input = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
            let formattedNumber = input;

            if (input.length >= 3) {
                formattedNumber = `(${input.slice(0, 3)})`;

                if (input.length > 3) {
                    formattedNumber += ` ${input.slice(3, 6)}`;

                    if (input.length > 6) {
                        formattedNumber += `-${input.slice(6, 10)}`;
                    }
                }
            }
            setPhone(formattedNumber);
        } else {
            setPhone(event.target.value);
        }

    }

    function handleDOBChange(event){
        setDOB(event.target.value);
    }
    function handleLanguageChange(event){
        setLanguage(event.target.value);
    }
    function handlePreferredAirportChange(event){
        setPreferredAirport(event.target.value);
    }


    const inputRef = useRef(null);
    useEffect(() => {

        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            console.log(JSON.stringify(place, null, '\t'));
            console.log(place.formatted_address);

            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            for (const component of place.address_components){
                if (component.types.includes("route")) {
                    _route_exists = true;
                } else if (component.types.includes("country")){
                    _country_code = component.short_name;
                }
            }

            place.address_components.forEach((component) => {
                if (component.types.includes("street_number")){
                    _address_line = component.long_name;
                } else if (component.types.includes("route")){
                    _address_line += " "+component.long_name;
                } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                    _address_line += " "+component.long_name;
                } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                    _address_city = component.long_name;
                } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                    _address_state = component.short_name;
                } else if (component.types.includes("postal_code")){
                    _address_zip = component.long_name;
                } else if (component.types.includes("country")){
                    if (_country_code !== "US"){
                        _address_state = component.long_name;
                    }
                    _address_country = component.short_name;
                }
            });

            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = place.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);

            setAddress(_address_line);
            setCity(_address_city);
            setState(_address_state);
            setZip(_address_zip);
            setCountry(_address_country);

        });

    }, []);



    return (
        <Dialog
            ref={dialogRefAttendees}
            open={isAttendeesDialogOpen}
            onClose={closeAttendeesDialog}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>{sortedAttendees.length} Attendee{sortedAttendees.length===1?'':'s'}</DialogTitle>

            {/* Combined Search Input Box */}
            <TextField
                sx={{
                    position: 'absolute',
                    right: 250, // Adjust as needed
                    top: 8,
                    width: '250px' // Adjust width to accommodate both names
                }}
                value={searchTerm}
                onChange={handleSearchChange} // Unified search change handler
                label="First or Last Name"
                type="search"
                variant="standard"
                size="small"
            />
            <Tooltip title="Sort">
                <IconButton
                    aria-label="Sort"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 200,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={handleAttendeeSort}
                >
                    <i
                        className="icon-sort-alpha-asc"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Bulk Upload">
                <IconButton
                    aria-label="Upload"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 150,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={handleUploadClick}
                >
                    <i
                        className="icon-upload"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Add New">
                <IconButton
                    aria-label="AddNew"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 100,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={closeAttendeesDialog}
                >
                    <i
                        className="icon-user-plus"
                        style={{
                            color: 'red',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Reset">
                <IconButton
                    aria-label="Reset"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 50,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={isUploadMode ? handleAttendeeFileReset : handleAttendeeReset}
                >
                    <i
                        className="icon-cw"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Tooltip title="Close">
                <IconButton
                    aria-label="Close"
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 0,
                        top: 8,
                        color: theme.palette.grey[700],
                        cursor: 'pointer',
                        fontSize: '18px',
                    })}
                    onClick={closeAttendeesDialog}
                >
                    <i
                        className="icon-cross"
                        style={{
                            color: 'green',
                            fontSize: '18px',
                            padding: '10px',
                            cursor: 'pointer'
                        }}
                    />
                </IconButton>
            </Tooltip>

            <DialogContent
                sx={{
                    display: 'flex',
                    gap: 2,
                    position: 'relative'
                }}
            >
                {isUploadMode ? (
                    <div>
                        <Typography variant="subtitle1">Select a file to upload:</Typography>
                        {/* Row with OutlinedInput, duplicateEmails, and Button */}
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginTop: 2, marginBottom: 2 }}>
                            {/* File Upload Input */}
                            <OutlinedInput
                                type="file"
                                inputRef={fileInputRef}
                                inputProps={{ accept: '.csv, .xls, .xlsx' }} // Accept CSV and Excel files
                                onChange={handleFileUpload}
                                sx={{ flexGrow: 1 }} // This will make the input box expand to fill the available space
                            />


                            {/* Submit Button */}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleButtonSubmit} // Button action when submitting
                                sx={{ whiteSpace: 'nowrap' }} // Ensure the button stays the same size
                            >
                                Submit
                            </Button>
                        </Box>

                        {/* Data Grid for uploaded data */}
                        {originalFileData.length > 0 && fileDataWithIds.length > 0 && (
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={fileDataWithIds}
                                    columns={
                                        fileDataWithIds[0]
                                            ? Object.keys(fileDataWithIds[0])
                                                .filter((header) => header !== 'id' && header !== 'isDuplicate')  // Exclude 'id' from being shown
                                                .map((header) => ({
                                                    field: header, // Display `displayId` but keep other columns intact
                                                    headerName: header === 'displayId' ? 'ID' : header, // Rename 'displayId' to 'ID'
                                                }))
                                            : [] // Provide an empty array if `fileDataWithIds[0]` is undefined
                                    }
                                    pageSize={5}
                                    getRowId={(row) => row.id}
                                    checkboxSelection // This enables checkboxes
                                    onRowSelectionModelChange={(newSelection) => {
                                        const selectedRows = newSelection.map(id => fileDataWithIds.find(row => row.id === id));
                                        setSelectedEntries(selectedRows);
                                    }}
                                    getRowClassName={(params) => (params.row.isDuplicate ? 'duplicate-row' : '')}
                                />
                            </div>
                        )}
                    </div>
                ) : (
                    <Box
                        sx={{
                            flex: 1,
                            marginRight: groupedAttendees.length > 0 || activeSection !== null ? '300px' : '0',
                            overflowY: 'auto',
                        }}
                    >
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                padding: '15px 0 15px 2px',
                            }}
                        >
                            {
                                sortedAttendees.map((_attendee, index) => (
                                    <Grid
                                        item
                                        key={_attendee.id}
                                        sx={{
                                            width: calculateCardWidth(),
                                        }}
                                    >
                                        <Card
                                            sx={{
                                                width: calculateCardWidth(),
                                                minWidth: '250px',
                                                height: '150px',  // Fixed height for all cards
                                                display: 'flex',
                                                flexDirection: 'column',  // Arrange image and title vertically
                                                justifyContent: 'space-between',
                                                overflow: 'visible',
                                                backgroundColor: groupedAttendees.includes(_attendee.id) || selectedAttendeeId === _attendee.id ? 'rgba(198, 226, 255, 0.8)' : 'white' // Peach if selected
                                            }}
                                        >
                                            <CardHeader
                                                sx={{
                                                    padding: '5px 8px 0 0',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    position: 'relative',
                                                    '& .MuiCardHeader-avatar': { // Targets the avatar container in CardHeader
                                                        marginRight: 0,           // Removes the right margin
                                                    }
                                                }}
                                                avatar={
                                                    _attendee.image === "" ? (
                                                        <>
                                                            <Avatar
                                                                {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    boxShadow: '' +
                                                                        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                                        '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                                        '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                    border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                    borderRadius: '10px',
                                                                    textAlign: 'center',
                                                                    fontWeight: '700',
                                                                    backgroundColor: '#FFF',
                                                                    color: 'black', // Text color (adjust as needed)
                                                                    fontSize: '15px',
                                                                    width: 30, // Adjust width
                                                                    height: 30, // Adjust height
                                                                }}
                                                                onClick={() => handleAvatarClick(_attendee.id)} // Open file input for this attendee
                                                            />
                                                            <input
                                                                id={`file-input-${_attendee.id}`}
                                                                type="file"
                                                                accept="image/*"
                                                                style={{display: 'none'}}
                                                                onChange={(event) => handleImageChange(event, _attendee.id)}
                                                            />
                                                        </>

                                                    ) : (
                                                        <>
                                                            <Avatar
                                                                src={_attendee.image} // Display the selected image
                                                                sx={{
                                                                    cursor: 'pointer',
                                                                    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                                    border: `1px solid ${stringToColor(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)}`,
                                                                    borderRadius: '10px',
                                                                    textAlign: 'center',
                                                                    fontWeight: '700',
                                                                    backgroundColor: '#FFF',
                                                                    color: 'black',
                                                                    fontSize: '15px',
                                                                    width: 30,
                                                                    height: 30,
                                                                }}
                                                                onClick={() => handleAvatarClick(_attendee.id)} // Open file input for this attendee
                                                            />
                                                            <input
                                                                id={`file-input-${_attendee.id}`}
                                                                type="file"
                                                                accept="image/*"
                                                                style={{display: 'none'}}
                                                                onChange={(event) => handleImageChange(event, _attendee.id)}
                                                            />
                                                        </>
                                                    )

                                                }
                                                action={
                                                    <>
                                                        <Typography
                                                            variant="caption"
                                                            sx={{
                                                                cursor: 'pointer',
                                                                position: 'absolute',
                                                                top: '-12px',
                                                                left: '10px',
                                                                fontSize: '11px',
                                                                color: '#FFFFFF',
                                                                backgroundColor: '#f09637',
                                                                border: '1px solid #428ce2',
                                                                borderRadius: '5px',
                                                                zIndex: 2
                                                            }}
                                                        >
                                                            Status
                                                        </Typography>

                                                        <IconButton
                                                            aria-label="more"
                                                            aria-controls={`menu-${index}`}
                                                            aria-haspopup="true"
                                                            onClick={(event) => handleOpenAttendeeMenu(event, index)}
                                                            sx={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0
                                                            }}
                                                        >
                                                            <i className="icon-menu2" style={{fontSize:'16px'}} />
                                                        </IconButton>
                                                        <Menu
                                                            sx={{
                                                                width: 320,
                                                                maxWidth: '100%'
                                                            }}
                                                            anchorEl={anchorAttendeeElements[index]}
                                                            open={Boolean(anchorAttendeeElements[index])}
                                                            onClose={() => handleCloseAttendeeMenu(index)}
                                                        >
                                                            <MenuItem
                                                                sx={{
                                                                    backgroundColor: 'rgba(198, 226, 255, 0.8)'
                                                                }}
                                                            >
                                                                <Typography
                                                                    variant="subtitle2"
                                                                >
                                                                    {capitalizeFirstLetter(_attendee.fname)} {capitalizeFirstLetter(_attendee.lname)}
                                                                </Typography>
                                                            </MenuItem>
                                                            <Divider />
                                                            <MenuItem
                                                                onClick={() => handleEditAttendee(_attendee.id, index)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-edit2" style={{fontSize:'16px'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Edit
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => handleSendEmail(_attendee.id, index)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-envelope" style={{fontSize:'16px'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Send an Email
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => handleAddToEvent(_attendee.id, index)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-calendar" style={{fontSize:'16px'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Add to an Event
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => handleSendCoupon(_attendee.id, index)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-ticket" style={{fontSize:'16px'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Send a Coupon
                                                                </Typography>
                                                            </MenuItem>
                                                            <MenuItem
                                                                onClick={() => handleDialogDeleteOpen(index, _attendee.id)}
                                                            >
                                                                <ListItemIcon>
                                                                    <i className="icon-trash" style={{fontSize:'16px', color:'#F00'}}/>
                                                                </ListItemIcon>
                                                                <Typography
                                                                    variant="body2"
                                                                >
                                                                    Delete
                                                                </Typography>
                                                            </MenuItem>
                                                        </Menu>
                                                    </>
                                                }
                                            />
                                            <CardContent
                                                sx={{
                                                    paddingTop: 0,
                                                    textAlign: 'center',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => handleGroupToggle(_attendee.id)} // Toggle group on name click
                                            >
                                                <Typography variant="subtitle2">{capitalizeFirstLetter(_attendee.fname)} {capitalizeFirstLetter(_attendee.lname)}</Typography>
                                                <Typography variant="caption">{_attendee.email}</Typography>
                                            </CardContent>
                                            <CardActions
                                                disableSpacing
                                                style={{ display: 'flex', justifyContent: 'space-between'}}
                                            >
                                                <Typography variant="caption">{dayjs(_attendee.created).format('D MMM YYYY')}</Typography>
                                                <Typography variant="caption">Hosted</Typography>
                                            </CardActions>

                                        </Card>

                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Box>
                )}

                {/* Display sections based on activeSection */}
                {activeSection === 'edit' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)',
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                Edit {capitalizeFirstLetter(Attendees.find(attendee => attendee.id === selectedAttendeeId)?.fname)}
                            </Typography>

                            <IconButton
                                onClick={() => setActiveSection(null)}
                                aria-label="Close"
                                sx={{ color: 'red' }}
                            >
                                <i className="icon-cross" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>

                        <Grid container spacing={2} direction="column">

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="fname"
                                    label="First Name"
                                    required
                                    value={fname}
                                    onChange={handleFnameChange}
                                />
                            </Grid>
                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="lname"
                                    label="Last Name"
                                    required
                                    value={lname}
                                    onChange={handleLnameChange}
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="nickname"
                                    label="Nickname"
                                    value={nickname}
                                    onChange={handleNicknameChange}
                                />
                            </Grid>
                            <Grid item style={{ flex: '1', position:'relative' }}>
                                <TextField
                                    fullWidth
                                    id="address"
                                    label="Address"
                                    value={address}
                                    onChange={handleAddressChange}
                                    inputRef={inputRef}
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="city"
                                    label="City"
                                    value={city}
                                    onChange={handleCityChange}
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="state"
                                    label="State"
                                    value={state}
                                    onChange={handleStateChange}
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="zip"
                                    label="Zip"
                                    value={zip}
                                    onChange={handleZipChange}
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="country"
                                    label="Country"
                                    value={country}
                                    onChange={handleCountryChange}
                                />
                            </Grid>
                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="email_address"
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    helperText="Required if they are going to be logging in"
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Phone Prefix</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={phonePrefix}
                                        onChange={handlePhonePrefixChange}
                                        label="Phone Prefix"
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        {
                                            country_codes.map((item, index) => (
                                                <MenuItem key={index} value={item.dialCode}>
                                                    {item.dialCode} : {item.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="phone"
                                    label="Phone"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="dob"
                                    label="Date of Birth"
                                    value={dob}
                                    onChange={handleDOBChange}
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="language"
                                    label="Preferred Language"
                                    value={language}
                                    onChange={handleLanguageChange}
                                />
                            </Grid>

                            <Grid item style={{ flex: '1' }}>
                                <TextField
                                    fullWidth
                                    id="language"
                                    label="Preferred Airport"
                                    value={preferredAirport}
                                    onChange={handlePreferredAirportChange}
                                />
                            </Grid>

                        </Grid>
                    </Box>
                )}

                {activeSection === 'email' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)',
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                Send {capitalizeFirstLetter(Attendees.find(attendee => attendee.id === selectedAttendeeId)?.fname)} an Email
                            </Typography>

                            <IconButton
                                onClick={() => setActiveSection(null)}
                                aria-label="Close"
                                sx={{ color: 'red' }}
                            >
                                <i className="icon-cross" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {activeSection === 'add' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)',
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                Add {capitalizeFirstLetter(Attendees.find(attendee => attendee.id === selectedAttendeeId)?.fname)} to an Event
                            </Typography>

                            <IconButton
                                onClick={() => setActiveSection(null)}
                                aria-label="Close"
                                sx={{ color: 'red' }}
                            >
                                <i className="icon-cross" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {activeSection === 'coupon' && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)'
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                Send {capitalizeFirstLetter(Attendees.find(attendee => attendee.id === selectedAttendeeId)?.fname)} a Coupon
                            </Typography>

                            <IconButton
                                onClick={() => setActiveSection(null)}
                                aria-label="Close"
                                sx={{ color: 'red' }}
                            >
                                <i className="icon-cross" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>
                    </Box>
                )}

                {/* Grouped Attendees Column */}
                {activeSection === 'grouped' && groupedAttendees.length > 0 && (
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            width: '320px',
                            height: '100%', // Make it fill the dialog height
                            borderLeft: '1px solid #ddd',
                            overflowY: 'auto', // Independent vertical scroll
                            backgroundColor: 'rgba(235,245,255,0.8)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 1,
                                backgroundColor: 'rgba(198, 226, 255, 0.8)'
                            }}
                        >
                            <Typography variant="subtitle2" sx={{paddingLeft: '10px'}}>
                                {groupedAttendees.length} Selected
                            </Typography>

                            <IconButton
                                onClick={() => clearMenuSelections()} // Clear both grouped attendees and active section
                                aria-label="Clear selected"
                                sx={{ color: 'green' }}
                            >
                                <i className="icon-cw" style={{ fontSize: '14px' }} /> {/* Replace with actual icon class or use Material-UI icon */}
                            </IconButton>
                        </Box>


                        {Attendees
                            .filter(attendee => groupedAttendees.includes(attendee.id))
                            .sort((a, b) => {
                                const lastNameComparison = a.lname.localeCompare(b.lname);
                                return lastNameComparison !== 0
                                    ? lastNameComparison // Sort by last name if they're different
                                    : a.fname.localeCompare(b.fname); // Otherwise, sort by first name
                            })
                            .map(attendee => (
                                <Box key={attendee.id} sx={{ padding: 1, borderBottom: '1px solid #ddd' }}>
                                    <Typography variant="subtitle2">
                                        {capitalizeFirstLetter(attendee.fname)} {capitalizeFirstLetter(attendee.lname)}
                                    </Typography>
                                </Box>
                            ))}
                    </Box>
                )}


            </DialogContent>
        </Dialog>
    );
}

export default MasterAttendees;
