import React, {useContext, useEffect, useRef, useState} from 'react';

import {Card, CardContent, CardMedia, Chip, Grid, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";

import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";

import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";
import Typography from "@mui/material/Typography";
import './ideas.css';
import IdeasCard from "./IdeasCard";
import dayjs from "dayjs";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";



function IdeasList(props) {

    const navigate = useNavigate();

    const { dataArray, setPlanningIdeas } = useContext(PAContext);
    const { Planning } = dataArray;

    const [eventIdeas, setEventIdeas] = useState([]);


    const [anchorIdeasListMenu, setAnchorIdeasListMenu] = useState(null);
    const handleIdeasListMenuOpenMenu = (event) => {
        setAnchorIdeasListMenu(event.currentTarget);
    };
    const handleIdeasListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorIdeasListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Ideas/AddNew');
        }
    };
    const handleIdeasListMenuClose = () => {
        setAnchorIdeasListMenu(null);
    };
    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }


    useEffect(() => {
        console.log("Planning.ideas : "+JSON.stringify(Planning.ideas, null, '\t'));

        if (Planning.ideas !== undefined) {
            setEventIdeas([...Planning.ideas]);
        }

        if (Planning.ideas && Planning.ideas.length > 0) {

            for (const _idea of Planning.ideas) {
                console.log("Idea : "+_idea["title"]);
                if (_idea["photos"] !== undefined
                    && _idea["photos"].length === 0
                    && _idea["location"].google_place_id !== "") {
                    //get the photos
                    console.log("Getting photos for : "+_idea["location"].google_place_id);
                    const request = {
                        placeId: _idea["location"].google_place_id,
                        fields: ['photo']
                    };

                    const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                    _global_map_service.getDetails(request, function (results, status) {
                        console.log("response : "+results);
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                            try {
                                const _photos_array = [];
                                for (const _photo of results.photos) {
                                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                                }
                                _idea.photos = _photos_array;

                                const ideaIndex = Planning.ideas.findIndex((idea) => idea.id === _idea.id);

                                if (ideaIndex !== -1) {
                                    // If the activity is found, update it in the eventIdeas array
                                    const updatedEventIdeasArray = [...Planning.ideas];
                                    updatedEventIdeasArray[ideaIndex] = _idea;

                                    setEventIdeas(updatedEventIdeasArray);

                                    //this updates the activity at the PAContext level
                                    setPlanningIdeas(Planning["id"], updatedEventIdeasArray);

                                    console.log("updatedEventIdeas");
                                }

                            } catch (e){}
                        }
                    });
                }
            }
        }
    }, [Planning.ideas]);

    const updateIdeaDetails = (updatedIdea) => {
        // Find the index of the updated Idea in eventIdeas
        const ideaIndex = eventIdeas.findIndex((idea) => idea.id === updatedIdea.id);

        if (ideaIndex !== -1) {
            // If the idea is found, update it in the eventIdeas array
            const updatedEventIdeasArray = [...eventIdeas];
            updatedEventIdeasArray[ideaIndex] = updatedIdea;

            setEventIdeas(updatedEventIdeasArray);

            //this updates the idea at the PAContext level
            setPlanningIdeas(Planning["id"], updatedEventIdeasArray);

            console.log("updatedEventIdeas");
        }

    };






    return (
        <div className="ideas-list-container" >

            {/* show the header */}
            <div
                className="ideas-list-header"
            >
                <div className="ideas-list-header-text">
                    <h5>Ideas List</h5>
                </div>
                <div className="ideas-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize:'16px'}} />
                        }
                        size="small"
                        onClick={handleIdeasListMenuOpenMenu}
                    />
                    <Menu
                        sx={{ width: 320, maxWidth: '100%' }}
                        anchorEl={anchorIdeasListMenu}
                        id="library-menu"
                        open={Boolean(anchorIdeasListMenu)}
                        onClose={handleIdeasListMenuClose}

                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuList
                            dense
                            sx={{ width: 320, maxWidth: '100%' }}
                        >
                            <MenuItem
                                data-id="new-entry"
                                onClick={handleIdeasListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>

            {/* show the list of Ideas */}
            <div className="ideas-list-items-container">

                {
                    eventIdeas && eventIdeas.length > 0 ? (
                        eventIdeas
                            .sort((a,b) => dayjs(a.id).diff(dayjs(b.id)))
                            .map((ideaDetails, index) => (

                                <Grid item key={index} sx={{display:'flex'}}>

                                    <IdeasCard
                                        idea={ideaDetails}
                                        updateIdeaDetails={updateIdeaDetails}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item >
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{ height: 140 }}
                                        image={lodgingHeaderImage}
                                        title="Add Ideas"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Add any type of Idea
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            There are several ways to easily add Lodgings:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            1. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the BUSINESS icon and search the area for <strong>Hotels, Motels, Campgrounds</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            2. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the HOUSE icon and search the area for <strong>VRBO, AirBnB, Personal Residence</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            3. <strong>From a PDF Receipt</strong>.
                                            We'll have our AI Concierge extract the relevant details from the PDF and let you see what we've
                                            found. You can edit the results before we actually add the details to the Itinerary.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            4. <strong>Camping</strong>. Use your GPS to pinpoint your location. Click on the
                                            <i className="icon-menu2" /> above.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            5. <strong>Enter manually</strong>. Fill in the input boxes with the exact Lodging details.
                                        </Typography>

                                    </CardContent>
                                </Card>

                            </Paper>
                        </Grid>
                    )

                }
            </div>

        </div>
    )

}

export default IdeasList;
