import React, {useContext, useEffect, useRef, useState} from 'react';

import {Card, CardContent, CardMedia, Chip, Grid, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import {cancelChipStyle, cancelGradientStyle} from "../../ButtonStyle/Cancel";
import {addGradientStyle} from "../../ButtonStyle/Add";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";

import lodgingHeaderImage from "../../../graphics/th-513411954.jpg";
import Typography from "@mui/material/Typography";
import './activities.css';
import ActivitiesCard from "./ActivitiesCard";
import dayjs from "dayjs";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import Box from "@mui/material/Box";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";



function ActivitiesList(props) {

    const navigate = useNavigate();

    const { dataArray, setPlanningActivities } = useContext(PAContext);
    const { Planning } = dataArray;

    const [eventActivities, setEventActivities] = useState([]);


    const [anchorActivitiesListMenu, setAnchorActivitiesListMenu] = useState(null);
    const handleActivitiesListMenuOpenMenu = (event) => {
        setAnchorActivitiesListMenu(event.currentTarget);
    };
    const handleActivitiesListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorActivitiesListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Activities/Add');
        }
    };
    const handleActivitiesListMenuClose = () => {
        setAnchorActivitiesListMenu(null);
    };
    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }


    useEffect(() => {
        console.log("Planning.activities : "+JSON.stringify(Planning.activities, null, '\t'));

        if (Planning.activities !== undefined) {
            setEventActivities([...Planning.activities]);
        }

        if (Planning.activities && Planning.activities.length > 0) {

            for (const _activity of Planning.activities) {
                console.log("Activity : "+JSON.stringify(_activity, null, '\t'));
                if (_activity["photos"]
                    && _activity["photos"].length === 0
                    && _activity["location"]
                    && _activity["location"].google_place_id !== "") {
                    //get the photos
                    console.log("Getting photos for : "+_activity["location"].google_place_id);
                    const request = {
                        placeId: _activity["location"].google_place_id,
                        fields: ['photo']
                    };

                    const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                    _global_map_service.getDetails(request, function (results, status) {
                        console.log("response : "+results);
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                            try {
                                const _photos_array = [];
                                for (const _photo of results.photos) {
                                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                                }
                                _activity.photos = _photos_array;

                                const activityIndex = Planning.activities.findIndex((activity) => activity.id === _activity.id);

                                if (activityIndex !== -1) {
                                    // If the activity is found, update it in the eventActivities array
                                    const updatedEventActivitiesArray = [...Planning.activities];
                                    updatedEventActivitiesArray[activityIndex] = _activity;

                                    setEventActivities(updatedEventActivitiesArray);

                                    //this updates the activity at the PAContext level
                                    setPlanningActivities(Planning["id"], updatedEventActivitiesArray);

                                    console.log("updatedEventActivities");
                                }

                            } catch (e){}
                        }
                    });
                }
            }
        }
    }, [Planning.activities]);

    const updateActivityDetails = (updatedActivity) => {
        // Find the index of the updated Activity in eventActivities
        const activityIndex = eventActivities.findIndex((activity) => activity.id === updatedActivity.id);

        if (activityIndex !== -1) {
            // If the activity is found, update it in the eventActivities array
            const updatedEventActivitiesArray = [...eventActivities];
            updatedEventActivitiesArray[activityIndex] = updatedActivity;

            setEventActivities(updatedEventActivitiesArray);

            //this updates the activity at the PAContext level
            setPlanningActivities(Planning["id"], updatedEventActivitiesArray);

            console.log("updatedEventActivities");
        }

    };






    return (

        <div className="activities-list-container" >

            {/* show the header */}
            <div
                className="activities-list-header"
            >
                <div className="activities-list-header-text">
                    <h5>Activities List</h5>
                </div>
                <div className="activities-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize:'16px'}} />
                        }
                        size="small"
                        onClick={handleActivitiesListMenuOpenMenu}
                    />
                    <Menu
                        sx={{ width: 320, maxWidth: '100%' }}
                        anchorEl={anchorActivitiesListMenu}
                        id="library-menu"
                        open={Boolean(anchorActivitiesListMenu)}
                        onClose={handleActivitiesListMenuClose}

                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuList
                            dense
                            sx={{ width: 320, maxWidth: '100%' }}
                        >
                            <MenuItem
                                data-id="new-entry"
                                onClick={handleActivitiesListMenuClick}>
                                <ListItemText>New Activity Entry</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of activities for dates/days */}
            <div className="activities-list-timetable-list-container">
                {
                    eventActivities ? (
                        (() => {
                            const allActivityChips = [];

                            Planning['time_table'].dates.forEach((eventDateObj) => {
                                const eventDate = eventDateObj.date;
                                const formattedDay = dayjs(eventDate, 'YYYY-MM-DD').format('ddd'); // e.g., "Wed"
                                const formattedDate = dayjs(eventDate, 'YYYY-MM-DD').format('M/D'); // e.g., "10/13"

                                // Filter meals for this specific date
                                const activitiesForDate = eventActivities.filter(activity =>
                                    activity.time_table?.dates?.length > 0 && activity.time_table.dates[0].date === eventDate
                                );

                                const activityCount = activitiesForDate.length;

                                // If there are meals for the date, show a Chip with a badge showing the count
                                if (activityCount > 0) {
                                    allActivityChips.push({
                                        date: eventDate, // Use the event date for sorting
                                        component: (
                                            <Box key={`activity-${eventDate}`}
                                                 sx={{
                                                     position: 'relative',
                                                     display: 'inline-block',
                                                     marginTop: '5px',
                                                     marginRight: '10px'
                                                 }}
                                            >
                                                {/* Chip Component with Activity Count */}
                                                <Chip
                                                    label={
                                                        <div style={{textAlign: 'center'}}>
                                                            <Typography variant="body2">{formattedDay}</Typography>
                                                            <Typography variant="caption">{formattedDate}</Typography>
                                                        </div>
                                                    }
                                                    sx={unselectedChipStyle} // Style for the Chips
                                                    style={{margin: '0.2rem', height: '40px'}}
                                                />

                                                {/* Badge with Activity count */}
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-5px',
                                                        right: '-5px',
                                                        backgroundColor: 'white',
                                                        color: 'green',
                                                        borderRadius: '35%',
                                                        width: '15px',
                                                        height: '15px',
                                                        fontSize: '12px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        zIndex: 1,
                                                        fontWeight: '700',
                                                        boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)'
                                                    }}
                                                >
                                        {activityCount}
                                    </span>
                                            </Box>
                                        )

                                    });
                                } else {
                                    // If no activities exist for the date, show a Chip with a warning icon
                                    allActivityChips.push({
                                        date: eventDate, // Use the event date for sorting
                                        component: (
                                            <Box key={`placeholder-activity-${eventDate}`}
                                                 sx={{
                                                     position: 'relative',
                                                     display: 'inline-block',
                                                     marginTop: '5px',
                                                     marginRight: '10px'
                                                 }}
                                            >
                                                {/* Chip Component without activities (with warning icon) */}
                                                <Chip
                                                    label={
                                                        <div style={{textAlign: 'center'}}>
                                                            <Typography variant="body2">{formattedDay}</Typography>
                                                            <Typography variant="caption">{formattedDate}</Typography>
                                                        </div>
                                                    }
                                                    sx={unselectedChipStyle} // Style for the placeholder Chips
                                                    style={{margin: '0.2rem', height: '40px'}}
                                                />

                                                {/* Warning icon */}
                                                <i
                                                    className="icon-warning"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-5px',
                                                        right: '-5px',
                                                        fontSize: '10px',
                                                        color: 'red',
                                                        backgroundColor: 'white',  // White background for the circle
                                                        borderRadius: '50%',       // Make it a circle
                                                        padding: '3px',            // Space between the icon and the border
                                                        zIndex: 1
                                                    }}
                                                />
                                            </Box>
                                        )
                                    });
                                }
                            });

                            // Now, return the rendered components from allMealChips
                            return allActivityChips.map((chip, idx) => (
                                <React.Fragment key={idx}>
                                    {chip.component}
                                </React.Fragment>
                            ));

                        })()
                    ) : (
                        <div style={{padding: '20px'}}>
                            <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                            <Typography variant="caption">Need to add some Activities first</Typography>
                        </div>
                    )
                }
            </div>



            {/* show the list of activities */}
            <div className="activities-list-items-container">

                {
                    eventActivities && eventActivities.length > 0 ? (
                        eventActivities
                            .sort((a, b) => {
                                // First, compare by date
                                const dateComparison = dayjs(a.begin_date).diff(dayjs(b.begin_date));
                                if (dateComparison !== 0) {
                                    return dateComparison;
                                }
                                // If dates are the same, compare by time
                                return dayjs(a.begin_time, 'h:mm A').diff(dayjs(b.begin_time, 'h:mm A'));
                            })
                            .map((activityDetails, index) => (

                                <Grid item key={index} sx={{
                                    display:'block',
                                    marginBottom:'10px'
                                }}>

                                    <ActivitiesCard
                                        activity={activityDetails}
                                        updateActivityDetails={updateActivityDetails}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item >
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{ height: 140 }}
                                        image={lodgingHeaderImage}
                                        title="Add Activity"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Add any type of Activity
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            There are several ways to easily add Activities:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            1. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the BUSINESS icon and search the area for <strong>Hotels, Motels, Campgrounds</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            2. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the HOUSE icon and search the area for <strong>VRBO, AirBnB, Personal Residence</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            3. <strong>From a PDF Receipt</strong>.
                                            We'll have our AI Concierge extract the relevant details from the PDF and let you see what we've
                                            found. You can edit the results before we actually add the details to the Itinerary.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            4. <strong>Camping</strong>. Use your GPS to pinpoint your location. Click on the
                                            <i className="icon-menu2" /> above.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            5. <strong>Enter manually</strong>. Fill in the input boxes with the exact Lodging details.
                                        </Typography>

                                    </CardContent>
                                </Card>

                            </Paper>
                        </Grid>
                    )

                }
            </div>

        </div>
    )

}

export default ActivitiesList;
