import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {Chip, Grid} from "@mui/material";
import {cancelGradientStyle} from "../../ButtonStyle/Cancel";
import Typography from "@mui/material/Typography";
import './suggestionsUnique.css';

import airbnb_logo from '../../../graphics/Airbnb-logo.png';
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";

function SuggestionsUnique(props){

    const { dataArray } = useContext(PAContext);
    const { SuggestionsUnique } = dataArray;

    const [uniqueSuggestions, setUniqueSuggestions] = useState([]);

    useEffect(() => {

        setUniqueSuggestions([]);

        //now add the new suggestions
        for (const _item of SuggestionsUnique){
            if (Object.keys(_item).length > 0){
                setUniqueSuggestions((prev) => [...prev, _item]);
            }
        }

    }, [SuggestionsUnique]);

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }



    return (

        <div className="unique-suggestions-list-container" >
            {/* show the header */}
            <div
                className="unique-suggestions-list-header"
            >
                <div className="unique-suggestions-list-header-text">
                    <h5>Unique Suggestions {uniqueSuggestions.length===0?'':uniqueSuggestions.length}</h5>
                </div>

                <div className="unique-suggestions-list-header-chip-holder">
                    <Chip
                        sx={cancelGradientStyle}
                        label="Close"
                        size="small"
                        color="error"
                        onClick={handlePromptChangeClose}
                    />
                </div>
            </div>


            {/* show the list of unique suggestions */}
            <div className="unique-suggestions-list-items-container">
                <Grid container spacing={2} direction="column">
                    {
                        uniqueSuggestions.length > 0 ? (
                            uniqueSuggestions.map((_item, index)=> (
                                <Grid item key={index} sx={{display: 'flex', cursor:'pointer'}}>

                                    <div
                                        className="unique-suggestions-list-item"
                                    >
                                        <div className="unique-suggestions-list-item-header-image-holder">
                                            <img className="unique-suggestions-list-item-header-image" src={airbnb_logo} alt="airbnb logo" />
                                            <span className="unique-suggestions-list-item-header-text">Experiences</span>
                                        </div>
                                        <div
                                            className="unique-suggestions-list-item-image-holder"
                                            onClick={() => {window.open("https://airbnb.com/"+_item['href'], "_blank")}}
                                        >
                                            {/* add the image */}
                                            <img src={_item['image']} alt={_item['title']}/>
                                        </div>
                                        <Typography variant="body2">
                                            {decodeURI(_item['title'])}
                                        </Typography>
                                        <div className="unique-suggestions-list-item-price-holder">
                                            <Typography sx={{mb: 1.5, flex:1}} color="text.secondary">
                                                {_item['price']}
                                            </Typography>
                                        </div>
                                        <div className="unique-suggestions-list-item-time-reviews-holder">
                                            <Typography sx={{mb: 1.5}} color="text.secondary">
                                                {_item['time']}
                                            </Typography>
                                            <Box sx={{mb: 1.5, flex:1, textAlign:'right'}} color="text.secondary">
                                                <Rating name="read-only" value={parseFloat(_item['rating'])} precision={0.1} readOnly />
                                                <Typography color="text.secondary">
                                                    {_item['rating']}
                                                </Typography>
                                            </Box>
                                        </div>

                                        {/* add the chip */}
                                    </div>

                                </Grid>
                            ))
                        ) : (
                            <div style={{padding: '20px', textAlign:'center'}} >
                                {/* place is required and preferably some real dates */}
                                <i className="icon-warning" style={{fontSize:'11px', color:'red', marginRight:'10px'}}/>
                                <Typography variant="subtitle2">No Unique suggestions yet.</Typography>
                                <Typography variant="caption">Once an area has been selected then the Concierge will look for Unique suggestions.</Typography>
                            </div>
                        )
                    }
                </Grid>
            </div>

        </div>

    )
}

export default SuggestionsUnique;
