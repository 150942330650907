import React, {useContext, useEffect, useState} from 'react';
import {Chip, Grid, Tab} from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import './supplies.css';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";


function SuppliesAddEssentials(){

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Account, Planning } = dataArray;

    function handleSuppliesAddOK() {
        console.log("handleSuppliesAddOK");
    }


    function handlePromptAddNewClose() {
        navigate('/Planner/Supplies')
    }

    const handleTabValueChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [tabValue, setTabValue] = useState("supplies");

    const [suppliesChecked, setSuppliesChecked] = useState([]);
    const [foodChecked, setFoodChecked] = useState([]);

    useEffect(()=>{




    }, [Account.essentials]);






    return (
        <div className="supplies-list-container" >
            {/* show the header */}
            <div
                className="supplies-list-header"
            >
                <div className="supplies-list-header-text">
                    <h5>Supply Essentials</h5>
                </div>
                <div className="supplies-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptAddNewClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        className="supplies-list-item-add-count-chip"
                        label="Add"
                        size="small"
                        onClick={handleSuppliesAddOK}
                    />
                </div>
            </div>


            {/* show all the fields */}
            <div className="supplies-list-items-container">
                <Grid container spacing={2} direction="column">

                    <Grid item >
                        <TabContext sx={{ position:'relative'}} value={tabValue}>
                            <Box
                                sx={{
                                    width: '350px',
                                    position: 'fixed',
                                    marginTop: '-5px',
                                    borderBottom: 1,
                                    borderColor: 'divider',
                                    backgroundColor: 'rgba(251, 251, 251, 0.8)',
                                    backdropFilter: 'blur(6px)',
                                    zIndex:'2'
                                }}
                            >
                                <TabList
                                    centered
                                    variant="fullWidth"
                                    onChange={handleTabValueChange}
                                    aria-label="Supply Suggestions List"
                                >
                                    <Tab
                                        label="Supplies"
                                        value="supplies"
                                    />
                                    <Tab
                                        label="Food"
                                        value="food"
                                    />
                                </TabList>
                            </Box>
                            <TabPanel value="supplies" sx={{ paddingTop:'50px'}}>

                            </TabPanel>
                            <TabPanel value="food" sx={{ paddingTop:'50px'}}>

                            </TabPanel>
                        </TabContext>
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}

export default SuppliesAddEssentials;
