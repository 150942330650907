import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import Badge from "@mui/material/Badge";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Chip,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    Paper
} from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import {addChipStyle} from "../../ButtonStyle/Add";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";



function Files(props) {

    const { dataArray } = useContext(PAContext);
    const { AdminsActions } = dataArray;

    const [ files, setFiles ] = useState([]);
    const [ activeFile, setActiveFile ] = useState('');
    const [ activeFileContents, setActiveFileContents ] = useState('');
    const [ fileAssistants, setFileAssistants ] = useState([]);

    useEffect(() => {

        try {
            console.log("Seeing if we need to get the list of AI Files");
            if (files.length === 0) {
                //get the list of Files
                const _sendString = {};
                _sendString.action = "adminServicesGetOpenAiFilesList";
                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            }
        } catch (e){}
    }, []);


    useEffect(()=> {

        console.log("AdminsActions : "+AdminsActions);
        for (let i = AdminsActions.length - 1; i >= 0; i--) {
            const _incoming = AdminsActions[i];
            console.log('Action:', _incoming.action);

            switch (_incoming.action) {
                case "adminServicesGetOpenAiFilesListResults":
                    addFiles(_incoming.files_list);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesGetOpenAiFileContentsResults":
                    showFileContents(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesGetOpenAiFileAssistantListResults":
                    setFileAssistants(_incoming.file_assistant_list);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesDeleteOpenAiFileResults":
                    deleteFile(_incoming.file_id);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                default:
                // Handle other cases if needed
            }
        }
    }, [AdminsActions]);



    function showFileContents(_incoming){
        if (_incoming.result === "ok"){
            setActiveFileContents(_incoming.file_contents);
        } else {
            setActiveFileContents(_incoming.message.error.message);
        }
    }

    function addFiles(_files){

        for (const _file of _files){

            setFiles((prevFiles) => {
                const updatedFiles = [...prevFiles];

                // Check if the File already exists
                const existingFilesIndex = updatedFiles.findIndex((file) => file.id === _file.id);

                if (existingFilesIndex === -1) {
                    updatedFiles.push(_file);
                }

                return updatedFiles;
            });
        }

    }

    function getFileContents(_file_id){
        console.log("getFileContents : "+_file_id);

        setActiveFile(_file_id);
        setActiveFileContents('');

        const _sendString = {};
        _sendString.action = "adminServicesGetOpenAiFileContents";
        _sendString.file_id = _file_id;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

    }
    function deleteFile(_file_id){
        setFiles(files.filter(file => file.id !== _file_id))
    }

    function getFileAssistantList(_file_id){
        console.log("getFileAssistantList : "+_file_id);

        setActiveFile(_file_id);
        setActiveFileContents('');
        setFileAssistants([]);

        const _sendString = {};
        _sendString.action = "adminServicesGetOpenAiFileAssistantList";
        _sendString.file_id = _file_id;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }

    function handlePromptDetailsClose() {

    }

    function addNewAssistantFile(){

    }

    const [openDialogDeleteFile, setOpenDialogDeleteFile] = useState(false);
    const [fileToDeleteId, setFileToDeleteId] = useState("");
    const [fileToDeleteName, setFileToDeleteName] = useState("");
    function handleDialogDeleteFileOpen(file_id) {
        console.log("handleDialogDeleteFileOpen : "+file_id);

        setFileToDeleteId(file_id);
        setFileToDeleteName(file_id);

        setOpenDialogDeleteFile(true);

    }
    function handleDialogDeleteFileClose() {
        setOpenDialogDeleteFile(false);
    }
    function handleDialogDeleteFileOK(){
        setOpenDialogDeleteFile(false);

        const _sendString = {};
        _sendString.action = "adminServicesDeleteOpenAiFile";
        _sendString.file_id = fileToDeleteId;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }



    return (

        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Paper style={{ height: '100%' }}>

                    <Badge badgeContent={files.length} color="primary">
                        <h3>Files</h3>
                    </Badge>
                    <Chip
                        sx={addChipStyle}
                        label="Add"
                        size="small"
                        color="success"
                        onClick={addNewAssistantFile}
                    />

                    {
                        files.map((_file) => (

                            <Card
                                key={_file.id}
                                sx={{
                                    minWidth: 275,
                                    margin: '15px',
                                    backgroundColor: _file.id === activeFile ? '#e0f7fa' : 'white'
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {_file.filename}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5, fontSize: 11}} color="text.secondary">
                                        Created : {dayjs.unix(_file.created_at).format('ddd MMM D, YYYY h:mm A')}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5, fontSize: 11}} color="text.secondary">
                                        Purpose : {_file.purpose}
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'space-evenly' }}>
                                    <Chip
                                        sx={addChipStyle}
                                        label="Contents"
                                        size="small"
                                        color="success"
                                        onClick={ () => getFileContents(_file.id)}
                                    />
                                    <Chip
                                        sx={addChipStyle}
                                        label="Assistants"
                                        size="small"
                                        color="success"
                                        onClick={ () => getFileAssistantList(_file.id)}
                                    />
                                    <Chip
                                        sx={cancelChipStyle}
                                        label="Delete"
                                        size="small"
                                        color="error"
                                        onClick={ () => handleDialogDeleteFileOpen(_file.id)}
                                    />
                                </CardActions>
                            </Card>

                        ))
                    }
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper style={{ height: '100%' }}>
                    {
                        fileAssistants.length > 0 ? (
                            <h3>File Assistants</h3>
                        ) : (
                            <></>
                        )
                    }

                    {
                        fileAssistants.map((_assistant) => (
                            <Card
                                key={_assistant.id}
                                sx={{
                                    minWidth: 275,
                                    margin: '15px',
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {_assistant.name}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5, fontSize: 11}} color="text.secondary">
                                        Created : {dayjs.unix(_assistant.created_at).format('ddd MMM D, YYYY')}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))
                    }
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper style={{ height: '100%' }}>
                    {
                        activeFileContents !== '' ? (
                            <div>
                                <h3>File Contents</h3>
                                <div>
                                    {activeFileContents}
                                </div>
                            </div>
                        ) : (
                            <></>
                        )
                    }
                </Paper>
            </Grid>

            <Dialog
                open={openDialogDeleteFile}
                onClose={handleDialogDeleteFileClose}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {fileToDeleteName}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogDeleteFileClose}>Cancel</Button>
                    <Button onClick={handleDialogDeleteFileOK} autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>


        </Grid>

    )

}

export default Files;
