import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent, Checkbox,
    Chip,
    Container, Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup,
    Grid, ListItem,
    Paper, Switch,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";
import dayjs from "dayjs";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {useNavigate} from "react-router-dom";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {VolumeOffRounded} from "@mui/icons-material";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import './styles.css';


function Assistants(props) {

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { AdminsActions } = dataArray;

    const [ assistants, setAssistants ] = useState([]);
    const [ threads, setThreads ] = useState([]);
    const [ messages, setMessages] = useState([]);
    const [ activeAssistant, setActiveAssistant ] = useState('');
    const [ activeAssistantName, setActiveAssistantName ] = useState('');
    const [ activeThread, setActiveThread ] = useState('');
    const [ activeThreadName, setActiveThreadName ] = useState('');
    const [ messageStatusList, setMessageStatusList ] = useState([]);

    const [ columnTwoContent, setColumnTwoContent] = useState('');
    const [ columnThreeContent, setColumnThreeContent] = useState('');

    useEffect(() => {

        try {
            console.log("Seeing if we need to get the list of AI Assistants");
            if (assistants.length === 0) {
                //get the list of Assistants
                const _sendString = {};
                _sendString.action = "adminServicesGetOpenAiAssistantList";
                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

                const _sendFunctionsString = {};
                _sendFunctionsString.action = "adminServicesGetOpenAiAssistantFunctionList";
                dataArray.Websocket.send(JSON.stringify(_sendFunctionsString) + "|^");
            }
        } catch (e){}
    }, []);

    useEffect(()=> {

        console.log("AdminsActions : "+AdminsActions);
        for (let i = AdminsActions.length - 1; i >= 0; i--) {
            const _incoming = AdminsActions[i];
            console.log('Action:', _incoming.action);

            switch (_incoming.action) {
                case "userLoggedIn":
                    console.log('userLoggedIn');
                    processUserLogin(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesUpdateOpenAiAssistantThreadUserLoginResults":
                    console.log('adminServicesUpdateOpenAiAssistantThreadUserLoginResults');
                    processUserLoginResults(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "userLoggedOut":
                    console.log('userLoggedOut');
                    processUserLogout(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesUpdateOpenAiAssistantThreadUserLogoutResults":
                    console.log('adminServicesUpdateOpenAiAssistantThreadUserLogoutResults');
                    processUserLogoutResults(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesGetOpenAiAssistantListResults":
                    console.log('assistant_list:', _incoming.assistant_list);
                    addAssistants(_incoming.assistant_list);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesGetOpenAiAssistantFunctionListResults":
                    console.log('functions_list:', _incoming.functions_list);
                    addAssistantFunctionsList(_incoming.functions_list);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesUpdateOpenAiAssistantPropertiesResults":
                    updateAssistantProperties(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesDeleteOpenAiAssistantResults":
                    deleteAssistant(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesCreateOpenAiAssistantThreadResults":
                    addThreads([_incoming.thread]);
                    //automatically show the messages for this thread
                    getAssistantThreadMessages(_incoming['thread'].assistant_id, _incoming['thread'].thread_id);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesCreateOpenAiAssistantLodgingNegotiatorThreadResults":
                    addThreads([_incoming.thread]);
                    //automatically show the messages for this thread
                    getAssistantThreadMessages(_incoming['thread'].assistant_id, _incoming['thread'].thread_id);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesGetOpenAiAssistantThreadListResults":
                    console.log('thread_list:', _incoming.assistant_threads);
                    addThreads(_incoming.assistant_threads);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesUpdateOpenAiAssistantThreadMetadataResults":
                    console.log('metadata:', _incoming.metadata);
                    updateThreadMetadata(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesGetOpenAiAssistantThreadMessagesResults":
                    console.log('messages_list:', _incoming.thread_messages);
                    addMessages(_incoming.thread_messages);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesSendOpenAiAssistantThreadMessageResults":
                    if (_incoming.result === "ok"){
                        console.log('message:', _incoming.thread_message);
                        updateMessageStatus({ message_id: _incoming['thread_message'].id, status: 'in_progress' });
                        addMessages([_incoming.thread_message]);
                    } else {
                        //there was an error
                        console.log('error:', _incoming.message);
                    }
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesSendOpenAiAssistantThreadMessageStatus":
                    if (_incoming.result === "ok"){
                        console.log('status:', _incoming);
                        updateMessageStatus(_incoming);
                    } else {
                        //there was an error
                        updateMessageStatus({ message_id: _incoming.id, status: 'completed' });
                        console.log('error:', _incoming.reason);
                    }
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                case "adminServicesDeleteOpenAiAssistantThreadResults":
                    deleteAssistantThread(_incoming);
                    // Remove the one item we just processed
                    AdminsActions.splice(i, 1);
                    break;
                default:
                // Handle other cases if needed
            }

        }
    }, [AdminsActions]);

    function processUserLogin(_incoming){

        if (activeAssistant !== '' && activeThread !== ''){
            const _sendString = {};
            _sendString.action = "adminServicesUpdateOpenAiAssistantThreadUserLogin";
            _sendString.assistant_id = activeAssistant;
            _sendString.thread_id = activeThread;
            _sendString.assistant_name = threads.find((thread)=>thread.thread_id===activeThread).metadata['assistantName'];

            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
        }

    }
    function processUserLoginResults(_incoming){
        if (_incoming.result === "ok"){

        } else {

        }
    }
    function processUserLogout(_incoming){
        const _sendString = {};
        _sendString.action = "adminServicesUpdateOpenAiAssistantThreadUserLogout";
        _sendString.assistant_id = activeAssistant;
        _sendString.thread_id = activeThread;
        _sendString.user_id = _incoming.user_id;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }
    function processUserLogoutResults(_incoming){
        if (_incoming.result === "ok"){

        } else {

        }
    }

    function addAssistants(_assistants){

        for (const _assistant of _assistants){

            setAssistants((prevAssistants) => {
                const updatedAssistants = [...prevAssistants];

                // Check if the Assistant already exists
                const existingAssistantsIndex = updatedAssistants.findIndex((assistant) => assistant.id === _assistant.id);

                if (existingAssistantsIndex === -1) {
                    updatedAssistants.push(_assistant);
                }

                return updatedAssistants;
            });
        }

    }
    function updateAssistantProperties(_incoming){
        if (_incoming.result === "ok"){
            setAssistants((prevAssistantsList) => {
                const updatedAssistantsList = [...prevAssistantsList];
                const index = updatedAssistantsList.findIndex((assistant) => assistant.id === _incoming.assistant_id);

                if (index !== -1) {
                    const _incoming_data = _incoming.data;
                    updatedAssistantsList[index][_incoming.field] = _incoming_data[_incoming.field];
                }

                return updatedAssistantsList;
            });
        } else {
            //problems with the update
        }
    }
    function deleteAssistant(_incoming){
        setAssistants(assistants.filter(assistant => assistant.id !== _incoming.assistant_id))
    }
    function addThreads(_threads){

        for (const _thread of _threads){

            console.log(_thread);

            setThreads((prevThreads) => {
                const updatedThreads = [...prevThreads];

                // Check if the Thread already exists
                const existingThreadsIndex = updatedThreads.findIndex((thread) => thread.thread_id === _thread.thread_id);

                if (existingThreadsIndex === -1) {
                    updatedThreads.push(_thread);
                }

                return updatedThreads;
            });
        }
    }
    function updateThreadMetadata(_incoming){
        if (_incoming.result === "ok"){
            if (activeAssistant === _incoming.assistant_id){
                setThreads((prevThreadsList) => {
                    const updatedThreadsList = [...prevThreadsList];
                    const index = updatedThreadsList.findIndex((thread) => thread.thread_id === _incoming.thread_id);

                    if (index !== -1) {
                        updatedThreadsList[index]['metadata'] = _incoming.metadata;
                    }

                    console.log("updatedThreadsList : "+JSON.stringify(updatedThreadsList, null, '\t'));

                    return updatedThreadsList;
                });
            }
        } else {
            //problems with the update
        }
    }
    function addMessages(_messages){

        for (const _message of _messages){

            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];

                // Check if the Message already exists
                const existingMessagesIndex = updatedMessages.findIndex((message) => message.id === _message.id);

                if (existingMessagesIndex === -1) {
                    updatedMessages.push(_message);
                }

                updatedMessages.sort((a, b) => b.created_at - a.created_at);

                return updatedMessages;
            });

            if (audioOn && _message.audio !== undefined){
                playAudioMessage(_message.audio);
            }

        }
    }
    function updateMessageStatus(_incoming){
        console.log("updatedStatusList : "+JSON.stringify(_incoming, null, '\t'));

        setMessageStatusList((prevStatusList) => {
            const updatedStatusList = [...prevStatusList];
            const index = updatedStatusList.findIndex((status) => status.message_id === _incoming.message_id);

            if (index !== -1) {
                updatedStatusList[index] = { message_id: _incoming.message_id, status: _incoming.status };
            } else {
                updatedStatusList.push({ message_id: _incoming.message_id, status: _incoming.status });
            }

            console.log("updatedStatusList : "+JSON.stringify(updatedStatusList, null, '\t'));

            return updatedStatusList;
        });
    }
    function createAssistantThread(){
        console.log("createAssistantThread");
        console.log("activeAssistantName : "+activeAssistantName);

        //see if this is a Negotiator of some sort
        if (activeAssistantName === "Lodging Negotiator"){
            const _sendString = {};
            _sendString.action = "adminServicesCreateOpenAiAssistantLodgingNegotiatorThread";
            _sendString.assistant_id = activeAssistant;
            _sendString.event_id = "";
            _sendString.google_place_id = "";
            _sendString.lodging_city = "Florence";
            _sendString.lodging_state = "South Carolina";
            _sendString.lodging_name = "Holiday Inn Express";
            _sendString.phone_prefix = "+1";
            _sendString.phone = "8885551212";
            _sendString.check_in_date = "2024-06-01";
            _sendString.check_out_date = "2024-06-12";
            _sendString.guests = [
                {"attendee_id":"abc123","fname":"Keith","lname":"Pittner"},
                {"attendee_id":"abc124","fname":"Patricia","lname":"Pittner"},
                {"attendee_id":"abc125","fname":"Kelsey","lname":"Pittner"},
            ];
            _sendString.memberships = [
                {
                    "attendee_id":"abc124",
                    "fname":"Patricia",
                    "lname":"Pittner",
                    "program":"Hilton Honors",
                    "program_id":"pittner-ksdhfjsdh"
                },
                {
                    "attendee_id":"abc124",
                    "fname":"Patricia",
                    "lname":"Pittner",
                    "program":"Holiday Inn",
                    "program_id":"82376987sk3LKKH"
                },
                {
                    "attendee_id":"abc125",
                    "fname":"Kelsey",
                    "lname":"Pittner",
                    "program":"Regal International",
                    "program_id":"kpitasdkhfasd78979"
                }
            ];
            _sendString.number_of_rooms = 1;

            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

        } else {
            const _sendString = {};
            _sendString.action = "adminServicesCreateOpenAiAssistantThread";
            _sendString.assistant_id = activeAssistant;
            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
        }



    }
    function getAssistantThreadList(_assistant_id){
        console.log("getAssistantThreadList : "+_assistant_id);

        setColumnTwoContent('Conversations');
        setColumnThreeContent('');
        setActiveAssistant(_assistant_id);
        setActiveAssistantName(assistants.find((_assistant) => _assistant.id === _assistant_id).name);

        setThreads([]);

        const _sendString = {};
        _sendString.action = "adminServicesGetOpenAiAssistantThreadList";
        _sendString.assistant_id = _assistant_id;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

    }
    function getAssistantThreadMessages(_assistant_id, _thread_id){
        console.log("getAssistantThreadMessages : "+_assistant_id);

        setColumnThreeContent('Messages');
        setActiveThread(_thread_id);
        setMessages([]);

        const _sendString = {};
        _sendString.action = "adminServicesGetOpenAiAssistantThreadMessages";
        _sendString.assistant_id = _assistant_id;
        _sendString.thread_id = _thread_id;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

    }
    function deleteAssistantThread(_incoming){
        setThreads(threads.filter(thread => thread.thread_id !== _incoming.thread_id))
    }

    const [ assistantFunctionsList, setAssistantFunctionsList ] = useState([]);
    function getEntireFunctionsList(){
        const _sendString = {};
        _sendString.action = "adminServicesGetOpenAiAssistantFunctionList";
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }
    function addAssistantFunctionsList(_incoming){
        setAssistantFunctionsList(_incoming);
    }

    function showAssistantFiles(_assistant_id){
        setColumnTwoContent('Files');
        setColumnThreeContent('');
        setActiveAssistant(_assistant_id);
        setActiveAssistantName(assistants.find((_assistant) => _assistant.id === _assistant_id).name);
    }
    function showAssistantDetails(_assistant_id){
        setColumnTwoContent('Details');
        setColumnThreeContent('');
        setActiveAssistant(_assistant_id);
        setActiveAssistantName(assistants.find((_assistant) => _assistant.id === _assistant_id).name);
    }
    function showAssistantDetailsInstructions(_assistant_id){
        setColumnThreeContent('Instructions');
        setAssistantInstructions(assistants.find((_assistant) => _assistant.id === activeAssistant).instructions);
    }
    function showAssistantDetailsFunctions(_assistant_id){

        //load the selected functions
        const _begin_with_functions = [];
        const _current_functions = assistants
            .find((_assistant) => _assistant.id === activeAssistant)
            .tools
            .filter((_tool) => _tool.type === "function");
        console.log("_current_functions : "+JSON.stringify(_current_functions, null, '\t'));
        for (const _funct of _current_functions){
            const _f = {};
            _f.name = _funct.function.name;
            _f.description = _funct.function.description;
            _f.purpose = _funct.function.purpose;
            _begin_with_functions.push(_f);
        }
        console.log("_begin_with_functions : "+JSON.stringify(_begin_with_functions, null, '\t'));
        setAssistantFunctionsSelected(_begin_with_functions);

        setColumnThreeContent('Functions');
    }


    const [openDialogUpdateAssistantName, setOpenDialogUpdateAssistantName] = useState(false);
    const [updatedAssistantName, setUpdatedAssistantName] = useState('');
    function handleDialogUpdateAssistantNameClose() {
        setOpenDialogUpdateAssistantName(false);
    }
    function handleDialogUpdateAssistantNameOK(){
        setOpenDialogUpdateAssistantName(false);

        const _sendString = {};
        _sendString.action = "adminServicesUpdateOpenAiAssistantProperties";
        _sendString.assistant_id = activeAssistant;
        _sendString.field = "name";

        //get the data together
        const _data = {};
        _data.name = updatedAssistantName;

        _sendString.data = _data;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }

    const [openDialogUpdateAssistantDescription, setOpenDialogUpdateAssistantDescription] = useState(false);
    const [updatedAssistantDescription, setUpdatedAssistantDescription] = useState('');
    function handleDialogUpdateAssistantDescriptionClose() {
        setOpenDialogUpdateAssistantDescription(false);
    }
    function handleDialogUpdateAssistantDescriptionOK(){
        setOpenDialogUpdateAssistantDescription(false);

        const _sendString = {};
        _sendString.action = "adminServicesUpdateOpenAiAssistantProperties";
        _sendString.assistant_id = activeAssistant;
        _sendString.field = "description";

        //get the data together
        const _data = {};
        _data.description = updatedAssistantDescription;

        _sendString.data = _data;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }

    const [assistantInstructions, setAssistantInstructions] = useState('');
    function handleAssistantInstructionsChange(event){
        setAssistantInstructions(event.target.value);
    }
    function handleUpdateAssistantInstructions(){
        const _sendString = {};
        _sendString.action = "adminServicesUpdateOpenAiAssistantProperties";
        _sendString.assistant_id = activeAssistant;
        _sendString.field = "instructions";

        //get the data together
        const _data = {};
        _data.instructions = assistantInstructions;

        _sendString.data = _data;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }

    const [openDialogUpdateAssistantFunctions, setOpenDialogUpdateAssistantFunctions] = useState(false);
    const [assistantFunctionsSelected, setAssistantFunctionsSelected] = useState([]);
    function toggleSelectedAssistantFunction(_function_name){
        console.log("toggleSelectedAssistantFunction : "+_function_name);

        setAssistantFunctionsSelected((prevSelected) => {

            console.log("looking at : "+JSON.stringify(prevSelected, null, '\t'));

            if (prevSelected.some(item => item.name === _function_name)) {
                console.log("Found the one to remove");
                // Remove the name if it's already selected
                return prevSelected.filter((item) => item.name !== _function_name);
            } else {
                console.log("Need to add this one");
                // Add the name if it's not selected
                return [...prevSelected, assistantFunctionsList
                    .find((_function) => _function.name === _function_name)];
            }
        });
    }
    function handleDialogUpdateAssistantFunctionsClose() {
        setOpenDialogUpdateAssistantFunctions(false);
    }
    function handleDialogUpdateAssistantFunctionsOK(){
        setOpenDialogUpdateAssistantFunctions(false);

        const _sendString = {};
        _sendString.action = "adminServicesUpdateOpenAiAssistantProperties";
        _sendString.assistant_id = activeAssistant;
        _sendString.field = "tools";

        //get the data together
        const _data = [];

        //build the new list of tools
        for (const _function of assistantFunctionsSelected){
            _data.push(_function.name);
        }

        _data.tools = _data;

        _sendString.data = _data;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }

    const [newMessage, setNewMessage] = useState('');
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
        }
    };
    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {

            const _sendString = {};
            _sendString.action = "adminServicesSendOpenAiAssistantThreadMessage";
            _sendString.assistant_id = activeAssistant;
            _sendString.thread_id = activeThread;
            _sendString.message = newMessage;
            _sendString.audio_on = audioOn;
            _sendString.language = selectedLanguage;
            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            setNewMessage('');
        }
    };

    const handleCommandClick = (command) => {
        // Implement your logic based on the clicked command
        console.log("Clicked command:", command);

        switch (command) {
            case "createNewEvent":
            case "createNewEvent ":
            case "createNewEvent,":
            case "createNewEvent.":
                navigate('/Planner');
                break;
            default:

        }



    };

    const renderMessageParts = (text) => {
        const parts = text.split(/(command: [^\s]+)/gi);

        return parts.map((part, index) => {
            if (part.startsWith('command: ')) {
                const command = part.substring('command: '.length);
                return (
                    <span
                        key={index}
                        style={{cursor:'pointer', color:'blue'}}
                        className="clickable"
                        onClick={() => handleCommandClick(command)}
                    >
            {part}
          </span>
                );
            } else {
                return <span key={index}>{part}</span>;
            }
        });
    };

    const [openDialogDeleteAssistant, setOpenDialogDeleteAssistant] = useState(false);
    const [assistantToDeleteId, setAssistantToDeleteId] = useState("");
    const [assistantToDeleteName, setAssistantToDeleteName] = useState("");
    function handleDialogDeleteAssistantOpen(assistant_id) {
        console.log("handleDialogDeleteAssistantOpen : "+assistant_id);

        setAssistantToDeleteId(assistant_id);
        setAssistantToDeleteName(assistant_id);

        setOpenDialogDeleteAssistant(true);

    }
    function handleDialogDeleteAssistantClose() {
        setOpenDialogDeleteAssistant(false);
    }
    function handleDialogDeleteAssistantOK(){
        setOpenDialogDeleteAssistant(false);

        const _sendString = {};
        _sendString.action = "adminServicesDeleteOpenAiAssistant";
        _sendString.assistant_id = assistantToDeleteId;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }

    const [openDialogThreadMetadata, setOpenDialogThreadMetadata] = useState(false);
    const [openDialogThreadMetadataToShow, setOpenDialogThreadMetadataToShow] = useState({});
    function handleDialogThreadMetadataOpen(thread_id) {
        console.log("handleDialogThreadMetadataOpen : "+thread_id);

        //set the Metadata
        setOpenDialogThreadMetadataToShow(threads.find((_thread) => _thread.thread_id === thread_id));
        setOpenDialogThreadMetadata(true);

    }
    function handleDialogThreadMetadataClose() {
        setOpenDialogThreadMetadata(false);
    }


    const [openDialogDeleteThread, setOpenDialogDeleteThread] = useState(false);
    const [threadToDeleteId, setThreadToDeleteId] = useState("");
    const [threadToDeleteName, setThreadToDeleteName] = useState("");
    function handleDialogDeleteThreadOpen(thread_id) {
        console.log("handleDialogDeleteThreadOpen : "+thread_id);

        setThreadToDeleteId(thread_id);
        setThreadToDeleteName(thread_id);

        setOpenDialogDeleteThread(true);

    }
    function handleDialogDeleteThreadClose() {
        setOpenDialogDeleteThread(false);
    }
    function handleDialogDeleteThreadOK(){
        setOpenDialogDeleteThread(false);

        const _sendString = {};
        _sendString.action = "adminServicesDeleteOpenAiAssistantThread";
        _sendString.assistant_id = activeAssistant;
        _sendString.thread_id = threadToDeleteId;
        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");
    }

    const languageOptions = [
        'English (USA)',
        'English (UK)',
        'English (Australia)',
        'English (Canada)',
        'Japanese',
        'Chinese',
        'German',
        'Hindi',
        'French (France)',
        'French (Canada)',
        'Korean',
        'Portuguese (Brazil)',
        'Portuguese (Portugal)',
        'Italian',
        'Spanish (Spain)',
        'Spanish (Mexico)',
        'Indonesian',
        'Dutch',
        'Turkish',
        'Filipino',
        'Polish',
        'Swedish',
        'Bulgarian',
        'Romanian',
        'Arabic (Saudi Arabia)',
        'Arabic (UAE)',
        'Czech',
        'Greek',
        'Finnish',
        'Croatian',
        'Malay',
        'Slovak',
        'Danish',
        'Tamil',
        'Ukrainian',
    ];
    const [audioOn, setAudioOn] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('English (USA)');

    const handleAudioToggle = () => {
        setAudioOn(!audioOn);
    };

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    };

    function playAudioMessage(_audio_content){

        const binaryString = window.atob(_audio_content);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);

        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const audioData = bytes.buffer;
        const audioPlayer = createAudioPlayer(audioData);

        console.log("ready to play the audio (start)");
        audioPlayer.start();

    }

    function createAudioPlayer(audioData) {
        console.log("createAudioPlayer");

        const audioContext = new AudioContext();
        const audioSource = audioContext.createBufferSource();

        audioContext.decodeAudioData(audioData, (buffer) => {
            audioSource.buffer = buffer;
            audioSource.connect(audioContext.destination);
        });

        return audioSource;
    }


    return (

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Paper style={{ height: '100%' }}>

                        <Badge badgeContent={assistants.length} color="primary">
                            <h2>Assistants</h2>
                        </Badge>

                    {
                        assistants.map((_assistant) => (

                            <Card
                                key={_assistant.id}
                                sx={{
                                    minWidth: 275,
                                    margin: '15px',
                                    backgroundColor: _assistant.id === activeAssistant ? '#e0f7fa' : 'white'
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        {_assistant.name}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5, fontSize: 11}} color="text.secondary">
                                        Created : {dayjs(_assistant.created_at*1000).format('ddd MMM D, YYYY')}
                                    </Typography>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'space-evenly' }}>
                                    <Badge badgeContent={_assistant.file_ids.length} color="primary">
                                        <Chip
                                            sx={addChipStyle}
                                            label="Files"
                                            size="small"
                                            color="success"
                                            onClick={ () => showAssistantFiles(_assistant.id)}
                                        />
                                    </Badge>
                                    <Badge color="primary">
                                        <Chip
                                            sx={addChipStyle}
                                            label="Details"
                                            size="small"
                                            color="success"
                                            onClick={ () => showAssistantDetails(_assistant.id)}
                                        />
                                    </Badge>
                                    <Chip
                                        sx={addChipStyle}
                                        label="Conversations"
                                        size="small"
                                        color="success"
                                        onClick={ () => getAssistantThreadList(_assistant.id)}
                                    />
                                    <Chip
                                        sx={cancelChipStyle}
                                        label="Delete"
                                        size="small"
                                        color="error"
                                        onClick={() => handleDialogDeleteAssistantOpen(_assistant.id)}
                                    />
                                </CardActions>
                            </Card>

                        ))
                    }
                    </Paper>
                </Grid>
                {
                    columnTwoContent === 'Details' ? (
                        <Grid item xs={4}>
                            <Paper style={{ height: '100%' }}>
                                {/* Content for the second column */}
                                <h2>{assistants.find((_assistant) => _assistant.id === activeAssistant).name} Details</h2>
                                <Card
                                    sx={{
                                        minWidth: 275,
                                        margin: '15px'
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            sx={{cursor: 'pointer'}}
                                            variant="h7"
                                            component="div"
                                            onClick={() => setOpenDialogUpdateAssistantName(true)}
                                        >
                                            Name :
                                            {
                                                assistants.find((_assistant) => _assistant.id === activeAssistant).name
                                            }
                                        </Typography>
                                        <Typography
                                            sx={{ mb: 1.5, fontSize: 11, cursor: 'pointer'}}
                                            color="text.secondary"
                                            onClick={() => setOpenDialogUpdateAssistantDescription(true)}
                                        >
                                            Description :
                                            {
                                                assistants.find((_assistant) => _assistant.id === activeAssistant).description
                                            }
                                        </Typography>
                                        <Typography
                                            sx={{ mb: 1.5, fontSize: 12, cursor: 'pointer'}}
                                            color="text.secondary"
                                            onClick={showAssistantDetailsInstructions}
                                        >
                                            Instructions :
                                            {
                                                assistants.find((_assistant) => _assistant.id === activeAssistant).instructions
                                                    ? assistants.find((_assistant) => _assistant.id === activeAssistant).instructions.length > 100
                                                        ? assistants.find((_assistant) => _assistant.id === activeAssistant).instructions.slice(0, 100) + ' ... ' + (assistants.find((_assistant) => _assistant.id === activeAssistant).instructions.length - 100) + ' chars'
                                                        : assistants.find((_assistant) => _assistant.id === activeAssistant).instructions
                                                    : ''
                                            }
                                        </Typography>
                                        <Badge badgeContent={assistants.find((_assistant) => _assistant.id === activeAssistant).tools.length} color="primary">
                                            <Chip
                                                sx={addChipStyle}
                                                label="Functions"
                                                size="small"
                                                color="success"
                                                onClick={showAssistantDetailsFunctions}
                                            />
                                        </Badge>
                                        <Typography sx={{ fontSize: 11, textAlign:'right'}} color="text.secondary">
                                            ID :
                                            {
                                                assistants.find((_assistant) => _assistant.id === activeAssistant).id
                                            }
                                        </Typography>
                                        <Typography sx={{ fontSize: 11, textAlign:'right'}} color="text.secondary">
                                            Created :
                                            {
                                                dayjs.unix(assistants.find((_assistant) => _assistant.id === activeAssistant).created_at).format('ddd MMM D, YYYY h:mm A')
                                            }
                                        </Typography>
                                    </CardContent>

                                </Card>

                            </Paper>
                        </Grid>
                    ) : (
                        columnTwoContent === 'Files' ? (
                            <Grid item xs={4}>
                                <Paper style={{ height: '100%' }}>
                                    {/* Content for the second column */}
                                    <Badge badgeContent={threads.length} color="primary">
                                        <h2>Files</h2>
                                    </Badge>
                                    {
                                        assistants
                                            .find((_assistant) => _assistant.id === activeAssistant)
                                            .file_ids.map((_file, _index) => (
                                                <Typography key={_index} variant="h7" component="div">
                                                    {_file}
                                                </Typography>
                                            ))
                                    }
                                </Paper>
                            </Grid>
                        ):(
                            <Grid item xs={4}>
                                <Paper style={{ height: '100%' }}>
                                    {/* Content for the second column */}
                                    <Badge badgeContent={threads.length} color="primary">
                                        <h2>Conversations</h2>
                                    </Badge>

                                    {
                                        activeAssistant !== '' ? (
                                            <Chip
                                                sx={addChipStyle}
                                                label="Add"
                                                size="small"
                                                color="success"
                                                onClick={createAssistantThread}
                                            />
                                        ):(
                                            <></>
                                        )

                                    }

                                    {
                                        threads.map((_thread) => (

                                            <Card
                                                key={_thread.thread_id}
                                                sx={{
                                                    minWidth: 275,
                                                    margin: '15px',
                                                    backgroundColor: _thread.thread_id === activeThread ? '#e0f7fa' : 'white'
                                                }}
                                            >
                                                <CardContent>
                                                    <Typography variant="h7" component="div">
                                                        {
                                                            _thread["metadata"].assistantName
                                                        }
                                                    </Typography>
                                                    <Typography sx={{ mb: 1.5, fontSize: 11}} color="text.secondary">
                                                        Created : {dayjs.unix(_thread.created_at).format('ddd MMM D, YYYY h:mm A')}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions style={{ justifyContent: 'space-evenly' }}>
                                                    <Chip
                                                        sx={addChipStyle}
                                                        label="Messages"
                                                        size="small"
                                                        color="success"
                                                        onClick={ () => getAssistantThreadMessages(_thread.assistant_id, _thread.thread_id) }
                                                    />
                                                    <Chip
                                                        sx={addChipStyle}
                                                        label="MetaData"
                                                        size="small"
                                                        color="success"
                                                        onClick={ () => handleDialogThreadMetadataOpen(_thread.thread_id)}
                                                    />
                                                    <Chip
                                                        sx={cancelChipStyle}
                                                        label="Delete"
                                                        size="small"
                                                        color="error"
                                                        onClick={() => handleDialogDeleteThreadOpen(_thread.thread_id)}
                                                    />
                                                </CardActions>
                                            </Card>
                                        ))
                                    }
                                </Paper>
                            </Grid>
                        )
                    )
                }

                {
                    columnThreeContent === 'Instructions' ? (
                        <Grid item xs={4}>
                            <Paper style={{ height: '100%' }}>
                                {/* Content for the third column */}
                                <Badge color="primary">
                                    <h2>Instructions</h2>
                                </Badge>
                                <Container>
                                    <Box
                                        style={{
                                            height: '50vh', // Adjust the height as needed
                                            display: 'flex',
                                            flexDirection: 'column',
                                            overflowY: 'auto',
                                        }}
                                    >
                                        <TextField
                                            multiline
                                            fullWidth
                                            value={assistantInstructions}
                                            onChange={handleAssistantInstructionsChange}                                        />
                                    </Box>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleUpdateAssistantInstructions}>
                                            Update
                                        </Button>
                                    </Box>

                                </Container>
                            </Paper>
                        </Grid>
                    ) : (
                        columnThreeContent === 'Functions' ? (
                            <Grid item xs={4}>
                                <Paper style={{ height: '100%' }}>
                                    {/* Content for the third column */}
                                    <Badge color="primary">
                                        <h2>Functions</h2>
                                    </Badge>
                                    <Container>
                                        <Box
                                            style={{
                                                height: '50vh', // Adjust the height as needed
                                                overflowY: 'auto',
                                            }}
                                        >
                                            {
                                                assistants.find((_assistant) => _assistant.id === activeAssistant).tools
                                                    .sort((a, b) => {
                                                        const nameA = a.type === 'function' ? a.function.name : '';
                                                        const nameB = b.type === 'function' ? b.function.name : '';
                                                        return nameA.localeCompare(nameB);
                                                    })
                                                    .map((_tool, _index) => (
                                                        _tool.type === 'function' ? (
                                                            <div
                                                                key={_index}
                                                                style={{marginBottom:'20px'}}
                                                            >
                                                                <Typography
                                                                    variant="subtitle1"
                                                                    component="div"
                                                                >
                                                                    Name : {_tool.function.name}
                                                                </Typography>
                                                                <Typography
                                                                    sx={{ mb: 1.5, fontSize: 11}} color="text.secondary"
                                                                    component="div"
                                                                >
                                                                    Description : {_tool.function.description}
                                                                </Typography>

                                                                <Grid container spacing={1}>
                                                                    {Object.keys(_tool.function.parameters.properties || {}).map((_propertyKey, _propertyIndex) => {
                                                                        const _propertyValue = _tool.function.parameters.properties[_propertyKey];

                                                                        return (
                                                                            <Grid container item xs={12} key={_propertyIndex} spacing={1}>
                                                                                <Grid item xs={3}>
                                                                                    <Typography
                                                                                        style={{ fontSize: 11, color: 'text.secondary' }}
                                                                                    >
                                                                                        <strong>{_propertyKey}:</strong>
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={9}>
                                                                                    <Typography
                                                                                        style={{ fontSize: 11, color: 'text.secondary' }}
                                                                                    >
                                                                                        {_propertyValue.description}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                                <Divider sx={{mt:'10px'}}/>
                                                            </div>
                                                        ):(
                                                            <div key={_index}></div>
                                                        )

                                                    ))

                                            }

                                        </Box>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setOpenDialogUpdateAssistantFunctions(true)}
                                            >
                                                Edit
                                            </Button>
                                        </Box>
                                    </Container>
                                </Paper>
                            </Grid>
                        ) : (
                            columnThreeContent === 'Messages' ? (
                                <Grid item xs={4}>
                                    <Paper style={{ height: '100%' }}>
                                        {/* Content for the third column */}
                                        <Badge badgeContent={messages.length} color="primary">
                                            <h2>Messages</h2>
                                        </Badge>
                                        <Container>
                                            <Box
                                                style={{
                                                    height: '50vh', // Adjust the height as needed
                                                    display: 'flex',
                                                    flexDirection: 'column-reverse', // Reverse the order of messages
                                                    overflowY: 'auto',
                                                }}
                                            >
                                                {
                                                    messages.map((message, index) => (
                                                        <div
                                                            key={message.id}
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent: message.role === 'assistant' ? 'flex-start' : 'flex-end',
                                                            }}
                                                        >
                                                            <div style={{
                                                                position: 'relative',
                                                                maxWidth: '70%',
                                                                backgroundColor: message.role === 'assistant' ? '#e0f7fa' : '#b3e0f7',
                                                                padding: '8px',
                                                                borderRadius: '12px',
                                                                marginBottom: '8px'
                                                            }}>
                                                                <Typography
                                                                    sx={{ fontSize: '13px' }}
                                                                    variant="body2"
                                                                    component="div"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: message.content[0].text.value,
                                                                    }}
                                                                />
                                                                <Typography
                                                                    component="div"
                                                                    sx={{
                                                                        fontSize: '11px',
                                                                        textAlign: message.role === 'assistant' ? 'left' : 'right',
                                                                        paddingRight: message.role === 'assistant' ? '0' : '15px'
                                                                    }}
                                                                    variant="caption"
                                                                >
                                                                    {dayjs.unix(message.created_at).format('ddd, MMM D, YYYY h:mm A')}
                                                                </Typography>

                                                                {/* Indicator for messages that aren't from 'assistant' */}
                                                                {
                                                                    message.role !== 'assistant' ? (

                                                                            messageStatusList.length > 0 ? (
                                                                                messageStatusList.find((status) => status.message_id === message.id && status.status === 'in_progress') ? (
                                                                                        <div
                                                                                            style={{
                                                                                                position: 'absolute',
                                                                                                bottom: '6px',
                                                                                                right: '2px',
                                                                                                padding: '4px',
                                                                                                borderRadius: '50%',
                                                                                                marginLeft: '15px'
                                                                                            }}
                                                                                        >
                                                                                            <i className="icon-spinner4" style={{ color: 'red', fontSize:'10px' }} />
                                                                                        </div>
                                                                                ) : (
                                                                                    <></>
                                                                                )
                                                                            ) : (
                                                                                <></>
                                                                            )

                                                                    ) : (
                                                                        message.audio === undefined ? (
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                bottom: 0,
                                                                                right: 0,
                                                                                padding: '4px',
                                                                            }}
                                                                            >
                                                                                <VolumeOffRounded
                                                                                    style={{fontSize: '13px'}}/>
                                                                            </div>
                                                                        ):(
                                                                            <div style={{
                                                                                position: 'absolute',
                                                                                bottom: 0,
                                                                                right: 0,
                                                                                padding: '4px',
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            >
                                                                                <VolumeUpIcon
                                                                                    style={{fontSize:'13px'}}
                                                                                    onClick={()=> playAudioMessage(message.audio)}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    )
                                                                }

                                                            </div>

                                                            {
                                                                message.file_ids.length > 0 && (
                                                                    <Avatar
                                                                        style={{
                                                                            marginLeft: '8px',
                                                                            backgroundColor: '#2196f3', // Change this color as needed
                                                                        }}
                                                                    >
                                                                        <AttachFileIcon />
                                                                    </Avatar>
                                                                )
                                                            }
                                                        </div>
                                                    ))
                                                }

                                            </Box>
                                            <Box>
                                                <TextField
                                                    multiline
                                                    maxRows={10}
                                                    fullWidth
                                                    variant="outlined"
                                                    value={newMessage}
                                                    onKeyDown={handleKeyPress}
                                                    onChange={(e) => setNewMessage(e.target.value)}
                                                />
                                                <Button
                                                    disabled={!messageStatusList.every((status) => status.status === 'completed')}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleSendMessage}>
                                                    Send
                                                </Button>
                                            </Box>
                                            <Grid container spacing={2} style={{ padding: 16 }}>
                                                {/* Switch for Audio */}
                                                <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <FormControl component="fieldset" variant="standard">
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch checked={audioOn} onChange={handleAudioToggle} name="audio" />
                                                                }
                                                                label="Audio"
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>

                                                {/* Dropdown for Language */}
                                                {/*
                                                    <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                            <InputLabel id="demo-simple-select-standard-label">Language</InputLabel>
                                                            <Select value={selectedLanguage} onChange={handleLanguageChange}>
                                                                {languageOptions.map((language, index) => (
                                                                    <MenuItem key={index} value={language}>
                                                                        {language}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                */}
                                            </Grid>
                                        </Container>
                                    </Paper>
                                </Grid>
                            ) : (
                                <></>
                            )
                        )
                    )
                }

                <Dialog
                    open={openDialogUpdateAssistantName}
                    onClose={handleDialogUpdateAssistantNameClose}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please enter the new Assistant Name
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="updated_assistant_name"
                            name="updated_assistant_name"
                            label="Assistant Name"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={updatedAssistantName}
                            onChange={(e) => setUpdatedAssistantName(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogUpdateAssistantNameClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleDialogUpdateAssistantNameOK}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDialogUpdateAssistantDescription}
                    onClose={handleDialogUpdateAssistantDescriptionClose}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Please enter the new Assistant Description
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="updated_assistant_description"
                            name="updated_assistant_description"
                            label="Assistant Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={updatedAssistantDescription}
                            onChange={(e) => setUpdatedAssistantDescription(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogUpdateAssistantDescriptionClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleDialogUpdateAssistantDescriptionOK}>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDialogUpdateAssistantFunctions}
                    onClose={handleDialogUpdateAssistantFunctionsClose}
                >
                    <DialogTitle id="scroll-dialog-title">Select Assistant Functions {assistantFunctionsSelected.length}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-functions">
                            {
                                assistantFunctionsList
                                    .sort((a, b) => {
                                        return a.name.localeCompare(b.name);
                                    })
                                    .map((_function, _index) => (
                                    <ListItem
                                        key={_index}
                                        secondaryAction={
                                            <Checkbox
                                                edge="end"
                                                onChange={()=>toggleSelectedAssistantFunction(_function.name)}
                                                checked={assistantFunctionsSelected.some(item => item.name === _function.name)}
                                            />
                                        }
                                    >
                                        <ListItemText
                                            primary={`${_index + 1}. ${_function.name}`}
                                            secondary={_function.description}
                                            primaryTypographyProps={{ style: { fontWeight: 'bold', fontSize: '13px' } }} // Adjust styling for the primary text
                                            secondaryTypographyProps={{ style: { fontSize: '12px' } }} // Adjust styling for the secondary text
                                        />
                                    </ListItem>
                                ))
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogUpdateAssistantFunctionsClose}>
                            Cancel
                        </Button>
                        <Button onClick={handleDialogUpdateAssistantFunctionsOK}>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={openDialogThreadMetadata}
                    onClose={handleDialogThreadMetadataClose}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">

                                {
                                    openDialogThreadMetadataToShow.metadata === undefined || Object.keys(openDialogThreadMetadataToShow.metadata).length === 0 ? (
                                        <></>
                                    ):(
                                        Object.entries(openDialogThreadMetadataToShow.metadata).map(([key, value]) => (
                                            <Typography
                                                key={key}
                                                variant="h7"
                                                component="span"
                                                style={{ display: 'block', width: '100%' }}
                                            >
                                                <strong>{key}:</strong> {value}
                                            </Typography>
                                        ))
                                    )
                                }

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogThreadMetadataClose} autoFocus>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openDialogDeleteAssistant}
                    onClose={handleDialogDeleteAssistantClose}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete {assistantToDeleteName}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogDeleteAssistantClose}>Cancel</Button>
                        <Button onClick={handleDialogDeleteAssistantOK} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openDialogDeleteThread}
                    onClose={handleDialogDeleteThreadClose}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete {threadToDeleteName}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogDeleteThreadClose}>Cancel</Button>
                        <Button onClick={handleDialogDeleteThreadOK} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>


            </Grid>

    )

}

export default Assistants;
