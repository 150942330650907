import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Button, Card, CardActions, CardContent, CardMedia, Checkbox,
    Chip,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    FormControl, FormControlLabel, FormGroup,
    Grid,
    InputLabel,
    MenuItem, Paper,
    Select,
    TextField
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import "./attendees.css";
import country_codes from "../../../sampleData/PhonePrefixes";
import PAAttendee from "../../../MasterEventObjects/Attendee";
import {PAContext} from "../../../Services/PAContext";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import helloHeaderImage from '../../../graphics/Hello_Header_Large.jpg';
import rsvpHeaderImage from '../../../graphics/rsvp.png';
import {addChipStyle, addGradientStyle} from "../../ButtonStyle/Add";
import {cancelChipStyle, cancelGradientStyle} from "../../ButtonStyle/Cancel";

function AttendeesAddNew(props) {

    const inputRef = useRef(null);
    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;


    const [fname,setFname] = useState("");
    const [lname,setLname] = useState("");
    const [nickname,setNickname] = useState("");
    const [address,setAddress] = useState("");
    const [city,setCity] = useState("");
    const [state,setState] = useState("");
    const [zip,setZip] = useState("");
    const [country,setCountry] = useState("");
    const [email,setEmail] = useState("");
    const [phonePrefix,setPhonePrefix] = useState("");
    const [phone,setPhone] = useState("");

    const [welcomeRequested,setWelcomeRequested] = useState(false);
    const [welcomeMessage,setWelcomeMessage] = useState("You can add a personal message here that will be included in the email.");
    const [rsvpRequested,setRsvpRequested] = useState(false);



    function handleFnameChange(event){
        const newValue = event.target.value;
        const capitalizedValue = newValue
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        setFname(capitalizedValue);
        setNickname(capitalizedValue);
    }
    function handleLnameChange(event){
        const newValue = event.target.value;
        const capitalizedValue = newValue
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        setLname(capitalizedValue);

    }
    function handleNicknameChange(event){
        const newValue = event.target.value;
        const capitalizedValue = newValue
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');

        setNickname(capitalizedValue);

    }

    function handleAddressChange(event){
        setAddress(event.target.value);
    }
    function handleCityChange(event){
        setCity(event.target.value);
    }
    function handleStateChange(event){
        setState(event.target.value);
    }
    function handleZipChange(event){
        setZip(event.target.value);
    }
    function handleCountryChange(event){
        setCountry(event.target.value);
    }

    function handleEmailChange(event){
        setEmail(event.target.value);
    }

    function handlePhonePrefixChange(event){
        console.log("setting phone prefix to : "+event.target.value);
        setPhonePrefix(event.target.value);
    }
    function handlePhoneChange(event){
        console.log("setting phone prefix to : "+event.target.value);

        if (phonePrefix === "+1"){
            //format for area code
            const input = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
            let formattedNumber = input;

            if (input.length >= 3) {
                formattedNumber = `(${input.slice(0, 3)})`;

                if (input.length > 3) {
                    formattedNumber += ` ${input.slice(3, 6)}`;

                    if (input.length > 6) {
                        formattedNumber += `-${input.slice(6, 10)}`;
                    }
                }
            }
            setPhone(formattedNumber);
        } else {
            setPhone(event.target.value);
        }

    }

    function handleWelcomeRequested(){
        console.log("handleWelcomeRequested");
        setWelcomeRequested(!welcomeRequested);
    }
    function handleWelcomeMessage(event){
        setWelcomeMessage(event.target.value);
    }
    function handleRsvpRequested(){
        console.log("handleRsvpRequested");
        setRsvpRequested(!rsvpRequested);
    }


    useEffect(() => {

        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            console.log(JSON.stringify(place, null, '\t'));
            console.log(place.formatted_address);

            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            for (const component of place.address_components){
                if (component.types.includes("route")) {
                    _route_exists = true;
                } else if (component.types.includes("country")){
                    _country_code = component.short_name;
                }
            }

            place.address_components.forEach((component) => {
                if (component.types.includes("street_number")){
                    _address_line = component.long_name;
                } else if (component.types.includes("route")){
                    _address_line += " "+component.long_name;
                } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                    _address_line += " "+component.long_name;
                } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                    _address_city = component.long_name;
                } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                    _address_state = component.short_name;
                } else if (component.types.includes("postal_code")){
                    _address_zip = component.long_name;
                } else if (component.types.includes("country")){
                    if (_country_code !== "US"){
                        _address_state = component.long_name;
                    }
                    _address_country = component.short_name;
                }
            });

            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = place.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);

            setAddress(_address_line);
            setCity(_address_city);
            setState(_address_state);
            setZip(_address_zip);
            setCountry(_address_country);

        });

    }, []);


    function handleAttendeeAddOK() {
        console.log("handleAttendeeAddOK");


        if (fname !== "" && lname !== ""){
            console.log("names are ok");

            if ((welcomeRequested || rsvpRequested) && email === ""){
                //email address needed  or unselect the checkboxes
                console.log("welcomeRequested : "+welcomeRequested);
                console.log("rsvpRequested : "+rsvpRequested);
                console.log("email : "+email);

            } else {
                const _new_attendee = new PAAttendee();
                _new_attendee.fname = fname;
                _new_attendee.lname = lname;
                _new_attendee.nickname = nickname;

                _new_attendee.address = address;
                _new_attendee.city = city;
                _new_attendee.state = state;
                _new_attendee.zip = zip;
                _new_attendee.country = country;

                _new_attendee.email = email;

                _new_attendee.phone_prefix = phonePrefix;
                _new_attendee.phone = phone;

                _new_attendee.welcome_requested = welcomeRequested;
                _new_attendee.welcome_message = welcomeMessage;

                _new_attendee.rsvp_requested = rsvpRequested;

                Planning.attendees.push(_new_attendee);
                console.log("new attendee : "+JSON.stringify(_new_attendee, null, '\t'));

                props.setAttendeesUpdated(true);

                navigate('/Planner/Attendees');

            }

        } else {
            //turn them red

        }

    }


    function handlePromptAddNewClose() {
        navigate('/Planner/Attendees')
    }



    const [openAddressExplanationDialog, setOpenAddressExplanationDialog] = useState(false);

    function handleOpenAddressExplanationDialog (){
        setOpenAddressExplanationDialog(true);
    }

    function handleAddressExplanationDialogClose(){
        setOpenAddressExplanationDialog(false);
    }


    return (

        <div className="attendee-list-container">
            {/* show the header */}
            <div
                className="attendee-list-header"
            >
                <div className="attendee-list-header-text">
                    <h5>New Attendee</h5>
                </div>
                <div className="attendee-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptAddNewClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label="Add"
                        size="small"
                        onClick={handleAttendeeAddOK}
                    />
                </div>
            </div>


            {/* show all the fields */}
            <div className="attendee-list-items-container">

                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="fname"
                            label="First Name"
                            required
                            value={fname}
                            onChange={handleFnameChange}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="lname"
                            label="Last Name"
                            required
                            value={lname}
                            onChange={handleLnameChange}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="nickname"
                            label="Nickname"
                            value={nickname}
                            onChange={handleNicknameChange}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1', position:'relative' }}>
                        <TextField
                            fullWidth
                            id="address"
                            label="Address"
                            value={address}
                            onChange={handleAddressChange}
                            inputRef={inputRef}
                        />
                        <i
                            className="icon-question-circle"
                            style={{position:'absolute', top:'15px', right:'0', color:'green', cursor:'pointer'}}
                            onClick={handleOpenAddressExplanationDialog}
                        />
                        <Dialog
                            open={openAddressExplanationDialog}
                            onClose={handleAddressExplanationDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"What is this used for?"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Knowing the Attendees address allows us to provide the following:
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Transportation</strong> : We can show routes to the destination
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Flights</strong> : We can show flight prices for trips where a flight might be involved.
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Timezone</strong> : Before and after the trip we can send scheduled reminders at the correct time.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleAddressExplanationDialogClose} autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="city"
                            label="City"
                            value={city}
                            onChange={handleCityChange}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="state"
                            label="State"
                            value={state}
                            onChange={handleStateChange}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="zip"
                            label="Zip"
                            value={zip}
                            onChange={handleZipChange}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="country"
                            label="Country"
                            value={country}
                            onChange={handleCountryChange}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="email_address"
                            label="Email"
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            helperText="Required if they are going to be logging in"
                        />
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Phone Prefix</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={phonePrefix}
                                onChange={handlePhonePrefixChange}
                                label="Phone Prefix"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {
                                    country_codes.map((item, index) => (
                                        <MenuItem key={index} value={item.dialCode}>
                                            {item.dialCode} : {item.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="phone"
                            label="Phone"
                            value={phone}
                            onChange={handlePhoneChange}
                        />
                    </Grid>

                    <Divider />

                    {/* add the Welcome option */}
                    <div>
                        <Paper elevation={3} sx={{margin: '20px 20px 20px 40px', backgroundColor:  'yellow'}}>
                            <Card>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={helloHeaderImage}
                                    title="welcome email"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h7" component="div">
                                        Send Welcome Email
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        We will send {fname} a welcome email that includes a link
                                        so they can login and start engaging.
                                    </Typography>
                                </CardContent>
                                <CardContent>
                                    <TextField
                                        fullWidth
                                        id="standard-multiline-static"
                                        multiline
                                        rows={4}
                                        defaultValue={welcomeMessage}
                                        onChange={handleWelcomeMessage}
                                        variant="standard"
                                    />
                                </CardContent>
                                <CardActions sx={{justifyContent:'flex-end'}}>
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{fontSize:'12px'}}
                                            control={
                                                <Checkbox
                                                    checked={welcomeRequested}
                                                    onChange={handleWelcomeRequested}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label="Yes, please"
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                </CardActions>
                            </Card>

                        </Paper>
                    </div>

                    {/* add the RSVP option */}
                    <div>
                        <Paper elevation={3} sx={{margin: '20px 20px 20px 40px', backgroundColor:  'yellow'}}>
                            <Card>
                                <CardMedia
                                    sx={{ height: 140 }}
                                    image={rsvpHeaderImage}
                                    title="rsvp email"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h7" component="div">
                                        Send RSVP Email
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        We will send {fname} an email that includes buttons where they can RSVP right from the email.
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{justifyContent:'flex-end'}}>
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{fontSize:'12px'}}
                                            control={
                                                <Checkbox
                                                    checked={rsvpRequested}
                                                    onChange={handleRsvpRequested}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label="Yes, please"
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                </CardActions>
                            </Card>

                        </Paper>
                    </div>

                </Grid>

            </div>
        </div>
    )
}

export default AttendeesAddNew;
