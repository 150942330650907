import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";

import {
    Avatar, Button, ButtonGroup,
    Card,
    CardActions,
    CardContent,
    CardMedia, Checkbox,
    Chip,
    Grid, ListItem, ListItemAvatar,
    TextField
} from "@mui/material";

import Typography from "@mui/material/Typography";
import './lodging.css';
import Badge from "@mui/material/Badge";
import {createTheme} from "@mui/material/styles";
import dayjs from "dayjs";

import _ from 'lodash';
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {DateCalendar, DatePicker, PickersDay} from "@mui/x-date-pickers";
import Divider from "@mui/material/Divider";
import Location from "../../../MasterEventObjects/Location";
import TimeTable from "../../../MasterEventObjects/TimeTable";
import Lodging from "../../../MasterEventObjects/Lodging";
import {addChipStyle} from "../../ButtonStyle/Add";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";


function LodgingAddFromSuggestions(props) {

    const navigate = useNavigate();

    const { dataArray, setPlanning } = useContext(PAContext);
    const { Planning } = dataArray;

    const [lodgingTabSelected, setLodgingTabSelected] = useState("");
    const [lodgingDetails, setLodgingDetails] = useState(props.lodgingFromSuggestions);
    const [lodgingBeginDate, setLodgingBeginDate] = useState(props.eventBeginDate);
    const [lodgingEndDate, setLodgingEndDate] = useState(props.eventBeginDate);
    const [numberOfNights, setNumberOfNights] = useState(1);
    const [lodgingOccupants, setLodgingOccupants] = useState([]);
    const [lodgingOccupantsCosts, setLodgingOccupantsCosts] = useState([]);
    const [lodgingOccupantsCostsUnassigned, setLodgingOccupantsCostsUnassigned] = useState(0);
    const [lodgingCost, setLodgingCost] = useState(0);
    const [lodgingCostCurrency, setLodgingCostCurrency] = useState("");

    const [showCalendarSelectBeginDate, setShowCalendarSelectBeginDate] = useState(false);
    const [showCalendarSelectEndDate, setShowCalendarSelectEndDate] = useState(false);

    const [showPriceOverride, setShowPriceOverride] = useState(false);
    const [assignEqual, setAssignEqual] = useState(true);

    const [bookedThrough, setBookedThrough] = useState("");
    const [confirmationNumber, setConfirmationNumber] = useState("");
    const [reservationName, setReservationName] = useState("");
    const [reservationNumber, setReservationNumber] = useState("");
    const [gateCode, setGateCode] = useState("");
    const [doorCode, setDoorCode] = useState("");
    const [wifiName, setWifiName] = useState("");
    const [wifiPassword, setWifiPassword] = useState("");
    const [notes, setNotes] = useState("");

    console.log("lodgingBeginDate : "+lodgingBeginDate);
    console.log("lodgingEndDate : "+lodgingEndDate);

    useEffect(() => {
        console.log("there's something in the lodgingFromSuggestions : " + JSON.stringify(props.lodgingFromSuggestions, null, '\t'));

        if (Object.keys(props.lodgingFromSuggestions).length > 0){

            const _new_lodging = _.cloneDeep(props.lodgingFromSuggestions);

            setLodgingDetails(_new_lodging);

            //set the number of nights
            console.log("lodgingBeginDate : "+lodgingBeginDate);
            console.log("lodgingEndDate : "+lodgingEndDate);

            if (lodgingBeginDate !== ""){
                const startDate = dayjs.unix(lodgingBeginDate / 1000);
                const endDate = dayjs.unix(lodgingEndDate / 1000);
                setNumberOfNights(endDate.diff(startDate, 'day')+1);
            }

            //set the occupants
            setLodgingOccupants(Planning.attendees.map(_att => _att));


            //set the costs
            try {
                const check_in_date = dayjs(_new_lodging['check_in_date'],'YYYY-MM-DD');
                const check_out_date = dayjs(_new_lodging['check_out_date'],'YYYY-MM-DD');
                console.log("check_in_date : "+check_in_date.format());
                console.log("check_out_date : "+check_out_date.format());

                const _number_of_nights = check_out_date.diff(check_in_date, 'day');
                console.log("_number_of_nights : "+_number_of_nights);

                const _item_price = parseFloat(_new_lodging['accommodation'].cheapest_rate_total_amount);
                console.log("_item_price : "+_item_price);
                const _item_currency = _new_lodging['accommodation'].cheapest_rate_currency;
                setLodgingCostCurrency(_item_currency);
                const _item_per_night_rate = _item_price / _number_of_nights;
                console.log("_item_per_night_rate : "+_item_per_night_rate);

                const _total_lodging_cost = _number_of_nights === 1 ? _item_per_night_rate.toFixed(2) : (_item_per_night_rate * (endDate.diff(startDate, 'day')+1)).toFixed(2);
                setLodgingCost(_total_lodging_cost);

                console.log("_total_lodging_cost : "+_total_lodging_cost);

                //set the Occupants fees
                if (Planning.attendees.length > 0) {
                    const numberOfAttendees = Planning.attendees.length;
                    const baseCostPerAttendee = parseFloat(Math.floor((_total_lodging_cost / numberOfAttendees) * 100) / 100).toFixed(2);
                    const remainder = Math.round((_total_lodging_cost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                    const updatedAttFees = Planning.attendees.map((_att, index) => ({
                        id: _att.id,
                        costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
                    }));
                    console.log("updatedAttFees : " + JSON.stringify(updatedAttFees, null, '\t'));
                    setLodgingOccupantsCosts(updatedAttFees);
                }

            } catch (e){
                console.log("error : "+e);
                setNumberOfNights(1);
                setLodgingCost(0);
            }
        }

    }, [props.lodgingFromSuggestions]);

    useEffect(() => {

        if (props.eventBeginDate !== null && props.eventEndDate !== null){

            setLodgingBeginDate(props.eventBeginDate);
            setLodgingEndDate(props.eventEndDate);

            let _event_day_count = 0;
            if (props.eventBeginDate !== ""){
                const startDate = dayjs.unix(props.eventBeginDate / 1000);
                const endDate = dayjs.unix(props.eventEndDate / 1000);
                _event_day_count = endDate.diff(startDate, 'day')+1;
                setNumberOfNights(_event_day_count);
            }

            console.log("lodgingDetails : "+JSON.stringify(lodgingDetails, null, '\t'));
            if (Object.keys(lodgingDetails).length > 0) {

                //update the numbers
                const check_in_date = dayjs(lodgingDetails['check_in_date'],'YYYY-MM-DD');
                const check_out_date = dayjs(lodgingDetails['check_out_date'],'YYYY-MM-DD');
                console.log("check_in_date : "+check_in_date.format());
                console.log("check_out_date : "+check_out_date.format());

                const _number_of_nights = check_out_date.diff(check_in_date, 'day');
                console.log("_number_of_nights : "+_number_of_nights);

                const _item_price = parseFloat(lodgingDetails['accommodation'].cheapest_rate_total_amount);
                console.log("_item_price : "+_item_price);
                const _item_currency = lodgingDetails['accommodation'].cheapest_rate_currency;
                setLodgingCostCurrency(_item_currency);
                const _item_per_night_rate = _item_price / _number_of_nights;
                console.log("_item_per_night_rate : "+_item_per_night_rate);

                const _new_lodging_cost = _event_day_count === 1 ? _item_per_night_rate.toFixed(2) : (_item_per_night_rate * _event_day_count).toFixed(2);
                console.log("_new_lodging_cost : "+_new_lodging_cost);
                setLodgingCost(_new_lodging_cost);


                //now re-figure the costs - just do even split
                console.log("lodgingOccupants : " + lodgingOccupants.length);
                if (lodgingOccupants.length > 0) {
                    const numberOfAttendees = lodgingOccupants.length;
                    const baseCostPerAttendee = parseFloat(Math.floor((_new_lodging_cost / numberOfAttendees) * 100) / 100).toFixed(2);
                    console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                    console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                    const remainder = Math.round((_new_lodging_cost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                    console.log("remainder : " + remainder);

                    const updatedAttFees = lodgingOccupants.map((_att, index) => ({
                        id: _att.id,
                        costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
                    }));
                    console.log(JSON.stringify(updatedAttFees, null, '\t'));
                    setLodgingOccupantsCosts(updatedAttFees);
                } else {
                    //set the occupants
                    setLodgingOccupants(Planning.attendees.map(_att => _att));


                    //set the costs
                    try {
                        //set the Occupants fees
                        if (Planning.attendees.length > 0) {
                            const numberOfAttendees = Planning.attendees.length;
                            const baseCostPerAttendee = parseFloat(Math.floor((_new_lodging_cost / numberOfAttendees) * 100) / 100).toFixed(2);
                            const remainder = Math.round((_new_lodging_cost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                            const updatedAttFees = Planning.attendees.map((_att, index) => ({
                                id: _att.id,
                                costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
                            }));
                            console.log("updatedAttFees : " + JSON.stringify(updatedAttFees, null, '\t'));
                            setLodgingOccupantsCosts(updatedAttFees);
                        }

                    } catch (e){
                        console.log("error : "+e);
                        setNumberOfNights(1);
                        setLodgingCost(0);
                    }
                }

                //update the unassigned cost
                setLodgingOccupantsCostsUnassigned(0);

                setAssignEqual(true);
            }

        }

    },[props.eventBeginDate, props.eventEndDate]);


    function handlePromptAddLodgingOK() {
        console.log("handlePromptAddLodgingOK");

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode(
            {
                'latLng': new window.google.maps.LatLng(
                    lodgingDetails['accommodation']['location']['geographic_coordinates'].latitude,
                    lodgingDetails['accommodation']['location']['geographic_coordinates'].longitude
                )
            },

            function (place, status) {

                console.log("status 1: " + status);
                console.log("The returned place : " + JSON.stringify(place, null, '\t'));

                if (status === 'OK' && place.length > 0) {

                    //the coordinates
                    const _geometry = place[0].geometry;
                    console.log("_geometry : "+JSON.stringify(_geometry, null, '\t'));
                    console.log("latitude : "+_geometry.location.lat());
                    console.log("longitude : "+_geometry.location.lng());

                    //just look at the first address

                    let _address_line = "";
                    let _address_city = "";
                    let _address_state = "";
                    let _address_zip = "";
                    let _address_country = "";

                    console.log("_address_line: " + _address_line);
                    console.log("_address_city: " + _address_city);
                    console.log("_address_state: " + _address_state);
                    console.log("_address_zip: " + _address_zip);
                    console.log("_address_country: " + _address_country);

                    let _route_exists = false;
                    let _country_code = "";
                    for (const component of place[0].address_components){
                        if (component.types.includes("route")) {
                            _route_exists = true;
                        } else if (component.types.includes("country")){
                            _country_code = component.short_name;
                        }
                    }

                    place[0].address_components.forEach((component) => {
                        if (component.types.includes("street_number")){
                            _address_line = component.long_name;
                        } else if (component.types.includes("route")){
                            _address_line += " "+component.long_name;
                        } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                            _address_line += " "+component.long_name;
                        } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                            _address_city = component.long_name;
                        } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                            _address_state = component.short_name;
                        } else if (component.types.includes("postal_code")){
                            _address_zip = component.long_name;
                        } else if (component.types.includes("country")){
                            if (_country_code !== "US"){
                                _address_state = component.long_name;
                            }
                            _address_country = component.short_name;
                        }
                    });

                    if (_address_line === ""){
                        //try getting it from the formatted address
                        try {
                            const _f_address_pieces = place[0].formatted_address.split(',');
                            if (_f_address_pieces[0] !== ""){
                                _address_line = _f_address_pieces[0].trim();
                            }
                        } catch (e){}
                    }

                    console.log("_address_line: " + _address_line);
                    console.log("_address_city: " + _address_city);
                    console.log("_address_state: " + _address_state);
                    console.log("_address_zip: " + _address_zip);
                    console.log("_address_country: " + _address_country);

                    const lodgingLocation = new Location();

                    lodgingLocation.google_place_id = place[0].place_id;
                    lodgingLocation.latitude = _geometry.location.lat();
                    lodgingLocation.longitude = _geometry.location.lng();

                    lodgingLocation.formatted_address = place[0].formatted_address;
                    lodgingLocation.address = _address_line;
                    lodgingLocation.city = _address_city;
                    lodgingLocation.state = _address_state;
                    lodgingLocation.zip = _address_zip;
                    lodgingLocation.country = _address_country;

                    const timeTable = new TimeTable();
                    if (lodgingBeginDate){


                    }


                    const _new_lodging = new Lodging();

                    _new_lodging.id = 0;

                    _new_lodging.name = lodgingDetails['accommodation'].name;

                    _new_lodging.timeTable = timeTable;
                    _new_lodging.begin_date = lodgingBeginDate;
                    _new_lodging.end_date = lodgingEndDate;
                    _new_lodging.check_in_time = "";
                    _new_lodging.check_out_time = "";

                    _new_lodging.location = lodgingLocation;

                    _new_lodging.phone = place[0].international_phone_number;

                    _new_lodging.booked_through = bookedThrough;
                    _new_lodging.confirmation_number = confirmationNumber;
                    _new_lodging.reservation_name = reservationName;
                    _new_lodging.reservation_number = reservationNumber;
                    _new_lodging.gate_code = gateCode;
                    _new_lodging.door_code = doorCode;
                    _new_lodging.wifi_name = wifiName;
                    _new_lodging.wifi_password = wifiPassword;

                    _new_lodging.attendees = lodgingOccupantsCosts;   // "{attendee_id: 'we3423', costs:100.00}"

                    _new_lodging.amount_total = lodgingCost;
                    _new_lodging.currency = lodgingCostCurrency;

                    _new_lodging.Duffel = _.cloneDeep(lodgingDetails);

                    _new_lodging.notes= notes;

                    Planning.lodging.push(_new_lodging);

                    //update the Event lodging expense and total expense
                    props.setEventBudgetActual(props.eventBudgetActual - -lodgingCost);


                    //update the expenses for each Occupant
                    Planning.attendees = Planning.attendees.map((attendee) => {
                        const matchingCost = lodgingOccupantsCosts.find((cost) => cost.id === attendee.id);
                        console.log("matchingCost : "+JSON.stringify(matchingCost));
                        if (matchingCost) {
                            const updatedAttendee = {
                                ...attendee,
                                lodging_costs: (attendee.lodging_costs || 0) + parseFloat(matchingCost.costs),
                                balance: (attendee.balance || 0) + parseFloat(matchingCost.costs),
                            };
                            console.log("updatedAttendee : "+JSON.stringify(updatedAttendee));
                            return updatedAttendee;
                        }
                        return attendee;
                    });

                    console.log("Planning after updating attendee lodging : "+JSON.stringify(Planning, null, '\t'));

                    setPlanning(Planning);

                    handlePromptAddLodgingClose();
                }
            }
        );

    }

    function handlePromptAddLodgingClose() {
        navigate('/Planner/Lodging');
    }


    const formatName = (name) => {
        // Capitalize the first letter and make the rest lowercase
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }
    function stringAvatar(name) {
        return {
            sx: {
                boxShadow: `0 0 5px ${stringToColor(name)}`,
                border: `1px solid ${stringToColor(name)}`,
                borderRadius: '10px',
                textAlign: 'center',
                fontWeight: '700',
                backgroundColor: '#FFF',
                color: 'black', // Text color (adjust as needed)
                fontSize: '15px'
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }



    //build the array of dates
    const startDate = dayjs.unix(lodgingBeginDate / 1000);
    const endDate = dayjs.unix(lodgingEndDate / 1000);

    // Calculate the range of dates
    const highlightedDates = Array.from(
        { length: endDate.diff(startDate, 'day') + 1 }, // Calculate the number of days between begin_date and end_date
        (_, index) =>
            startDate
                .add(index, 'day')
                .toISOString() // Convert the date to ISO format
                .slice(0, 10) // Extract the YYYY-MM-DD portion
    );
    console.log("the highlightedDates : " + highlightedDates);

    function ServerDay(props) {
        const { highlightedDates = [], day, outsideCurrentMonth, ...other } = props;

        const isSelected =
            !props.outsideCurrentMonth &&
            highlightedDates.includes(props.day.format('YYYY-MM-DD'));

        return (
            <Badge
                key={props.day.toString()}
                variant={isSelected ? "dot" : "standard"} // Use variant="dot" for a dot, "standard" for a regular badge
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{
                    '& .MuiBadge-badge': {
                        backgroundColor: isSelected ? 'red' : 'transparent', // Customize the dot color here
                        width: '8px', // Adjust the dot size as needed
                        height: '8px',
                        borderRadius: '50%', // Make it a circle
                        top: '10px', // Adjust top and right values to move the dot
                        right: '10px', // Adjust top and right values to move the dot
                    },
                }}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Badge>
        );
    }

    function handlePromptShowLodgingBeginDateCalendar(){
        //toggle the button
        setShowCalendarSelectBeginDate(!showCalendarSelectBeginDate);
    }

    function handlePromptShowLodgingEndDateCalendar(){
        //toggle the button
        setShowCalendarSelectEndDate(!showCalendarSelectEndDate);

    }

    function handleUpdatedBeginDate(e) {
        console.log("handleUpdatedBeginDate : "+e);
        setLodgingBeginDate(e);
        setLodgingEndDate(dayjs(e).add(1, 'day'));
        setShowCalendarSelectBeginDate(false);

        props.setEventBeginDate(e);

    }
    function handleUpdatedEndDate(e) {
        console.log("handleUpdatedEndDate : "+e);
        setLodgingEndDate(e);
        setShowCalendarSelectEndDate(false);

        props.setEventEndDate(e);

    }


    const handleOccupantsToggle = (value) => () => {
        console.log("value : "+value);
        const _exists = lodgingOccupants.some((_att) => _att.id === value);
        console.log(_exists);
        let newChecked = [...lodgingOccupants];
        if (!_exists) {
            newChecked.push(Planning.attendees.find((_att) => _att.id === value));
        } else {
            newChecked = newChecked.filter((_att) => _att.id !== value);
        }
        console.log("newChecked : "+newChecked);
        setLodgingOccupants(newChecked);

        //now for the lodgingOccupantsCosts
        const _costs_exists = lodgingOccupantsCosts.some((_att) => _att.id === value);
        console.log(_costs_exists);
        let newCostsChecked = [...lodgingOccupantsCosts];
        if (!_costs_exists) {
            // Use find to get the matching attendee and push it
            const matchingAttendee = Planning.attendees.find((_att) => _att.id === value);
            if (matchingAttendee) {
                newCostsChecked.push({
                    id: matchingAttendee.id,
                    costs: parseFloat(0).toFixed(2),
                });
            }
        } else {
            newCostsChecked = newCostsChecked.filter((_att) => _att.id !== value);
        }

        //now re-figure the costs - just do even split
        const numberOfAttendees = newCostsChecked.length;
        const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
        console.log("baseCostPerAttendee : " + baseCostPerAttendee);
        console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
        const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
        console.log("remainder : " + remainder);

        const updatedAttFees = newCostsChecked.map((_att, index) => ({
            id: _att.id,
            costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
        }));
        console.log(JSON.stringify(updatedAttFees, null, '\t'));

        setLodgingOccupantsCosts(updatedAttFees);

        setLodgingOccupantsCostsUnassigned(0);

        setAssignEqual(true);
    };

    function toggleShowOverride(){
        setShowPriceOverride(!showPriceOverride);
    }

    function handleCostOverride(event){
        console.log("handleCostOverride : "+event.target.value);
        setLodgingCost(event.target.value);

        //update the occupants to evenly share the cost
        console.log("lodgingOccupants : " + lodgingOccupants.length);
        if (lodgingOccupants.length > 0) {
            const numberOfAttendees = lodgingOccupants.length;
            const baseCostPerAttendee = parseFloat(Math.floor((event.target.value / numberOfAttendees) * 100) / 100).toFixed(2);
            console.log("baseCostPerAttendee : " + baseCostPerAttendee);
            console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
            const remainder = Math.round((event.target.value - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
            console.log("remainder : " + remainder);

            const updatedAttFees = lodgingOccupants.map((_att, index) => ({
                id: _att.id,
                costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
            }));
            console.log(JSON.stringify(updatedAttFees, null, '\t'));
            setLodgingOccupantsCosts(updatedAttFees);
        }

        //update the unassigned cost
        setLodgingOccupantsCostsUnassigned(0);

    }

    function handleToggleAssignEqual(){

        if (assignEqual){
            //they are changing to custom

        } else {
            //they are changing to equal
            console.log("lodgingOccupants : " + lodgingOccupants.length);
            if (lodgingOccupants.length > 0) {
                const numberOfAttendees = lodgingOccupants.length;
                const baseCostPerAttendee = parseFloat(Math.floor((lodgingCost / numberOfAttendees) * 100) / 100).toFixed(2);
                console.log("baseCostPerAttendee : " + baseCostPerAttendee);
                console.log("occupants total : " + parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2));
                const remainder = Math.round((lodgingCost - parseFloat(baseCostPerAttendee * numberOfAttendees).toFixed(2)) * 100) / 100;
                console.log("remainder : " + remainder);

                const updatedAttFees = lodgingOccupants.map((_att, index) => ({
                    id: _att.id,
                    costs: parseFloat(baseCostPerAttendee - (index === 0 ? -remainder : 0)).toFixed(2),
                }));
                console.log(JSON.stringify(updatedAttFees, null, '\t'));
                setLodgingOccupantsCosts(updatedAttFees);
            }

            //update the unassigned cost
            setLodgingOccupantsCostsUnassigned(0);

        }

        setAssignEqual(!assignEqual);

    }

    function handleCustomOccupantCost(_attendee_id, e){
        console.log("handleCustomOccupantCost : "+_attendee_id+" : "+e.target.value);

        const updatedLodgingOccupantsCosts = lodgingOccupantsCosts.map((attendee) => {
            if (attendee.id === _attendee_id) {
                // Update the costs for the matching attendee
                console.log("---- attendee : "+JSON.stringify(attendee));
                return { ...attendee, costs: e.target.value };
            }
            console.log("---- attendee : "+JSON.stringify(attendee));
            return attendee; // Keep other attendees unchanged
        });

        // Use the state updater function to work with the updated state
        setLodgingOccupantsCosts((prevCosts) => {

            const _new_unassigned = parseFloat(lodgingCost - parseFloat(updatedLodgingOccupantsCosts.reduce((sum, attendee) => sum - -attendee.costs, 0)).toFixed(2));
            console.log("_new_unassigned: " + _new_unassigned);
            setLodgingOccupantsCostsUnassigned(_new_unassigned);

            return updatedLodgingOccupantsCosts;
        });

    }

    function handleBookedThroughChange(event){
        setBookedThrough(event.target.value);
    }
    function handleConfirmationNumberChange(event){
        setConfirmationNumber(event.target.value);
    }
    function handleReservationNameChange(event){
        setReservationName(event.target.value);
    }
    function handleReservationNumberChange(event){
        setReservationNumber(event.target.value);
    }
    function handleGateCodeChange(event){
        setGateCode(event.target.value);
    }
    function handleDoorCodeChange(event){
        setDoorCode(event.target.value);
    }
    function handleWifiNameChange(event){
        setWifiName(event.target.value);
    }
    function handleWifiPasswordChange(event){
        setWifiPassword(event.target.value);
    }
    function handleNotesChange(event){
        setNotes(event.target.value);
    }

    function handleFooterItemClick(_item){
        lodgingTabSelected === _item ? setLodgingTabSelected("") : setLodgingTabSelected(_item)
    }

    const theme = createTheme({
        palette: {
            badge: {
                main: '#cbe0f7'
            },
        }
    });



    return (

        <div className="lodging-suggestions-list-container" >
            {/* show the header */}
            <div
                className="lodging-suggestions-list-header"
            >
                <div className="lodging-suggestions-list-header-text">
                    <h5>Add Lodging</h5>
                </div>

                <div className="lodging-suggestions-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        color="error"
                        onClick={handlePromptAddLodgingClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label="Add"
                        size="small"
                        onClick={handlePromptAddLodgingOK}
                    />
                </div>
            </div>

            <div className="lodging-suggestions-add-list-items-container">
                <Grid container spacing={2} direction="column">

                    {
                        Object.keys(lodgingDetails).length > 0 ? (
                            <Grid item sx={{display:'flex'}}>

                                <Card
                                    className="lodging-suggestions-add-list-item"
                                >
                                    {
                                        lodgingDetails['accommodation']['photos'][0] === undefined ? (
                                            <></>
                                        ) : (
                                            <CardMedia
                                                sx={{ height: 100 }}
                                                image={lodgingDetails['accommodation']['photos'][0].url}
                                                title="green iguana"
                                            />
                                        )
                                    }
                                    <CardContent>
                                        <Typography variant="body2">
                                            {lodgingDetails['accommodation'].name}
                                        </Typography>
                                        <Typography sx={{ mb: 1.5, fontSize:'12px' }} color="text.secondary">
                                            {lodgingDetails['accommodation']['location']['address'].line_one}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <div className="lodging-suggestions-add-list-item-footer-container">
                                            {/* add the stay dates */}
                                            <div
                                                style={{backgroundColor: lodgingTabSelected === "Dates" ? '#EDEDED' : 'initial'}}
                                                className="lodging-suggestions-add-list-item-footer-dates-container"
                                                onClick={()=>handleFooterItemClick('Dates')}
                                            >
                                                <Badge
                                                    badgeContent={numberOfNights}
                                                    color="badge"
                                                    showZero
                                                >
                                                    <i className="icon-calendar3" title="Dates"/>
                                                </Badge>
                                            </div>
                                            {/* add the stay occupants */}
                                            <div
                                                style={{backgroundColor: lodgingTabSelected === "Occupants" ? '#EDEDED' : 'initial'}}
                                                className="lodging-suggestions-add-list-item-footer-occupants-container"
                                                onClick={()=>handleFooterItemClick('Occupants')}
                                            >

                                                <Badge
                                                    badgeContent={lodgingOccupants.length}
                                                    color="badge"
                                                    showZero
                                                >
                                                    <i className="icon-user-24"  title="Occupants"/>
                                                </Badge>
                                            </div>
                                            {/* add the stay costs */}
                                            <div
                                                style={{backgroundColor: lodgingTabSelected === "Costs" ? '#EDEDED' : 'initial'}}
                                                className="lodging-suggestions-add-list-item-footer-costs-container"
                                                onClick={()=>handleFooterItemClick('Costs')}
                                            >
                                                <i className="icon-wallet"  title="Costs"/>
                                                <span className="lodging-suggestions-add-list-item-footer-costs-tab-amount">
                                                    {parseFloat(lodgingCost).toFixed(2)}
                                                </span>
                                            </div>
                                            {/* add the stay details */}
                                            <div
                                                style={{backgroundColor: lodgingTabSelected === "Details" ? '#EDEDED' : 'initial'}}
                                                className="lodging-suggestions-add-list-item-footer-details-container"
                                                onClick={()=>handleFooterItemClick('Details')}
                                            >
                                                <Badge
                                                    badgeContent={0}
                                                    color="badge"
                                                >
                                                    <i className="icon-checklist2"  title="Details"/>
                                                </Badge>
                                            </div>
                                        </div>
                                    </CardActions>
                                    <CardContent sx={{padding:'20px 0', display:lodgingTabSelected?'block':'none'}}>
                                        {
                                            lodgingTabSelected === "Dates" ? (
                                                <div className="lodging-suggestions-add-list-item-footer-dates-holder">
                                                    <div
                                                        className="lodging-suggestions-add-list-item-footer-dates-checkin"
                                                    >
                                                        Check In :
                                                        {
                                                            lodgingBeginDate === null ? (
                                                                <>Unknown</>
                                                            ) : (
                                                                dayjs(lodgingBeginDate).format('D MMM, YYYY')
                                                            )
                                                        }
                                                        <Chip
                                                            sx={addChipStyle}
                                                            label={showCalendarSelectBeginDate?'Done':'Edit'}
                                                            size="small"
                                                            onClick={handlePromptShowLodgingBeginDateCalendar}
                                                        />
                                                    </div>
                                                    <div
                                                        className="lodging-suggestions-add-list-item-footer-dates-checkin-input"
                                                        style={{display: showCalendarSelectBeginDate ? 'block' : 'none'}}>
                                                        <DatePicker
                                                            sx={{ width: '100%' }}
                                                            id="event_begin_date_picker"
                                                            label="Check In Date"
                                                            defaultValue={lodgingBeginDate}
                                                            onChange={handleUpdatedBeginDate}
                                                        />
                                                    </div>
                                                    <div
                                                        className="lodging-suggestions-add-list-item-footer-dates-checkout"
                                                    >
                                                        Check Out :
                                                        {
                                                            lodgingEndDate === null ? (
                                                                <>Unknown</>
                                                            ) : (
                                                                dayjs(lodgingEndDate).format('D MMM, YYYY')
                                                            )
                                                        }
                                                        <Chip
                                                            sx={addChipStyle}
                                                            label={showCalendarSelectEndDate?'Done':'Edit'}
                                                            size="small"
                                                            onClick={handlePromptShowLodgingEndDateCalendar}
                                                        />
                                                    </div>
                                                    <div
                                                        className="lodging-suggestions-add-list-item-footer-dates-checkout-input"
                                                        style={{display: showCalendarSelectEndDate ? 'block' : 'none'}}>
                                                        <DatePicker
                                                            sx={{ width: '100%' }}
                                                            id="event_end_date_picker"
                                                            label="Check Out Date"
                                                            defaultValue={lodgingEndDate}
                                                            minDate={lodgingBeginDate}
                                                            onChange={handleUpdatedEndDate}
                                                        />
                                                    </div>
                                                    <div style={{marginTop: '20px'}}>
                                                        <DateCalendar
                                                            showDaysOutsideCurrentMonth
                                                            fixedWeekNumber={6}
                                                            slots={{
                                                                day: ServerDay,
                                                            }}
                                                            slotProps={{
                                                                day: {
                                                                    highlightedDates, // Pass the highlightedDates array to the ServerDay component
                                                                },
                                                            }}
                                                            referenceDate={lodgingBeginDate === null ? dayjs() : dayjs(lodgingBeginDate)}
                                                        />
                                                    </div>
                                                </div>

                                            ) : (
                                                <></>
                                            )
                                        }
                                        {
                                            lodgingTabSelected === "Occupants" ? (
                                                Planning.attendees.length > 0 ? (
                                                    <List dense sx={{ width: '100%'}} >
                                                        {
                                                            Planning.attendees.map((_attendee, index) => (
                                                                <div key={index}>
                                                                    <ListItem
                                                                        secondaryAction={
                                                                            <Checkbox
                                                                                edge="end"
                                                                                onChange={handleOccupantsToggle(_attendee.id)}
                                                                                checked={lodgingOccupants.some((_att) => _att.id === _attendee.id)}
                                                                                inputProps={{ 'aria-labelledby': index }}
                                                                            />
                                                                        }
                                                                        disablePadding
                                                                    >
                                                                        <ListItemButton>
                                                                            <ListItemAvatar>
                                                                                <Avatar {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)} />
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                id={index}
                                                                                primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>

                                                            ))
                                                        }
                                                    </List>
                                                ) : (
                                                    <div style={{fontSize:'12px', textAlign:'center'}}>
                                                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                            <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                        </Typography>
                                                    </div>
                                                )
                                            ) : (
                                                <></>
                                            )

                                        }
                                        {
                                            lodgingTabSelected === "Costs" ? (
                                                <div className="lodging-suggestions-add-list-item-footer-costs-holder">
                                                    {/* Cost FlightSummary */}
                                                    <Typography variant="body2">
                                                        Cost Summary
                                                    </Typography>
                                                    <div
                                                        className="lodging-suggestions-add-list-item-footer-costs-header"
                                                    >
                                                        {numberOfNights} night{numberOfNights===1?'':'s'} at {(lodgingCost/numberOfNights).toFixed(2)}/night</div>
                                                    <div
                                                        className="lodging-suggestions-add-list-item-footer-costs-total-holder"
                                                    >
                                                        <div onClick={toggleShowOverride}>
                                                            <Chip
                                                                sx={addChipStyle}
                                                                label={showPriceOverride?'Done':'Edit'}
                                                                size="small"
                                                            />
                                                        </div>
                                                        <div className="lodging-suggestions-add-list-item-footer-costs-total">
                                                            Total: {parseFloat(lodgingCost).toFixed(2)} {lodgingCostCurrency}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="lodging-suggestions-add-list-item-footer-costs-override-holder"
                                                        style={{display:showPriceOverride?'block':'none'}}
                                                    >
                                                        <div
                                                            className="lodging-suggestions-add-list-item-footer-costs-override-input-holder"
                                                        >
                                                            <TextField
                                                                id="new_lodging_cost_total_override"
                                                                type="number"
                                                                label="Lodging Total"
                                                                step="0.01"
                                                                value={lodgingCost}
                                                                onChange={handleCostOverride}
                                                            />
                                                        </div>
                                                    </div>

                                                    <Divider />

                                                    {/* _new_lodging is the Assignment area */}
                                                    <div className="lodging-suggestions-add-list-item-footer-cost-assignment-holder" >
                                                        <Typography variant="body2">
                                                            Cost Assignment
                                                        </Typography>
                                                        {
                                                            Planning.attendees.length === 0 ? (
                                                                <div
                                                                    className="lodging-suggestions-add-list-item-footer-cost-assignment-no-attendees"
                                                                >
                                                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                                                        <i className="icon-warning" style={{color:'#FF0000'}} /> Add Attendees to Event first
                                                                    </Typography>
                                                                </div>
                                                            ) : (

                                                                <div className="lodging-suggestions-add-list-item-footer-cost-assignment-holder">
                                                                    {/* _new_lodging is the toggle section */}
                                                                    <div className="lodging-suggestions-add-list-item-footer-cost-assignment-toggle-holder">
                                                                        <ButtonGroup variant="text" aria-label="outlined button group">
                                                                            <Button
                                                                                style={{
                                                                                    fontSize: '11px',
                                                                                    backgroundColor: assignEqual
                                                                                        ? 'rgba(198, 226, 255, 0.8)'
                                                                                        : 'initial'
                                                                                }}
                                                                                onClick={handleToggleAssignEqual}
                                                                            >
                                                                                Divide Evenly
                                                                            </Button>
                                                                            <Button
                                                                                style={{
                                                                                    fontSize: '11px',
                                                                                    backgroundColor: !assignEqual
                                                                                        ? 'rgba(198, 226, 255, 0.8)'
                                                                                        : 'initial'
                                                                                }}
                                                                                onClick={handleToggleAssignEqual}
                                                                            >
                                                                                Custom
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </div>

                                                                    {/* _new_lodging is the attendee section */}
                                                                    <List dense sx={{ width: '100%'}} >
                                                                        {
                                                                            lodgingOccupants.map((_attendee, index) => (
                                                                                <div
                                                                                    className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-holder"
                                                                                    key={index}
                                                                                >
                                                                                    <ListItem
                                                                                        className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost-list-item"
                                                                                        secondaryAction={
                                                                                            assignEqual ? (
                                                                                                <span className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-cost">
                                                                    {
                                                                        lodgingOccupantsCosts.find((attendee) => attendee.id === _attendee.id)
                                                                            ? lodgingOccupantsCosts.find((attendee) => attendee.id === _attendee.id).costs
                                                                            : ''
                                                                    } {lodgingCostCurrency}
                                                                </span>
                                                                                            ) : (
                                                                                                <span className="lodging-suggestions-add-list-item-footer-cost-assignment-occupant-edit-cost">
                                                                    <TextField
                                                                        id={"new_lodging_cost_attendee_cost_" + _attendee.id}
                                                                        type="number"
                                                                        step="0.01"
                                                                        label={_attendee.fname + ' ' + _attendee.lname}
                                                                        value={
                                                                            lodgingOccupantsCosts.find((attendee) => attendee.id === _attendee.id)
                                                                                ? lodgingOccupantsCosts.find((attendee) => attendee.id === _attendee.id).costs
                                                                                : ''
                                                                        }
                                                                        onChange={(e)=>handleCustomOccupantCost(_attendee.id, e)}

                                                                        variant="standard"
                                                                    />
                                                                </span>
                                                                                            )
                                                                                        }
                                                                                        disablePadding
                                                                                    >
                                                                                        <ListItemAvatar>
                                                                                            <Avatar {...stringAvatar(`${formatName(_attendee.fname)}, ${formatName(_attendee.lname)}`)} />
                                                                                        </ListItemAvatar>
                                                                                        <ListItemText
                                                                                            sx={{display: assignEqual ? 'block' : 'none'}}
                                                                                            id={index}
                                                                                            primary={`${formatName(_attendee.lname)}, ${formatName(_attendee.fname)}`}
                                                                                        />
                                                                                    </ListItem>
                                                                                    <Divider />
                                                                                </div>

                                                                            ))
                                                                        }
                                                                    </List>

                                                                    {/* show the unassigned */}
                                                                    <div className="lodging-suggestions-add-list-item-footer-cost-assignment-unassigned-holder">
                                                                        <Typography sx={{ mb: 1.5, flex:'1' }}  variant="body2">
                                                                            Unassigned Amount
                                                                        </Typography>
                                                                        <span className="lodging-suggestions-add-list-item-footer-cost-assignment-unassigned-amount">
                                            {
                                                lodgingOccupantsCostsUnassigned.toFixed(2)
                                            }
                                        </span>

                                                                    </div>

                                                                </div>

                                                            )
                                                        }

                                                    </div>

                                                </div>
                                            ) : (
                                                <></>
                                            )

                                        }
                                        {
                                            lodgingTabSelected === "Details" ? (
                                                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>

                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_booked_through"
                                                            label="Booked Through"
                                                            value={props.bookedThrough}
                                                            onChange={handleBookedThroughChange}
                                                        />
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_confirmation_number"
                                                            label="Confirmation Number"
                                                            value={props.confirmationNumber}
                                                            onChange={handleConfirmationNumberChange}
                                                        />
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_reservation_name"
                                                            label="Reservation Name"
                                                            value={props.reservationName}
                                                            onChange={handleReservationNameChange}
                                                        />
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_reservation_number"
                                                            label="Reservation Number"
                                                            value={props.reservationNumber}
                                                            onChange={handleReservationNumberChange}
                                                        />
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_gate_code"
                                                            label="Gate Code"
                                                            value={props.gateCode}
                                                            onChange={handleGateCodeChange}
                                                        />
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_door_code"
                                                            label="Door Code"
                                                            value={props.doorCode}
                                                            onChange={handleDoorCodeChange}
                                                        />
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_wifi_name"
                                                            label="Wifi Name"
                                                            value={props.wifiName}
                                                            onChange={handleWifiNameChange}
                                                        />
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_wifi_password"
                                                            label="Wifi Password"
                                                            value={props.wifiPassword}
                                                            onChange={handleWifiPasswordChange}
                                                        />
                                                        <TextField
                                                            sx={{marginBottom: '20px'}}
                                                            fullWidth
                                                            id="new_lodging_notes"
                                                            label="Notes"
                                                            multiline
                                                            rows={4}
                                                            value={props.notes}
                                                            onChange={handleNotesChange}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            ) : (
                                                <></>
                                            )

                                        }
                                    </CardContent>
                                </Card>
                            </Grid>
                        ) : (
                            <></>
                        )

                    }
                </Grid>
            </div>
        </div>
    )
}

export default LodgingAddFromSuggestions;
