import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../Services/PAContext";
import {
    Avatar,
    Button,
    FormControl, FormControlLabel, FormGroup,
    Grid,
    Menu,
    MenuItem,
    MenuList, Paper, Switch,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import './planningPageStyle.css';

import {addChipStyle} from "../../components/ButtonStyle/Add";
import ListItemText from "@mui/material/ListItemText";

import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";

import {VolumeOffRounded} from "@mui/icons-material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import AttachFileIcon from "@mui/icons-material/AttachFile";


const delay = 30;
let _concierge_service_message_index = 0;
let _concierge_service_message_printing_timeout = null;


function ConciergeServiceNew(props) {

    const { dataArray } = useContext(PAContext);
    const { ConciergeActions } = dataArray;


    const [planningItemsCount, setPlanningItemsCount] = useState(0);

    useEffect(()=> {
        console.log("ConciergePlanning changed!!");
        setPlanningItemsCount(props.conciergePlanningItemsFromMap.length);
    }, [props.conciergePlanningItemsFromMap]);

    useEffect(()=>{
        console.log("!! location changed to : "+props.searchingForLocation);
        console.log("dataArray.Websocket : "+dataArray.Websocket);

        if (props.searchingForLocation !== "" && dataArray.Websocket){

            const _sendString = {};
            _sendString.action = "planningConciergeServiceLocationChanged";
            _sendString.new_location = props.searchingForLocation;
            _sendString.language = "EN";

            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

        } else {
            //websocket not available

        }

    }, [props.searchingForLocation]);

    useEffect(() => {
        //handle the incoming action
        for (let i = ConciergeActions.length - 1; i >= 0; i--) {
            const _incoming = ConciergeActions[i];
            // Perform operations on the current action
            console.log('Action:', JSON.stringify(_incoming, null, '\t'));

            switch (_incoming.action) {
                case "userLoggedIn":
                    console.log('userLoggedIn');
                    processUserLogin(_incoming);
                    // Remove the one item we just processed
                    ConciergeActions.splice(i, 1);
                    break;
                case "userLoggedOut":
                    console.log('userLoggedOut');
                    processUserLogout(_incoming);
                    // Remove the one item we just processed
                    ConciergeActions.splice(i, 1);
                    break;
                case "getAllAttendeeAssistantThreadsResults":
                    processIncomingAttendeeThreadsList(_incoming);
                    ConciergeActions.splice(i, 1);
                    break;
                case "planningConciergeChatServiceUpdateOpenAiAssistantThreadMetadataResults":
                    updateThreadMetadata(_incoming);
                    // Remove the one item we just processed
                    ConciergeActions.splice(i, 1);
                    break;
                case "getAttendeeAssistantThreadMessagesResults":
                    processIncomingAttendeeThreadMessagesList(_incoming);
                    ConciergeActions.splice(i, 1);
                    break;
                case "planningConciergeChatServiceUserInputResults":
                    if (_incoming.result === "ok"){
                        updateMessageStatus({ message_id: _incoming['thread_message'].id, status: 'in_progress' });
                        addMessages([_incoming.thread_message]);
                    } else {
                        //there was an error
                        console.log('error:', _incoming.message);
                    }
                    // Remove the one item we just processed
                    ConciergeActions.splice(i, 1);
                    break;
                case "planningConciergeChatServiceUserInputMessageStatus":
                    if (_incoming.result === "ok"){
                        updateMessageStatus(_incoming);
                    } else {
                        //there was an error
                        updateMessageStatus({ message_id: _incoming.id, status: 'completed' });
                        console.log('error:', _incoming.reason);
                    }
                    // Remove the one item we just processed
                    ConciergeActions.splice(i, 1);
                    break;
                case "planningConciergeChatServiceThreadMessagesResults":
                    addMessages(_incoming.thread_messages);
                    // Remove the one item we just processed
                    ConciergeActions.splice(i, 1);
                    break;
                case "planningConciergeServiceLocationChangedResults":
                    //incomingContentLocationSummaryInfo(_incoming.ai_response);
                    ConciergeActions.splice(i, 1);
                    break;
                case "planningConciergeServiceMapQueryResults":
                    //incomingContentMapQuerySummaryInfo(_incoming.ai_response);
                    ConciergeActions.splice(i, 1);
                    break;
                case "suggestions":
                    //incomingContentSuggestions(_incoming);
                    ConciergeActions.splice(i, 1);
                    break;

                default:

            }

        }

    }, [ConciergeActions]);

    const [ userLoggedIn, setUserLoggedIn ] = useState(false);
    function processUserLogin(_incoming) {
        setUserLoggedIn(true);
    }
    function processUserLogout(_incoming) {
        setUserLoggedIn(true);
    }

    const [ activeAssistant, setActiveAssistant ] = useState('');
    const [ activeAssistantName, setActiveAssistantName ] = useState('');
    const [ activeThread, setActiveThread ] = useState('');

    const [ attendeeAiThreads, setAttendeeAiThreads] = useState([]);
    const [ messages, setMessages] = useState([]);
    const [ messageStatusList, setMessageStatusList ] = useState([]);

    function processIncomingAttendeeThreadsList(_incoming){
        try {
            const results = _incoming.result;
            if (results === "ok"){
                const _openAI_threads = _incoming.openAI_threads;
                console.log(JSON.stringify(_openAI_threads, null, '\t'));

                setAttendeeAiThreads(_incoming.openAI_threads);
            } else {
                //problems getting Attendee Thread list
            }
        } catch (e){}
    }
    function processIncomingAttendeeThreadMessagesList(_incoming){
        try {
            const results = _incoming.result;
            if (results === "ok"){
                for (const _message of _incoming.thread_messages){

                    setMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages];

                        // Check if the Message already exists
                        const existingMessagesIndex = updatedMessages.findIndex((message) => message.id === _message.id);

                        if (existingMessagesIndex === -1) {
                            updatedMessages.push(_message);
                        }

                        updatedMessages.sort((a, b) => b.created_at - a.created_at);

                        return updatedMessages;
                    });

                    if (audioOn && _message.audio !== undefined){
                        playAudioMessage(_message.audio);
                    }

                }
            } else {
                //problems getting Attendee Thread list
            }
        } catch (e){}
    }

    function updateThreadMetadata(_incoming){
        if (_incoming.result === "ok"){
            if (activeAssistant === _incoming.assistant_id){
                setAttendeeAiThreads((prevThreadsList) => {
                    const updatedThreadsList = [...prevThreadsList];
                    const index = updatedThreadsList.findIndex((thread) => thread.thread_id === _incoming.thread_id);

                    if (index !== -1) {
                        updatedThreadsList[index]['metadata'] = _incoming.metadata;

                        if (activeAssistant === _incoming.assistant_id){
                            //update the display name in case it changed
                            setActiveAssistantName(_incoming['metadata'].assistantName);
                        }
                    }

                    console.log("updatedThreadsList : "+JSON.stringify(updatedThreadsList, null, '\t'));

                    return updatedThreadsList;
                });
            }
        } else {
            //problems with the update
        }
    }
    function addMessages(_messages){

        for (const _message of _messages){

            setMessages((prevMessages) => {
                const updatedMessages = [...prevMessages];

                // Check if the Message already exists
                const existingMessagesIndex = updatedMessages.findIndex((message) => message.id === _message.id);

                if (existingMessagesIndex === -1) {
                    updatedMessages.push(_message);
                }

                updatedMessages.sort((a, b) => b.created_at - a.created_at);

                return updatedMessages;
            });

            if (audioOn && _message.audio !== undefined){
                playAudioMessage(_message.audio);
            }

        }
    }
    function updateMessageStatus(_incoming){
        console.log("updateMessageStatus : "+JSON.stringify(_incoming, null, '\t'));

        setMessageStatusList((prevStatusList) => {
            const updatedStatusList = [...prevStatusList];
            const index = updatedStatusList.findIndex((status) => status.message_id === _incoming.message_id);

            if (index !== -1) {
                updatedStatusList[index] = { message_id: _incoming.message_id, status: _incoming.status };
            } else {
                updatedStatusList.push({ message_id: _incoming.message_id, status: _incoming.status });
            }

            console.log("updatedStatusList : "+JSON.stringify(updatedStatusList, null, '\t'));

            return updatedStatusList;
        });
    }

    const [newMessage, setNewMessage] = useState('');
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage();
        }
    };
    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {

            const _sendString = {};
            _sendString.action = "planningConciergeChatServiceUserInput";
            _sendString.assistant_id = activeAssistant;
            _sendString.thread_id = activeThread;
            _sendString.message = newMessage;
            _sendString.audio_on = audioOn;

            console.log(JSON.stringify(_sendString));
            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

            setNewMessage('');
        }
    };

    /*
    this is for the Menu list in the header
    */
    const [anchorConciergeListMenu, setAnchorConciergeListMenu] = useState(null);
    const handleConciergeListMenuOpenMenu = (event) => {
        setAnchorConciergeListMenu(event.currentTarget);
    };
    function handleConciergeThreadsClick(_event){
        //load the Thread conversation
        const target = _event.currentTarget;
        const dataId = target.getAttribute('data-id');

        console.log("load the Thread conversation : "+dataId);

        const _thread = attendeeAiThreads.find((_thrd) => _thrd["metadata"].assistantName === dataId);
        console.log("The selected Thread : "+_thread);

        setMessages([]);
        setActiveAssistant(_thread.assistant_id);
        setActiveAssistantName(_thread['metadata'].assistantName);
        setActiveThread(_thread.thread_id);

        //get the thread messages
        const _sendString = {};
        _sendString.action = "getAttendeeAssistantThreadMessages";
        _sendString.assistant_id = _thread.assistant_id;
        _sendString.thread_id = _thread.thread_id;

        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

        setAnchorConciergeListMenu(null);

    }

    const handleConciergeListMenuClose = () => {
        setAnchorConciergeListMenu(null);
    };

    useEffect(() => {

        if (props.clearEvent) {
            try {
                // Clear any ongoing printing timeout
                clearTimeout(_concierge_service_message_printing_timeout);

                _concierge_service_message_index = 0;
                document.getElementById("planning_concierge_output_area_content_text").textContent = "";
            } catch (e){
                console.log(e);
            }
        }

    }, [props.clearEvent]);


    const [audioOn, setAudioOn] = useState(false);
    const handleAudioToggle = () => {
        setAudioOn(!audioOn);
    };

    function playAudioMessage(_audio_content){

        const binaryString = window.atob(_audio_content);
        const length = binaryString.length;
        const bytes = new Uint8Array(length);

        for (let i = 0; i < length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const audioData = bytes.buffer;
        const audioPlayer = createAudioPlayer(audioData);

        console.log("ready to play the audio (start)");
        audioPlayer.start();

    }

    function createAudioPlayer(audioData) {
        console.log("createAudioPlayer");

        const audioContext = new AudioContext();
        const audioSource = audioContext.createBufferSource();

        audioContext.decodeAudioData(audioData, (buffer) => {
            audioSource.buffer = buffer;
            audioSource.connect(audioContext.destination);
        });

        return audioSource;
    }



    return (

        <div
            className="planning-recommendations-list"
             style={{
                 width: '300px',
             }}
        >
            <Paper
                id="planning_concierge_holder"
                sx={{
                    backgroundColor: 'rgb(251, 251, 251)',
                    overflow: 'auto',
                    height: 'calc(100vh - 265px)'
                }}
            >

                <div
                    className="planning-concierge-header-holder"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Badge
                        badgeContent={attendeeAiThreads.length}
                        color="primary"
                    >
                        <i
                            className="planning-concierge-header-holder-icon pointer icon-robot"
                            onClick={handleConciergeListMenuOpenMenu}
                        />

                        {
                            attendeeAiThreads.length > 1 ? (
                                <Menu
                                    sx={{width: 320, maxWidth: '100%'}}
                                    anchorEl={anchorConciergeListMenu}
                                    id="library-menu"
                                    open={Boolean(anchorConciergeListMenu)}
                                    onClose={handleConciergeListMenuClose}

                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                >
                                    <MenuList
                                        dense
                                        sx={{width: 320, maxWidth: '100%'}}
                                    >
                                        {
                                            attendeeAiThreads.map((_thread, _index) => (
                                                <MenuItem
                                                    key={_index}
                                                    data-id={_thread["metadata"].assistantName}
                                                    onClick={handleConciergeThreadsClick}>
                                                    <ListItemText>{_thread["metadata"].assistantName}</ListItemText>
                                                </MenuItem>
                                            ))
                                        }


                                    </MenuList>
                                </Menu>
                            ) : (
                                <></>
                            )
                        }
                    </Badge>
                    {
                        activeAssistantName !== '' && (
                            <div className="details-list-header-text">
                                <h5>{activeAssistantName}</h5>
                            </div>
                        )
                    }

                </div>

                <Paper style={{height: '100%'}}>

                    <Box
                        style={{
                            height: '58vh', // Adjust the height as needed
                            display: 'flex',
                            flexDirection: 'column-reverse', // Reverse the order of messages
                            overflowY: 'auto',
                        }}
                    >
                        {
                            messages.map((message, index) => (
                                <div
                                    key={message.id}
                                    style={{
                                        display: 'flex',
                                        justifyContent: message.role === 'assistant' ? 'flex-start' : 'flex-end',
                                    }}
                                >
                                    <div style={{
                                        position: 'relative',
                                        maxWidth: '70%',
                                        backgroundColor: message.role === 'assistant' ? '#e0f7fa' : '#b3e0f7',
                                        padding: '8px',
                                        borderRadius: '12px',
                                        marginBottom: '8px'
                                    }}>
                                        <Typography
                                            sx={{fontSize: '13px'}}
                                            variant="body2"
                                            component="div"
                                            dangerouslySetInnerHTML={{
                                                __html: message.content[0].text.value,
                                            }}
                                        />
                                        <Typography
                                            component="div"
                                            sx={{
                                                fontSize: '11px',
                                                textAlign: message.role === 'assistant' ? 'left' : 'right',
                                                paddingRight: message.role === 'assistant' ? '0' : '15px'
                                            }}
                                            variant="caption"
                                        >
                                            {dayjs.unix(message.created_at).format('ddd, MMM D, YYYY h:mm A')}
                                        </Typography>

                                        {/* Indicator for messages that aren't from 'assistant' */}
                                        {
                                            message.role !== 'assistant' ? (

                                                messageStatusList.length > 0 ? (
                                                    messageStatusList.find((status) => status.message_id === message.id && status.status === 'in_progress') ? (
                                                        <div
                                                            style={{
                                                                position: 'absolute',
                                                                bottom: '6px',
                                                                right: '2px',
                                                                padding: '4px',
                                                                borderRadius: '50%',
                                                                marginLeft: '15px'
                                                            }}
                                                        >
                                                            <i className="icon-spinner4"
                                                               style={{color: 'red', fontSize: '10px'}}/>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                ) : (
                                                    <></>
                                                )

                                            ) : (
                                                message.audio === undefined ? (
                                                    <div style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        right: 0,
                                                        padding: '4px',
                                                    }}
                                                    >
                                                        <VolumeOffRounded
                                                            style={{fontSize: '13px'}}/>
                                                    </div>
                                                ) : (
                                                    <div style={{
                                                        position: 'absolute',
                                                        bottom: 0,
                                                        right: 0,
                                                        padding: '4px',
                                                        cursor: 'pointer'
                                                    }}
                                                    >
                                                        <VolumeUpIcon
                                                            style={{fontSize: '13px'}}
                                                            onClick={() => playAudioMessage(message.audio)}
                                                        />
                                                    </div>
                                                )
                                            )
                                        }

                                    </div>

                                    {
                                        message.file_ids.length > 0 && (
                                            <Avatar
                                                style={{
                                                    marginLeft: '8px',
                                                    backgroundColor: '#2196f3', // Change this color as needed
                                                }}
                                            >
                                                <AttachFileIcon/>
                                            </Avatar>
                                        )
                                    }
                                </div>
                            ))
                        }

                    </Box>

                    <Box>
                        <TextField
                            label={userLoggedIn ? "" : "Must be logged in"}
                            multiline
                            maxRows={10}
                            fullWidth
                            variant="outlined"
                            value={newMessage}
                            onKeyDown={handleKeyPress}  // Ensure handleKeyPress is defined elsewhere in your component
                            onChange={(e) => setNewMessage(e.target.value)}
                        />
                        <Grid container spacing={2} style={{paddingLeft: 10}}>
                            <Grid item xs={6} style={{display: 'flex', alignItems: 'center'}}>
                                {/* Switch for Audio */}
                                <FormControl component="fieldset" variant="standard">
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch checked={audioOn} onChange={handleAudioToggle} name="audio"/>
                                            }
                                            label="Audio"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                                {/* Send Button */}
                                <Button
                                    sx={addChipStyle}
                                    disabled={!userLoggedIn}
                                    variant="contained"
                                    onClick={handleSendMessage}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>

                </Paper>
            </Paper>

        </div>
)
}

export default ConciergeServiceNew;


