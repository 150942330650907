
const sampleDuffelFlights = [
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-10T21:39:28Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T21:39:28Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "16.14",
            "owner": {
                "iata_code": "AA",
                "conditions_of_carriage_url": "https://www.aa.com/i18n/customer-service/support/conditions-of-carriage.jsp",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/AA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/AA.svg",
                "name": "American Airlines",
                "id": "arl_00009VME7DAGiJjwomhv32"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT2H36M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Basic",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AkwrOw==",
                    "ngs_shelf": 1,
                    "id": "sli_0000Amm9myOMa9vonzQsLs",
                    "conditions": {
                        "change_before_departure": {
                            "penalty_amount": null,
                            "allowed": false,
                            "penalty_currency": null
                        },
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "Y20LGTN2",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "n/a",
                                                "pitch": "30",
                                                "type": null
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": "2",
                            "distance": "1521.2840471378152",
                            "arriving_at": "2024-10-15T13:26:00",
                            "aircraft": null,
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "AA",
                                "conditions_of_carriage_url": "https://www.aa.com/i18n/customer-service/support/conditions-of-carriage.jsp",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/AA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/AA.svg",
                                "name": "American Airlines",
                                "id": "arl_00009VME7DAGiJjwomhv32"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1274",
                            "marketing_carrier": {
                                "iata_code": "AA",
                                "conditions_of_carriage_url": "https://www.aa.com/i18n/customer-service/support/conditions-of-carriage.jsp",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/AA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/AA.svg",
                                "name": "American Airlines",
                                "id": "arl_00009VME7DAGiJjwomhv32"
                            },
                            "duration": "PT2H36M",
                            "marketing_carrier_flight_number": "1274",
                            "departing_at": "2024-10-15T10:50:00",
                            "stops": [],
                            "id": "seg_0000Amm9myOMa9vonzQsLr"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:28.378229Z",
            "total_emissions_kg": "430",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "AA",
                "CX",
                "MH",
                "BA",
                "AY",
                "UL",
                "QF",
                "IB",
                "JM",
                "AS",
                "QR",
                "UM",
                "RJ",
                "AT"
            ],
            "expires_at": "2024-10-07T22:09:28.377746Z",
            "updated_at": "2024-10-07T21:39:28.378229Z",
            "total_amount": "105.80",
            "live_mode": false,
            "base_amount": "89.66",
            "id": "off_0000Amm9myOMa9vonzQsLt",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-10T21:39:28Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T21:39:28Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "16.81",
            "owner": {
                "iata_code": "ZZ",
                "conditions_of_carriage_url": null,
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/ZZ.svg",
                "logo_lockup_url": null,
                "name": "Duffel Airways",
                "id": "arl_00009VME7D6ivUu8dn35WK"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT2H36M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Basic",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "F3ee",
                    "ngs_shelf": 1,
                    "id": "sli_0000Amm9myOMa9vonzQsLp",
                    "conditions": {
                        "change_before_departure": {
                            "penalty_amount": "10.00",
                            "allowed": true,
                            "penalty_currency": "GBP"
                        },
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "Y20LGTN2",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "n/a",
                                                "pitch": "30",
                                                "type": null
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": "2",
                            "distance": "1521.2840471378152",
                            "arriving_at": "2024-10-15T13:26:00",
                            "aircraft": null,
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "ZZ",
                                "conditions_of_carriage_url": null,
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/ZZ.svg",
                                "logo_lockup_url": null,
                                "name": "Duffel Airways",
                                "id": "arl_00009VME7D6ivUu8dn35WK"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "7027",
                            "marketing_carrier": {
                                "iata_code": "ZZ",
                                "conditions_of_carriage_url": null,
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/ZZ.svg",
                                "logo_lockup_url": null,
                                "name": "Duffel Airways",
                                "id": "arl_00009VME7D6ivUu8dn35WK"
                            },
                            "duration": "PT2H36M",
                            "marketing_carrier_flight_number": "7027",
                            "departing_at": "2024-10-15T10:50:00",
                            "stops": [],
                            "id": "seg_0000Amm9myOMa9vonzQsLo"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:28.378068Z",
            "total_emissions_kg": "644",
            "total_currency": "USD",
            "supported_loyalty_programmes": [],
            "expires_at": "2024-10-07T22:09:28.377420Z",
            "updated_at": "2024-10-07T21:39:28.378068Z",
            "total_amount": "110.18",
            "live_mode": false,
            "base_amount": "93.37",
            "id": "off_0000Amm9myOMa9vonzQsLq",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "10.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "10.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H50M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "7Knz",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7oZY5FC1slQx6",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T19:44:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "521",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H39M",
                            "marketing_carrier_flight_number": "521",
                            "departing_at": "2024-10-15T18:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oDZOxc0mb9PE"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T22:55:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1190",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "1190",
                            "departing_at": "2024-10-15T20:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oDZOxc0mb9PF"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "60",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7oZY5FC1slQx8",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H32M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Ah/IOA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7oZY5FC1slQxK",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T17:48:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1606",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "1606",
                            "departing_at": "2024-10-15T16:23:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oZY5FC1slQxI"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T22:55:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1190",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "1190",
                            "departing_at": "2024-10-15T20:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oZY5FC1slQxJ"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "60",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7oZY5FC1slQxM",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H11M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AjCIBw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7oZY5FC1slQxY",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T17:01:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "519",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H46M",
                            "marketing_carrier_flight_number": "519",
                            "departing_at": "2024-10-15T15:15:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oZY5FC1slQxW"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T21:26:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "357",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H6M",
                            "marketing_carrier_flight_number": "357",
                            "departing_at": "2024-10-15T18:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oZY5FC1slQxX"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "79",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7ovWlWm2yviVM",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H1M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BrEMqg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7ovWlWm2yviVY",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T16:00:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2454",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H28M",
                            "marketing_carrier_flight_number": "2454",
                            "departing_at": "2024-10-15T14:32:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVW"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T19:33:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "542",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H28M",
                            "marketing_carrier_flight_number": "542",
                            "departing_at": "2024-10-15T17:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVX"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7ovWlWm2yviVa",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H33M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BjofgA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7ovWlWm2yviVm",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T13:25:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2067",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "2067",
                            "departing_at": "2024-10-15T12:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVk"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T19:33:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "542",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H28M",
                            "marketing_carrier_flight_number": "542",
                            "departing_at": "2024-10-15T17:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVl"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7ovWlWm2yviVo",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H24M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BMv3LA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pHVRoM45603g",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T13:25:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2067",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "2067",
                            "departing_at": "2024-10-15T12:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pHVRoM45603e"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T18:24:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "362",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "362",
                            "departing_at": "2024-10-15T15:59:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pHVRoM45603f"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7pHVRoM45603i",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H55M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A+Fkjg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pHVRoM45603u",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T13:25:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2067",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "2067",
                            "departing_at": "2024-10-15T12:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pHVRoM45603s"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T16:55:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2464",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "2464",
                            "departing_at": "2024-10-15T14:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pHVRoM45603t"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7pHVRoM45603w",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H20M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A85q2A==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pdU85w5BGHbx",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T11:59:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "592",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H24M",
                            "marketing_carrier_flight_number": "592",
                            "departing_at": "2024-10-15T10:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pdU85w5BGHbv"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T16:55:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2464",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "2464",
                            "departing_at": "2024-10-15T14:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pdU85w5BGHbw"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7pdU85w5BGHbz",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H8M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AkzFlw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pzSoNW6HQZAD",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T12:05:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "378",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H43M",
                            "marketing_carrier_flight_number": "378",
                            "departing_at": "2024-10-15T10:22:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pzSoNW6HQZAB"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T17:30:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2290",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H5M",
                            "marketing_carrier_flight_number": "2290",
                            "departing_at": "2024-10-15T14:25:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pzSoNW6HQZAC"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "58",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7pzSoNW6HQZAF",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H7M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Afn5yg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pzSoNW6HQZAR",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "663.04808",
                            "arriving_at": "2024-10-15T11:10:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2098",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H40M",
                            "marketing_carrier_flight_number": "2098",
                            "departing_at": "2024-10-15T09:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pzSoNW6HQZAP"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": "857.77822",
                            "arriving_at": "2024-10-15T14:37:00",
                            "aircraft": {
                                "iata_code": "73G",
                                "name": "Boeing 737-700",
                                "id": "arc_00009VMF8AhBTmADP77oj6"
                            },
                            "origin": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1940",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H54M",
                            "marketing_carrier_flight_number": "1940",
                            "departing_at": "2024-10-15T12:43:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pzSoNW6HQZAQ"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "65",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7pzSoNW6HQZAT",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H11M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "6hi8",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qLRUf67NaqiZ",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "2565.28796",
                            "arriving_at": "2024-10-15T11:13:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KIAH",
                                "city_name": "Houston",
                                "iata_code": "IAH",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "HOU",
                                    "latitude": null,
                                    "name": "Houston",
                                    "iata_city_code": "HOU",
                                    "id": "cit_hou_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 29.987309,
                                "name": "George Bush Intercontinental Airport",
                                "iata_city_code": "HOU",
                                "id": "arp_iah_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -95.339091
                            },
                            "destination_terminal": "C",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "226",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT4H13M",
                            "marketing_carrier_flight_number": "226",
                            "departing_at": "2024-10-15T08:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67NaqiX"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "31",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1105.61658",
                            "arriving_at": "2024-10-15T15:11:00",
                            "aircraft": {
                                "iata_code": "E7W",
                                "name": "Embraer 175 (Enhanced Winglets)",
                                "id": "arc_00009VMF8AiFPp0xSPcfNy"
                            },
                            "origin": {
                                "icao_code": "KIAH",
                                "city_name": "Houston",
                                "iata_code": "IAH",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "HOU",
                                    "latitude": null,
                                    "name": "Houston",
                                    "iata_city_code": "HOU",
                                    "id": "cit_hou_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 29.987309,
                                "name": "George Bush Intercontinental Airport",
                                "iata_city_code": "HOU",
                                "id": "arp_iah_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -95.339091
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "6062",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H16M",
                            "marketing_carrier_flight_number": "6062",
                            "departing_at": "2024-10-15T11:55:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67NaqiY"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "146",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7qLRUf67Naqib",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H40M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Aaa9qA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qLRUf67Naqin",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T09:49:00",
                            "aircraft": {
                                "iata_code": "32Q",
                                "name": "Airbus A321neo",
                                "id": "arc_00009lcSPZuSbgKG1u4Pku"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "660",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H49M",
                            "marketing_carrier_flight_number": "660",
                            "departing_at": "2024-10-15T08:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqil"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T13:40:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2225",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H5M",
                            "marketing_carrier_flight_number": "2225",
                            "departing_at": "2024-10-15T10:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqim"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "70",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7qLRUf67Naqip",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H40M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BKGoWQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qLRUf67Naqj1",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T07:48:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1414",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H48M",
                            "marketing_carrier_flight_number": "1414",
                            "departing_at": "2024-10-15T06:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqiz"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T13:40:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2225",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H5M",
                            "marketing_carrier_flight_number": "2225",
                            "departing_at": "2024-10-15T10:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqj0"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "75",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7qLRUf67Naqj3",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H49M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A9lN1g==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qLRUf67NaqjF",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T07:48:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1414",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H48M",
                            "marketing_carrier_flight_number": "1414",
                            "departing_at": "2024-10-15T06:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67NaqjD"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T11:49:00",
                            "aircraft": {
                                "iata_code": "73G",
                                "name": "Boeing 737-700",
                                "id": "arc_00009VMF8AhBTmADP77oj6"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "307",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H4M",
                            "marketing_carrier_flight_number": "307",
                            "departing_at": "2024-10-15T08:45:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67NaqjE"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "77",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7qhQAwg8Tl8Ge",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "31.40",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H27M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AuZpBg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qhQAwg8Tl8Gq",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "663.04808",
                            "arriving_at": "2024-10-15T07:27:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1785",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H42M",
                            "marketing_carrier_flight_number": "1785",
                            "departing_at": "2024-10-15T05:45:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qhQAwg8Tl8Go"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": "857.77822",
                            "arriving_at": "2024-10-15T10:12:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2642",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H52M",
                            "marketing_carrier_flight_number": "2642",
                            "departing_at": "2024-10-15T08:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qhQAwg8Tl8Gp"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "65",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "122.98",
            "live_mode": false,
            "base_amount": "91.58",
            "id": "off_0000Amm9n7qhQAwg8Tl8Gs",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "24.03",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT2H49M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Ar0QwQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6pxBSkqzsP1Sz",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T10:49:00",
                            "aircraft": {
                                "iata_code": "223",
                                "name": "Airbus A220-300",
                                "id": "arc_00009oBdrPis4D1mAnkllM"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1097",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H49M",
                            "marketing_carrier_flight_number": "1097",
                            "departing_at": "2024-10-15T08:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1Sy"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "77",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.835531Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "143.10",
            "live_mode": false,
            "base_amount": "119.07",
            "id": "off_0000Amm9n6pxBSkqzsP1T1",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "24.03",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT2H54M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AdobrA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6pxBSkqzsP1T5",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T18:39:00",
                            "aircraft": {
                                "iata_code": "223",
                                "name": "Airbus A220-300",
                                "id": "arc_00009oBdrPis4D1mAnkllM"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "697",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H54M",
                            "marketing_carrier_flight_number": "697",
                            "departing_at": "2024-10-15T15:45:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1T4"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "77",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.838318Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "143.10",
            "live_mode": false,
            "base_amount": "119.07",
            "id": "off_0000Amm9n6pxBSkqzsP1T7",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.53",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H15M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "2bMn",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6pxBSkqzsP1TC",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T11:28:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2417",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H13M",
                            "marketing_carrier_flight_number": "2417",
                            "departing_at": "2024-10-15T10:15:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1TA"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T15:30:00",
                            "aircraft": {
                                "iata_code": "320",
                                "name": "Airbus A320",
                                "id": "arc_00009VMF8AgpV5sdO0xXAr"
                            },
                            "origin": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2719",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H35M",
                            "marketing_carrier_flight_number": "2719",
                            "departing_at": "2024-10-15T12:55:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1TB"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "58",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.839519Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "152.61",
            "live_mode": false,
            "base_amount": "119.08",
            "id": "off_0000Amm9n6pxBSkqzsP1TE",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.53",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H2M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AtZbKw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6pxBSkqzsP1TJ",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T15:30:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1517",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "1517",
                            "departing_at": "2024-10-15T14:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1TH"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T20:07:00",
                            "aircraft": {
                                "iata_code": "320",
                                "name": "Airbus A320",
                                "id": "arc_00009VMF8AgpV5sdO0xXAr"
                            },
                            "origin": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "819",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H47M",
                            "marketing_carrier_flight_number": "819",
                            "departing_at": "2024-10-15T17:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1TI"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "58",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.841230Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "152.61",
            "live_mode": false,
            "base_amount": "119.08",
            "id": "off_0000Amm9n6pxBSkqzsP1TL",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.53",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H50M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BdtrIQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6pxBSkqzsP1TQ",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T09:00:00",
                            "aircraft": {
                                "iata_code": "223",
                                "name": "Airbus A220-300",
                                "id": "arc_00009oBdrPis4D1mAnkllM"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2217",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H20M",
                            "marketing_carrier_flight_number": "2217",
                            "departing_at": "2024-10-15T07:40:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1TO"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T15:30:00",
                            "aircraft": {
                                "iata_code": "320",
                                "name": "Airbus A320",
                                "id": "arc_00009VMF8AgpV5sdO0xXAr"
                            },
                            "origin": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2719",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H35M",
                            "marketing_carrier_flight_number": "2719",
                            "departing_at": "2024-10-15T12:55:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1TP"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "57",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.842472Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "152.61",
            "live_mode": false,
            "base_amount": "119.08",
            "id": "off_0000Amm9n6pxBSkqzsP1TS",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H11M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AjCIBw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7oZY5FC1slQxR",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T17:01:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "519",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H46M",
                            "marketing_carrier_flight_number": "519",
                            "departing_at": "2024-10-15T15:15:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oZY5FC1slQxP"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T21:26:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "357",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H6M",
                            "marketing_carrier_flight_number": "357",
                            "departing_at": "2024-10-15T18:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oZY5FC1slQxQ"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "79",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7oZY5FC1slQxT",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H24M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BMv3LA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7ovWlWm2yviVt",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T13:25:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2067",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "2067",
                            "departing_at": "2024-10-15T12:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVr"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T18:24:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "362",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "362",
                            "departing_at": "2024-10-15T15:59:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVs"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7ovWlWm2yviVv",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H8M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AkzFlw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pdU85w5BGHc4",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T12:05:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "378",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H43M",
                            "marketing_carrier_flight_number": "378",
                            "departing_at": "2024-10-15T10:22:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pdU85w5BGHc2"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T17:30:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2290",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H5M",
                            "marketing_carrier_flight_number": "2290",
                            "departing_at": "2024-10-15T14:25:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pdU85w5BGHc3"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "58",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7pzSoNW6HQZA8",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H40M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Aaa9qA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qLRUf67Naqig",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T09:49:00",
                            "aircraft": {
                                "iata_code": "32Q",
                                "name": "Airbus A321neo",
                                "id": "arc_00009lcSPZuSbgKG1u4Pku"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "660",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H49M",
                            "marketing_carrier_flight_number": "660",
                            "departing_at": "2024-10-15T08:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqie"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T13:40:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2225",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H5M",
                            "marketing_carrier_flight_number": "2225",
                            "departing_at": "2024-10-15T10:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqif"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "70",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7qLRUf67Naqii",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H27M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AuZpBg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qhQAwg8Tl8Gj",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "663.04808",
                            "arriving_at": "2024-10-15T07:27:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1785",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H42M",
                            "marketing_carrier_flight_number": "1785",
                            "departing_at": "2024-10-15T05:45:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qhQAwg8Tl8Gh"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": "857.77822",
                            "arriving_at": "2024-10-15T10:12:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2642",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H52M",
                            "marketing_carrier_flight_number": "2642",
                            "departing_at": "2024-10-15T08:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qhQAwg8Tl8Gi"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "65",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7qhQAwg8Tl8Gl",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H50M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "7Knz",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7oDZOxc0mb9P9",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T19:44:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "521",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H39M",
                            "marketing_carrier_flight_number": "521",
                            "departing_at": "2024-10-15T18:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oDZOxc0mb9P7"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T22:55:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1190",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "1190",
                            "departing_at": "2024-10-15T20:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oDZOxc0mb9P8"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "60",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7oDZOxc0mb9PB",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H32M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Ah/IOA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7oZY5FC1slQxD",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T17:48:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1606",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "1606",
                            "departing_at": "2024-10-15T16:23:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oZY5FC1slQxB"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T22:55:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1190",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "1190",
                            "departing_at": "2024-10-15T20:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oZY5FC1slQxC"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "60",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7oZY5FC1slQxF",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H1M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BrEMqg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7ovWlWm2yviVR",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T16:00:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2454",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H28M",
                            "marketing_carrier_flight_number": "2454",
                            "departing_at": "2024-10-15T14:32:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVP"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T19:33:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "542",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H28M",
                            "marketing_carrier_flight_number": "542",
                            "departing_at": "2024-10-15T17:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVQ"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7ovWlWm2yviVT",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H33M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BjofgA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7ovWlWm2yviVf",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T13:25:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2067",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "2067",
                            "departing_at": "2024-10-15T12:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVd"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T19:33:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "542",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H28M",
                            "marketing_carrier_flight_number": "542",
                            "departing_at": "2024-10-15T17:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7ovWlWm2yviVe"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7ovWlWm2yviVh",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H55M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A+Fkjg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pHVRoM45603n",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T13:25:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2067",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "2067",
                            "departing_at": "2024-10-15T12:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pHVRoM45603l"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T16:55:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2464",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "2464",
                            "departing_at": "2024-10-15T14:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pHVRoM45603m"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7pHVRoM45603p",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H20M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A85q2A==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pHVRoM456041",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T11:59:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "592",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H24M",
                            "marketing_carrier_flight_number": "592",
                            "departing_at": "2024-10-15T10:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pHVRoM45603z"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T16:55:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2464",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "2464",
                            "departing_at": "2024-10-15T14:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pHVRoM456040"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7pdU85w5BGHbs",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H7M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Afn5yg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7pzSoNW6HQZAK",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "663.04808",
                            "arriving_at": "2024-10-15T11:10:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2098",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H40M",
                            "marketing_carrier_flight_number": "2098",
                            "departing_at": "2024-10-15T09:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pzSoNW6HQZAI"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": "857.77822",
                            "arriving_at": "2024-10-15T14:37:00",
                            "aircraft": {
                                "iata_code": "73G",
                                "name": "Boeing 737-700",
                                "id": "arc_00009VMF8AhBTmADP77oj6"
                            },
                            "origin": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1940",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H54M",
                            "marketing_carrier_flight_number": "1940",
                            "departing_at": "2024-10-15T12:43:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7pzSoNW6HQZAJ"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "65",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7pzSoNW6HQZAM",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H11M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "6hi8",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qLRUf67NaqiS",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "2565.28796",
                            "arriving_at": "2024-10-15T11:13:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KIAH",
                                "city_name": "Houston",
                                "iata_code": "IAH",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "HOU",
                                    "latitude": null,
                                    "name": "Houston",
                                    "iata_city_code": "HOU",
                                    "id": "cit_hou_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 29.987309,
                                "name": "George Bush Intercontinental Airport",
                                "iata_city_code": "HOU",
                                "id": "arp_iah_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -95.339091
                            },
                            "destination_terminal": "C",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "226",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT4H13M",
                            "marketing_carrier_flight_number": "226",
                            "departing_at": "2024-10-15T08:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67NaqiQ"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "31",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1105.61658",
                            "arriving_at": "2024-10-15T15:11:00",
                            "aircraft": {
                                "iata_code": "E7W",
                                "name": "Embraer 175 (Enhanced Winglets)",
                                "id": "arc_00009VMF8AiFPp0xSPcfNy"
                            },
                            "origin": {
                                "icao_code": "KIAH",
                                "city_name": "Houston",
                                "iata_code": "IAH",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "HOU",
                                    "latitude": null,
                                    "name": "Houston",
                                    "iata_city_code": "HOU",
                                    "id": "cit_hou_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 29.987309,
                                "name": "George Bush Intercontinental Airport",
                                "iata_city_code": "HOU",
                                "id": "arp_iah_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -95.339091
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "6062",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H16M",
                            "marketing_carrier_flight_number": "6062",
                            "departing_at": "2024-10-15T11:55:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67NaqiR"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "146",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7qLRUf67NaqiU",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H40M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BKGoWQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qLRUf67Naqiu",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T07:48:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1414",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H48M",
                            "marketing_carrier_flight_number": "1414",
                            "departing_at": "2024-10-15T06:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqis"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T13:40:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2225",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H5M",
                            "marketing_carrier_flight_number": "2225",
                            "departing_at": "2024-10-15T10:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqit"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "75",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7qLRUf67Naqiw",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "33.75",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H49M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A9lN1g==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7qLRUf67Naqj8",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "1390.46976",
                            "arriving_at": "2024-10-15T07:48:00",
                            "aircraft": {
                                "iata_code": "738",
                                "name": "Boeing 737-800",
                                "id": "arc_00009VMF8AhBTmADP77oj0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination_terminal": "1",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1414",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H48M",
                            "marketing_carrier_flight_number": "1414",
                            "departing_at": "2024-10-15T06:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqj6"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "KAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "1",
                            "distance": "975.26004",
                            "arriving_at": "2024-10-15T11:49:00",
                            "aircraft": {
                                "iata_code": "73G",
                                "name": "Boeing 737-700",
                                "id": "arc_00009VMF8AhBTmADP77oj6"
                            },
                            "origin": {
                                "icao_code": "KORD",
                                "city_name": "Chicago",
                                "iata_code": "ORD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "CHI",
                                    "latitude": null,
                                    "name": "Chicago",
                                    "iata_city_code": "CHI",
                                    "id": "cit_chi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 41.976381,
                                "name": "O'Hare International Airport",
                                "iata_city_code": "CHI",
                                "id": "arp_ord_us",
                                "time_zone": "America/Chicago",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -87.90606
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "307",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H4M",
                            "marketing_carrier_flight_number": "307",
                            "departing_at": "2024-10-15T08:45:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7qLRUf67Naqj7"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "77",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "158.04",
            "live_mode": false,
            "base_amount": "124.29",
            "id": "off_0000Amm9n7qLRUf67NaqjA",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "39.13",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT8H22M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BLTQcQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6qJA92R0yZJ1F",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T12:59:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "217",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H14M",
                            "marketing_carrier_flight_number": "217",
                            "departing_at": "2024-10-15T11:45:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6pxBSkqzsP1TV"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T20:07:00",
                            "aircraft": {
                                "iata_code": "320",
                                "name": "Airbus A320",
                                "id": "arc_00009VMF8AgpV5sdO0xXAr"
                            },
                            "origin": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "819",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H47M",
                            "marketing_carrier_flight_number": "819",
                            "departing_at": "2024-10-15T17:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1E"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "58",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.843597Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "158.21",
            "live_mode": false,
            "base_amount": "119.08",
            "id": "off_0000Amm9n6qJA92R0yZJ1H",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "39.13",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT8H32M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Bb/sjw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6qJA92R0yZJ1M",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T12:49:00",
                            "aircraft": {
                                "iata_code": "223",
                                "name": "Airbus A220-300",
                                "id": "arc_00009oBdrPis4D1mAnkllM"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2317",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H14M",
                            "marketing_carrier_flight_number": "2317",
                            "departing_at": "2024-10-15T11:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1K"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "WI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T20:07:00",
                            "aircraft": {
                                "iata_code": "320",
                                "name": "Airbus A320",
                                "id": "arc_00009VMF8AgpV5sdO0xXAr"
                            },
                            "origin": {
                                "icao_code": "KJFK",
                                "city_name": "New York",
                                "iata_code": "JFK",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.640556,
                                "name": "John F. Kennedy International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_jfk_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.778519
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "819",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H47M",
                            "marketing_carrier_flight_number": "819",
                            "departing_at": "2024-10-15T17:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1L"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "57",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.844986Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "158.21",
            "live_mode": false,
            "base_amount": "119.08",
            "id": "off_0000Amm9n6qJA92R0yZJ1O",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "35.59",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H45M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A3WyZw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7oDZOxc0mb9P2",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "LAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T07:23:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1833",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H23M",
                            "marketing_carrier_flight_number": "1833",
                            "departing_at": "2024-10-15T06:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oDZOxc0mb9P0"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "LAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T11:45:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2641",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H31M",
                            "marketing_carrier_flight_number": "2641",
                            "departing_at": "2024-10-15T09:14:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oDZOxc0mb9P1"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "183.42",
            "live_mode": false,
            "base_amount": "147.83",
            "id": "off_0000Amm9n7oDZOxc0mb9P4",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "37.29",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H53M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Uqfd",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7nraig1zgQrr7",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "TAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "663.04808",
                            "arriving_at": "2024-10-15T16:02:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "721",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H42M",
                            "marketing_carrier_flight_number": "721",
                            "departing_at": "2024-10-15T14:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrr5"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "TAA7AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": "857.77822",
                            "arriving_at": "2024-10-15T19:13:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2081",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H58M",
                            "marketing_carrier_flight_number": "2081",
                            "departing_at": "2024-10-15T17:15:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrr6"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "53",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "208.67",
            "live_mode": false,
            "base_amount": "171.38",
            "id": "off_0000Amm9n7oDZOxc0mb9Oq",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "37.58",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H41M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BkPNdQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6qJA92R0yZJ1T",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "PI7ABEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T18:21:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "865",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H21M",
                            "marketing_carrier_flight_number": "865",
                            "departing_at": "2024-10-15T17:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1R"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "MI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T22:41:00",
                            "aircraft": {
                                "iata_code": "223",
                                "name": "Airbus A220-300",
                                "id": "arc_00009oBdrPis4D1mAnkllM"
                            },
                            "origin": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2514",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H42M",
                            "marketing_carrier_flight_number": "2514",
                            "departing_at": "2024-10-15T19:59:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1S"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "51",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.846173Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "210.60",
            "live_mode": false,
            "base_amount": "173.02",
            "id": "off_0000Amm9n6qJA92R0yZJ1V",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "37.58",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H25M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Az8IXA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6qJA92R0yZJ1a",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "PI7ABEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T10:45:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "531",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H15M",
                            "marketing_carrier_flight_number": "531",
                            "departing_at": "2024-10-15T09:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1Y"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "MI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T15:55:00",
                            "aircraft": {
                                "iata_code": "320",
                                "name": "Airbus A320",
                                "id": "arc_00009VMF8AgpV5sdO0xXAr"
                            },
                            "origin": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2814",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "2814",
                            "departing_at": "2024-10-15T13:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1Z"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "58",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.847524Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "210.60",
            "live_mode": false,
            "base_amount": "173.02",
            "id": "off_0000Amm9n6qJA92R0yZJ1c",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "37.58",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT7H41M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AjdmFQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6qJA92R0yZJ1h",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "PI7ABEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T16:15:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "631",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H15M",
                            "marketing_carrier_flight_number": "631",
                            "departing_at": "2024-10-15T15:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1f"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "MI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T22:41:00",
                            "aircraft": {
                                "iata_code": "223",
                                "name": "Airbus A220-300",
                                "id": "arc_00009oBdrPis4D1mAnkllM"
                            },
                            "origin": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2514",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H42M",
                            "marketing_carrier_flight_number": "2514",
                            "departing_at": "2024-10-15T19:59:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qJA92R0yZJ1g"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "51",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.848614Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "210.60",
            "live_mode": false,
            "base_amount": "173.02",
            "id": "off_0000Amm9n6qJA92R0yZJ1j",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "43.18",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT8H15M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BZL+pg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6qf8pK124jaZX",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "PI7ABEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T08:57:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "331",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H17M",
                            "marketing_carrier_flight_number": "331",
                            "departing_at": "2024-10-15T07:40:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qf8pK124jaZV"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "MI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T15:55:00",
                            "aircraft": {
                                "iata_code": "320",
                                "name": "Airbus A320",
                                "id": "arc_00009VMF8AgpV5sdO0xXAr"
                            },
                            "origin": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2814",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "2814",
                            "departing_at": "2024-10-15T13:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qf8pK124jaZW"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "58",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.849786Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "216.20",
            "live_mode": false,
            "base_amount": "173.02",
            "id": "off_0000Amm9n6qf8pK124jaZZ",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "43.18",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT8H39M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BcWjRQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6qf8pK124jaZe",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "PI7ABEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T10:45:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "531",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H15M",
                            "marketing_carrier_flight_number": "531",
                            "departing_at": "2024-10-15T09:30:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qf8pK124jaZc"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "MI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T18:09:00",
                            "aircraft": {
                                "iata_code": "223",
                                "name": "Airbus A220-300",
                                "id": "arc_00009oBdrPis4D1mAnkllM"
                            },
                            "origin": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2614",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H40M",
                            "marketing_carrier_flight_number": "2614",
                            "departing_at": "2024-10-15T15:29:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qf8pK124jaZd"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "51",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.850631Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "216.20",
            "live_mode": false,
            "base_amount": "173.02",
            "id": "off_0000Amm9n6qf8pK124jaZg",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-08T21:39:29Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "43.18",
            "owner": {
                "iata_code": "B6",
                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                "name": "JetBlue Airways",
                "id": "arl_00009VME7DCOaPRQvNhcNY"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT8H41M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": null,
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A/a7tA==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n6qf8pK124jaZl",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "PI7ABEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": false
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T15:15:00",
                            "aircraft": {
                                "iata_code": "E90",
                                "name": "Embraer 190",
                                "id": "arc_00009VMF8AiFPp0xSPcfO0"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "831",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT1H15M",
                            "marketing_carrier_flight_number": "831",
                            "departing_at": "2024-10-15T14:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qf8pK124jaZj"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "MI7AUEL1",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "more",
                                                "pitch": "32",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "free",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T22:41:00",
                            "aircraft": {
                                "iata_code": "223",
                                "name": "Airbus A220-300",
                                "id": "arc_00009oBdrPis4D1mAnkllM"
                            },
                            "origin": {
                                "icao_code": "KLGA",
                                "city_name": "New York",
                                "iata_code": "LGA",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.777062,
                                "name": "LaGuardia Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_lga_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -73.873281
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2514",
                            "marketing_carrier": {
                                "iata_code": "B6",
                                "conditions_of_carriage_url": "https://www.jetblue.com/magnoliapublic/dam/ui-assets/p/contract_of_carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/B6.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/B6.svg",
                                "name": "JetBlue Airways",
                                "id": "arl_00009VME7DCOaPRQvNhcNY"
                            },
                            "duration": "PT2H42M",
                            "marketing_carrier_flight_number": "2514",
                            "departing_at": "2024-10-15T19:59:00",
                            "stops": [],
                            "id": "seg_0000Amm9n6qf8pK124jaZk"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "known_traveler_number",
                "passenger_redress_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.853411Z",
            "total_emissions_kg": "51",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "B6"
            ],
            "expires_at": "2024-10-07T22:39:29.851781Z",
            "updated_at": "2024-10-07T21:39:29.853411Z",
            "total_amount": "216.20",
            "live_mode": false,
            "base_amount": "173.02",
            "id": "off_0000Amm9n6qf8pK124jaZn",
            "conditions": {
                "change_before_departure": null,
                "refund_before_departure": null
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "37.94",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT5H45M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "A3WyZw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7oDZOxc0mb9Ov",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "LAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T07:23:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1833",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H23M",
                            "marketing_carrier_flight_number": "1833",
                            "departing_at": "2024-10-15T06:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oDZOxc0mb9Ot"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "LAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T11:45:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2641",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H31M",
                            "marketing_carrier_flight_number": "2641",
                            "departing_at": "2024-10-15T09:14:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7oDZOxc0mb9Ou"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "56",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "218.48",
            "live_mode": false,
            "base_amount": "180.54",
            "id": "off_0000Amm9n7oDZOxc0mb9Ox",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": null,
                "requires_instant_payment": true,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "38.09",
            "owner": {
                "iata_code": "NK",
                "conditions_of_carriage_url": "https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/NK.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/NK.svg",
                "name": "Spirit Airlines",
                "id": "arl_00009VME7DDSWSIAygCT2J"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT3H",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Go",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "AvS4wQ==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n5RoLjqggi6ECN",
                    "conditions": {
                        "change_before_departure": {
                            "penalty_amount": "0.00",
                            "allowed": true,
                            "penalty_currency": "USD"
                        },
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "HNR",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [],
                                    "cabin": {
                                        "amenities": null,
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T12:00:00",
                            "aircraft": null,
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "NK",
                                "conditions_of_carriage_url": "https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/NK.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/NK.svg",
                                "name": "Spirit Airlines",
                                "id": "arl_00009VME7DDSWSIAygCT2J"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1460",
                            "marketing_carrier": {
                                "iata_code": "NK",
                                "conditions_of_carriage_url": "https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/NK.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/NK.svg",
                                "name": "Spirit Airlines",
                                "id": "arl_00009VME7DDSWSIAygCT2J"
                            },
                            "duration": "PT3H",
                            "marketing_carrier_flight_number": "1460",
                            "departing_at": "2024-10-15T09:00:00",
                            "stops": [],
                            "id": "seg_0000Amm9n5RoLjqggi6ECM"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.610955Z",
            "total_emissions_kg": "77",
            "total_currency": "USD",
            "supported_loyalty_programmes": [],
            "expires_at": "2024-10-07T21:54:29.369936Z",
            "updated_at": "2024-10-07T21:39:29.610955Z",
            "total_amount": "232.09",
            "live_mode": false,
            "base_amount": "194.00",
            "id": "off_0000Amm9n5RoLjqggi6ECP",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "USD"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "39.51",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H55M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BmxNHg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7nraig1zgQrqf",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "SAA3AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T11:59:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "592",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H24M",
                            "marketing_carrier_flight_number": "592",
                            "departing_at": "2024-10-15T10:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrqd"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "SAA3AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T15:30:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1359",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "1359",
                            "departing_at": "2024-10-15T13:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrqe"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "49",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "239.68",
            "live_mode": false,
            "base_amount": "200.17",
            "id": "off_0000Amm9n7nraig1zgQrqh",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "39.51",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H25M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BIpj+A==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7nraig1zgQrqt",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "SAA3AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T10:30:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1805",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "1805",
                            "departing_at": "2024-10-15T09:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrqr"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "SAA3AHDN",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T15:30:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1359",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "1359",
                            "departing_at": "2024-10-15T13:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrqs"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "53",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "239.68",
            "live_mode": false,
            "base_amount": "200.17",
            "id": "off_0000Amm9n7nraig1zgQrqv",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "39.77",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H53M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "Uqfd",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7nraig1zgQrr0",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "TAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "663.04808",
                            "arriving_at": "2024-10-15T16:02:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "721",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H42M",
                            "marketing_carrier_flight_number": "721",
                            "departing_at": "2024-10-15T14:20:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrqy"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "TAA7AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": "857.77822",
                            "arriving_at": "2024-10-15T19:13:00",
                            "aircraft": {
                                "iata_code": "319",
                                "name": "Airbus A319",
                                "id": "arc_00009VMF8AgpV5sdO0xXAn"
                            },
                            "origin": {
                                "icao_code": "KIAD",
                                "city_name": "Washington",
                                "iata_code": "IAD",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "WAS",
                                    "latitude": null,
                                    "name": "Washington",
                                    "iata_city_code": "WAS",
                                    "id": "cit_was_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 38.948808,
                                "name": "Washington Dulles International Airport",
                                "iata_city_code": "WAS",
                                "id": "arp_iad_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -77.45617
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "2081",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H58M",
                            "marketing_carrier_flight_number": "2081",
                            "departing_at": "2024-10-15T17:15:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrqz"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "53",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "243.86",
            "live_mode": false,
            "base_amount": "204.09",
            "id": "off_0000Amm9n7nraig1zgQrr2",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": null,
                "requires_instant_payment": true,
                "price_guarantee_expires_at": null
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "70.58",
            "owner": {
                "iata_code": "NK",
                "conditions_of_carriage_url": "https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/NK.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/NK.svg",
                "name": "Spirit Airlines",
                "id": "arl_00009VME7DDSWSIAygCT2J"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT14H31M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Go",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BHOPHw==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n5RoLjqggi6ECH",
                    "conditions": {
                        "change_before_departure": {
                            "penalty_amount": "0.00",
                            "allowed": true,
                            "penalty_currency": "USD"
                        },
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "HNR",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [],
                                    "cabin": {
                                        "amenities": null,
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T09:47:00",
                            "aircraft": null,
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KBWI",
                                "city_name": "Baltimore",
                                "iata_code": "BWI",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BWI",
                                    "latitude": null,
                                    "name": "Baltimore",
                                    "iata_city_code": "BWI",
                                    "id": "cit_bwi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 39.176402,
                                "name": "Baltimore Washington International Thurgood Marshall Airport",
                                "iata_city_code": "BWI",
                                "id": "arp_bwi_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -76.668345
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "NK",
                                "conditions_of_carriage_url": "https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/NK.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/NK.svg",
                                "name": "Spirit Airlines",
                                "id": "arl_00009VME7DDSWSIAygCT2J"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1072",
                            "marketing_carrier": {
                                "iata_code": "NK",
                                "conditions_of_carriage_url": "https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/NK.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/NK.svg",
                                "name": "Spirit Airlines",
                                "id": "arl_00009VME7DDSWSIAygCT2J"
                            },
                            "duration": "PT1H33M",
                            "marketing_carrier_flight_number": "1072",
                            "departing_at": "2024-10-15T08:14:00",
                            "stops": [],
                            "id": "seg_0000Amm9n5RSN3Z6fbvwe0"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "HNR",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [],
                                    "cabin": {
                                        "amenities": null,
                                        "marketing_name": "Economy",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "Economy"
                                }
                            ],
                            "origin_terminal": null,
                            "distance": null,
                            "arriving_at": "2024-10-15T22:45:00",
                            "aircraft": null,
                            "origin": {
                                "icao_code": "KBWI",
                                "city_name": "Baltimore",
                                "iata_code": "BWI",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BWI",
                                    "latitude": null,
                                    "name": "Baltimore",
                                    "iata_city_code": "BWI",
                                    "id": "cit_bwi_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 39.176402,
                                "name": "Baltimore Washington International Thurgood Marshall Airport",
                                "iata_city_code": "BWI",
                                "id": "arp_bwi_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -76.668345
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": null,
                            "operating_carrier": {
                                "iata_code": "NK",
                                "conditions_of_carriage_url": "https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/NK.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/NK.svg",
                                "name": "Spirit Airlines",
                                "id": "arl_00009VME7DDSWSIAygCT2J"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "310",
                            "marketing_carrier": {
                                "iata_code": "NK",
                                "conditions_of_carriage_url": "https://content.spirit.com/Shared/en-us/Documents/Contract_of_Carriage.pdf",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/NK.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/NK.svg",
                                "name": "Spirit Airlines",
                                "id": "arl_00009VME7DDSWSIAygCT2J"
                            },
                            "duration": "PT1H59M",
                            "marketing_carrier_flight_number": "310",
                            "departing_at": "2024-10-15T20:46:00",
                            "stops": [],
                            "id": "seg_0000Amm9n5RoLjqggi6ECG"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [],
            "available_services": null,
            "created_at": "2024-10-07T21:39:29.610955Z",
            "total_emissions_kg": "50",
            "total_currency": "USD",
            "supported_loyalty_programmes": [],
            "expires_at": "2024-10-07T21:54:29.369936Z",
            "updated_at": "2024-10-07T21:39:29.610955Z",
            "total_amount": "264.58",
            "live_mode": false,
            "base_amount": "194.00",
            "id": "off_0000Amm9n5RoLjqggi6ECJ",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "USD"
                },
                "refund_before_departure": {
                    "penalty_amount": null,
                    "allowed": false,
                    "penalty_currency": null
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "42.26",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT4H55M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BmxNHg==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7nVc2ORyaGaIM",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "SAA3AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T11:59:00",
                            "aircraft": {
                                "iata_code": "7M9",
                                "name": "Boeing 737 MAX 9 / BBJ MAX 9",
                                "id": "arc_00009oBdrPis4D1mAnkllc"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "592",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H24M",
                            "marketing_carrier_flight_number": "592",
                            "departing_at": "2024-10-15T10:35:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nVc2ORyaGaIK"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "SAA3AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T15:30:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1359",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "1359",
                            "departing_at": "2024-10-15T13:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nVc2ORyaGaIL"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "49",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "279.06",
            "live_mode": false,
            "base_amount": "236.80",
            "id": "off_0000Amm9n7nraig1zgQrqa",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        },
        {
            "payment_requirements": {
                "payment_required_by": "2024-10-09T03:59:00Z",
                "requires_instant_payment": false,
                "price_guarantee_expires_at": "2024-10-09T03:59:00Z"
            },
            "passenger_identity_documents_required": false,
            "base_currency": "USD",
            "private_fares": [],
            "tax_amount": "42.26",
            "owner": {
                "iata_code": "UA",
                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                "name": "United Airlines",
                "id": "arl_00009VME7DDoV8ZkzmMkaN"
            },
            "tax_currency": "USD",
            "slices": [
                {
                    "duration": "PT6H25M",
                    "destination_type": "airport",
                    "origin": {
                        "icao_code": "KBOS",
                        "city_name": "Boston",
                        "iata_code": "BOS",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "BOS",
                            "latitude": null,
                            "name": "Boston",
                            "iata_city_code": "BOS",
                            "id": "cit_bos_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 42.364956,
                        "name": "General Edward Lawrence Logan International Airport",
                        "iata_city_code": "BOS",
                        "id": "arp_bos_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -71.007381
                    },
                    "fare_brand_name": "Economy Fully Refundable",
                    "destination": {
                        "icao_code": "KATL",
                        "city_name": "Atlanta",
                        "iata_code": "ATL",
                        "city": {
                            "icao_code": null,
                            "city_name": null,
                            "iata_code": "ATL",
                            "latitude": null,
                            "name": "Atlanta",
                            "iata_city_code": "ATL",
                            "id": "cit_atl_us",
                            "time_zone": null,
                            "type": "city",
                            "iata_country_code": "US",
                            "longitude": null
                        },
                        "latitude": 33.638714,
                        "name": "Hartsfield-Jackson Atlanta International Airport",
                        "iata_city_code": "ATL",
                        "id": "arp_atl_us",
                        "time_zone": "America/New_York",
                        "type": "airport",
                        "iata_country_code": "US",
                        "longitude": -84.4279
                    },
                    "comparison_key": "BIpj+A==",
                    "ngs_shelf": 2,
                    "id": "sli_0000Amm9n7nraig1zgQrqm",
                    "conditions": {
                        "change_before_departure": null,
                        "priority_boarding": null,
                        "advance_seat_selection": null,
                        "priority_check_in": null
                    },
                    "origin_type": "airport",
                    "segments": [
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "SAA3AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "B",
                            "distance": "321.86800",
                            "arriving_at": "2024-10-15T10:30:00",
                            "aircraft": {
                                "iata_code": "739",
                                "name": "Boeing 737-900",
                                "id": "arc_00009VMF8AhBTmADP77oj1"
                            },
                            "origin": {
                                "icao_code": "KBOS",
                                "city_name": "Boston",
                                "iata_code": "BOS",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "BOS",
                                    "latitude": null,
                                    "name": "Boston",
                                    "iata_city_code": "BOS",
                                    "id": "cit_bos_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 42.364956,
                                "name": "General Edward Lawrence Logan International Airport",
                                "iata_city_code": "BOS",
                                "id": "arp_bos_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -71.007381
                            },
                            "destination": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination_terminal": "A",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1805",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT1H25M",
                            "marketing_carrier_flight_number": "1805",
                            "departing_at": "2024-10-15T09:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrqk"
                        },
                        {
                            "passengers": [
                                {
                                    "cabin_class": "economy",
                                    "fare_basis_code": "SAA3AHDM",
                                    "passenger_id": "pas_0000Amm9my83Ymt5zPnqvx",
                                    "baggages": [
                                        {
                                            "quantity": 0,
                                            "type": "checked"
                                        },
                                        {
                                            "quantity": 1,
                                            "type": "carry_on"
                                        }
                                    ],
                                    "cabin": {
                                        "amenities": {
                                            "seat": {
                                                "legroom": "standard",
                                                "pitch": "30",
                                                "type": "standard"
                                            },
                                            "wifi": {
                                                "cost": "paid",
                                                "available": true
                                            },
                                            "power": {
                                                "available": true
                                            }
                                        },
                                        "marketing_name": "ECONOMY",
                                        "name": "economy"
                                    },
                                    "cabin_class_marketing_name": "ECONOMY"
                                }
                            ],
                            "origin_terminal": "A",
                            "distance": "1197.34896",
                            "arriving_at": "2024-10-15T15:30:00",
                            "aircraft": {
                                "iata_code": "7M8",
                                "name": "Boeing 737 MAX 8 / BBJ MAX 8/MAX200",
                                "id": "arc_00009g3mppxYZbG6EEWxlI"
                            },
                            "origin": {
                                "icao_code": "KEWR",
                                "city_name": "Newark",
                                "iata_code": "EWR",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "NYC",
                                    "latitude": null,
                                    "name": "New York",
                                    "iata_city_code": "NYC",
                                    "id": "cit_nyc_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 40.691016,
                                "name": "Newark Liberty International Airport",
                                "iata_city_code": "NYC",
                                "id": "arp_ewr_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -74.171581
                            },
                            "destination": {
                                "icao_code": "KATL",
                                "city_name": "Atlanta",
                                "iata_code": "ATL",
                                "city": {
                                    "icao_code": null,
                                    "city_name": null,
                                    "iata_code": "ATL",
                                    "latitude": null,
                                    "name": "Atlanta",
                                    "iata_city_code": "ATL",
                                    "id": "cit_atl_us",
                                    "time_zone": null,
                                    "type": "city",
                                    "iata_country_code": "US",
                                    "longitude": null
                                },
                                "latitude": 33.638714,
                                "name": "Hartsfield-Jackson Atlanta International Airport",
                                "iata_city_code": "ATL",
                                "id": "arp_atl_us",
                                "time_zone": "America/New_York",
                                "type": "airport",
                                "iata_country_code": "US",
                                "longitude": -84.4279
                            },
                            "destination_terminal": "N",
                            "operating_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "media": [],
                            "operating_carrier_flight_number": "1359",
                            "marketing_carrier": {
                                "iata_code": "UA",
                                "conditions_of_carriage_url": "https://www.united.com/ual/en/GB/fly/contract.html",
                                "logo_symbol_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/UA.svg",
                                "logo_lockup_url": "https://assets.duffel.com/img/airlines/for-light-background/full-color-lockup/UA.svg",
                                "name": "United Airlines",
                                "id": "arl_00009VME7DDoV8ZkzmMkaN"
                            },
                            "duration": "PT2H25M",
                            "marketing_carrier_flight_number": "1359",
                            "departing_at": "2024-10-15T13:05:00",
                            "stops": [],
                            "id": "seg_0000Amm9n7nraig1zgQrql"
                        }
                    ]
                }
            ],
            "passengers": [
                {
                    "loyalty_programme_accounts": [],
                    "id": "pas_0000Amm9my83Ymt5zPnqvx",
                    "fare_type": null,
                    "given_name": "Amelia",
                    "type": "adult",
                    "family_name": "Earhart",
                    "age": null
                }
            ],
            "supported_passenger_identity_document_types": [
                "passport",
                "passenger_redress_number",
                "known_traveler_number"
            ],
            "available_services": null,
            "created_at": "2024-10-07T21:39:30.022630Z",
            "total_emissions_kg": "53",
            "total_currency": "USD",
            "supported_loyalty_programmes": [
                "UA"
            ],
            "expires_at": "2024-10-07T21:59:28.000000Z",
            "updated_at": "2024-10-07T21:39:30.022630Z",
            "total_amount": "279.06",
            "live_mode": false,
            "base_amount": "236.80",
            "id": "off_0000Amm9n7nraig1zgQrqo",
            "conditions": {
                "change_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                },
                "refund_before_departure": {
                    "penalty_amount": "0.00",
                    "allowed": true,
                    "penalty_currency": "GBP"
                }
            },
            "partial": false
        }
    ];

export default sampleDuffelFlights;