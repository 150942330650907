import React from 'react';
import {Button, Card, CardHeader, Chip, Dialog, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {selectedSuggestionStyle, unselectedSuggestionStyle} from "../ButtonStyle/Suggestions";


function GettingStarted({ isOpen, onClose }){

    const topics = [
        { title: 'Let\'s fill in the basic details',
            options: ['Add dates/days', 'Add event photos', 'Add event name'] },
        { title: 'Invite people - Select how you want to add people to your event',
            options: ['From past event', 'Add new', 'Upload spreadsheet'] },
        { title: 'Where are you staying?',
            options: ['AirBnB', 'Hotel', 'Camping', 'Enter address', 'From GPS', 'Don\'t know yet, but I know the city and state'] },
        { title: 'Make your to-do list',
            options: ['Add new', 'Pull from traditional to-do items', 'Don\'t have any'] },
        { title: 'Start your packing list - Never forget essential items for your trip',
            options: ['Pull from essentials list', 'Add new', 'Get inspiration from suggested lists'] },
        { title: 'Have any activities planned yet?',
            options: ['Yes, let\'s add it!', 'Need suggestions please', 'Nothing yet'] },
        { title: 'Keep your travel expenses under control - set your budget',
            options: ['Yes please!', 'Maybe later'] },
    ];

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle
                sx={{
                    color: '#151269',
                    textAlign:'center',
                    backgroundColor:'rgba(198, 226, 255, 0.8)',
                    paddingRight: '30px'
                }}
            >
                Organize all your travel details in one place!
            </DialogTitle>
            <DialogTitle
                sx={{
                    fontSize: '18px',
                    textAlign:'center',
                    color: '#151269',
                    backgroundColor:'rgba(198, 226, 255, 0.8)',
                    padding:0
                }}
            >
                We're here to help you get started
            </DialogTitle>
            <DialogContent>

                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: '#151269',
                    })}
                >
                    <i className="icon-cross" style={{fontSize: '18px', padding: '5px'}}/>
                </IconButton>

                {topics.map((topic, index) => (
                    <Box key={index} mx={1} my={1}>
                        {/* Horizontal Line + Topic + Horizontal Line */}
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <Divider sx={{ width: '20px', height: '1px', marginRight: '10px', backgroundColor: '#0096ff' }} />
                            <Typography variant="subtitle2">{topic.title}</Typography>
                            <Divider sx={{ width: '20px', height: '1px', marginLeft: '10px', flex: 1, backgroundColor: '#0096ff' }} />
                        </Box>
                        {/* Options below each topic */}
                        <Box display="flex" justifyContent="flex-start" mt={1} gap={1} flexWrap="wrap">
                            {topic.options.map((option, idx) => (
                                <Chip
                                    key={idx}
                                    sx={{
                                        boxShadow: '' +
                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                        border: '1px solid #fff',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        backgroundColor: '#FFF',
                                        color: 'black', // Text color (adjust as needed)
                                        '&:hover': {
                                            background: '#cbe0f7', // Reverse the gradient on hover
                                            border: '1px solid #0096ff',
                                        },
                                    }}
                                    label={option}
                                    clickable
                                    style={{margin: '0.5rem'}}
                                />

                            ))}
                        </Box>
                    </Box>
                ))}
            </DialogContent>
        </Dialog>
    );

}

export default GettingStarted;