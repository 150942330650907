// SummaryComponent.js
import React from 'react';
import { Box, Typography } from '@mui/material';

const FlightSummary = ({ flights }) => {
    if (!flights.length) {
        return (
            <div>
                <Typography variant="h7">Flight Summary</Typography>
            </div>
        );
    }

    return (
        <div>
            <Typography variant="h7">Flight Summary ({flights.length})</Typography>
            {flights.map((flight, idx) => (
                <Box key={idx} border={1} padding={2} marginBottom={2}>
                    <Typography variant="h6">{flight.owner.name}</Typography>
                    <Typography>{`From: ${flight.slices[0].origin.iata_code}`}</Typography>
                    <Typography>{`To: ${flight.slices[0].destination.iata_code}`}</Typography>
                    <Typography>{`Duration: ${flight.slices[0].duration}`}</Typography>
                    <Typography>{`Price: $${flight.total_amount}`}</Typography>
                </Box>
            ))}
        </div>
    );
};


export default FlightSummary;
