import React, {useContext} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";

import {Card, CardActions, CardHeader, CardMedia} from "@mui/material";
import Grid from '@mui/material/Grid2';
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import './dashboard.css';
import dayjs from "dayjs";
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);



function CenterContentEvents(props){
    console.log("CenterContentEvents : "+JSON.stringify(props));

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Events, Account } = dataArray;


    const getMessageForEvent = (event) => {
        const now = dayjs();
        const beginDate = dayjs(event.calendar.begin_date);
        const endDate = dayjs(event.calendar.end_date);
        const daysUntilEvent = beginDate.diff(now, 'days');
        const daysAgo = now.diff(endDate, 'days');

        if (now.isBefore(beginDate)) {
            if (daysUntilEvent >= 730) { // 730 days = 24 months
                const yearsUntilEvent = beginDate.diff(now, 'years');
                if (yearsUntilEvent >= 2) {
                    return `Coming in ${yearsUntilEvent} years`;
                } else {
                    return 'Coming in 1 year';
                }
            } else if (daysUntilEvent >= 60) {
                const monthsUntilEvent = beginDate.diff(now, 'months');
                if (monthsUntilEvent >= 2) {
                    return `Coming in ${monthsUntilEvent} months`;
                } else {
                    return 'Coming in 1 month';
                }
            } else {
                return `Coming in ${daysUntilEvent} day${daysUntilEvent !== 1 ? 's' : ''}`;
            }
        } else if (now.isAfter(endDate)) {
            if (daysAgo >= 730) { // 730 days = 24 months
                const yearsAgo = now.diff(endDate, 'years');
                if (yearsAgo >= 2) {
                    return `${yearsAgo} years ago`;
                } else {
                    return '1 year ago';
                }
            } else if (daysAgo >= 60) {
                const monthsAgo = now.diff(endDate, 'months');
                if (monthsAgo >= 2) {
                    return `${monthsAgo} months ago`;
                } else {
                    return '1 month ago';
                }
            } else {
                return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
            }
        } else {
            return 'Happening now';
        }
    };

    const filterEventsByCategory = (events, categoryToFilter) => {
        console.log("filterEventsByCategory : "+JSON.stringify(categoryToFilter));
        // Filter events based on the specific category
        return events.filter((event) => {
            // Check if the event has menu items
            if (event.menu_items) {
                return event.menu_items.some((menuItem) => {
                    // Check if the menu item belongs to the specific attendee
                    if (menuItem.id === Account.id) {
                        // Check if the menu item has the specific category
                        return menuItem.categories.includes(categoryToFilter);
                    }
                    return false;
                });
            }
            return false;
        });
    };


    const showRelevantEvents = () => {

        if (props.type === "current"){
            if (Events.filter(event => dayjs(event.calendar.begin_date).diff(dayjs(), 'day') >= 0).length > 0) {

            } else {
                //nothing to show

            }
        } else if (props.type === "past") {
            if (Events.filter(event => dayjs(event.calendar.end_date).isBefore(dayjs())).length > 0) {

            } else {
                //nothing to show

            }
        } else if (props.type === "category") {
            if (filterEventsByCategory(Events, props.viewingEventGroup).length > 0){

            } else {
                //nothing to show
            }
        } else {
            //show everything that exists
            if (Events.length > 0){

            } else {
                //nothing to show
            }
        }



        return (
          <></>
        );



    }





    function handleEventItemClick(e){
        console.log('handleEventItemClick ', e);
        props.setViewingEventId(e);
        navigate('/dashboard/Event');
    }


    return (

        Events.length > 0 ? (
            <Grid
                className="dashboard-container"
                container
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 1, sm: 8, md: 12 }}>

                {
                    props.type === "current" ? (
                        //show all current and Upcoming Events

                        Events
                            .filter(event => dayjs(event.calendar.begin_date).diff(dayjs(), 'day') >= 0)
                            .sort((eventA, eventB) => {
                                const dateA = dayjs(eventA.calendar.begin_date);
                                const dateB = dayjs(eventB.calendar.begin_date);
                                return dateB.diff(dateA);
                            })
                            .map((event, index) => (

                            <Grid xs={2} sm={4} md={4} key={index}>

                                <Card
                                    className="event-item"
                                    onClick={() => handleEventItemClick(event.id)}
                                >
                                    <CardHeader
                                        sx={{
                                            padding:0
                                        }}
                                        title={event.name}
                                        subheader={dayjs(event.calendar.begin_date).format('MMMM Do YYYY')}
                                    />

                                    {
                                        (event.image === "" || event.image === "/assets/images/user_bg1.png") ? (
                                            <CardMedia
                                                component="img"
                                                height="194"
                                                image={"https://plannedadventure.com/assets/images/user_bg1.png"}
                                                alt={event.name}
                                            />
                                        ) : (
                                            <CardMedia
                                                component="img"
                                                height="194"
                                                image={event.cover_image_thumbnail}
                                                alt={event.name}
                                            />
                                        )
                                    }

                                    <CardActions
                                        disableSpacing
                                        sx={{
                                            float: 'right'
                                        }}
                                    >
                                        <p style={{fontSize: '14px', margin: 0}} >
                                            {getMessageForEvent(event)}
                                        </p>

                                        <IconButton aria-label="add to favorites">
                                            <FavoriteIcon
                                                sx={{
                                                    fontSize: '14px'
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton aria-label="share">
                                            <ShareIcon
                                                sx={{
                                                    fontSize: '14px'
                                                }}
                                            />
                                        </IconButton>
                                    </CardActions>
                                </Card>


                            </Grid>

                        ))

                    ) : props.type === "past" ? (

                        Events
                            .filter(event => dayjs(event.calendar.end_date).isBefore(dayjs()))
                            .sort((eventA, eventB) => {
                                const dateA = dayjs(eventA.calendar.begin_date);
                                const dateB = dayjs(eventB.calendar.begin_date);
                                return dateB.diff(dateA)})
                            .map((event, index) => (

                                <Grid xs={2} sm={4} md={4} key={index}>

                                    <Card
                                        className="event-item"
                                        onClick={() => handleEventItemClick(event.id)}
                                    >
                                        <CardHeader
                                            sx={{
                                                padding:0
                                            }}
                                            title={event.name}
                                            subheader={dayjs(event.calendar.begin_date).format('MMMM Do YYYY')}
                                        />
                                        {
                                            (event.image === "" || event.image === "/assets/images/user_bg1.png") ? (
                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={"https://plannedadventure.com/assets/images/user_bg1.png"}
                                                    alt={event.name}
                                                />
                                            ) : (
                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={event.cover_image_thumbnail}
                                                    alt={event.name}
                                                />
                                            )
                                        }

                                        <CardActions
                                            disableSpacing
                                            sx={{
                                                float: 'right'
                                            }}
                                        >
                                            <p style={{fontSize: '14px', margin: 0}} >
                                                {getMessageForEvent(event)}
                                            </p>

                                            <IconButton aria-label="add to favorites">
                                                <FavoriteIcon
                                                    sx={{
                                                        fontSize: '14px'
                                                    }}
                                                />
                                            </IconButton>
                                            <IconButton aria-label="share">
                                                <ShareIcon
                                                    sx={{
                                                        fontSize: '14px'
                                                    }}
                                                />
                                            </IconButton>
                                        </CardActions>
                                    </Card>


                                </Grid>

                            ))
                    ) : props.type === "category" ? (
                        //show all Events with the specific category
                            filterEventsByCategory(Events, props.viewingEventGroup)
                            .sort((eventA, eventB) => {
                                const dateA = dayjs(eventA.calendar.begin_date);
                                const dateB = dayjs(eventB.calendar.begin_date);
                                return dateB.diff(dateA)})
                            .map((event, index) => (

                                <Grid xs={2} sm={4} md={4} key={index}>

                                    <Card
                                        className="event-item"
                                        onClick={() => handleEventItemClick(event.id)}
                                    >
                                        <CardHeader
                                            sx={{
                                                padding:0
                                            }}
                                            title={event.name}
                                            subheader={dayjs(event.calendar.begin_date).format('MMMM Do YYYY')}
                                        />
                                        {
                                            (event.image === "" || event.image === "/assets/images/user_bg1.png") ? (
                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={"https://plannedadventure.com/assets/images/user_bg1.png"}
                                                    alt={event.name}
                                                />
                                            ) : (
                                                <CardMedia
                                                    component="img"
                                                    height="194"
                                                    image={event.cover_image_thumbnail}
                                                    alt={event.name}
                                                />
                                            )
                                        }

                                        <CardActions
                                            disableSpacing
                                            sx={{
                                                float: 'right'
                                            }}
                                        >
                                            <p style={{fontSize: '14px', margin: 0}} >
                                                {getMessageForEvent(event)}
                                            </p>

                                            <IconButton aria-label="add to favorites">
                                                <FavoriteIcon
                                                    sx={{
                                                        fontSize: '14px'
                                                    }}
                                                />
                                            </IconButton>
                                            <IconButton aria-label="share">
                                                <ShareIcon
                                                    sx={{
                                                        fontSize: '14px'
                                                    }}
                                                />
                                            </IconButton>
                                        </CardActions>
                                    </Card>


                                </Grid>

                            ))
                    ) : (
                        //show all Events
                        Events
                            .sort((eventA, eventB) => {
                                const dateA = dayjs(eventA.calendar.begin_date);
                                const dateB = dayjs(eventB.calendar.begin_date);
                                return dateB.diff(dateA)})
                            .map((event, index) => (

                            <Grid xs={2} sm={4} md={4} key={index}>

                                <Card
                                    className="event-item"
                                    onClick={() => handleEventItemClick(event.id)}
                                >
                                    <CardHeader
                                        sx={{
                                            padding:0
                                        }}
                                        title={event.name}
                                        subheader={dayjs(event.calendar.begin_date).format('MMMM Do YYYY')}
                                    />
                                    {
                                        (event.image === "" || event.image === "/assets/images/user_bg1.png") ? (
                                            <CardMedia
                                                component="img"
                                                height="194"
                                                image={"https://plannedadventure.com/assets/images/user_bg1.png"}
                                                alt={event.name}
                                            />
                                        ) : (
                                            <CardMedia
                                                component="img"
                                                height="194"
                                                image={event.cover_image_thumbnail}
                                                alt={event.name}
                                            />
                                        )
                                    }

                                    <CardActions
                                        disableSpacing
                                        sx={{
                                            float: 'right'
                                        }}
                                    >
                                        <p style={{fontSize: '14px', margin: 0}} >
                                            {getMessageForEvent(event)}
                                        </p>

                                        <IconButton aria-label="add to favorites">
                                            <FavoriteIcon
                                                sx={{
                                                    fontSize: '14px'
                                                }}
                                            />
                                        </IconButton>
                                        <IconButton aria-label="share">
                                            <ShareIcon
                                                sx={{
                                                    fontSize: '14px'
                                                }}
                                            />
                                        </IconButton>
                                    </CardActions>
                                </Card>


                            </Grid>

                        ))
                    )

                }

            </Grid>

        ) : (
              <div>No {props.type} Events</div>
            )
    )

}

export default CenterContentEvents;
