import React, {useContext, useEffect, useState} from 'react';

import {useNavigate} from "react-router-dom";
import {
    Button,
    Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    FormControl,
    Grid,
    InputLabel, MenuItem, Select,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import {PAContext} from "../../../Services/PAContext";
import Box from "@mui/material/Box";
import './supplies.css';
import Typography from "@mui/material/Typography";
import country_codes from "../../../sampleData/PhonePrefixes";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";


function SuppliesAddNew() {

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Account, Planning, Categories } = dataArray;

    function handleSuppliesAddOK() {
        console.log("handleSuppliesAddOK");
    }

    function handlePromptAddNewClose() {
        navigate('/Planner/Supplies')
    }


    const [itemName, setItemName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('supplies');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [subCategoryOptions, setSubCategoryOptions] = useState([]);
    const [publicPrivateAlignment, setPublicPrivateAlignment] = useState('public');

    useEffect(() => {
        //populate the subCategoryOptions based on the selectedCategory

        if (Categories !== undefined) {
            const selectedCategoryName = selectedCategory === "supplies" ? "Supply Categories" : "Food Categories";
            const selectedCategoryObject = Categories.find((_cat) => _cat.name === selectedCategoryName);

            if (selectedCategoryObject !== undefined) {
                const sortedSubCategoryOptions = [...selectedCategoryObject].sort();
                setSubCategoryOptions(sortedSubCategoryOptions);
            } else {
                // Handle the case where selectedCategoryObject is undefined
                console.error(`Category "${selectedCategoryName}" not found in Categories`);
            }
        }

    }, [Categories]);

    function handleNameChange(event){
        setItemName(event.target.value);
    }
    function handleSelectedCategoryChange(event){
        setSelectedCategory(event.target.value);

        //now update the appropriate sub categories
        if (Categories !== undefined) {
            const selectedCategoryName = selectedCategory === "supplies" ? "Supply Categories" : "Food Categories";
            const selectedCategoryObject = Categories.find((_cat) => _cat.name === selectedCategoryName);

            if (selectedCategoryObject !== undefined) {
                const sortedSubCategoryOptions = [...selectedCategoryObject].sort();
                setSubCategoryOptions(sortedSubCategoryOptions);
            } else {
                // Handle the case where selectedCategoryObject is undefined
                console.error(`Category "${selectedCategoryName}" not found in Categories`);
            }
        }

    }
    function handleSelectedSubCategoryChange(event){
        setSelectedSubCategory(event.target.value);
    }
    const handlePublicPrivateChange = (event, newAlignment) => {
        setPublicPrivateAlignment(newAlignment);
    };



    const [openCategoryExplanationDialog, setOpenCategoryExplanationDialog] = useState(false);

    function handleOpenCategoryExplanationDialog (){
        setOpenCategoryExplanationDialog(true);
    }

    function handleCategoryExplanationDialogClose(){
        setOpenCategoryExplanationDialog(false);
    }



    return (

        <div className="supplies-list-container" >
            {/* show the header */}
            <div
                className="supplies-list-header"
            >
                <div className="supplies-list-header-text">
                    <h5>New Supplies</h5>
                </div>
                <div className="supplies-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        color="error"
                        onClick={handlePromptAddNewClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        className="supplies-list-item-add-count-chip"
                        label="Add"
                        size="small"
                        onClick={handleSuppliesAddOK}
                    />
                </div>
            </div>


            {/* show all the fields */}
            <div className="supplies-list-items-container">
                <Grid container spacing={2} direction="column">

                    <Grid item style={{ flex: '1' }}>
                        <TextField
                            fullWidth
                            id="name"
                            label="Name"
                            required
                            value={itemName}
                            onChange={handleNameChange}
                        />
                    </Grid>

                    <Grid item style={{ flex: '1', position:'relative' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedCategory}
                                onChange={handleSelectedCategoryChange}
                                label="Category"
                            >
                                <MenuItem value="supplies">
                                    <em>Supplies</em>
                                </MenuItem>
                                <MenuItem value="food">
                                    <em>Food</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <i
                            className="icon-question-circle"
                            style={{position:'absolute', top:'15px', right:'0', color:'green', cursor:'pointer'}}
                            onClick={handleOpenCategoryExplanationDialog}
                        />
                        <Dialog
                            open={openCategoryExplanationDialog}
                            onClose={handleCategoryExplanationDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {"Organization and Flexibility"}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Knowing the Attendees address allows us to provide the following:
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Transportation</strong> : We can show routes to the destination
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Flights</strong> : We can show flight prices for trips where a flight might be involved.
                                </DialogContentText>
                                <DialogContentText id="alert-dialog-description">
                                    <strong>Timezone</strong> : Before and after the trip we can send scheduled reminders at the correct time.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCategoryExplanationDialogClose} autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-standard-label">Sub Category</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedSubCategory}
                                onChange={handleSelectedSubCategoryChange}
                                label="Sub Category"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>

                                {
                                    subCategoryOptions.map((_item, index) => (
                                        <MenuItem key={index} value={_item} >
                                            <em>{_item}</em>
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item style={{ flex: '1' }}>
                <Box sx={{padding:'10px'}}>
                    <Typography gutterBottom variant="h6" component="div">
                        Public or Private
                    </Typography>

                    <Typography gutterBottom variant="body2" color="text.secondary">
                        <strong>Public</strong> All Attendees in the Event will be able to see this item.
                    </Typography>
                    <Typography gutterBottom variant="body2" color="text.secondary">
                        <strong>Private</strong> None of the Attendees in the Event will be able to see this item.
                    </Typography>

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <ToggleButtonGroup
                            color="primary"
                            value={publicPrivateAlignment}
                            exclusive
                            onChange={handlePublicPrivateChange}
                            aria-label="Public/Private"
                        >
                            <ToggleButton value="public">
                                <Stack direction="column" alignItems="center">
                                    <i className="icon-eye" style={{fontSize: '36px', color:'#f09637'}} />
                                    Public
                                </Stack>
                            </ToggleButton>
                            <ToggleButton value="private">
                                <Stack direction="column" alignItems="center">
                                    <i className="icon-eye-cross" style={{fontSize: '36px', color:'#5E9F3D'}} />
                                    Private
                                </Stack>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </Box>
                    </Grid>


            </Grid>

            </div>


            {/* show the summary area */}
            <div className="supplies-list-items-summary-container">


            </div>

        </div>

    )
}

export default SuppliesAddNew;
