import React, {useContext, useEffect, useState} from 'react';

import {PAContext} from "../../../Services/PAContext";
import {Card, CardContent, Chip, CircularProgress, Grid, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDropzone} from "react-dropzone";
import {updateChipStyle} from "../../ButtonStyle/Update";
import Typography from "@mui/material/Typography";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {addChipStyle} from "../../ButtonStyle/Add";


const predefinedKeys = [
    "Airbnb receipt",
    "Lodging name",
    "address",
    "Check in date",
    "Check in time",
    "Check out date",
    "Check out time",
    "guests",
    "guest count",
    "reservation name",
    "reservation number",
    "confirmation code",
    "door code",
    "gate code",
    "message",
    "total cost"
];

function LodgingAddFromPDF(props) {

    const navigate = useNavigate();
    const { dataArray, setPlanning } = useContext(PAContext);
    const { Planning, ConciergeScanningDocumentActions } = dataArray;

    useEffect(() => {
        console.log("ConciergeScanningDocumentActions!!");
        try {
            for (let i = 0; i < ConciergeScanningDocumentActions.length; i++) {
                const lodgingDocumentScanningAction = ConciergeScanningDocumentActions[i];
                console.log("lodgingDocumentScanningAction : " + JSON.stringify(lodgingDocumentScanningAction, null, '\t'));

                if (lodgingDocumentScanningAction.action === "planningConciergeScanningServiceLodgingReceiptDetailsResults") {
                    //remove this one
                    ConciergeScanningDocumentActions.splice(i, 1);

                    //process the results
                    setNeedToProcessPDF(false);
                    setProcessingPDF(false);

                    setShowPDFDetails(true);

                    const _data_array = lodgingDocumentScanningAction.data_array;
                    for (const obj of _data_array) {
                        for (const key in obj) {
                            if (obj.hasOwnProperty(key)) {
                                const value = obj[key];
                                console.log(`Key: ${key}, Value: ${value}`);

                                for (const predefinedKey of predefinedKeys) {
                                    // Check if the key exists in the current JSON object
                                    console.log('comparing '+key+" and "+predefinedKey+" : "+(key === predefinedKey));
                                    if (key === predefinedKey) {

                                        switch (key){
                                            case "Airbnb receipt":
                                                break;
                                            case "Lodging name":
                                                setName(value);
                                                break;
                                            case "address":
                                                //break apart the address
                                                setAddress(value);
                                                break;
                                            case "Check in date":
                                                setCheckInDate(value);
                                                break;
                                            case "Check out date":
                                                setCheckOutDate(value);
                                                break;
                                            case "Check in time":
                                                setCheckInTime(value);
                                                break;
                                            case "Check out time":
                                                setCheckOutTime(value);
                                                break;

                                            case "guests":
                                                setGuests(value);
                                                break;

                                            case "guest count":
                                                setNumGuests(value);
                                                break;

                                            case "reservation name":
                                                setReservationName(value);
                                                break;
                                            case "reservation number":
                                                setReservationNumber(value);
                                                break;
                                            case "confirmation code":
                                                setConfirmationNumber(value);
                                                break;

                                            case "door code":
                                                setDoorCode(value);
                                                break;
                                            case "gate code":
                                                setGateCode(value);
                                                break;

                                            case "message":
                                                setNotes(value);
                                                break;

                                            case "total cost":
                                                setTotalCost(value);
                                                break;

                                            default:

                                        }
                                    }
                                }

                            }
                        }
                    }

                    /*
                    for (const jsonObj of _data_array) {

                        const value = _data_array[jsonObj];
                        for (const predefinedKey of predefinedKeys) {
                            // Check if the key exists in the current JSON object
                            console.log('comparing '+jsonObj+" and "+predefinedKey+" : "+(jsonObj === predefinedKey));
                            if (jsonObj === predefinedKey) {

                                switch (jsonObj){
                                    case "Airbnb receipt":
                                        break;
                                    case "Lodging name":
                                        setName(value);
                                        break;
                                    case "address":
                                        //break apart the address
                                        setAddress(value);
                                        break;
                                    case "Check in date":
                                        setCheckInDate(value);
                                        break;
                                    case "Check out date":
                                        setCheckOutDate(value);
                                        break;
                                    case "Check in time":
                                        setCheckInTime(value);
                                        break;
                                    case "Check out time":
                                        setCheckOutTime(value);
                                        break;

                                    case "guests":
                                        setGuests(value);
                                        break;

                                    case "guest count":
                                        setNumGuests(value);
                                        break;

                                    case "reservation name":
                                        setReservationName(value);
                                        break;
                                    case "reservation number":
                                        setReservationNumber(value);
                                        break;
                                    case "confirmation code":
                                        setConfirmationNumber(value);
                                        break;

                                    case "door code":
                                        setDoorCode(value);
                                        break;
                                    case "gate code":
                                        setGateCode(value);
                                        break;

                                    case "message":
                                        setNotes(value);
                                        break;

                                    case "total cost":
                                        setTotalCost(value);
                                        break;

                                    default:

                                }
                            }
                        }
                    }

                    Object.keys(_data_array).forEach((key) => {
                        const value = _data_array[key];
                        console.log(`Key: ${key}, Value: ${value}`);

                        for (const predefinedKey of predefinedKeys) {
                            // Check if the key exists in the current JSON object
                            console.log('comparing '+key+" and "+predefinedKey+" : "+(key === predefinedKey));
                            if (key === predefinedKey) {

                                switch (key){
                                    case "Airbnb receipt":
                                        break;
                                    case "Lodging name":
                                        setName(value);
                                        break;
                                    case "address":
                                        //break apart the address
                                        setAddress(value);
                                        break;
                                    case "Check in date":
                                        setCheckInDate(value);
                                        break;
                                    case "Check out date":
                                        setCheckOutDate(value);
                                        break;
                                    case "Check in time":
                                        setCheckInTime(value);
                                        break;
                                    case "Check out time":
                                        setCheckOutTime(value);
                                        break;

                                    case "guests":
                                        setGuests(value);
                                        break;

                                    case "guest count":
                                        setNumGuests(value);
                                        break;

                                    case "reservation name":
                                        setReservationName(value);
                                        break;
                                    case "reservation number":
                                        setReservationNumber(value);
                                        break;
                                    case "confirmation code":
                                        setConfirmationNumber(value);
                                        break;

                                    case "door code":
                                        setDoorCode(value);
                                        break;
                                    case "gate code":
                                        setGateCode(value);
                                        break;

                                    case "message":
                                        setNotes(value);
                                        break;

                                    case "total cost":
                                        setTotalCost(value);
                                        break;

                                    default:

                                }
                            }
                        }
                        console.log(`----------------`);
                    });
                    */
                }


            }


        } catch (e) {
            console.log(e);
        }

    }, [ConciergeScanningDocumentActions]);



    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");

    const [phone, setPhone] = useState("");

    const [checkInDate, setCheckInDate] = useState("");
    const [checkOutDate, setCheckOutDate] = useState("");
    const [checkInTime, setCheckInTime] = useState("");
    const [checkOutTime, setCheckOutTime] = useState("");

    const [guests, setGuests] = useState([]);
    const [numGuests, setNumGuests] = useState(0);

    const [bookedThrough, setBookedThrough] = useState("");
    const [confirmationNumber, setConfirmationNumber] = useState("");
    const [reservationName, setReservationName] = useState("");
    const [reservationNumber, setReservationNumber] = useState("");
    const [gateCode, setGateCode] = useState("");
    const [doorCode, setDoorCode] = useState("");
    const [wifiName, setWifiName] = useState("");
    const [wifiPassword, setWifiPassword] = useState("");
    const [notes, setNotes] = useState("");

    const [totalCost, setTotalCost] = useState(0);

    function handleNameChange(e){
        setName(e.target.value);
    }
    function handleAddressChange(e){
        setAddress(e.target.value);
    }
    function handleCityChange(e){
        setCity(e.target.value);
    }
    function handleStateChange(e){
        setState(e.target.value);
    }
    function handleNameZipChange(e){
        setZip(e.target.value);
    }
    function handleCountryChange(e){
        setCountry(e.target.value);
    }
    function handlePhoneChange(e){
        setPhone(e.target.value);
    }
    function handleBookedThroughChange(event){
        setBookedThrough(event.target.value);
    }
    function handleConfirmationNumberChange(event){
        setConfirmationNumber(event.target.value);
    }
    function handleReservationNameChange(event){
        setReservationName(event.target.value);
    }
    function handleReservationNumberChange(event){
        setReservationNumber(event.target.value);
    }
    function handleGateCodeChange(event){
        setGateCode(event.target.value);
    }
    function handleDoorCodeChange(event){
        setDoorCode(event.target.value);
    }
    function handleWifiNameChange(event){
        setWifiName(event.target.value);
    }
    function handleWifiPasswordChange(event){
        setWifiPassword(event.target.value);
    }
    function handleNotesChange(event){
        setNotes(event.target.value);
    }


    const [ needToProcessPDF , setNeedToProcessPDF ] = useState(false);
    const [ processingPDF , setProcessingPDF ] = useState(false);
    const [ needToSavePDF , setNeedToSavePDF ] = useState(false);
    const [ showPDFDetails , setShowPDFDetails ] = useState(false);
    const [ lodgingReceiptName, setLodgingReceiptName ] = useState('');
    const [ lodgingReceiptType, setLodgingReceiptType ] = useState('');
    const [ lodgingReceiptSize, setLodgingReceiptSize ] = useState(0);
    const [ lodgingReceiptData, setLodgingReceiptData ] = useState('');

    const [ files, setFiles ] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/pdf': []
        },
        onDrop: acceptedFiles => {
            console.log("acceptedFiles : "+JSON.stringify(acceptedFiles));

            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            setNeedToProcessPDF(true);
        }
    });

    useEffect(() => {

        for (const _file of files){
            console.log("_file : "+_file);

            const reader = new FileReader();
            reader.onload = function (event){
                console.log("name : " + _file.name);
                console.log("size : " + _file.size);
                console.log("type : " + _file.type);
                console.log("height : " + _file.height);
                console.log("width : " + _file.width);

                console.log("event.target.result : "+ event.target.result);

                setLodgingReceiptName(_file.name);
                setLodgingReceiptSize(_file.size);
                setLodgingReceiptType(_file.type);
                setLodgingReceiptData(event.target.result);
            }
            reader.readAsDataURL(_file);
        }

    }, [files]);

    function handleEventLodgingPDFSave() {

    }
    function handleEventLodgingPDFProcessing(){
        //change the button display and behavior
        setProcessingPDF(true);

        //send the document data to server
        if (dataArray.Websocket){

            const _sendString = {};
            _sendString.action = "planningConciergeScanningServiceLodgingReceiptDetails";
            _sendString.fileName = lodgingReceiptName;
            _sendString.fileSize = lodgingReceiptSize;
            _sendString.fileType = lodgingReceiptType;
            _sendString.fileData = lodgingReceiptData;

            dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

        } else {
            //show error that network not available

        }


    }



    function handleEventLodgingPDFProcessingCancel() {
        setProcessingPDF(false);
    }


    function handlePromptAddLodgingOK(){



        handlePromptAddLodgingClose();
    }
    function handlePromptAddLodgingClose(){
        navigate('/Planner/Lodging');
    }

    return (
        <div className="lodging-suggestions-list-container" >
            {/* show the header */}
            <div
                className="lodging-suggestions-list-header"
            >
                <div className="lodging-suggestions-list-header-text">
                    <h5>Add Lodging</h5>
                </div>

                <div className="lodging-suggestions-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        color="error"
                        onClick={handlePromptAddLodgingClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label="Add"
                        size="small"
                        onClick={handlePromptAddLodgingOK}
                    />
                </div>
            </div>

            <div className="lodging-suggestions-add-list-items-container">
                <Grid container spacing={2} direction="column">

                    <Grid item sx={{display:'flex'}}>
                        <Card
                            className="details-list-item"
                        >
                            <CardContent>

                                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>
                                    <Grid item style={{ flex: '1' }}>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p style={{cursor:'pointer'}}>Drag 'n' drop PDF receipt here,
                                                or click to select file.</p>
                                        </div>

                                        {/* add the document details */}
                                        {
                                            lodgingReceiptData.length > 0 ? (
                                                <div>
                                                    <Typography gutterBottom variant="body2" color="text.secondary">
                                                        <strong>File Name</strong> : {lodgingReceiptName}
                                                    </Typography>
                                                    <Typography gutterBottom variant="body2" color="text.secondary">
                                                        <strong>File Size</strong> : {lodgingReceiptSize}
                                                    </Typography>
                                                    <Typography gutterBottom variant="body2" color="text.secondary">
                                                        <strong>File Type</strong> : {lodgingReceiptType}
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <></>
                                            )
                                        }


                                        {/* add the Process/Save button */}
                                        {
                                            needToSavePDF ? (
                                                <div className="details-list-item-update-name-chip-holder" >
                                                    <Chip
                                                        sx={updateChipStyle}
                                                        className="details-list-item-update-name-chip"
                                                        label="Save"
                                                        size="small"
                                                        onClick={handleEventLodgingPDFSave}
                                                    />
                                                </div>
                                            ) : (

                                                needToProcessPDF ? (
                                                    <div className="details-list-item-update-name-chip-holder" >
                                                        <Chip
                                                            sx={updateChipStyle}
                                                            className="details-list-item-update-name-chip"
                                                            label={processingPDF ? 'Processing' : 'Process'}
                                                            size="small"
                                                            onClick={handleEventLodgingPDFProcessing}
                                                            icon={processingPDF ? <CircularProgress size={20} /> : null}
                                                            disabled={processingPDF}
                                                        />
                                                        {
                                                            processingPDF ? (
                                                                <Chip
                                                                    sx={cancelChipStyle}
                                                                    className="details-list-item-update-name-chip"
                                                                    label="Cancel"
                                                                    size="small"
                                                                    onClick={handleEventLodgingPDFProcessingCancel}
                                                                />
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </div>
                                                ) : (
                                                    <></>
                                                )

                                            )
                                        }
                                        {/* add the checkbox to save Document with Event */}
                                        {
                                            showPDFDetails ? (
                                                <>Save PDF with Event Documents?</>
                                            ) : (
                                                <></>
                                            )
                                        }

                                        {/* add the input boxes */}
                                        {
                                            showPDFDetails ? (
                                                <Grid container spacing={2} direction="column" sx={{marginTop:'0'}}>

                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="name"
                                                            label="Name"
                                                            required
                                                            value={name}
                                                            onChange={handleNameChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="address"
                                                            label="Address"
                                                            value={address}
                                                            onChange={handleAddressChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="city"
                                                            label="City"
                                                            value={city}
                                                            onChange={handleCityChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="state"
                                                            label="State"
                                                            value={state}
                                                            onChange={handleStateChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="zip"
                                                            label="Zip"
                                                            value={zip}
                                                            onChange={handleNameZipChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="country"
                                                            label="Country"
                                                            value={country}
                                                            onChange={handleCountryChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_booked_through"
                                                            label="Booked Through"
                                                            value={bookedThrough}
                                                            onChange={handleBookedThroughChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_confirmation_number"
                                                            label="Confirmation Number"
                                                            value={confirmationNumber}
                                                            onChange={handleConfirmationNumberChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_reservation_name"
                                                            label="Reservation Name"
                                                            value={reservationName}
                                                            onChange={handleReservationNameChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_reservation_number"
                                                            label="Reservation Number"
                                                            value={reservationNumber}
                                                            onChange={handleReservationNumberChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_gate_code"
                                                            label="Gate Code"
                                                            value={gateCode}
                                                            onChange={handleGateCodeChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_door_code"
                                                            label="Door Code"
                                                            value={doorCode}
                                                            onChange={handleDoorCodeChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_wifi_name"
                                                            label="Wifi Name"
                                                            value={wifiName}
                                                            onChange={handleWifiNameChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_wifi_password"
                                                            label="Wifi Password"
                                                            value={wifiPassword}
                                                            onChange={handleWifiPasswordChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_total_cost"
                                                            label="Total Cost"
                                                            value={totalCost}
                                                            onChange={handleWifiPasswordChange}
                                                        />
                                                    </Grid>
                                                    <Grid item style={{ flex: '1' }}>
                                                        <TextField
                                                            fullWidth
                                                            id="new_lodging_notes"
                                                            label="Notes"
                                                            multiline
                                                            rows={4}
                                                            value={notes}
                                                            onChange={handleNotesChange}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                    ) : (
                                                <></>
                                            )
                                        }

                                    </Grid>
                                </Grid>

                                {/* Show the area to select/drop the document */}


                                {/* Show the processing spinner */}


                                {/* Show the results of the parsing */}



                                {/* Show the 'How'd we do' survey */}

                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </div>
        </div>

    )
}

export default LodgingAddFromPDF;
