import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {PAContext} from "../../../Services/PAContext";
import {
    Card, CardActions,
    CardContent,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import Grid from '@mui/material/Grid2';

import DOMPurify from 'dompurify';

import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import {updateChipStyle, updateGradientStyle} from "../../ButtonStyle/Update";

import {useDropzone} from 'react-dropzone';

import './details.css';
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";





function DetailsView(props){

    const navigate = useNavigate();

    const { dataArray, setShowSuccessSnackbar  } = useContext(PAContext);
    const { Planning, Events, Categories, Account } = dataArray;

    const [detailsTabSelected, setDetailsTabSelected] = useState("Name");
    const [eventName, setEventName] = useState(Planning.title);

    useEffect(() => {
        setEventName(Planning.title);
        setStatus(Planning.status);
        setCoverImageData(Planning.cover_image_thumbnail);
    }, [Planning]);

    function handlePromptDetailsClose() {
        props.setShowEventItemConfig(false);
    }

    function handleFooterItemClick(_item){
        detailsTabSelected === _item ? setDetailsTabSelected("") : setDetailsTabSelected(_item)
    }

    function handleEventNameChange(event){
        setEventName(event.target.value);
    }

    function handleEventNameUpdate(){

        Planning.title = eventName;

        //propagate the change
        props.setEventName(eventName);

        //show that it was successful
        setShowSuccessSnackbar(true);

    }


    const [ needToSaveImage , setNeedToSaveImage ] = useState(false);
    const [ coverImageName, setCoverImageName ] = useState('');
    const [ coverImageType, setCoverImageType ] = useState('');
    const [ coverImageSize, setCoverImageSize ] = useState('');
    const [ coverImageData, setCoverImageData ] = useState(Planning.cover_image_thumbnail);
    const [ files, setFiles ] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            setNeedToSaveImage(true);
            console.log("acceptedFiles : "+JSON.stringify(acceptedFiles));

            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    useEffect(() => {

        for (const _file of files){
            console.log("_file : "+_file);

            const reader = new FileReader();
            reader.onload = function (event){
                console.log("name : " + _file.name);
                console.log("size : " + _file.size);
                console.log("type : " + _file.type);
                console.log("height : " + _file.height);
                console.log("width : " + _file.width);

                console.log("event.target.result : "+ event.target.result);

                setCoverImageName(_file.name);
                setCoverImageSize(_file.size);
                setCoverImageType(_file.type);
                setCoverImageData(event.target.result);
            }
            reader.readAsDataURL(_file);
        }

    }, [files]);

    useEffect(() => {
        console.log("Planning.coverImage : "+Planning.coverImage);
        if (Planning.coverImage){
            const _coverImage = Planning.coverImage;
            setCoverImageName(_coverImage.name);
            setCoverImageSize(_coverImage.size);
            setCoverImageType(_coverImage.type);
            setCoverImageData(_coverImage.data);
        }

        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
//        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    function handleEventCoverImageSave(){

        Planning.coverImage = {
            name:coverImageName,
            size:coverImageSize,
            type:coverImageType,
            data:coverImageData
        };

        //show that it was successful
        setShowSuccessSnackbar(true);

        //turn off the save button
        setNeedToSaveImage(false);
    }

    /*
        for the Event Introduction/description
     */
    const [userInput, setUserInput] = useState(Planning.eventIntro);
    const [sanitizedContent, setSanitizedContent] = useState(Planning.eventIntro);

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const renderContent = () => {
        const formattedInput = userInput.replace(/\n/g, '<br />');
        const sanitized = DOMPurify.sanitize(formattedInput);
        setSanitizedContent(sanitized);

        Planning.eventIntro = sanitized;
    };


    useEffect(()=> {
        console.log("Categories Change!! : "+Categories);
    }, [Categories]);
    const _attendee_categories = [];
    const _event_categories = [
        "Birthdays",
        "Holidays",
        "Reunions",
        "Anniversaries",
        "Vacations",
        "Weddings",
        "Weekends",
        "Sporting Events",
        "Family Getaways"
    ];

    useEffect(() => {
        console.log("In the Events for getting Categories");
        console.log("Events.length : "+Events.length);

        const _previously_selected_categories = [];

        if (Events.length > 0){

            for (const _event of Events){
                let _entries_exist = false;
                for (const _menu_item of _event.menu_items){
                    console.log("Event menu items : ");
                    if (_menu_item.id === Account.id){

                        //found an entry for this attendee
                        for (const _cat of _menu_item["categories"]){
                            _attendee_categories.push(_cat);


                            if (_event.id === Planning.id){
                                //this is a category they selected for this Event
                                _previously_selected_categories.push(_cat);
                            }
                        }

                        _entries_exist = true;
                    }

                }
            }

            console.log("Adding attendee categories : "+_attendee_categories);

            setEventCategories([ ...new Set([ ..._event_categories , ..._attendee_categories]) ].sort());
            setSelectedEventCategories(_previously_selected_categories);
        }
    }, [Events]);

    const initialCategories = [...new Set([..._event_categories, ..._attendee_categories])].sort();
    const [ enableUpdateButton , setEnableUpdateButton ] = useState(false);
    const [ needToUpdate , setNeedToUpdate ] = useState(false);
    const [ eventCategories, setEventCategories ] = useState(initialCategories);
    const [ selectedEventCategories , setSelectedEventCategories ] = useState([]);
    const [ newCategory, setNewCategory ] = useState("");
    function handleEventCategoryNewCategory(e){
        setNewCategory(e.target.value);
    }
    function handleEventCategoryAddNewCategory(){

        //add the category to the event categories list
        setEventCategories([...eventCategories, newCategory]);

        //add the category to the selected list
        setSelectedEventCategories([...selectedEventCategories, newCategory]);

        //clear the input box
        setNewCategory('');

    }
    function handleEventCategoryToggleSelectedCategory(_category){
        //either add or remove the category from the selectedEventCategories list
        console.log("toggle "+_category);

        setSelectedEventCategories(prevCategories => {
            if (prevCategories.includes(_category)) {
                // Category exists, so remove it
                return prevCategories.filter(cat => cat !== _category);
            } else {
                // Category doesn't exist, so add it
                return [...prevCategories, _category];
            }
        });

        setNeedToUpdate(true);

    }

    useEffect(() => {
        showCategoryUpdateButton();
    }, [selectedEventCategories, needToUpdate]);
    function showCategoryUpdateButton(){
        //to be eligible to update
        // 1. must be logged in
        // 2. must be added to the event
        // 3. categories must have changed

        console.log("Logged in ? : "+(Account.id !== undefined));
        console.log("in event ? : "+(Planning.attendees && Planning.attendees.some((attendee) => attendee.id === Account.id)));
        console.log("Need to update ? : "+needToUpdate);

        let _loggedIn = (Account.id !== undefined);
        let _inEvent = (Planning.attendees && Planning.attendees.some((attendee) => attendee.id === Account.id));

        console.log("(_loggedIn&&_inEvent&&needToUpdate) ? : "+(_loggedIn&&_inEvent&&needToUpdate));

        setEnableUpdateButton((_loggedIn&&_inEvent&&needToUpdate));

    }
    function handleEventCategoriesUpdate(){
        //this user needs to be logged in

        console.log("Account.id : "+Account.id);
        console.log("Planning.attendees : "+JSON.stringify(Planning.attendees, null, '\t'));
        console.log("Planning.attendees.includes(Account.id) : "+Planning.attendees.some((attendee) => attendee.id === Account.id));

        if (Account.id !== undefined && Planning.attendees && Planning.attendees.some((attendee) => attendee.id === Account.id)){
            console.log("Ok to add categories!!");

            const _attendee_categories = {};
            _attendee_categories.id = Account.id;
            _attendee_categories.categories = [...selectedEventCategories];
            Planning.menu_items.push(_attendee_categories);

            //show that it was successful
            setShowSuccessSnackbar(true);

            //turn off the update button
            setNeedToUpdate(false);

        } else {
            //show the error message

        }

    }



    const [ status, setStatus] = useState(Planning.status || 0);
    function handleEventStatusUpdate(e){
        setStatus(e.target.value);
        Planning.status = e.target.value;
        //show that it was successful
        setShowSuccessSnackbar(true);
    }



    return (

        <div className="details-list-container" >
            {/* show the header */}
            <div
                className="details-list-header"
            >
                <div className="details-list-header-text">
                    <h5>Event Details</h5>
                </div>

                <div className="details-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptDetailsClose}
                    />
                </div>
            </div>

            <div className="details-list-items-container">
                <Grid container spacing={2} direction="column">

                    <Grid item sx={{display:'flex'}}>
                        <Card
                            className="details-list-item"
                        >

                            <CardActions>
                                <div className="details-list-item-footer-container">

                                    {/* add the Event Name */}
                                    <div
                                        style={{backgroundColor: detailsTabSelected === "Name" ? '#EDEDED' : 'initial'}}
                                        className="details-list-item-footer-name-container pointer"
                                        onClick={()=>handleFooterItemClick('Name')}
                                    >
                                        <i className="icon-handwriting" title="Name"/>
                                    </div>

                                    {/* add the Event Cover Image */}
                                    <div
                                        style={{backgroundColor: detailsTabSelected === "Cover" ? '#EDEDED' : 'initial'}}
                                        className="details-list-item-footer-cover-container pointer"
                                        onClick={()=>handleFooterItemClick('Cover')}
                                    >
                                        <i className="icon-portrait" title="Cover Image"/>
                                    </div>

                                    {/* add the Event Intro / description */}
                                    <div
                                        style={{backgroundColor: detailsTabSelected === "Intro" ? '#EDEDED' : 'initial'}}
                                        className="details-list-item-footer-cover-container pointer"
                                        onClick={()=>handleFooterItemClick('Intro')}
                                    >
                                        <i className="icon-file-user" title="Introduction/description"/>
                                    </div>

                                    {/* add the Event Categories */}
                                    <div
                                        style={{backgroundColor: detailsTabSelected === "Categories" ? '#EDEDED' : 'initial'}}
                                        className="details-list-item-footer-categories-container pointer"
                                        onClick={()=>handleFooterItemClick('Categories')}
                                    >
                                        <Badge
                                            badgeContent={selectedEventCategories.length}
                                            color="badge"
                                        >
                                            <i className="icon-heart-shiny" title="Categories"/>
                                        </Badge>
                                    </div>

                                    {/* add the Event Status */}
                                    <div
                                        style={{backgroundColor: detailsTabSelected === "Status" ? '#EDEDED' : 'initial'}}
                                        className="details-list-item-footer-status-container pointer"
                                        onClick={()=>handleFooterItemClick('Status')}
                                    >
                                        <i className="icon-sync-gear" title="Status"/>
                                    </div>
                                </div>
                            </CardActions>

                            <CardContent>
                                {
                                    detailsTabSelected === "Name" ? (
                                        <Grid spacing={2} direction="column" sx={{marginTop:'0'}}>

                                            <Grid item style={{ flex: '1' }}>
                                                <TextField
                                                    fullWidth
                                                    id="event_name"
                                                    label="Event Name"
                                                    value={eventName}
                                                    onChange={handleEventNameChange}
                                                />

                                                {/* add the update button */}
                                                <div className="details-list-item-update-name-chip-holder" >
                                                    <Chip
                                                        sx={updateChipStyle}
                                                        className="details-list-item-update-name-chip"
                                                        label="Update"
                                                        size="small"
                                                        onClick={handleEventNameUpdate}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    detailsTabSelected === "Cover" ? (

                                        <Grid spacing={2} direction="column" sx={{marginTop:'0'}}>
                                            <Grid item style={{ flex: '1' }}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p style={{cursor:'pointer'}}>Click to select image</p>
                                                </div>
                                                <div>
                                                    <img
                                                        src={coverImageData}
                                                        style={{ width: '256px' }}
                                                        alt=""
                                                    />
                                                </div>

                                                {/* add the save button */}
                                                {
                                                    needToSaveImage ? (
                                                        <div className="details-list-item-update-name-chip-holder" >
                                                            <Chip
                                                                sx={updateGradientStyle}
                                                                className="details-list-item-update-name-chip"
                                                                label="Save"
                                                                size="small"
                                                                onClick={handleEventCoverImageSave}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>

                                    ) : (
                                        <></>
                                    )

                                }

                                {
                                    detailsTabSelected === "Intro" ? (
                                        <Grid spacing={2} direction="column" sx={{marginTop:'0'}}>
                                            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                                                <TextField
                                                    label="Enter text or HTML"
                                                    multiline
                                                    rows={10}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={userInput}
                                                    onChange={handleInputChange}
                                                />
                                                {/* add the update button */}
                                                <div className="details-list-item-update-intro-chip-holder" >
                                                    <Chip
                                                        sx={updateChipStyle}
                                                        className="details-list-item-update-intro-chip"
                                                        label="Update"
                                                        size="small"
                                                        onClick={renderContent}
                                                    />
                                                </div>

                                            </Box>
                                            <Box sx={{ mt: 2, border: '1px solid #ccc', padding: 2 }}>
                                                <Typography variant="h6">Rendered Content:</Typography>
                                                <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                                            </Box>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )
                                }

                                {
                                    detailsTabSelected === "Categories" ? (

                                        <Grid spacing={2} direction="column" sx={{marginTop:'0'}}>
                                            <Grid item style={{ flex: '1' }}>

                                                {
                                                    eventCategories.map((_category, _index) => (
                                                        <Chip
                                                            key={_index}
                                                            label={_category}
                                                            style={{ cursor: 'pointer', backgroundColor: selectedEventCategories.includes(_category) ? 'rgba(198, 226, 255, 0.8)' : 'rgba(0, 0, 0, 0.04)' }}
                                                            onClick={() => handleEventCategoryToggleSelectedCategory(_category)}
                                                            clickable
                                                        />
                                                    ))
                                                }

                                                <Card
                                                    className="details-list-item"
                                                >
                                                    <CardContent>
                                                    <TextField
                                                        fullWidth
                                                        id="new_category_name"
                                                        label="New Category"
                                                        value={newCategory}
                                                        onChange={handleEventCategoryNewCategory}
                                                    />

                                                    {/* add button */}
                                                    <div className="details-list-item-update-name-chip-holder" >
                                                        <Chip
                                                            sx={updateChipStyle}
                                                            label="Add"
                                                            size="small"
                                                            onClick={handleEventCategoryAddNewCategory}
                                                        />
                                                    </div>
                                                    </CardContent>
                                                </Card>


                                                <Grid item style={{ flex: '1' }}>
                                                    {/* update button */}
                                                    <div className="details-list-item-update-name-chip-holder" >
                                                        <Chip
                                                            sx={updateChipStyle}
                                                            label="Update"
                                                            size="small"
                                                            onClick={handleEventCategoriesUpdate}
                                                            clickable
                                                            disabled={!enableUpdateButton}
                                                        />
                                                        {
                                                            !enableUpdateButton ? (
                                                                <div style={{padding: '20px'}} >
                                                                    {/* need to add some attendees first */}
                                                                    <i className="icon-warning" style={{fontSize:'11px', color:'red', marginRight:'10px'}}/>
                                                                    <Typography variant="caption">Before assigning categories : </Typography>
                                                                    <div><Typography variant="caption">1. You must login/create an account</Typography></div>
                                                                    <div><Typography variant="caption">2. You must be added as an Attendee</Typography></div>
                                                                    <div><Typography variant="caption">3. Select or Create categories</Typography></div>
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                    ) : (
                                        <></>
                                    )

                                }

                                {
                                    detailsTabSelected === "Status" ? (
                                        <Grid spacing={2} direction="column" sx={{marginTop:'0'}}>
                                            <Grid item style={{ flex: '1' }}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={status}
                                                        label="Status"
                                                        onChange={handleEventStatusUpdate}
                                                    >
                                                        <MenuItem value={10}>Planning</MenuItem>
                                                        <MenuItem value={0}>Open</MenuItem>
                                                        <MenuItem value={2}>Rescheduled</MenuItem>
                                                        <MenuItem value={1}>Closed</MenuItem>
                                                        <MenuItem value={3}>Cancelled</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <></>
                                    )

                                }
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </div>
        </div>
    )
}

export default DetailsView;
