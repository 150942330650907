import React, {useContext, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {PAContext} from "../../../Services/PAContext";

import {Chip, Stack} from "@mui/material";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import dayjs from "dayjs";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";



function ItineraryView(props) {

    /*
        shows all that's going on for each day of the Event
        so cycle through each day
     */

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Planning } = dataArray;

    const [selectedTimetable, setSelectedTimetable] = useState("dates");
    const handlePromptTimetableClick = (timetable) => {
        setSelectedTimetable(timetable);
    }

    const [selectedSection, setSelectedSection] = useState("Intro");
    const handleSectionClick = (sectionName, type) => {
        setSelectedSection(sectionName);
        props.setItineraryViewSection(sectionName);
        props.setItineraryViewType(type);
        props.setShowEventItemConfig(true);
        props.setShowEventItemExtendedConfig(true);
        navigate('/Planner/Itinerary/Section');
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    return (

        <div className="itinerary-list-container">
            {/* show the header */}
            <div
                className="itinerary-list-header"
            >
                <div className="itinerary-list-header-text">
                    <h5>Itinerary</h5>
                </div>
                <div className="itinerary-section-header-chip-holder">
                    <Badge
                        badgeContent={
                            Planning.time_table?.dates?.length
                        }
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'white', // Customize the dot color here
                                border: '1px solid #ddd', // Make it a circle
                                width: '16px', // Adjust the dot size as needed
                                height: '16px',
                                borderRadius: '50%', // Make it a circle
                            },
                        }}
                    >
                        <Chip
                            sx={selectedTimetable === "dates" ? selectedChipStyle : unselectedChipStyle}
                            label="Dates"
                            size="small"
                            onClick={() => handlePromptTimetableClick('dates')}
                        />
                    </Badge>
                    <Badge
                        badgeContent={
                            Planning.time_table?.days?.length
                        }
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'white', // Customize the dot color here
                                border: '1px solid #ddd', // Make it a circle
                                width: '16px', // Adjust the dot size as needed
                                height: '16px',
                                borderRadius: '50%', // Make it a circle
                            },
                        }}
                    >
                        <Chip
                            sx={{
                                ...(selectedTimetable === "days" ? selectedChipStyle : unselectedChipStyle),
                                marginLeft: '10px'
                            }}
                            label="Days"
                            size="small"
                            onClick={() => handlePromptTimetableClick('days')}
                        />
                    </Badge>

                </div>
            </div>

            <div className="itinerary-list-items-container">
                <Box sx={{display: 'flex', flexDirection: 'row', overflowX: 'auto', padding: '0.5rem'}}>
                    {/* Show the Intro button */}
                    <Chip
                        sx={selectedSection === "Intro" ? selectedChipStyle : unselectedChipStyle}
                        label="Intro"
                        clickable
                        onClick={() => handleSectionClick("Intro")}
                        style={{margin: '0.5rem', height: '50px'}}
                    />

                    {/* Show a button for each day/date of the Event */}
                    {
                        selectedTimetable === "dates" ? (
                            Planning.time_table?.dates?.length > 0 ? (
                                Planning.time_table.dates
                                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                                    .map((date, index) => (
                                        <Chip
                                            key={index}
                                            sx={selectedSection === date.date ? selectedChipStyle : unselectedChipStyle}
                                            label={
                                                <div style={{textAlign: 'center'}}>
                                                    <Typography
                                                        variant="body2">{dayjs(date.date).format('ddd')}</Typography> {/* Day abbreviation */}
                                                    <Typography
                                                        variant="caption">{dayjs(date.date).format('M/D')}</Typography> {/* Date */}
                                                </div>
                                            }
                                            style={{
                                                margin: '0.5rem',
                                                height: '50px',
                                                width: '70px'
                                            }} // Adjust Chip size as needed
                                            onClick={() => handleSectionClick(`${date.date}`, 'date')}
                                        />
                                    ))
                            ) : (
                                <></>
                            )
                        ) : (
                            Planning.time_table?.days?.length > 0 ? (
                                Planning.time_table.days.map((day, index) => {
                                    const dayName = Object.values(day)[0]; // e.g., 'Wednesday'
                                    const dayAbbreviation = dayName.slice(0, 3); // Shorten to 'Wed'

                                    return (
                                        <Chip
                                            key={index}
                                            sx={selectedSection === Object.keys(day)[0] ? selectedChipStyle : unselectedChipStyle}
                                            label={
                                                <div style={{textAlign: 'center'}}>
                                                    <Typography
                                                        variant="body2">{dayAbbreviation}</Typography> {/* Shortened day name */}
                                                    <Typography variant="caption">
                                                        {capitalizeFirstLetter(Object.keys(day)[0])} {/* Capitalize 'day' */}
                                                    </Typography> {/* Display day number */}
                                                </div>
                                            }
                                            style={{
                                                margin: '0.5rem',
                                                height: '50px',
                                                width: '70px'
                                            }} // Adjust Chip size as needed
                                            onClick={() => handleSectionClick(`${Object.keys(day)[0]}`, 'days')}
                                        />
                                    );
                                })
                            ) : (
                                <></>
                            )
                        )
                    }
                </Box>
            </div>






        </div>

    )
}

export default ItineraryView;
