import TimeTable from "./TimeTable";


class PAEvent {
    constructor(){

        this.id = "";
        this.title = "";
        this.description = "";
        this.eventIntro = "";

        this.coverImage = {};
        this.image = "";
        this.cover_image_thumbnail = "";
        this.host = [];
        this.hostNotes = "";

        this.time_table = new TimeTable();

        this.locations = [];

        this.attendees = [];
        this.activities = [];
        this.lodging = [];
        this.meals = [];
        this.ideas = [];
        this.flights = [];
        this.car_rentals = [];
        this.cruises = [];
        this.tours = [];
        this.supplies = [];
        this.to_do = [];
        this.documents = [];
        this.receipts = [];
        this.messages = [];
        this.latest_message_timestamp = 0;
        this.photos = [];

        this.menu_items = [];

        this.weather = [];      //this is an array of weather data for locations; lodging and attendee home address (city, state)

        this.suggestions = [
            {
                'outdoor':[]
            },
            {
                'indoor':[]
            },
            {
                'walking':[]
            },
            {
                'unique':[]
            },
            {
                'hidden':[]
            }

        ];

        this.budget_total = 0;
        this.budget_currency = "USD";
        this.budget_actual = 0;

        this.version = 2.0;     //version 1.0 is the first structure, 2.0 is this version
                                //so the version is what the Event was at conception

        this.need_to_save = false;
        this.last_mod = 0;
        this.status = 0;        //10 = planning

    }
}

export default PAEvent;
