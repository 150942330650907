import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import {Tab, TextField} from "@mui/material";
import Grid from '@mui/material/Grid2';
import TabPanel from "@mui/lab/TabPanel";





function MealLocationTab(props){

    const { dataArray } = useContext(PAContext);
    const { Planning, Attendees } = dataArray;

    const [eventMeal, setEventMeal] = useState(props.eventMeal);
    useEffect(() => {
        setEventMeal(props.eventMeal);
    }, [props.eventMeal]);
    useEffect(() => {
        if (eventMeal !== props.eventMeal){
            //props.updateMealLocationDetails(eventMeal);
        }
    }, [eventMeal]);


    const [selectedTab, setSelectedTab] = useState('');
    const handleTabChange  = (event, newValue) => {
        console.log("handleTabChange ");
        setSelectedTab(newValue);
    };
    const [imaCookingDetails, setImaCookingDetails] = useState(eventMeal['eat_in_details']);
    function handleImaCookingDetails(e){
        setImaCookingDetails(e.target.value);
    }




    return (

        <Grid item style={{flex: '1'}}>

            <TabContext sx={{ position:'relative'}} value={selectedTab}>
                <TabList
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        backgroundColor: 'rgba(251, 251, 251, 0.8)',
                        backdropFilter: 'blur(6px)',
                    }}
                    centered
                    variant="fullWidth"
                    onChange={handleTabChange}
                    aria-label="Meal Location"
                >
                    <Tab
                        label="Find"
                        value="find"
                    />
                    <Tab
                        label="I'm Cooking"
                        value="ima_cooking"
                    />
                </TabList>

                <TabPanel value="find" sx={{ padding: '24px 0' }}>

                </TabPanel>

                <TabPanel value="ima_cooking" sx={{ padding: '24px 0' }}>
                    <TextField
                        fullWidth
                        id="ima_cooking_details"
                        label="Meal Details"
                        multiline
                        rows={4}
                        value={imaCookingDetails}
                        onChange={handleImaCookingDetails}
                    />
                </TabPanel>
            </TabContext>
        </Grid>

    );

}

export default MealLocationTab;