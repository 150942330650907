import React, {useCallback, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";

import './photos.css';


import {Button, ButtonGroup, Card, CardActions, CardContent, CardMedia, Chip, Grid} from "@mui/material";
import {addChipStyle} from "../../ButtonStyle/Add";
import {useNavigate} from "react-router-dom";
import {useDropzone} from 'react-dropzone'
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";



function PhotosAddNew(){

    const navigate = useNavigate();

    const [imagesToAdd, setImagesToAdd] = useState([]);
    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const imageData = {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    public: true, // Set to true for example, modify as needed
                    sharable: false, // Set to false for example, modify as needed
                    data: reader.result,
                };

                console.log("imageData : "+JSON.stringify(imageData, null, '\t'));

                setImagesToAdd(prevImages => [...prevImages, imageData]);
            }
            reader.readAsDataURL(file)
        })
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        maxFiles: 5,
        accept: 'image/jpeg, image/gif'
    });

    // Function to toggle the public property of an image data object
    function togglePublic(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[index].public = !updatedImages[index].public;
            return updatedImages;
        });
    }

    // Function to toggle the sharable property of an image data object
    function toggleSharable(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages[index].sharable = !updatedImages[index].sharable;
            return updatedImages;
        });
    }

    function removeImage(index) {
        setImagesToAdd(prevImages => {
            const updatedImages = [...prevImages];
            updatedImages.splice(index, 1);
            return updatedImages;
        });
    }

    function handlePromptAddNewClose() {
        navigate('/Planner/Photos');
    }

    function handlePhotosAddOK() {
        console.log("handlePhotosAddOK");

        //add the photos to the server



        navigate('/Planner/Photos');
    }

    return (

        <div className="photos-list-container" >
            {/* show the header */}
            <div
                className="photos-list-header"
            >
                <div className="photos-list-header-text">
                    <h5>New Photos</h5>
                </div>
                <div className="photos-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Cancel"
                        size="small"
                        onClick={handlePromptAddNewClose}
                    />
                    {imagesToAdd.length === 0 ? (
                        <></>
                    ) : (
                        <IconButton onClick={handlePhotosAddOK}>
                            <Chip
                                sx={addChipStyle}
                                label={`Add ${imagesToAdd.length}`}
                                size="small"
                            />
                        </IconButton>
                    )}
                </div>
            </div>


            {/* the items for adding photos */}
            <div className="photos-list-items-container">

                <Grid container spacing={2} direction="column">

                    <div {...getRootProps()}>
                        <input
                            {...getInputProps({
                                accept: 'image/jpeg, image/gif', // Specify accepted file types
                            })}
                        />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag 'n' drop some files here, or click to select files</p>
                        }
                    </div>

                </Grid>

                <Grid container justify="center" spacing={2}>
                    {

                        imagesToAdd && imagesToAdd.map((imageData, index) => (

                            <Grid item key={index}>
                                <Card sx={{ maxWidth: 345 }}>
                                    <CardMedia
                                        component="img"
                                        src={imageData.data}
                                        alt={`Image ${index + 1}`}
                                    />
                                    <CardContent>
                                        {/* add the public/private area */}
                                        <Typography variant="subtitle2" color="text.secondary">
                                            PUBLIC / PRIVATE
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ textIndent: '1em' }}>
                                            PUBLIC means that ALL Event attendees will be able to see this image.
                                            PRIVATE means that you are the only Event attendee that will be able to see this image.
                                        </Typography>

                                        <span style={{ display: 'flex', justifyContent: 'flex-end', marginTop:'10px', marginBottom:'10px'}}>
                                            <ButtonGroup variant="text" aria-label="outlined button group">
                                                <Button
                                                    style={{
                                                        fontSize: '11px',
                                                        backgroundColor: imagesToAdd.find(
                                                            (imageItem) => imageItem.name === imageData.name
                                                        ).public
                                                            ? 'rgba(198, 226, 255, 0.8)'
                                                            : 'initial'
                                                    }}
                                                    onClick={()=>togglePublic(index)}
                                                >
                                                    Public
                                                </Button>
                                                <Button
                                                    style={{
                                                        fontSize: '11px',
                                                        backgroundColor: imagesToAdd.find(
                                                            (imageItem) => imageItem.name === imageData.name
                                                        ).public
                                                            ? 'initial'
                                                            : 'rgba(198, 226, 255, 0.8)'
                                                    }}
                                                    onClick={()=>togglePublic(index)}
                                                >
                                                    Private
                                                </Button>
                                            </ButtonGroup>
                                        </span>

                                        {/* add the publicly sharable area */}
                                        <Typography variant="subtitle2" color="text.secondary">
                                            SHARABLE
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ textIndent: '1em' }}>
                                            SHARABLE means that a link to this image will be made available for you to share
                                            with individuals you choose. This link will expire 30 days after the Event ends.
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary" sx={{ textIndent: '1em' }}>
                                            IMPORTANT! This is different from choosing Public / Private above. If you've marked
                                            this as Private above you can still share this image with the link.
                                        </Typography>

                                        <span style={{ display: 'flex', justifyContent: 'flex-end', marginTop:'10px', marginBottom:'10px' }}>
                                            <ButtonGroup variant="text" aria-label="outlined button group">
                                                <Button
                                                    style={{
                                                        fontSize: '11px',
                                                        backgroundColor: imagesToAdd.find(
                                                            (imageItem) => imageItem.name === imageData.name
                                                        ).sharable
                                                            ? 'rgba(198, 226, 255, 0.8)'
                                                            : 'initial'
                                                    }}
                                                    onClick={()=>toggleSharable(index)}
                                                >
                                                    Sharable
                                                </Button>
                                                <Button
                                                    style={{
                                                        fontSize: '11px',
                                                        backgroundColor: imagesToAdd.find(
                                                            (imageItem) => imageItem.name === imageData.name
                                                        ).sharable
                                                            ? 'initial'
                                                            : 'rgba(198, 226, 255, 0.8)'
                                                    }}
                                                    onClick={()=>toggleSharable(index)}
                                                >
                                                    Not Sharable
                                                </Button>
                                            </ButtonGroup>
                                        </span>



                                    </CardContent>
                                    <CardActions
                                        style={{justifyContent:'flex-end'}}
                                    >
                                        {/* add the delete icon */}

                                        <IconButton
                                            title="Remove"
                                            sx={{
                                                color: '#f00',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => removeImage(index)}
                                        >
                                            {
                                                <i className="icon-trash" />
                                            }
                                        </IconButton>

                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </div>

    )

}

export default PhotosAddNew;
