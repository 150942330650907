import React, {useContext, useEffect, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import dayjs from "dayjs";
import WeatherUsingVisualCrossing from "../../../Services/WeatherUsingVisualCrossing";
import {Chip} from "@mui/material";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import Typography from "@mui/material/Typography";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import './weather.css';

function WeatherDetails(props) {

    const {dataArray} = useContext(PAContext);
    const {Planning} = dataArray;

    const [weatherData, setWeatherData] = useState(Planning.weather);
    const [pullWeatherData, setPullWeatherData] = useState(false);
    const [weatherDataUnit, setWeatherDataUnit] = useState('dates');

    useEffect(() => {
        console.log("the new weather data : " + JSON.stringify(Planning.weather, null, '\t'));

        if (Planning.weather) {
            setWeatherData(Planning.weather);
        }
    }, [Planning.weather])

    useEffect(() => {

        try {
            const hasDates = Planning?.time_table?.dates?.length > 0;
            const begin_date = dayjs(Planning.time_table.dates[0].date); // First date in the time table
            const hasLocation = Planning?.lodging?.some(lodge => lodge.location && lodge.location.latitude && lodge.location.longitude);
            const daysUntilBeginDate = begin_date.diff(dayjs(), 'day');

            // Proceed only if both conditions are met
            if (hasDates && hasLocation && daysUntilBeginDate <= 14) {
                //get the weather data
                WeatherUsingVisualCrossing(Planning)
            }
        } catch (e){}

    }, [weatherData]);

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }


    return (

        <div className="weather-list-container">

            {/* show the header */}
            <div
                className="weather-list-header"
            >
                <div className="weather-list-header-text">
                    <h5>Daily Weather</h5>
                </div>
                <div className="weather-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                </div>
            </div>

            {/* show the list of dates/days for Event */}

            <div className="weather-list-timetable-list-container">
                {
                    weatherDataUnit === 'dates' ? (
                        Planning['time_table'] && Planning['time_table'].dates.map((eventDateObj, index) => {
                            const eventDate = eventDateObj.date;
                            const formattedDay = dayjs(eventDate, 'YYYY-MM-DD').format('ddd'); // e.g., "Wed"
                            const formattedDate = dayjs(eventDate, 'YYYY-MM-DD').format('M/D'); // e.g., "10/13"

                            return (
                                <Chip
                                    key={index}
                                    label={
                                        <div style={{textAlign: 'center'}}>
                                            <Typography variant="body2">{formattedDay}</Typography>
                                            <Typography variant="caption">{formattedDate}</Typography>
                                        </div>
                                    }
                                    sx={unselectedChipStyle} // Style for the Chips
                                    style={{margin: '0.2rem', height: '40px'}}
                                />
                            );
                        })
                    ) : (
                        <div>No weather data available for days</div> // Add a fallback for 'days' or another unit.
                    )
                }
            </div>


            {

                weatherData ? (

                    <div>
                        {/* show the weather for the relevant areas for the selected day */}
                        {
                            weatherData.map((location, index) => (

                            <div key={index}>
                                {/* Display the day's weather data here */}
                                <p>Location: {location.city} {location.state}</p>

                                {
                                    location['report'] ? (
                                        location['report'].days.map((day, day_index) => (
                                            <div key={day_index}>
                                                <p>Date: {dayjs(day.datetime, 'YYYY-MM-DD').format('dddd MMM D')}</p>
                                                <p>Icon: {day.icon}</p>
                                                <p>Conditions: {day.conditions}</p>
                                                <p>Description: {day.description}</p>
                                            </div>

                                        ))
                                    ) : (
                                        <></>
                                    )

                                }

                            </div>
                        ))}
                    </div>
                ) : (
                    <div>No Weather Data</div>
                )
            }
        </div>
    )
}

export default WeatherDetails;
