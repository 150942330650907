import React, {useContext, useEffect, useRef, useState} from 'react';
import {PAContext} from "../../../Services/PAContext";
import {Checkbox, ListItem, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";


// Helper function to calculate layover time between two segments
const calculateLayoverTime = (arrivalTime, nextDepartureTime) => {
    const diffMs = new Date(nextDepartureTime) - new Date(arrivalTime); // Difference in milliseconds
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // Convert to hours
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
    return `${diffHours > 0 ? `${diffHours} hr${diffHours > 1 ? 's' : ''}` : ''} ${diffMinutes > 0 ? `${diffMinutes} min${diffMinutes > 1 ? 's' : ''}` : ''}`.trim();
};

const formatDuration = (isoDuration) => {
    const match = isoDuration.match(/P(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?/);
    const days = parseInt(match[1] || '0', 10);
    const hours = parseInt(match[2] || '0', 10);
    const minutes = parseInt(match[3] || '0', 10);

    const dayText = days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '';
    const hourText = hours > 0 ? `${hours} hr${hours > 1 ? 's' : ''}` : '';
    const minuteText = minutes > 0 ? `${minutes} min${minutes > 1 ? 's' : ''}` : '';

    return [dayText, hourText, minuteText].filter(Boolean).join(' ');
};

const blueShades = [
    '#1E90FF', // DodgerBlue
    '#00BFFF', // DeepSkyBlue
    '#4682B4', // SteelBlue
    '#5F9EA0', // CadetBlue
    '#87CEEB', // SkyBlue
    '#4169E1', // RoyalBlue
    '#6495ED', // CornflowerBlue
    '#6A5ACD', // SlateBlue
    '#00CED1', // DarkTurquoise
    '#7B68EE', // MediumSlateBlue
];


function FlightGoogleMap({
                             _global_map_ref,
                             selectedFlights,
                             onSelectFlight,
                             departureAirport,
                             destinationAirport }){

    const { dataArray } = useContext(PAContext);
    const { Planning, FlightPlanningPipeline } = dataArray;

    const airportMarkersRef = useRef([]);
    const flightMarkersRef = useRef([]);

    const [mapMarkerLayers, setMapMarkerLayers] = useState([]); // Store layers of flights
    const [anchorLayersEl, setAnchorLayersEl] = useState(null); // Menu anchor



    // Clear airport markers
    const clearAirportMarkers = () => {
        console.log('clearAirportMarkers');
        airportMarkersRef.current.forEach(marker => marker.setMap(null));
        airportMarkersRef.current = [];
    };

    // Clear flight markers and polylines
    const clearFlightMarkersAndPolylines = () => {
        console.log('clearFlightMarkersAndPolylines');
        flightMarkersRef.current.forEach(marker => marker.setMap(null));
        flightMarkersRef.current = [];
    };

    const addAirportMarker = (map, position, airportCode) => {
        console.log('addAirportMarker');

        const markerContentDiv = document.createElement('div');
        markerContentDiv.style.backgroundColor = 'yellow';
        markerContentDiv.style.width = '12px';
        markerContentDiv.style.height = '12px';
        markerContentDiv.style.borderRadius = '50%';
        markerContentDiv.style.border = '2px solid black';
        markerContentDiv.style.boxShadow = '0 0 5px rgba(0,0,0,0.5)';

        const marker = new window.google.maps.marker.AdvancedMarkerElement({
            position: position,
            map: map,
            content: markerContentDiv,
            title: airportCode, // Display the airport code on hover
        });

        airportMarkersRef.current.push(marker); // Store the airport marker
    };

    // Open/Close layers menu
    const handleButtonOpenLayersMenuClick = (event) => setAnchorLayersEl(event.currentTarget);
    const handleButtonLayersMenuClose = () => setAnchorLayersEl(null);


    // Toggle visibility of an entire flight layer
    const toggleLayerVisibility = (flightId) => {
        setMapMarkerLayers((prevLayers) =>
            prevLayers.map((layer) => {
                if (layer.id === flightId) {
                    layer.visible = !layer.visible;

                    // Toggle marker visibility
                    layer.markers.forEach((marker) => {
                        if (layer.visible) {
                            marker.map = _global_map_ref.current;  // Re-add marker to the map
                        } else {
                            marker.map = null;  // Remove marker from the map
                        }
                    });

                    // Toggle polyline visibility
                    layer.polylines.forEach((polyline) => {
                        if (layer.visible) {
                            polyline.setMap(_global_map_ref.current);  // Re-add polyline to the map
                        } else {
                            polyline.setMap(null);  // Remove polyline from the map
                        }
                    });
                }
                return layer;
            })
        );
    };

    // Delete flight layer
    const handleMarkerLayerDeleteClick = (flightId) => {
        console.log('handleMarkerLayerDeleteClick : '+flightId);

        setMapMarkerLayers((prevLayers) => {
            const layerToDelete = prevLayers.find((layer) => layer.id === flightId);
            if (layerToDelete) {
                // Remove markers and polylines from the map
                layerToDelete.markers.forEach((marker) => marker.setMap(null));
                layerToDelete.polylines.forEach((polyline) => polyline.setMap(null));

                // Notify FlightSelection to uncheck the flight
                onSelectFlight(flightId);
            }
            return prevLayers.filter((layer) => layer.id !== flightId); // Remove layer
        });
    };

    // Add flight layers to the map
    const addFlightToMap = (flight, index) => {
        console.log('addFlightToMap : '+flight.id);

        const bounds = new window.google.maps.LatLngBounds();
        const markers = [];
        const polylines = [];

        // Retrieve airline name and flight number from the first segment
        const airlineName = flight.slices[0]?.segments[0]?.marketing_carrier?.name;
        const flightNumber = flight.slices[0]?.segments[0]?.marketing_carrier_flight_number;
        const layerName = `${airlineName} # ${flightNumber}`;

        // Check if the flight is already added as a layer
        setMapMarkerLayers(prevLayers => {
            const layerExists = prevLayers.some(layer => layer.id === flight.id);

            if (layerExists) {
                console.log(`Layer ${flight.id} already exists, skipping.`);
                //make sure the layer is showing
                toggleLayerVisibility(flight.id);

                return prevLayers; // If the layer exists, return the previous state without changes
            }

            // Cycle through the blue shades using the index
            const polylineColor = blueShades[index % blueShades.length];

            // If the layer doesn't exist, proceed to add it
            flight.slices.forEach((slice) => {
                slice.segments.forEach((segment, segmentIndex) => {
                    const origin = { lat: segment.origin.latitude, lng: segment.origin.longitude };
                    const destination = { lat: segment.destination.latitude, lng: segment.destination.longitude };

                    // Add departure marker for the first segment (if it's the first flight of the slice)
                    if (segmentIndex === 0) {
                        const departMarkerContentDiv = document.createElement('div');
                        departMarkerContentDiv.innerHTML = `
                            <div class="planning-google-map-marker-layer-item">
                                <span class="planning-google-map-marker-layer-item-name">
                                    Depart: ${new Date(segment.departing_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                                </span>
                            </div>
                            `;
                        const departMarker = new window.google.maps.marker.AdvancedMarkerView({
                            position: origin,
                            map: _global_map_ref.current,
                            content: departMarkerContentDiv,
                            title: `Depart: ${segment.origin.iata_code} - ${new Date(segment.departing_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}`,
                        });

                        markers.push(departMarker);
                        bounds.extend(departMarker.position);
                    }

                    // Add layover marker if there's a next segment
                    if (segmentIndex < slice.segments.length - 1) {
                        const nextSegment = slice.segments[segmentIndex + 1];
                        const layoverTime = calculateLayoverTime(segment.arriving_at, nextSegment.departing_at);

                        const layoverMarkerContentDiv = document.createElement('div');
                        layoverMarkerContentDiv.innerHTML = `
                    <div class="planning-google-map-marker-layer-item">
                        <span class="planning-google-map-marker-layer-item-name">
                           Arrive: ${new Date(segment.arriving_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}<br/>
                           Layover: ${layoverTime}<br/>
                           Depart: ${new Date(nextSegment.departing_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                        </span>
                    </div>
                    `;
                        const layoverMarker = new window.google.maps.marker.AdvancedMarkerView({
                            position: destination,
                            map: _global_map_ref.current,
                            content: layoverMarkerContentDiv,
                            title: `Layover: ${layoverTime}`,
                        });

                        markers.push(layoverMarker);
                        bounds.extend(layoverMarker.position);

                    } else {
                        // If no next segment, add a regular arrival marker
                        const arriveMarkerContentDiv = document.createElement('div');
                        arriveMarkerContentDiv.innerHTML = `
                    <div class="planning-google-map-marker-layer-item">
                        <span class="planning-google-map-marker-layer-item-name">
                           Arrive: ${new Date(segment.arriving_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}
                        </span>
                    </div>
                    `;
                        const arriveMarker = new window.google.maps.marker.AdvancedMarkerView({
                            position: destination,
                            map: _global_map_ref.current,
                            content: arriveMarkerContentDiv,
                            title: `Arrive: ${new Date(segment.arriving_at).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true })}`,
                        });

                        markers.push(arriveMarker);
                        bounds.extend(arriveMarker.position);
                    }

                    // Draw the flight path for this segment
                    const flightPath = new window.google.maps.Polyline({
                        path: [origin, destination],
                        geodesic: true,
                        strokeColor: polylineColor,
                        strokeOpacity: 1.0,
                        strokeWeight: 2,
                        map: _global_map_ref.current,
                    });
                    polylines.push(flightPath);


                    // Calculate the flight duration and format it (assuming `segment.duration` is in ISO 8601 format)
                    const formattedDuration = formatDuration(segment.duration);

                    // Calculate the midpoint of the flight path using google.maps.geometry.spherical.interpolate
                    const midpoint = new window.google.maps.geometry.spherical.interpolate(origin, destination, 0.5);

                    // Create a label or marker at the midpoint
                    const midpointMarkerContentDiv = document.createElement('div');
                    midpointMarkerContentDiv.innerHTML = `
                            <div class="planning-google-map-marker-layer-item">
                                <span class="planning-google-map-marker-layer-item-name">
                                    ${formattedDuration}
                                </span>
                            </div>
                            `;
                    const midpointMarker = new window.google.maps.marker.AdvancedMarkerView({
                        position: midpoint,
                        map: _global_map_ref.current,
                        content: midpointMarkerContentDiv,
                        title: `Flight ${flightNumber}`,
                    });

                    markers.push(midpointMarker);

                    bounds.extend(midpointMarker.position);

                });
            });

            // Adjust the map bounds to include all markers for this flight
            _global_map_ref.current.fitBounds(bounds);

            // Store markers and polylines for this flight
            flightMarkersRef.current.push(...markers);
            flightMarkersRef.current.push(...polylines);

            // Add the new layer if it doesn't already exist
            return [
                ...prevLayers,
                {
                    id: flight.id, // Assign flight id here
                    name: layerName, // Set the layer name as airline name and flight number
                    visible: true,
                    markers: markers,
                    polylines: polylines
                }
            ];
        });
    };

    const _default_map_options = {
        mapId: "6c79697cd5555167",
        center: departureAirport
            ? { lat: parseFloat(departureAirport.lat), lng: parseFloat(departureAirport.lon) }
            : { lat: 37.422222, lng: -122.083333 }, // Default to a location if no airport is selected
        zoom: 10,
        mapTypeControl: false,
        fullscreenControl: false,
    };

    // Store flight-related map objects (markers and polylines) in a map for easy removal
    const flightMapObjects = {};

    useEffect(() => {

        if (!window.google || !window.google.maps) {
            console.error("Google Maps API is not available.");
            return;
        }

        if (Planning && Planning.location){

            _global_map_ref.current = new window.google.maps.Map(
                document.getElementById("global_google_map_holder"),
                {
                    mapId: "6c79697cd5555167",
                    center: {
                        lat: Planning.location.latitude,
                        lng: Planning.location.longitude,
                    },
                    zoom: 10,
                    mapTypeControl: false,
                    fullscreenControl: false
                }
            );
        } else {
            _global_map_ref.current = new window.google.maps.Map(
                document.getElementById("global_google_map_holder"),
                _default_map_options
            );

        }

        if (selectedFlights.length && _global_map_ref && _global_map_ref.current) {

            // Function to clear markers and polylines of a specific flight
            const clearFlightFromMap = (flightId) => {
                if (flightMapObjects[flightId]) {
                    flightMapObjects[flightId].markers.forEach(marker => marker.setMap(null));
                    flightMapObjects[flightId].polylines.forEach(polyline => polyline.setMap(null));
                    delete flightMapObjects[flightId]; // Remove the entry from the map
                }
            };

            // Function to clear all existing flight markers and polylines
            const clearMap = () => {
                Object.keys(flightMapObjects).forEach(flightId => {
                    clearFlightFromMap(flightId);
                });
            };

            // Clear previous markers and polylines
            clearMap();

            // Add the selected flights to the map
            selectedFlights.forEach(addFlightToMap);

            // Clean up on component unmount or when selected flights change
            return () => {
                clearMap();
            };


        }
    }, [_global_map_ref]);

    useEffect(() => {
        if (_global_map_ref.current) {
            const map = _global_map_ref.current;

            clearAirportMarkers(); // Clear existing airport markers

            const bounds = new window.google.maps.LatLngBounds();

            // Add markers for selected airports
            if (departureAirport) {
                const departurePosition = {
                    lat: parseFloat(departureAirport.lat),
                    lng: parseFloat(departureAirport.lon),
                };
                addAirportMarker(map, departurePosition, departureAirport.iata);
                bounds.extend(departurePosition);
            }

            if (destinationAirport) {
                const destinationPosition = {
                    lat: parseFloat(destinationAirport.lat),
                    lng: parseFloat(destinationAirport.lon),
                };
                addAirportMarker(map, destinationPosition, destinationAirport.iata);
                bounds.extend(destinationPosition);
            }

            // Adjust bounds for airports
            if (departureAirport || destinationAirport) {
                map.fitBounds(bounds);
            }
        }
    }, [departureAirport, destinationAirport]);

    // Handle Flight Markers (selectedFlights) useEffect
    useEffect(() => {
        console.error("selectedFlights has changed!!");

        if (_global_map_ref.current) {
            clearFlightMarkersAndPolylines(); // Clear existing flight markers and polylines

            setMapMarkerLayers([]);

            // Add selected flights as markers and polylines
            selectedFlights.forEach((flight, index) => {
                addFlightToMap(flight, index);
            });
        }
    }, [selectedFlights]);


    return (

        <div style={{position:'relative'}}>
            {/* Map container */}
            <div
                ref={_global_map_ref}
                className="planning-map-area-google-map-holder"
                id="global_google_map_holder"
                style={{width: '100%', height: 'calc(100vh - 243px)'}}
            />

            {/* Add layers control */}
            <div style={{position:'absolute', top: '5px', right : '5px'}}>
                <Paper component="form" sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}>
                    <Badge badgeContent={mapMarkerLayers.length} color="primary">
                        <IconButton
                            type="button"
                            sx={{p: '10px'}}
                            aria-label="map layers for Markers"
                            onClick={handleButtonOpenLayersMenuClick}
                        >
                            <i className="icon-layers" title="Selected Flights"/>
                        </IconButton>
                    </Badge>
                    <Menu
                        id="split-button-menu"
                        anchorEl={anchorLayersEl}
                        open={Boolean(anchorLayersEl)}
                        onClose={handleButtonLayersMenuClose}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList dense sx={{ cursor: 'default' }}>
                            {mapMarkerLayers.length > 0 ? (
                                mapMarkerLayers.map((layer, index) => (
                                    <div key={index}>
                                        <ListItem key={index} sx={{ cursor: 'default' }}>
                                            <ListItemText>{layer.name}</ListItemText>
                                            <Checkbox
                                                edge="end"
                                                checked={layer.visible}
                                                inputProps={{ 'aria-labelledby': "Toggle Layer" }}
                                                onClick={() => toggleLayerVisibility(layer.id)}
                                            />
                                            <i
                                                style={{ color: 'red', marginLeft: '10px', cursor: 'pointer' }}
                                                className="icon-trash"
                                                onClick={() => handleMarkerLayerDeleteClick(layer.id)}
                                            />
                                        </ListItem>
                                    </div>
                                ))
                            ) : (
                                <MenuItem>
                                    <ListItemText>No Layers</ListItemText>
                                </MenuItem>
                            )}
                        </MenuList>

                    </Menu>
                </Paper>
            </div>
        </div>
    )
}

export default FlightGoogleMap;