import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import {
    Button,
    Chip,
    Dialog, DialogActions,
    DialogContent, DialogContentText, DialogTitle,
    Menu,
    MenuItem,
    MenuList,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import ListItemText from "@mui/material/ListItemText";
import dayjs from "dayjs";
import MealCard from "../Meals/MealCard";
import Typography from "@mui/material/Typography";
import './meals.css';
import Box from "@mui/material/Box";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {capitalizeFirstLetter} from "../../Helpers/TextStyling";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

function MealsList(props) {

    const navigate = useNavigate();

    const { dataArray, setPlanningMeals } = useContext(PAContext);
    const { Planning } = dataArray;

    const [eventMeals, setEventMeals] = useState([]);
    const [filteredMeals, setFilteredMeals] = useState([]);

    const [viewType, setViewType] = useState('all'); // Either 'dates' or 'days'
    const [eventHasDates, setEventHasDates] = useState(Planning.time_table?.dates?.length > 0);
    const [eventHasDays, setEventHasDays] = useState(Planning.time_table?.days?.length > 0);
    const [selectedDate, setSelectedDate] = useState(null); // For selected date
    const [selectedDay, setSelectedDay] = useState(null); // For selected day

    // Toggle visibility of dates or days chips
    const handleToggleViewType = () => {

        if ((viewType === 'all' || viewType === 'dates') && eventHasDays) {
            console.log("changing Chips to days : "+Object.keys(Planning.time_table.days[0])[0]);
            setViewType('days');
            setSelectedDay(Object.keys(Planning.time_table.days[0])[0]); // Select first day when switching to days
        } else if ((viewType === 'all' || viewType === 'days') && eventHasDates) {
            console.log("changing Chips to dates : "+Planning.time_table.dates[0].date);
            setViewType('dates');
            setSelectedDate(Planning.time_table.dates[0].date); // Select first date when switching to dates
        }
    };

    const [anchorMealsListMenu, setAnchorMealsListMenu] = useState(null);
    const handleMealsListMenuOpenMenu = (event) => {
        setAnchorMealsListMenu(event.currentTarget);
    };
    const handleMealsListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorMealsListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Meals/AddNew');
        }
    };
    const handleMealsListMenuClose = () => {
        setAnchorMealsListMenu(null);
    };
    function handlePromptChangeClose() {
        setAnchorMealsListMenu(null);
        props.setShowEventItemConfig(false);
    }

    useEffect(() => {
        console.log("Planning.meals triggered again: "+JSON.stringify(Planning.meals));

        if (Planning.meals !== eventMeals){
            setEventMeals([...Planning.meals]);
            setEventHasDates(Planning.time_table?.dates?.length > 0);
            setEventHasDays(Planning.time_table?.days?.length > 0);

            if (viewType !== 'all'){
                setViewType('all');
            }
        }

    }, [Planning.meals]);

    useEffect(() => {
        console.log("eventMeals triggered again: "+JSON.stringify(eventMeals));

        //get any photos for eateries that don't have any
        for (const _meal of eventMeals) {
            console.log("Meal : "+_meal["name"]);
            if (Object.keys(_meal.Place).length > 0) {
                const request = {
                    placeId: _meal['Place'].place_id,
                    fields: ['photo']
                };

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.getDetails(request, function (results, status) {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        console.log("The returned place : " + JSON.stringify(results, null, '\t'));

                        const _photos_array = [];
                        for (const _photo of results.photos) {
                            _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                        }
                        _meal.photos = _photos_array;

                        updateMealDetails(_meal);
                    }
                });
            }
            if (_meal["photos"] !== undefined
                && _meal["photos"].length === 0
                && _meal["location"].google_place_id !== "") {
                //get the photos
                console.log("Getting photos for : "+_meal["location"].google_place_id);
                const request = {
                    placeId: _meal["location"].google_place_id,
                    fields: ['photo']
                };

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.getDetails(request, function (results, status) {
                    console.log("response : "+results);
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                        try {
                            const _photos_array = [];
                            for (const _photo of results.photos) {
                                _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                            }
                            _meal.photos = _photos_array;

                            updateMealDetails(_meal);

                        } catch (e){console.log("error : "+e);}
                    }
                });
            }
        }

        if (viewType === 'all') {
            handleMealChipAllClick();
        }
    }, [eventMeals]);

    useEffect(() => {
        console.log("mealsList : "+JSON.stringify(eventMeals, null, '\t'));

        //get any photos for eateries that don't have any
        for (const _meal of eventMeals) {
            console.log("Meal : "+_meal["name"]);
            if (Object.keys(_meal.Place).length > 0) {
                const request = {
                    placeId: _meal['Place'].place_id,
                    fields: ['photo']
                };

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.getDetails(request, function (results, status) {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        console.log("The returned place : " + JSON.stringify(results, null, '\t'));

                        const _photos_array = [];
                        for (const _photo of results.photos) {
                            _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                        }
                        _meal.photos = _photos_array;

                        updateMealDetails(_meal);
                    }
                });
            }
            if (_meal["photos"] !== undefined
                && _meal["photos"].length === 0
                && _meal["location"].google_place_id !== "") {
                //get the photos
                console.log("Getting photos for : "+_meal["location"].google_place_id);
                const request = {
                    placeId: _meal["location"].google_place_id,
                    fields: ['photo']
                };

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.getDetails(request, function (results, status) {
                    console.log("response : "+results);
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                        try {
                            const _photos_array = [];
                            for (const _photo of results.photos) {
                                _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                            }
                            _meal.photos = _photos_array;

                            updateMealDetails(_meal);

                        } catch (e){console.log("error : "+e);}
                    }
                });
            }
        }

    }, []);

    function handleMealChipAllClick(){
        console.log("handleMealChipAllClick");

        if (viewType !== "all"){
            setViewType("all");
        }

        let filtered = [...eventMeals];

        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.dates?.[0]?.begin_time || a.time_table.days?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.dates?.[0]?.begin_time || b.time_table.days?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = a.time_table.dates?.[0]?.date
                || (Array.isArray(a.time_table.days) && a.time_table.days[0]
                    ? dayjs().day(Object.keys(a.time_table.days[0])[0].replace('day ', '')).format('YYYY-MM-DD')
                    : null);
            const dateB = b.time_table.dates?.[0]?.date
                || (Array.isArray(b.time_table.days) && b.time_table.days[0]
                    ? dayjs().day(Object.keys(b.time_table.days[0])[0].replace('day ', '')).format('YYYY-MM-DD')
                    : null);
//            const dateA = a.time_table.dates?.[0]?.date || dayjs().day(Object.keys(a.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');
//            const dateB = b.time_table.dates?.[0]?.date || dayjs().day(Object.keys(b.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });

        setFilteredMeals(filtered);
    }
    function handleMealChipDateClick(_meal_date){
        console.log("handleMealChipDateClick : "+_meal_date);

        if (viewType !== "dates"){
            setViewType("dates");
        }
        setSelectedDate(_meal_date);

        let filtered = [];
        filtered = eventMeals.filter(meal =>
            meal.time_table?.dates?.length > 0 && meal.time_table.dates[0].date === _meal_date
        );
        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.dates?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.dates?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = a.time_table.dates?.[0]?.date;
            const dateB = b.time_table.dates?.[0]?.date;

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });

        console.log("filtered : "+JSON.stringify(filtered, null, '\t'));

        setFilteredMeals(filtered);
    }
    function handleMealChipDayClick(_meal_day){
        console.log("handleMealChipDayClick : "+_meal_day);

        if (viewType !== "days"){
            setViewType("days");
        }
        setSelectedDay(_meal_day);

        let filtered = [];
        filtered = eventMeals.filter(meal =>
            meal.time_table?.days?.length > 0 && Object.keys(meal.time_table.days[0])[0] === _meal_day
        );
        // Sort meals by the begin time (if available)
        filtered = filtered.sort((a, b) => {
            // Extract begin_time for both meals, defaulting to '00:00' if not found
            const timeA = a.time_table.days?.[0]?.begin_time || '00:00';
            const timeB = b.time_table.days?.[0]?.begin_time || '00:00';

            // Extract the date or day (if date is present, use it, otherwise use a placeholder date with the day's index)
            const dateA = dayjs().day(Object.keys(a.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');
            const dateB = dayjs().day(Object.keys(b.time_table.days?.[0])[0].replace('day ', '')).format('YYYY-MM-DD');

            // Combine date and time for comparison
            const dateTimeA = dayjs(`${dateA} ${timeA}`, 'YYYY-MM-DD HH:mm A');
            const dateTimeB = dayjs(`${dateB} ${timeB}`, 'YYYY-MM-DD HH:mm A');

            // Compare the combined date and time
            return dateTimeA.diff(dateTimeB);
        });
        console.log("filtered : "+JSON.stringify(filtered, null, '\t'));

        setFilteredMeals(filtered);
    }

    const updateMealDetails = (updatedMeal) => {
        // Find the index of the updated Meal in eventMeals
        const mealIndex = eventMeals.findIndex((meal) => meal.id === updatedMeal.id);

        console.log("mealIndex to update : "+mealIndex);

        if (mealIndex !== -1) {
            // If the meal is found, update it in the eventMeals array
            const updatedEventMealsArray = [...eventMeals];
            updatedEventMealsArray[mealIndex] = updatedMeal;

            setEventMeals(updatedEventMealsArray);

            //this updates the meal at the PAContext level
            setPlanningMeals(Planning['id'], updatedEventMealsArray);

            console.log("updatedEventMeals");
        }

    };

    const [mealToDelete, setMealToDelete] = useState(null);  // Holds the meal to delete for confirmation dialog
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);  // Controls the confirmation dialog visibility
    const handleDeleteMeal = (mealId) => {
        // Remove the meal with the given ID
        const updatedMeals = eventMeals.filter(meal => meal.id !== mealId);

        // You can call a function to persist this change or update parent state
        setEventMeals(updatedMeals);


        //this updates the meals array at the PAContext level
        setPlanningMeals(Planning['id'], updatedMeals);

        closeDeleteDialog();

    };
    const openDeleteDialog = (meal) => {
        setMealToDelete(meal);  // Set the meal to be deleted
        setDeleteDialogOpen(true);  // Open the confirmation dialog
    };
    const closeDeleteDialog = () => {
        setMealToDelete(null);  // Reset the meal to be deleted
        setDeleteDialogOpen(false);  // Close the confirmation dialog
    };



    return (

        <div className="meals-list-container">

            {/* show the header */}
            <div className="meals-list-header">
                <div className="meals-list-header-text">
                    <h5>Meals List</h5>
                </div>
                <div className="meals-list-header-controls">
                    {/* Toggle for Dates/Days */}
                    {
                        (eventHasDates && eventHasDays) && (
                            <Box sx={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                {/* Icon Button for Dates */}
                                <IconButton
                                    type="button"
                                    sx={{
                                        padding: '3px',
                                        backgroundColor: viewType === 'dates' ? '#FFF' : '',
                                        boxShadow: '' +
                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                        border: '1px solid #0096ff',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        color: 'black', // Text color (adjust as needed)
                                        '&:hover': {
                                            background: '#cbe0f7', // Reverse the gradient on hover
                                        },
                                    }}
                                    aria-label="toggle to dates"
                                    onClick={() => setViewType('dates')}
                                >
                                    <Typography variant="caption">Dates</Typography>
                                </IconButton>

                                {/* Divider between buttons */}
                                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

                                {/* Icon Button for Days */}
                                <IconButton
                                    type="button"
                                    sx={{
                                        padding: '3px',
                                        backgroundColor: viewType === 'days' ? '#FFF' : '',
                                        boxShadow: '' +
                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                        border: '1px solid #0096ff',
                                        borderRadius: '10px',
                                        textAlign: 'center',
                                        color: 'black', // Text color (adjust as needed)
                                        '&:hover': {
                                            background: '#cbe0f7', // Reverse the gradient on hover
                                        },
                                    }}
                                    aria-label="toggle to days"
                                    onClick={() => setViewType('days')}
                                >
                                    <Typography variant="caption">Days</Typography>
                                </IconButton>
                            </Box>


                        )
                    }

                    {/* Menu Chip */}
                    <Chip
                        sx={menuChipStyle}
                        label={<i className="icon-menu2" style={{fontSize: '16px'}}/>}
                        size="small"
                        onClick={handleMealsListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorMealsListMenu}
                        id="library-menu"
                        open={Boolean(anchorMealsListMenu)}
                        onClose={handleMealsListMenuClose}
                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList dense sx={{width: 320, maxWidth: '100%'}}>
                            <MenuItem data-id="new-entry" onClick={handleMealsListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={handlePromptChangeClose}>
                                <ListItemText>Close</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of meals for dates/days */}
            <div className="meals-list-timetable-list-container">
                {/* "All" Chip to show all meals */}
                {
                    eventHasDates || eventHasDays ? (
                        <Box
                            sx={{
                                position: 'relative',
                                display: 'inline-block',
                                marginTop: '5px',
                                marginRight: '10px',
                            }}
                        >
                            <Chip
                                label="All"
                                sx={viewType === 'all' ? selectedChipStyle : unselectedChipStyle}
                                style={{ margin: '0.2rem', height: '40px' }}
                                onClick={handleMealChipAllClick}

                            />
                        </Box>
                    ) : (
                        <></>
                    )
                }


                {
                    (
                        viewType === 'all' || viewType === 'dates') && eventHasDates ? (
                        Planning['time_table'].dates.map((eventDateObj) => {
                            const eventDate = eventDateObj.date;
                            const formattedDay = dayjs(eventDate, 'YYYY-MM-DD').format('ddd');
                            const formattedDate = dayjs(eventDate, 'YYYY-MM-DD').format('M/D');

                            // Filter meals for this specific date
                            const mealsForDate = eventMeals.filter(meal =>
                                meal.time_table?.dates?.length > 0 && meal.time_table.dates[0].date === eventDate
                            );
                            const mealCount = mealsForDate.length;

                            return (
                                <Box
                                    key={`meal-${eventDate}`}
                                    sx={{
                                        position: 'relative',
                                        display: 'inline-block',
                                        marginTop: '5px',
                                        marginRight: '10px',
                                    }}
                                >
                                    <Chip
                                        label={
                                            <div style={{textAlign: 'center'}}>
                                                <Typography variant="body2">{formattedDay}</Typography>
                                                <Typography variant="caption">{formattedDate}</Typography>
                                            </div>
                                        }
                                        sx={viewType === 'dates' && selectedDate === eventDate ? selectedChipStyle : unselectedChipStyle}
                                        style={{margin: '0.2rem', height: '40px'}}
                                        onClick={() => handleMealChipDateClick(eventDate)}
                                    />
                                    {/* Badge or warning icon */}
                                    {mealCount > 0 ? (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                backgroundColor: 'white',
                                                color: 'green',
                                                borderRadius: '35%',
                                                width: '15px',
                                                height: '15px',
                                                fontSize: '12px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                zIndex: 1,
                                                border: '1px solid',
                                                fontWeight: '700',
                                            }}
                                        >
                                {mealCount}
                            </span>
                                    ) : (
                                        <i
                                            className="icon-warning"
                                            style={{
                                                position: 'absolute',
                                                top: '-5px',
                                                right: '-5px',
                                                fontSize: '10px',
                                                color: 'red',
                                                backgroundColor: 'white',
                                                borderRadius: '50%',
                                                padding: '3px',
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </Box>
                            );
                        })
                    ) : (viewType === 'all' || viewType === 'days') && eventHasDays ? (
                            Planning['time_table'].days.map((dayObj) => {
                                const dayKey = Object.keys(dayObj)[0];
                                const dayName = dayObj[dayKey];

                                // Filter meals for this specific day
                                const mealsForDay = eventMeals.filter(meal =>
                                    meal.time_table?.days?.length > 0 && meal.time_table.days[0][dayKey] === dayName
                                );
                                const mealCount = mealsForDay.length;

                                return (
                                    <Box
                                        key={`meal-${dayKey}`}
                                        sx={{
                                            position: 'relative',
                                            display: 'inline-block',
                                            marginTop: '5px',
                                            marginRight: '10px',
                                        }}
                                    >
                                        <Chip
                                            label={
                                                <div style={{textAlign: 'center'}}>
                                                    <Typography variant="body2">{dayName.slice(0, 3)}</Typography>
                                                    <Typography
                                                        variant="caption">{capitalizeFirstLetter(dayKey)}</Typography>
                                                </div>
                                            }
                                            sx={viewType === 'days' && selectedDay === dayKey ? selectedChipStyle : unselectedChipStyle}
                                            style={{margin: '0.2rem', height: '40px'}}
                                            onClick={() => handleMealChipDayClick(dayKey)}
                                        />
                                        {/* Badge or warning icon */}
                                        {mealCount > 0 ? (
                                            <span
                                                style={{
                                                    position: 'absolute',
                                                    top: '-5px',
                                                    right: '-5px',
                                                    backgroundColor: 'white',
                                                    color: 'green',
                                                    borderRadius: '35%',
                                                    width: '15px',
                                                    height: '15px',
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    zIndex: 1,
                                                    border: '1px solid',
                                                    fontWeight: '700',
                                                }}
                                            >
                                    {mealCount}
                                </span>
                                        ) : (
                                            <i
                                                className="icon-warning"
                                                style={{
                                                    position: 'absolute',
                                                    top: '-5px',
                                                    right: '-5px',
                                                    fontSize: '10px',
                                                    color: 'red',
                                                    backgroundColor: 'white',
                                                    borderRadius: '50%',
                                                    padding: '3px',
                                                    zIndex: 1,
                                                }}
                                            />
                                        )}
                                    </Box>
                                );
                            })
                    ) : (
                        <></>
                    )
                }
            </div>

            {/* show the list of meals */}
            <div className="meals-list-items-container">
                {
                    filteredMeals.length > 0 ? (
                        filteredMeals.map((mealDetails, index) => (
                            <Grid
                                item
                                key={`${mealDetails.id || mealDetails.temp_id}-${selectedDate || selectedDay}-${index}`} // Ensure key is unique to the current meal and date/day selection
                                sx={{ display: 'block', marginBottom: '10px' }}
                            >
                                <MealCard
                                    meal={mealDetails}
                                    updateMealDetails={updateMealDetails}
                                    onDeleteMeal={() => openDeleteDialog(mealDetails)}
                                />
                            </Grid>
                        ))
                    ) : (
                        <Typography>
                            No meals available for {viewType === 'dates' ? 'date' : 'day'}.
                        </Typography>
                    )
                }
            </div>


            {/* Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={closeDeleteDialog}
            >
                <DialogTitle>Delete Meal</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the meal "{mealToDelete?.title}"? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleDeleteMeal(mealToDelete.id)} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

export default MealsList;
