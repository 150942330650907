import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import {PAContext} from "../../../Services/PAContext";
import {
    Avatar,
    Button,
    Card,
    CardActions, CardContent,
    CardHeader, CardMedia,
    Chip, Dialog,
    DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
    Grid, InputLabel,
    Menu,
    MenuItem,
    MenuList, Paper, Select, TextField
} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import "./attendees.css";
import sample_attendees from "../../../sampleData/Attendees";
import Box from "@mui/material/Box";
import attendeesHeaderImage from '../../../graphics/Backpackers-2.jpg';
import IconButton from "@mui/material/IconButton";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import currencyList from "../../../sampleData/currencyList";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {rsvpDeclineChipStyle, rsvpNaChipStyle, rsvpYesChipStyle} from "../../ButtonStyle/RSVP";
import {addChipStyle} from "../../ButtonStyle/Add";


function AttendeesList(props) {

    const navigate = useNavigate();

    const { dataArray } = useContext(PAContext);
    const { Planning, Attendees } = dataArray;

    const [sortedAttendees, setSortedAttendees] = useState([]);

    /*
        this is for the Menu list in the header
     */
    const [anchorAttendeeListMenu, setAnchorAttendeeListMenu] = useState(null);
    const handleAttendeeListMenuOpenMenu = (event) => {
        setAnchorAttendeeListMenu(event.currentTarget);
    };
    const handleAttendeeListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorAttendeeListMenu(event.currentTarget);

        if (dataId === "from-previous"){
            navigate('/Planner/Attendees/AddPrevious');
        } else if (dataId === "new-entry"){
            navigate('/Planner/Attendees/AddNew');
        } else if (dataId === "sample-attendees"){
            navigate('/Planner/Attendees/AddSamples');
        }
    };

    const handleAttendeeListMenuClose = () => {
        setAnchorAttendeeListMenu(null);
    };


    /*
        this is for the Menu list for each individual Card
     */
    const [anchorAttendeeElements, setAnchorAttendeeElements] = useState(sortedAttendees.map(() => null));
    const handleOpenAttendeeMenu = (event, index) => {
        const updatedAnchors = [...anchorAttendeeElements];
        updatedAnchors[index] = event.currentTarget;
        setAnchorAttendeeElements(updatedAnchors);
    };

    // Function to handle closing the menu for a specific attendee.
    const handleCloseAttendeeMenu = (index) => {
        const updatedAnchors = [...anchorAttendeeElements];
        updatedAnchors[index] = null;
        setAnchorAttendeeElements(updatedAnchors);
    };



    const [attendeeExpenses, setAttendeeExpenses] = useState([]);
    useEffect(() => {
        // Sort the attendees by lname whenever the Attendees array changes

        if (Planning.attendees !== undefined){

            setSortedAttendees([...Planning.attendees].sort((attendeeA, attendeeB) => {
                const lnameA = attendeeA.lname.toLowerCase();
                const lnameB = attendeeB.lname.toLowerCase();

                // Compare the lname values
                if (lnameA < lnameB) return -1;
                if (lnameA > lnameB) return 1;
                return 0;
            }));

            //update any attendee total expenses
            const totalExpensesForAttendees = [];
            Planning.attendees.map((_att) => {
                const totalExpensesByCurrency = {};

                console.log("Working on "+_att.fname);

                try {
                    // Calculate total expenses for activities
                    Planning.activities.forEach((activity) => {
                        const _activityAttendees = activity.attendees;

                        const {currency} = activity;
                        for (const _activityAttendee of _activityAttendees) {
                            if (_activityAttendee.id === _att.id) {
                                // Check if the currency exists in the totalExpensesByCurrency object
                                if (!totalExpensesByCurrency[currency]) {
                                    totalExpensesByCurrency[currency] = 0;
                                }

                                // Add the amount to the total for the respective currency
                                totalExpensesByCurrency[currency] += parseFloat(_activityAttendee.cost);
                            }
                        }
                    });
                    console.log("after activities : "+totalExpensesByCurrency);

                    // Calculate total expenses for lodgings
                    Planning.lodging.forEach((lodging) => {
                        const _lodgingAttendees = lodging.attendees;

                        const {currency} = lodging;
                        for (const _lodgingAttendee of _lodgingAttendees) {
                            if (_lodgingAttendee.id === _att.id) {
                                // Check if the currency exists in the totalExpensesByCurrency object
                                if (!totalExpensesByCurrency[currency]) {
                                    totalExpensesByCurrency[currency] = 0;
                                }

                                // Add the amount to the total for the respective currency
                                totalExpensesByCurrency[currency] += parseFloat(_lodgingAttendee.cost);
                            }
                        }
                    });
                    console.log("after lodging : "+totalExpensesByCurrency);

                    // Calculate total expenses for meals
                    Planning.meals.forEach((meal) => {
                        const _mealAttendees = meal.attendees;

                        const {currency} = meal;
                        for (const _mealAttendee of _mealAttendees) {
                            if (_mealAttendee.id === _att.id) {
                                // Check if the currency exists in the totalExpensesByCurrency object
                                if (!totalExpensesByCurrency[currency]) {
                                    totalExpensesByCurrency[currency] = 0;
                                }

                                // Add the amount to the total for the respective currency
                                totalExpensesByCurrency[currency] += parseFloat(_mealAttendee.cost);
                            }
                        }
                    });
                    console.log("after meals : "+totalExpensesByCurrency);

                } catch (e){
                    console.log(e);
                }

                // Print the total expenses by currency
                console.log(JSON.stringify(totalExpensesByCurrency));
                for (const currency in totalExpensesByCurrency) {
                    console.log(`Total expenses ${_att.fname} in ${currency}: ${totalExpensesByCurrency[currency]}`);
                }

                //check for no properties
                if (Object.keys(totalExpensesByCurrency).length === 0){
                    totalExpensesByCurrency['USD'] = 0;
                }

                totalExpensesForAttendees.push(
                    {
                        id: _att.id,
                        totalExpenses: totalExpensesByCurrency
                    });

            });

            console.log(JSON.stringify(totalExpensesForAttendees, null, '\t'));
            setAttendeeExpenses(totalExpensesForAttendees);
        }



    }, [Planning.attendees]);

    const formatName = (name) => {
        // Capitalize the first letter and make the rest lowercase
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    };

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }


    function handleDialogEditOpen(attendee_id){
        props.setEditAttendeeId(attendee_id);
        navigate('/Planner/Attendees/Edit');
    }

    const [openDialogEventAdmin, setOpenDialogEventAdmin] = useState(false);
    const [attendeeToRemovePlannerStatusName, setAttendeeToRemovePlannerStatusName] = useState("");
    function handleDialogEventAdminClose() {
        setOpenDialogEventAdmin(false);
    }

    function handleDialogEventHostToggle(index, attendee_id){
        //see if they are adding or removing host privileges
        const foundAttendee = Planning.attendees.find((_att) => _att.id === attendee_id);

        if (foundAttendee.role === "guest"){
            foundAttendee.role = "admin";
        } else {
            //they are trying to remove admin privileges so make sure another admin exists
            const isAdminPresent = Planning.attendees.some(attendee => attendee.role === 'admin' && attendee.id !== attendee_id);

            if (isAdminPresent) {
                foundAttendee.role = "guest";
            } else {
                setAttendeeToRemovePlannerStatusName(foundAttendee.fname);
                setOpenDialogEventAdmin(true);
            }
        }

        //this will refresh the attendee card immediately
        setSortedAttendees([...sortedAttendees]);

        handleCloseAttendeeMenu(index);
    }


    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [attendeeToDeleteId, setAttendeeToDeleteId] = useState("");
    const [attendeeToDeleteName, setAttendeeToDeleteName] = useState("");

    function handleDialogDeleteOpen(index, attendee_id) {
        console.log("handleDialogDeleteOpen : "+attendee_id);

        handleCloseAttendeeMenu(index);

        setAttendeeToDeleteId(attendee_id);

        const foundAttendee = Planning.attendees.find((_att) => _att.id === attendee_id);
        setAttendeeToDeleteName(foundAttendee.fname + ' ' + foundAttendee.lname);

        setOpenDialogDelete(true);

    }

    function handleDialogDeleteClose() {
        setOpenDialogDelete(false);
    }

    function handleDialogDeleteOK(){
        setOpenDialogDelete(false);

        const indexToDelete = Planning.attendees.findIndex((attendee) => attendee.id === attendeeToDeleteId);

        if (indexToDelete !== -1) {
            Planning.attendees.splice(indexToDelete, 1);
        }

        //this forces the features count to update
        navigate('/Planner/Attendees');

        setSortedAttendees([...Planning.attendees].sort((attendeeA, attendeeB) => {
            const lnameA = attendeeA.lname.toLowerCase();
            const lnameB = attendeeB.lname.toLowerCase();

            // Compare the lname values
            if (lnameA < lnameB) return -1;
            if (lnameA > lnameB) return 1;
            return 0;
        }));
    }



    const [openDialogRSVP, setOpenDialogRSVP] = useState(false);
    const [attendeeToRSVPId, setAttendeeToRSVPId] = useState("");
    function handleDialogRSVPOpen(attendee_id) {
        console.log("handleDialogRSVPOpen : "+attendee_id);

        setAttendeeToRSVPId(attendee_id);
        setOpenDialogRSVP(true);
    }
    function handleDialogRSVPClose() {
        setOpenDialogRSVP(false);
    }
    function handleDialogRSVPOK(status) {
        setOpenDialogRSVP(false);

        const foundAttendee = Planning.attendees.find((_att) => _att.id === attendeeToRSVPId);
        foundAttendee.rsvp = status;
    }


    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }


    const [dialogOpen, setDialogOpen] = useState(false);
    const [attendeeBudgetId, setAttendeeBudgetId] = useState("");
    const [budgetAmount, setBudgetAmount] = useState('');
    const [budgetCurrency, setBudgetCurrency] = useState('USD');

    // Handle opening and closing the dialog
    const handleChipClick = (attendee_id) => {
        setAttendeeBudgetId(attendee_id);  // Set the attendee when clicking the chip
        setDialogOpen(true);
    };

    const handleCloseBudgetDialog = () => {
        setDialogOpen(false);
        setAttendeeBudgetId(null);  // Reset the selected attendee after closing
    };

    // Handle currency change
    const handleBudgetCurrencyChange = (event) => {
        setBudgetCurrency(event.target.value);
    };

    // Handle saving the attendee's budget and currency
    const handleBudgetDialogSave = () => {
        // You would save the budget and currency here
        console.log("Budget:", budgetAmount, "Currency:", budgetCurrency);

        handleCloseBudgetDialog();
    };


    return (

        <div className="attendee-list-container" >
            {/* show the header */}
            <div
                className="attendee-list-header"
            >
                <div className="attendee-list-header-text">
                    <h5>Attendee List</h5>
                </div>
                <div className="attendee-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize:'16px'}} />
                        }
                        size="small"
                        onClick={handleAttendeeListMenuOpenMenu}
                    />
                    <Menu
                        sx={{ width: 320, maxWidth: '100%' }}
                        anchorEl={anchorAttendeeListMenu}
                        id="library-menu"
                        open={Boolean(anchorAttendeeListMenu)}
                        onClose={handleAttendeeListMenuClose}

                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuList
                            dense
                            sx={{ width: 320, maxWidth: '100%' }}
                        >
                            <MenuItem
                                data-id="from-previous"
                                onClick={handleAttendeeListMenuClick}
                                disabled={Attendees.length === 0}
                            >
                                <ListItemText>From Previous</ListItemText>

                                <Typography variant="body2">
                                    {Attendees.length}
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                data-id="new-entry"
                                onClick={handleAttendeeListMenuClick}>
                                <ListItemText>New Entry</ListItemText>

                            </MenuItem>
                            <MenuItem
                                data-id="from-phone"
                                onClick={handleAttendeeListMenuClick}
                                disabled
                            >
                                <ListItemText>Phone Contacts (Android only)</ListItemText>

                            </MenuItem>
                            <MenuItem
                                data-id="sample-attendees"
                                onClick={handleAttendeeListMenuClick}>
                                <ListItemText>Sample Attendees</ListItemText>

                                <Typography variant="body2">
                                    {sample_attendees.length}
                                </Typography>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of sample attendees */}
            <div className="attendee-list-items-container">

                <Grid container spacing={2} direction="column" sx={{flexWrap:'nowrap'}}>

                    {
                        sortedAttendees.length > 0 ?(

                            sortedAttendees.map((attendee, index) => (

                                <Grid item key={attendee.id} sx={{display:'flex'}}>

                                    <Card
                                        className="attendee-list-item"
                                        sx={{
                                            borderRadius: '5px',
                                            border: '1px solid #f09637'
                                        }}
                                    >
                                        <CardHeader
                                            sx={{
                                                padding:0
                                            }}
                                            avatar={
                                                <Avatar
                                                    {...stringAvatar(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}
                                                    sx={{
                                                        boxShadow: '' +
                                                            '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
                                                            '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
                                                            '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
                                                        border: `1px solid ${stringToColor(`${formatName(attendee.fname)}, ${formatName(attendee.lname)}`)}`,
                                                        borderRadius: '10px',
                                                        textAlign: 'center',
                                                        fontWeight: '700',
                                                        backgroundColor: '#FFF',
                                                        color: 'black', // Text color (adjust as needed)
                                                        fontSize: '15px',
                                                        width: 30, // Adjust width
                                                        height: 30, // Adjust height
                                                    }}
                                                />
                                            }
                                            title={`${formatName(attendee.lname)}, ${formatName(attendee.fname)}`}
                                            subheader={attendee.email}
                                            action={
                                                <>
                                                    <IconButton
                                                        aria-label="more"
                                                        aria-controls={`menu-${index}`}
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleOpenAttendeeMenu(event, index)}
                                                    >
                                                        <i className="icon-menu2" style={{fontSize:'16px'}} />
                                                    </IconButton>
                                                    <Menu
                                                        sx={{ width: 320, maxWidth: '100%' }}
                                                        anchorEl={anchorAttendeeElements[index]}
                                                        open={Boolean(anchorAttendeeElements[index])}
                                                        onClose={() => handleCloseAttendeeMenu(index)}
                                                    >
                                                        <MenuItem onClick={() => handleDialogEditOpen(attendee.id)}>Edit</MenuItem>
                                                        {
                                                            attendee.role === "admin" ? (
                                                                <MenuItem onClick={() => handleDialogEventHostToggle(index, attendee.id)}>Remove as Planner</MenuItem>
                                                            ) : (
                                                                <MenuItem onClick={() => handleDialogEventHostToggle(index, attendee.id)}>Assign as Planner</MenuItem>
                                                            )
                                                        }
                                                        <MenuItem onClick={() => handleDialogDeleteOpen(index, attendee.id)}>Delete</MenuItem>
                                                    </Menu>
                                                </>
                                            }
                                        />

                                        {/* add the key icons */}
                                        <CardActions
                                            disableSpacing
                                            style={{ display: 'flex', justifyContent: 'space-between'}}
                                        >
                                            <div>
                                                {
                                                    attendeeExpenses.find(att => att.id === attendee.id) ? (
                                                        Object.keys(attendeeExpenses.find(att => att.id === attendee.id).totalExpenses).length > 0 ? (
                                                            <Chip
                                                                label={(() => {

                                                                    return Object.entries(attendeeExpenses.find(att => att.id === attendee.id).totalExpenses).map(([currency, amount]) => (
                                                                        <div key={currency}>
                                                                            {parseFloat(amount).toFixed(2)} {currency}
                                                                        </div>
                                                                    ));
                                                                })()}

                                                                size="small"
                                                                variant="outlined"
                                                                color="success"
                                                                sx={addChipStyle}
                                                                onClick={() => handleChipClick(attendee.id)}
                                                            />
                                                        ) : (
                                                            <></>
                                                        )
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                    {
                                                        attendee.role === "admin" ?
                                                            <i className="icon-crown"/>
                                                            : <></>
                                                    }
                                                </div>
                                                <div style={{ marginLeft:'5px' }}>
                                                    {
                                                        attendee.push_notifications ?
                                                            <i className="icon-bell-ringing2"/>
                                                            : <></>
                                                    }
                                                </div>
                                                <div style={{ marginLeft:'5px' }}>
                                                    <Chip
                                                        sx={
                                                            attendee.rsvp === "yes" ? (
                                                                rsvpYesChipStyle
                                                            ): (
                                                                attendee.rsvp === "decline" ? (
                                                                    rsvpDeclineChipStyle
                                                                ) : (
                                                                    rsvpNaChipStyle
                                                                )
                                                            )
                                                        }
                                                        label="RSVP"
                                                        size="small"
                                                        onClick={()=>handleDialogRSVPOpen(attendee.id)}
                                                    />
                                                </div>
                                            </div>

                                        </CardActions>
                                    </Card>

                                </Grid>
                            ))

                        ):(

                            <Grid item >
                                <Paper elevation={2} sx={{margin: '20px'}}>
                                    <Card>
                                        <CardMedia
                                            sx={{ height: 140 }}
                                            image={attendeesHeaderImage}
                                            title="Add Attendees"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h7" component="div">
                                                Adding Attendees could be the most important thing!
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="text.secondary">
                                                There are several ways to easily add Attendees:
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="text.secondary">
                                                1. <strong>From previous Events</strong>. All of the Attendees from
                                                previous Events will be available for you
                                                to choose from.
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="text.secondary">
                                                2. <strong>Enter manually</strong>. As you start to build your
                                                Events portfolio, easily add just some basic
                                                information to get them fully engaged.
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="text.secondary">
                                                3. <strong>Android phone and Chrome</strong>. Select Attendees right from
                                                your Contacts to add.
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="text.secondary">
                                                4. <strong>Bulk upload</strong>. If you've got a spreadsheet or list
                                                of Contacts you can use that to make things super
                                                simple and quick.
                                            </Typography>
                                            <Typography gutterBottom variant="body2" color="text.secondary">
                                                5. <strong>Samples</strong>. You can get started planning quick if
                                                you just want to use some of our sample Attendees too.
                                            </Typography>
                                        </CardContent>
                                    </Card>

                                </Paper>
                            </Grid>

                        )

                    }
                </Grid>

                {/*  add a footer  */}



                <Dialog
                    open={openDialogEventAdmin}
                    onClose={handleDialogEventAdminClose}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Before we can remove Planner status for <strong>{attendeeToRemovePlannerStatusName}</strong> there needs to be another attendee assigned as Planner.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogEventAdminClose} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openDialogDelete}
                    onClose={handleDialogDeleteClose}
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete {attendeeToDeleteName}?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogDeleteClose}>Cancel</Button>
                        <Button onClick={handleDialogDeleteOK} autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openDialogRSVP}
                    onClose={handleDialogRSVPClose}
                >
                    <DialogTitle id="alert-dialog-title">
                        Update RSVP Status
                    </DialogTitle>
                    <DialogContent sx={{ textAlign: 'center' }}>

                        <Box display="flex" justifyContent="space-between" mt={2}>
                            <Chip
                                variant="contained"
                                sx={rsvpDeclineChipStyle}
                                style={{marginRight:'8px'}}
                                label="Decline"
                                onClick={()=>handleDialogRSVPOK('decline')}
                            />
                            <Chip
                                variant="contained"
                                sx={rsvpYesChipStyle}
                                style={{marginRight:'8px'}}
                                label="Yes"
                                onClick={()=>handleDialogRSVPOK('yes')}
                            />
                            <Chip
                                variant="contained"
                                sx={rsvpNaChipStyle}
                                label="N/A"
                                onClick={()=>handleDialogRSVPOK('na')}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogRSVPClose}>Close</Button>
                    </DialogActions>
                </Dialog>

                {/* Dialog for setting the attendee budget and preferred currency */}
                <Dialog open={dialogOpen} onClose={handleCloseBudgetDialog}>
                    <DialogTitle>
                        Budget for
                        {
                            sortedAttendees.find((_att) => _att.id === attendeeBudgetId)?.nickname
                        }
                    </DialogTitle>
                    <DialogContent>
                        {/* Budget Input */}
                        <TextField
                            label="Budget"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={budgetAmount}
                            onChange={(e) => {
                                // Only allow numbers, commas, and decimals
                                const value = e.target.value;
                                const formattedValue = value.replace(/[^\d,\.]/g, ''); // Remove any character that's not a number, comma, or period
                                setBudgetAmount(formattedValue);
                            }}
                            inputProps={{
                                inputMode: 'decimal',  // Helps to bring up the numeric keyboard on mobile
                                pattern: "[0-9,]*"    // Additional hint to browsers about valid input
                            }}
                        />

                        {/* Preferred Currency Select */}
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="currency-select-label">Currency</InputLabel>
                            <Select
                                labelId="currency-select-label"
                                value={budgetCurrency}
                                label="Currency"
                                onChange={handleBudgetCurrencyChange}
                            >
                                {/* Map through the currencyList to generate MenuItem options */}
                                {currencyList.map((option) => (
                                    <MenuItem
                                        key={option.code}
                                        value={option.code}
                                        sx={{ fontSize: '12px' }}
                                    >
                                        {option.code} : {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseBudgetDialog}>Cancel</Button>
                        <Button onClick={handleBudgetDialogSave} color="primary">Save</Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>

    )
}

export default AttendeesList;
