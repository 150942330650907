import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    Card,
    CardContent,
    CardMedia,
    Chip,
    Menu,
    MenuItem,
    MenuList,
    Paper
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../../Services/PAContext";
import './lodging.css';

import lodgingHeaderImage from '../../../graphics/th-513411954.jpg';
import LodgingCard from "./LodgingCard";
import dayjs from "dayjs";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import {selectedChipStyle} from "../../ButtonStyle/Selected";
import {unselectedChipStyle} from "../../ButtonStyle/Unselected";
import Box from "@mui/material/Box";


function LodgingList(props){

    const navigate = useNavigate();

    const { dataArray, setPlanningLodging } = useContext(PAContext);
    const { Planning } = dataArray;

    const [eventLodging, setEventLodging] = useState([]);
    const [selectedLodgingUnit , setSelectedLodgingUnit ] = useState("dates");
    const [selectedLodgingForDates , setSelectedLodgingForDates ] = useState("");
    const [selectedLodgingForDays , setSelectedLodgingForDays ] = useState("");


    useEffect(() => {
        console.log("eventLodging changed : "+JSON.stringify(eventLodging));
        console.log("eventLodging count : "+eventLodging.length);

        //get any photos for lodgings that don't have any
        for (const _lodging of eventLodging) {
            console.log("Lodging : "+_lodging["name"]);
            if (Object.keys(_lodging.Place).length > 0) {
                const request = {
                    placeId: _lodging['Place'].place_id,
                    fields: ['photo']
                };

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.getDetails(request, function (results, status) {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        console.log("The returned place : " + JSON.stringify(results, null, '\t'));

                        const _photos_array = [];
                        for (const _photo of results.photos) {
                            _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                        }
                        _lodging.photos = _photos_array;

                        updateLodgingDetails(_lodging);
                    }
                });
            }
            if (_lodging["photos"] !== undefined
                && _lodging["photos"].length === 0
                && _lodging["location"].google_place_id !== "") {
                //get the photos
                console.log("Getting photos for : "+_lodging["location"].google_place_id);
                const request = {
                    placeId: _lodging["location"].google_place_id,
                    fields: ['photo']
                };

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.getDetails(request, function (results, status) {
                    console.log("response : "+results);
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                        try {
                            const _photos_array = [];
                            for (const _photo of results.photos) {
                                _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                            }
                            _lodging.photos = _photos_array;

                            updateLodgingDetails(_lodging);

                        } catch (e){console.log("error : "+e);}
                    }
                });
            }
        }


    }, [eventLodging]);

    useEffect(() => {
        console.log("lodgingList : "+JSON.stringify(eventLodging, null, '\t'));

        //get any photos for lodgings that came from the map
        for (const _lodging of eventLodging) {
            console.log("Lodging : "+_lodging["name"]);
            if (Object.keys(_lodging.Place).length > 0) {
                const request = {
                    placeId: _lodging['Place'].place_id,
                    fields: ['photo']
                };

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.getDetails(request, function (results, status) {
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        console.log("The returned place : " + JSON.stringify(results, null, '\t'));

                        const _photos_array = [];
                        for (const _photo of results.photos) {
                            _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                        }
                        _lodging.photos = _photos_array;

                        updateLodgingDetails(_lodging);
                    }
                });
            }
            if (_lodging["photos"] !== undefined
                && _lodging["photos"].length === 0
                && _lodging["location"].google_place_id !== "") {
                //get the photos
                console.log("Getting photos for : "+_lodging["location"].google_place_id);
                const request = {
                    placeId: _lodging["location"].google_place_id,
                    fields: ['photo']
                };

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.getDetails(request, function (results, status) {
                    console.log("response : "+results);
                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                        try {
                            const _photos_array = [];
                            for (const _photo of results.photos) {
                                _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                            }
                            _lodging.photos = _photos_array;

                            updateLodgingDetails(_lodging);

                        } catch (e){console.log("error : "+e);}
                    }
                });
            }
        }

    }, []);

    useEffect(() => {
        console.log("Planning.lodging got triggered : "+JSON.stringify(Planning.lodging));
        console.log("lodging count : "+Planning.lodging.length);

        if (Planning.lodging !== undefined) {
            setEventLodging([...Planning.lodging]);
        }

        //get any photos for lodgings that came from the map
        /*
        if (Planning.lodging && Planning.lodging.length > 0) {


            for (const _lodging of Planning.lodging) {

                if (_lodging.Place !== undefined && Object.keys(_lodging.Place).length > 0) {

                    console.log("_lodging['Place'].place_id : "+_lodging['Place'].place_id);

                    const request = {
                        placeId: _lodging['Place'].place_id,
                        fields: ['photo']
                    };

                    const _global_map_service = new window.google.maps.places.PlacesService(_global_map_ref.current);
                    _global_map_service.getDetails(request, function (results, status) {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                            const _photos_array = [];
                            if (results.photos && Array.isArray(results.photos)) {
                                for (const _photo of results.photos) {
                                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                                }
                            }
                            _lodging.photos = _photos_array;
                        }
                    });
                }
                if (_lodging["photos"] !== undefined
                    && _lodging["photos"].length === 0
                    && _lodging["location"].google_place_id !== "") {

                    const request = {
                        placeId: _lodging["location"].google_place_id,
                        fields: ['photo']
                    };

                    const _global_map_service = new window.google.maps.places.PlacesService(_global_map_ref.current);
                    _global_map_service.getDetails(request, function (results, status) {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                            try {
                                const _photos_array = [];
                                for (const _photo of results.photos) {
                                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                                }
                                _lodging.photos = _photos_array;

                                // Find the index of the updated lodging in eventLodging
                                const lodgingIndex = _lodging['id'] === 0
                                    ? Planning.lodging.findIndex((lodging) => lodging.temp_id === _lodging.temp_id)
                                    : Planning.lodging.findIndex((lodging) => lodging.id === _lodging.id);

                                if (lodgingIndex !== -1) {
                                    // If the lodging is found, update it in the eventLodging array
                                    const updatedEventLodgingArray = [...Planning.lodging];
                                    updatedEventLodgingArray[lodgingIndex] = _lodging;

                                    setEventLodging(updatedEventLodgingArray);

                                    //this updates the lodging at the PAContext level
                                    setPlanningLodging(Planning["id"], updatedEventLodgingArray);

                                }
                            } catch (e){}
                        }
                    });
                }
            }

        }

         */
    }, [Planning.lodging]);

    useEffect(() => {

    }, [selectedLodgingUnit]);

    const updateLodgingDetails = (updatedLodging) => {
        console.log("updateLodgingDetails");

        // Find the index of the updated lodging in eventLodging
        const lodgingIndex = updatedLodging['id'] === 0
            ? eventLodging.findIndex((lodging) => lodging.temp_id === updatedLodging.temp_id)
            : eventLodging.findIndex((lodging) => lodging.id === updatedLodging.id);

        console.log("lodgingIndex : "+lodgingIndex);

        if (lodgingIndex !== -1) {
            // If the lodging is found, update it in the eventLodging array
            const updatedEventLodgingArray = [...eventLodging];
            updatedEventLodgingArray[lodgingIndex] = updatedLodging;

            setEventLodging(updatedEventLodgingArray);

            //this updates the lodging at the PAContext level
            setPlanningLodging(Planning["id"], updatedEventLodgingArray);

        }

    };

    /*
        this is for the Menu list in the header
     */
    const [anchorLodgingListMenu, setAnchorLodgingListMenu] = useState(null);
    const handleLodgingListMenuOpenMenu = (event) => {
        setAnchorLodgingListMenu(event.currentTarget);
    };
    const handleLodgingListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorLodgingListMenu(event.currentTarget);

        if (dataId === "new-entry"){
            navigate('/Planner/Lodging/AddNew');
        } else if (dataId === "from-pdf"){
            navigate('/Planner/Lodging/AddFromPDF');
        }
    };
    const handleLodgingListMenuClose = () => {
        setAnchorLodgingListMenu(null);
    };

    function handlePromptChangeClose() {
        props.setShowEventItemConfig(false);
    }

    function handleLodgingChipClick(_lodging_id){

    }


    return (
        <div className="lodging-list-container">

            {/* show the header */}
            <div
                className="lodging-list-header"
            >
                <div className="lodging-list-header-text">
                    <h5>Lodging List</h5>
                </div>
                <div className="lodging-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={menuChipStyle}
                        label={
                            <i className="icon-menu2" style={{fontSize: '16px'}}/>
                        }
                        size="small"
                        onClick={handleLodgingListMenuOpenMenu}
                    />
                    <Menu
                        sx={{width: 320, maxWidth: '100%'}}
                        anchorEl={anchorLodgingListMenu}
                        id="library-menu"
                        open={Boolean(anchorLodgingListMenu)}
                        onClose={handleLodgingListMenuClose}

                        transformOrigin={{horizontal: 'right', vertical: 'top'}}
                        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                    >
                        <MenuList
                            dense
                            sx={{width: 320, maxWidth: '100%'}}
                        >
                            <MenuItem
                                data-id="new-entry"
                                onClick={handleLodgingListMenuClick}>
                                <ListItemText>New Entry</ListItemText>
                            </MenuItem>
                            <MenuItem
                                data-id="from-pdf"
                                onClick={handleLodgingListMenuClick}>
                                <ListItemText>From PDF</ListItemText>
                            </MenuItem>
                            <MenuItem
                                data-id="pin-me"
                                onClick={handleLodgingListMenuClick}>
                                <ListItemText>Use GPS</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>


            {/* show the list of lodging dates/days */}

            <div className="lodging-list-timetable-list-container">
                {eventLodging && eventLodging.length > 0 ? (
                    (() => {
                        const allChips = [];

                        // Step 1: Add lodging Chips to the allChips array
                        eventLodging.forEach((lodging, index) => {
                            const timeTable = lodging.time_table;

                            if (selectedLodgingUnit === 'dates' && timeTable?.dates?.length > 0) {
                                const firstDate = dayjs(timeTable.dates[0].date);
                                const lastDate = dayjs(timeTable.dates[timeTable.dates.length - 1].date);

                                const formattedFirstDay = firstDate.format('ddd'); // e.g., "Wed"
                                const formattedFirstDate = firstDate.format('M/D'); // e.g., "8/19"
                                const formattedLastDay = lastDate.format('ddd'); // e.g., "Fri"
                                const formattedLastDate = lastDate.format('M/D'); // e.g., "8/21"

                                allChips.push({
                                    date: firstDate, // Use the first lodging date for sorting
                                    component: (
                                        <Box key={index}
                                             sx={{
                                                 position: 'relative',
                                                 display: 'inline-block',
                                                 marginTop: '5px',
                                                 marginRight: '10px'
                                            }}
                                        >
                                            {/* Chip Component */}
                                            <Chip
                                                label={
                                                    <div style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}>
                        <span style={{ textAlign: 'center', marginRight: '5px' }}>
                            <Typography variant="body2">{formattedFirstDay}</Typography>
                            <Typography variant="caption">{formattedFirstDate}</Typography>
                        </span>
                                                        <span style={{ margin: '0 5px' }}>
                            <Typography variant="body2">-</Typography>
                        </span>
                                                        <span style={{ textAlign: 'center', marginLeft: '5px' }}>
                            <Typography variant="body2">{formattedLastDay}</Typography>
                            <Typography variant="caption">{formattedLastDate}</Typography>
                        </span>
                                                    </div>
                                                }
                                                clickable
                                                sx={
                                                    selectedLodgingForDates === lodging.id ? selectedChipStyle : unselectedChipStyle
                                                }
                                                onClick={() => handleLodgingChipClick(lodging.id)}
                                                style={{ margin: '0.2rem', height: '40px' }}
                                            />

                                            {/* Icon Component Positioned Outside the Chip */}
                                            <i
                                                className="icon-check"
                                                style={{
                                                    position: 'absolute',
                                                    top: '-5px', // Position the icon above the Chip
                                                    right: '-5px',
                                                    fontSize: '12px',
                                                    color: 'green',
                                                    zIndex: 1,
                                                    borderColor: 'green',
                                                    backgroundColor: 'white',  // White background for the circle
                                                    borderRadius: '35%',       // Make it a circle
                                                    padding: '2px',            // Space between the icon and the border
                                                    border: '1px solid',
                                                    fontWeight: '800',
                                                    textShadow: '0 0 0 green, 0 0 0 green',
                                                }}
                                            />
                                        </Box>
                                    )
                                });

                            }
                        });

                        // Step 2: Add placeholder Chips for missing event dates
                        let allLodgingDates = [];

                        eventLodging.forEach(lodging => {
                            if (lodging.time_table?.dates?.length > 0) {
                                lodging.time_table.dates.forEach(dateObj => allLodgingDates.push(dateObj.date));
                            }
                        });

                        if (Planning['time_table']?.dates?.length > 0) {

                            Planning['time_table']?.dates?.forEach(eventDateObj => {
                                const eventDate = dayjs(eventDateObj.date, "YYYY-MM-DD");

                                const lodgingDateExists = allLodgingDates.some(lodgingDate =>
                                    dayjs(lodgingDate).isSame(eventDate, 'day')
                                );

                                if (!lodgingDateExists) {
                                    const formattedDay = eventDate.format('ddd');
                                    const formattedDate = eventDate.format('M/D');

                                    allChips.push({
                                        date: eventDate, // Use the event date for sorting
                                        component: (
                                            <Box key={`placeholder-${eventDateObj.date}`}
                                                 sx={{
                                                     position: 'relative',
                                                     display: 'inline-block',
                                                     marginTop: '5px',
                                                     marginRight: '10px'
                                                 }}
                                            >
                                                {/* Placeholder Chip Component */}
                                                <Chip
                                                    label={
                                                        <div style={{ textAlign: 'center' }}>
                                                            <Typography variant="body2">{formattedDay}</Typography>
                                                            <Typography variant="caption">{formattedDate}</Typography>
                                                        </div>
                                                    }
                                                    sx={unselectedChipStyle} // Placeholder style for Chips
                                                    style={{ margin: '0.2rem', height: '40px' }}
                                                />

                                                {/* Icon Component Positioned Outside the Chip */}
                                                <i
                                                    className="icon-warning"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '-2px', // Position the icon above the Chip
                                                        right: '-7px',
                                                        fontSize: '10px',
                                                        color: '#10318f',
                                                        zIndex: 1,
                                                        backgroundColor: 'white',  // Green background for the circle
                                                        borderRadius: '50%',       // Make it a circle
                                                        padding: '3px',            // Space between the icon and the border
                                                        border: '1px solid #F00'
                                                    }}
                                                />
                                            </Box>
                                        )
                                    });

                                }
                            });
                        }

                        // Step 3: Sort all Chips by date
                        allChips.sort((a, b) => a.date.isBefore(b.date) ? -1 : 1);

                        // Step 4: Render the Chips in chronological order
                        return allChips.map((chipObj) => chipObj.component);
                    })()
                ) : (
                    <div style={{padding: '20px'}}>
                        <i className="icon-warning" style={{fontSize: '11px', color: 'red', marginRight: '10px'}}/>
                        <Typography variant="caption">Need to add some Lodging information first</Typography>
                    </div>
                )}
            </div>


            {/* show the list of lodgings */}
            <div className="lodging-list-items-container">

                {
                    eventLodging && eventLodging.length > 0 ? (
                        eventLodging
                            .sort((a, b) => dayjs(a.begin_date).diff(dayjs(b.begin_date)))  // Sort flights by time
                            .map((lodgingDetails, index) => (

                                <Grid item key={index}
                                      sx={{
                                          display: 'block',
                                          marginBottom: '10px'
                                      }}
                                >

                                    <LodgingCard
                                        lodging={lodgingDetails}
                                        updateLodgingDetails={updateLodgingDetails}
                                    />

                                </Grid>

                            ))
                    ) : (
                        <Grid item>
                            <Paper elevation={2} sx={{margin: '20px'}}>
                                <Card>
                                    <CardMedia
                                        sx={{height: 140}}
                                        image={lodgingHeaderImage}
                                        title="Add Lodging"
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h7" component="div">
                                            Add any type of Lodging
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            There are several ways to easily add Lodgings:
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            1. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the BUSINESS icon and search the area for <strong>Hotels, Motels,
                                            Campgrounds</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            2. <strong>From the Map</strong>. Use the <strong>Search Bar</strong>.
                                            Select the HOUSE icon and search the area for <strong>VRBO, AirBnB, Personal
                                            Residence</strong>.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            3. <strong>From a PDF Receipt</strong>.
                                            We'll have our AI Concierge extract the relevant details from the PDF and
                                            let you see what we've
                                            found. You can edit the results before we actually add the details to the
                                            Itinerary.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            4. <strong>Camping</strong>. Use your GPS to pinpoint your location. Click
                                            on the
                                            <i className="icon-menu2"/> above.
                                        </Typography>
                                        <Typography gutterBottom variant="body2" color="text.secondary">
                                            5. <strong>Enter manually</strong>. Fill in the input boxes with the exact
                                            Lodging details.
                                        </Typography>

                                    </CardContent>
                                </Card>

                            </Paper>
                        </Grid>
                    )

                }
            </div>
        </div>
    )

}

export default LodgingList;

