import React, {useContext, useEffect, useState} from 'react';
import {Chip} from "@mui/material";
import {PAContext} from "../../Services/PAContext";
import './planningPageStyle.css';
import Badge from "@mui/material/Badge";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import TourRadarTour from "../../MasterEventObjects/TourRadarTour";
import {selectedSuggestionStyle, unselectedSuggestionStyle} from "../../components/ButtonStyle/Suggestions";


const chipLabels = [
    'Edventure Travel',
    'Slow Travel',
    'Tours',
    'Lodging',
    'Meals',
    'Walking',
    'Unique',
    'Hidden'
];



function Suggestions(props) {

    const navigate = useNavigate();

    const { dataArray, addSuggestionsLodging, clearSuggestionsLodging,
            addSuggestionsTours, clearSuggestionsTours,
            addSuggestionsUnique, clearSuggestionsUnique} = useContext(PAContext);
    const { SuggestionsPipeline, Planning } = dataArray;

    const [selectedChip, setSelectedChip] = useState(null);

    const [edventureTravelItems, setEdventureTravelItems] = useState([]);
    const [slowTravelItems, setSlowTravelItems] = useState([]);
    const [tourItems, setTourItems] = useState([]);
    const [lodgingItems, setLodgingItems] = useState([]);
    const [mealItems, setMealItems] = useState([]);
    const [walkingItems, setWalkingItems] = useState([]);
    const [uniqueItems, setUniqueItems] = useState([]);
    const [hiddenItems, setHiddenItems] = useState([]);
    const [sustainableItems, setSustainableItems] = useState([]);
    const [insuranceItems, setInsuranceItems] = useState([]);


    const addItemToLodging = (item) => {
        setLodgingItems((prevItems) => [...prevItems, item]);
    };
    const addItemToTours = (_item) => {

        const _tour = new TourRadarTour();
        _tour.id = _item.tour_id;
        _tour.is_active = _item.is_active;
        _tour.tour_name = _item.tour_name;
        _tour.description = _item.description;
        _tour.images = Object.assign(_item.images);

        _tour.language = _item.language;
        _tour.locale = _item.locale;

        _tour.operator = Object.assign(_item.operator);
        _tour.age_range = Object.assign(_item.age_range);

        _tour.max_group_size = _item.max_group_size;
        _tour.tour_length_days = _item.tour_length_days;

        _tour.ratings = Object.assign(_item.ratings);

        _tour.destinations = Object.assign(_item.destinations);
        _tour.start_city = Object.assign(_item.start_city);
        _tour.end_city = Object.assign(_item.end_city);

        _tour.prices = Object.assign(_item.prices);

        _tour.budget = _item.budget;

        _tour.tour_types = Object.assign(_item.tour_types);
        _tour.links = Object.assign(_item.links);

        setTourItems((prevItems) => [...prevItems, _tour]);
    };
    const addItemToUnique = (item) => {
        setUniqueItems((prevItems) => [...prevItems, item]);
    };

    useEffect(()=> {
        console.log("Something new in the SuggestionsPipeline : "+SuggestionsPipeline.length);

        try {
            for (let i = 0; i < SuggestionsPipeline.length; i++) {
                const suggestionAction = SuggestionsPipeline[i];
                console.log("suggestionAction : "+JSON.stringify(suggestionAction, null, '\t'));

                if (suggestionAction.action === "planningConciergeSuggestionServiceLodgingResults"){

                    //clear any existing lodging items
                    setLodgingItems([]);
                    clearSuggestionsLodging();

                    for (const _lodging of suggestionAction.lodgings){
                        //add the new lodgings
                        addItemToLodging(_lodging);
                        addSuggestionsLodging(_lodging);
                    }
                } else if (suggestionAction.action === "planningConciergeSuggestionServiceToursResults"){
                    //clear any existing unique items
                    setTourItems([]);
                    clearSuggestionsTours();

                    for (const _tour of suggestionAction.tours){
                        //add the new Tour
                        addItemToTours(_tour);
                        addSuggestionsTours(_tour);
                    }
                } else if (suggestionAction.action === "planningConciergeSuggestionServiceUniqueResults"){
                    //clear any existing unique items
                    setUniqueItems([]);
                    clearSuggestionsUnique();

                    for (const _unique of suggestionAction.unique){
                        //add the new unique
                        addItemToUnique(_unique);
                        addSuggestionsUnique(_unique);
                    }
                }

                //remove the one item we just processed
                SuggestionsPipeline.splice(i, 1);

            }


            } catch (e){
            console.log(e);
        }

    }, [SuggestionsPipeline]);

    useEffect(()=> {
        //if the location changes reset any chips that might be showing

        setSelectedChip(null);

        console.log("!! location changed to : "+props.searchingForLocation);
        console.log("dataArray.Websocket : "+dataArray.Websocket);

        console.log("props.zoomInOnLocation : "+props.zoomInOnLocation);

        if (props.searchingForLocation !== ""
            && dataArray.Websocket
            && Object.keys(props.zoomInOnLocation).length > 0){

            try {

                const currentDate = dayjs();
                const twoWeeksLater = currentDate.add(14, 'day');
                const fiveDaysAfter = twoWeeksLater.add(5, 'day');

                console.log("Planning['time_table'] : "+JSON.stringify(Planning['time_table'], null, '\t'));

                const _event_dates = Planning['time_table'];
                let _event_begin_date = "";
                let _event_end_date = "";
                if (_event_dates !== undefined && _event_dates['dates'].length > 0){
                    //there are assigned dates
                    if (_event_dates['dates'].length === 1){
                        //one day Event
                        _event_begin_date = _event_dates['dates'][0].date;
                        _event_end_date = _event_dates['dates'][0].date;
                    } else {
                        _event_begin_date = _event_dates['dates'][0].date;
                        _event_end_date = _event_dates['dates'][_event_dates['dates'].length-1].date;
                    }
                } else {
                    //no dates are assigned

                }

                console.log("_event_begin_date :"+_event_begin_date);
                console.log("_event_end_date :"+_event_end_date);

                const _sendLodgingString = {};
                _sendLodgingString.action = "planningConciergeSuggestionServiceLodging";

                _sendLodgingString.location = props.zoomInOnLocation;
                _sendLodgingString.adults = Planning.attendees.length > 0 ? Planning.attendees.length : 2 ;
                _sendLodgingString.rooms = 1;
                _sendLodgingString.begin_date = _event_begin_date === "" ? twoWeeksLater : _event_begin_date;
                _sendLodgingString.end_date = _event_end_date === "" ? fiveDaysAfter : _event_end_date;

                console.log("!!!! Sending out the lodging request : "+JSON.stringify(_sendLodgingString));
//                dataArray.Websocket.send(JSON.stringify(_sendLodgingString) + "|^");

                const _sendToursString = {};
                _sendToursString.action = "planningConciergeSuggestionServiceTours";

                _sendToursString.location = props.zoomInOnLocation;
                _sendToursString.begin_date = _event_begin_date === "" ? twoWeeksLater : _event_begin_date;
                _sendToursString.end_date = _event_end_date === "" ? fiveDaysAfter : _event_end_date;

                console.log("!!!! Sending out the Tours request");
//                dataArray.Websocket.send(JSON.stringify(_sendToursString) + "|^");


                const _sendUniqueString = {};
                _sendUniqueString.action = "planningConciergeSuggestionServiceUnique";

                _sendUniqueString.location = props.zoomInOnLocation;
                _sendUniqueString.begin_date = _event_begin_date === "" ? twoWeeksLater : _event_begin_date;
                _sendUniqueString.end_date = _event_end_date === "" ? fiveDaysAfter : _event_end_date;

                console.log("!!!! Sending out the unique request");
//                dataArray.Websocket.send(JSON.stringify(_sendUniqueString) + "|^");

            } catch (e){
                console.log("!!!!!! "+e);
            }

        } else {
            //websocket not available or no location info

        }

    }, [props.searchingForLocation]);


    const handleChipClick = (chipLabel) => {
        setSelectedChip(chipLabel);
        // Perform your desired function here
        console.log(`Clicked on ${chipLabel} chip`);

        props.setSuggestionForLocation(chipLabel);

        let _navigate_to = "";
        let _sendString = {};
        switch (chipLabel){
            case "Edventure Travel":
                _sendString.action = "planningConciergeSuggestionServiceActivities";
                _sendString.type = 'outdoor';
                _sendString.location = Planning.location;
                break;
            case "Slow Travel":
                _sendString.action = "planningConciergeSuggestionServiceActivities";
                _sendString.type = 'indoor';
                _sendString.location = Planning.location;
                break;
            case "Tours":
                _sendString.action = "planningConciergeSuggestionServiceTours";
                _sendString.type = 'tours';
                _sendString.location = Planning.location;
                _navigate_to = '/Planner/Suggestions/Tours';
                break;
            case "Lodging":
                //in case all of the required info isn't ready yet
                const currentDate = dayjs();
                const twoWeeksLater = currentDate.add(14, 'day');
                const fiveDaysAfter = twoWeeksLater.add(5, 'day');

                _sendString.action = "planningConciergeSuggestionServiceLodging";
                _sendString.location = Planning.location;
                _sendString.adults = Planning.attendees.length > 0 ? Planning.attendees.length : 2 ;
                _sendString.rooms = 1;
                _sendString.begin_date = Planning.begin_date !== "" ? Planning.begin_date : twoWeeksLater;
                _sendString.end_date = Planning.end_date !== "" ? Planning.end_date : fiveDaysAfter;

                props.setViewSuggestionLodgingMarkers(true);

                _navigate_to = '/Planner/Lodging/Suggestions';

                break;
            case "Meals":
                _sendString.action = "planningConciergeSuggestionServiceMeals";
                _sendString.location = Planning.location;
                _sendString.begin_date = Planning.begin_date;
                _sendString.end_date = Planning.end_date;
                break;
            case "Walking":
                _sendString.action = "planningConciergeSuggestionServiceWalking";
                _sendString.location = Planning.location;
                _sendString.begin_date = Planning.begin_date;
                _sendString.end_date = Planning.end_date;
                break;
            case "Unique":
                _navigate_to = '/Planner/Suggestions/Unique';
                break;
            case "Hidden":
                _sendString.action = "planningConciergeSuggestionServiceHidden";
                _sendString.location = Planning.location;
                _sendString.begin_date = Planning.begin_date;
                _sendString.end_date = Planning.end_date;
                break;
            default:

        }

//        dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");

        if (!props.showEventItemConfig){
            //if it's not showing then show it
            props.setShowEventItemConfig(true);
        }

        navigate(_navigate_to);
    };

    useEffect(() => {

        if (props.clearEvent) {
            setEdventureTravelItems([]);
            setSlowTravelItems([]);
            setTourItems([]);
            setLodgingItems([]);
            setMealItems([]);
            setWalkingItems([]);
            setUniqueItems([]);
            setHiddenItems([]);
            setSustainableItems([]);
            setInsuranceItems([]);

            clearSuggestionsLodging();
            clearSuggestionsUnique();

        }

    }, [props.clearEvent]);


    return (

        <>
            {chipLabels.map((label, index) => {

                let _item_count = 0;
                switch (label){
                    case "Edventure Travel":
                        _item_count = edventureTravelItems.length;
                        break;
                    case "Slow Travel":
                        _item_count = slowTravelItems.length;
                        break;
                    case "Tours":
                        _item_count = tourItems.length;
                        break;
                    case "Lodging":
                        _item_count = lodgingItems.length;
                        break;
                    case "Meals":
                        _item_count = mealItems.length;
                        break;
                    case "Walking":
                        _item_count = walkingItems.length;
                        break;
                    case "Unique":
                        _item_count = uniqueItems.length;
                        break;
                    case "Hidden":
                        _item_count = hiddenItems.length;
                        break;
                    default:

                }

                return(
                    <Badge
                        key={index}
                        badgeContent={_item_count}
                        sx={{
                            '& .MuiBadge-badge': {
                                backgroundColor: 'white', // Customize the dot color here
                                border: '1px solid #ddd', // Make it a circle
                                width: '16px', // Adjust the dot size as needed
                                height: '16px',
                                borderRadius: '50%', // Make it a circle
                                top: '10px', // Adjust top and right values to move the dot
                                right: '10px', // Adjust top and right values to move the dot
                            },
                        }}
                    >

                        <Chip
                            sx={selectedChip === label ? selectedSuggestionStyle : unselectedSuggestionStyle}
                            label={label}
                            clickable
                            onClick={() => handleChipClick(label)}
                            style={{margin: '0.5rem'}}
                        />
                    </Badge>
                    );

            })}
        </>

    )
}

export default Suggestions;
