export const selectedAttendeeStyle = {
    boxShadow: '' +
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
        '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
        '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #0096ff',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#cbe0f7',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#cbe0f7', // Reverse the gradient on hover
    },
};

export const unselectedAttendeeStyle = {
    boxShadow: '0 0 5px #0096ff',
    border: '1px solid #0096ff',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#cbe0f7', // Reverse the gradient on hover
    },
};