import React, {useContext, useState} from 'react';

import {
    TextField, Typography, Button, Checkbox, FormControlLabel, RadioGroup, Radio, Box,
    Switch, Grid, Autocomplete, Chip, Menu, MenuList, MenuItem
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Activity from "../../../MasterEventObjects/Activity";
import PALocation from "../../../MasterEventObjects/Location";
import {PAContext} from "../../../Services/PAContext";
import {cancelChipStyle} from "../../ButtonStyle/Cancel";
import './activities.css';
import {addWaitGradientStyle} from "../../ButtonStyle/AddWait";
import {addChipStyle} from "../../ButtonStyle/Add";


function ActivitiesAdd() {

    const {dataArray} = useContext(PAContext);
    const {Planning, Attendees} = dataArray;

    // State management for the new Activity
    const [activity, setActivity] = useState(new Activity());
    const [allDay, setAllDay] = useState(true); // Toggle for all-day activity
    const [selectedAttendees, setSelectedAttendees] = useState([]); // Attendees selection
    const [useMasterAttendees, setUseMasterAttendees] = useState(false); // Toggle for attendee selection

    // State for location
    const [locationMode, setLocationMode] = useState(""); // Near Me, Find Location, Enter Address
    const [location, setLocation] = useState(new PALocation());

    // Date handling
    const handleDateChange = (field, date) => {
        setActivity({ ...activity, [field]: dayjs(date).format('MM/DD/YYYY') });
    };

    // Time handling for begin and end times
    const handleTimeChange = (field, time) => {
        setActivity({ ...activity, [field]: dayjs(time).format('HH:mm') });
    };

    // Toggle between all-day and specific time activity
    const handleAllDayToggle = (event) => {
        setAllDay(event.target.checked);
        setActivity({ ...activity, all_day_activity: event.target.checked });
    };

    // Attendee Selection
    const handleAttendeeToggle = (attendeeId) => {
        setSelectedAttendees(prev =>
            prev.includes(attendeeId)
                ? prev.filter(id => id !== attendeeId)
                : [...prev, attendeeId]
        );
        setActivity({ ...activity, attendees: selectedAttendees });
    };

    // Location Methods
    const handleLocationModeChange = (event) => {
        setLocationMode(event.target.value);
    };

    const handleSubmit = () => {
        // Add validation and logic for submission
        onSubmit(activity);
    };

    function onSubmit(){

    }


    function handlePromptChangeClose() {
//        props.setShowEventItemConfig(false);
    }



    return (

        <div className="activities-list-container">

            {/* show the header */}
            <div
                className="activities-list-header"
            >
                <div className="activities-list-header-text">
                    <h5>New Activity</h5>
                </div>
                <div className="activities-list-header-chip-holder">
                    <Chip
                        sx={cancelChipStyle}
                        label="Close"
                        size="small"
                        onClick={handlePromptChangeClose}
                    />
                    <Chip
                        sx={addChipStyle}
                        label='Add'
                        size="small"
                        onClick={onSubmit}
                    />
                </div>
            </div>

            <div className="activities-list-add-item-container">

                <Box sx={{padding: '20px'}}>
                    {/* Title Section */}
                    <Typography variant="h6" gutterBottom>New Activity</Typography>
                    <TextField
                        label="Activity Title"
                        value={activity.title}
                        onChange={(e) => setActivity({...activity, title: e.target.value})}
                        fullWidth
                        margin="normal"
                    />

                    {/* Date and Time Section */}
                    <Typography variant="h6" gutterBottom>Activity Dates</Typography>
                    <DatePicker
                        label="Begin Date"
                        value={activity.begin_date}
                        onChange={(date) => handleDateChange('begin_date', date)}
                        renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                    />
                    <DatePicker
                        label="End Date"
                        value={activity.end_date}
                        onChange={(date) => handleDateChange('end_date', date)}
                        renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                    />

                    <FormControlLabel
                        control={<Switch checked={allDay} onChange={handleAllDayToggle}/>}
                        label="All Day Activity"
                    />

                    {!allDay && (
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TimePicker
                                    label="Begin Time"
                                    value={activity.begin_time}
                                    onChange={(time) => handleTimeChange('begin_time', time)}
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    label="End Time"
                                    value={activity.end_time}
                                    onChange={(time) => handleTimeChange('end_time', time)}
                                    renderInput={(params) => <TextField {...params} fullWidth margin="normal"/>}
                                />
                            </Grid>
                        </Grid>
                    )}

                    {/* Attendee Section */}
                    <Typography variant="h6" gutterBottom>Attendees</Typography>
                    <FormControlLabel
                        control={<Checkbox checked={useMasterAttendees}
                                           onChange={() => setUseMasterAttendees(!useMasterAttendees)}/>}
                        label="Use Master Attendee List"
                    />
                    <Autocomplete
                        multiple
                        options={useMasterAttendees ? Attendees : Planning['attendees']}
                        getOptionLabel={(option) => `${option.fname} ${option.lname}`}
                        value={selectedAttendees}
                        onChange={(event, newValue) => setSelectedAttendees(newValue)}
                        renderInput={(params) => <TextField {...params} label="Select Attendees" margin="normal"
                                                            fullWidth/>}
                    />

                    {/* Notes Section */}
                    <Typography variant="h6" gutterBottom>Notes</Typography>
                    <TextField
                        label="Notes"
                        value={activity.notes}
                        onChange={(e) => setActivity({...activity, notes: e.target.value})}
                        multiline
                        rows={4}
                        fullWidth
                        margin="normal"
                    />

                    {/* Location Section */}
                    <Typography variant="h6" gutterBottom>Location</Typography>
                    <RadioGroup value={locationMode} onChange={handleLocationModeChange}>
                        <FormControlLabel value="near_me" control={<Radio/>} label="Near Me (Use my location)"/>
                        <FormControlLabel value="find_location" control={<Radio/>} label="Find Location"/>
                        <FormControlLabel value="enter_address" control={<Radio/>} label="Enter Address"/>
                    </RadioGroup>

                    {/* Conditionally render location input fields */}
                    {locationMode === "find_location" && <TextField label="Search Location" fullWidth margin="normal"/>}
                    {locationMode === "enter_address" && (
                        <>
                            <TextField label="Address" fullWidth margin="normal"/>
                            <TextField label="City" fullWidth margin="normal"/>
                            <TextField label="State" fullWidth margin="normal"/>
                            <TextField label="Zip" fullWidth margin="normal"/>
                        </>
                    )}

                </Box>
            </div>
        </div>
    )
}


export default ActivitiesAdd;
