export const cancelChipStyle = {
    boxShadow: '' +
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
        '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
        '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #ff0000',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#ffd6d6', // Reverse the gradient on hover
    },
};

export const cancelGradientStyle = {
    marginLeft: '10px',
    display: 'inline-block', // Ensure the gradient wraps tightly around the Chip
    padding: '2px', // Space for the gradient border effect
    borderRadius: '10px', // Border radius for the outer container (gradient)
    background: 'linear-gradient(45deg, #ff0000, #fff)', // Gradient for the border
    textAlign: 'center', // Center the text inside
};

export const cancelChipStyle1 = {
    backgroundColor: '#fff', // Inner chip background (white)
    borderRadius: '8px', // Smaller border radius for the chip itself
    fontWeight: '700', // Make the text bold
    '&:hover': {
        background: 'linear-gradient(45deg, #ff0000, #fff)', // Reverse the gradient on hover
    },
};
export const cancelGradientStyleOriginal = {
    boxShadow: 2,
    borderRadius: 2,
    textAlign: 'center',
    fontWeight: '700',
    background: 'linear-gradient(195deg, #FFFFFF, #FF0000)',
    border: 0, // Remove border (optional)
    color: 'black', // Text color (adjust as needed)
};
