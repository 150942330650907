export const addWaitChipStyle = {
    boxShadow: '' +
        '0px 3px 1px -2px rgba(0, 0, 0, 0.2), ' +
        '0px 2px 2px 0px rgba(0, 0, 0, 0.14), ' +
        '0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
    border: '1px solid #c5c5c5',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: '700',
    backgroundColor: '#FFF',
    color: 'black', // Text color (adjust as needed)
    '&:hover': {
        background: '#ffd6d6', // Reverse the gradient on hover
    },
};

export const addWaitGradientStyle = {
    marginLeft: '10px',
    boxShadow: 2,
    borderRadius: 2,
    textAlign: 'center',
    fontWeight: '700',
    background: 'linear-gradient(195deg, #e8f2ed, #c5c5c5)',
    border: 0, // Remove border (optional)
    color: 'black', // Text color (adjust as needed)
};
