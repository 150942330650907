import React, {useContext, useEffect, useRef, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {PAContext} from "../../Services/PAContext";

import dayjs from "dayjs";
import {CardMedia, Checkbox, Chip, InputBase, ListItem, Menu, MenuItem, MenuList, Paper} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import {cancelChipStyle} from "../../components/ButtonStyle/Cancel";
import Rating from '@mui/material/Rating';
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import _ from "lodash";
import './planningPageStyle.css';
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Idea from "../../MasterEventObjects/Idea";
import Meal from "../../MasterEventObjects/Meal";
import PALocation from "../../MasterEventObjects/Location";
import Lodging from "../../MasterEventObjects/Lodging";
import {updateChipStyle} from "../../components/ButtonStyle/Update";
import Activity from "../../MasterEventObjects/Activity";
import Box from "@mui/material/Box";


let openInfoWindow = null;
let _global_map_service = null;


function GoogleMap(props) {

    const navigate = useNavigate();

    const inputRefEstablishment = useRef(null);
    const inputRefAddress = useRef(null);


    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorLayersEl, setAnchorLayersEl] = useState(null);

    const { dataArray, setPlanningLodging, setPlanningActivities, setPlanningMeals } = useContext(PAContext);
    const { Planning, ConciergeMapActions, SuggestionsLodging } = dataArray;

    const [zoomInOnLocation, setZoomInOnLocation] = useState(props.zoomInOnLocation);
    const [searchingForLocation, setSearchingForLocation] = useState(props.searchingForLocation);
    const [suggestionForLocation, setSuggestionForLocation] = useState("");

    const [eventActivityMarkers, setEventActivityMarkers] = useState([]);
    const [viewEventActivityMarkers, setViewEventActivityMarkers] = useState(true);

    const [eventLodgingMarkers, setEventLodgingMarkers] = useState([]);
    const [viewEventLodgingMarkers, setViewEventLodgingMarkers] = useState(true);

    const [eventIdeaMarkers, setEventIdeaMarkers] = useState([]);
    const [viewEventIdeaMarkers, setViewEventIdeaMarkers] = useState(true);

    const [eventMealMarkers, setEventMealMarkers] = useState([]);
    const [viewEventMealMarkers, setViewEventMealMarkers] = useState(true);

    const [eventFlightMarkers, setEventFlightMarkers] = useState([]);
    const [viewEventFlightMarkers, setViewEventFlightMarkers] = useState(true);

    const [suggestionLodgingMarkers, setSuggestionLodgingMarkers] = useState([]);
    const [viewSuggestionLodgingMarkers, setViewSuggestionLodgingMarkers] = useState(props.viewSuggestionLodgingMarkers);
    const [viewSuggestionLodgingMarkersFilter, setViewSuggestionLodgingMarkersFilter] = useState(props.viewSuggestionLodgingMarkersFilter);

    const [mapSearchType, setMapSearchType] = useState("establishment");
    const [mapSearchText, setMapSearchText] = useState("");
    const [mapSearchTextResultCount, setMapSearchTextResultCount] = useState(0);

    const [viewPlaceInfoPlace, setViewPlaceInfoPlace] = useState(null);
    const [viewPlaceInfoWindow, setViewPlaceInfoWindow] = useState(false);

    const [mapMarkerLayers, setMapMarkerLayers] = useState([]);
    const [mapMarkerLayersCount, setMapMarkerLayersCount] = useState(0);


    const _default_map_options = {
        mapId: "6c79697cd5555167",
        center: {
            lat: 37.422222,
            lng: -122.083333,
        },
        zoom: 10,
        mapTypeControl: false,
        fullscreenControl: false
    }


    useEffect(() => {
        console.log("props._global_map_ref has been set! "+props._global_map_ref);
        console.log("props._global_map_ref.current : "+props._global_map_ref?.current);

        if (props._global_map_ref) {

            if (!window.google || !window.google.maps) {
                console.error("Google Maps API is not available.");
                return;
            }

            let _planningExists = false;
            if (Planning && Planning.location){
                _planningExists = true;
                props._global_map_ref.current = new window.google.maps.Map(
                    document.getElementById("global_google_map_holder"),
                    {
                        mapId: "6c79697cd5555167",
                        center: {
                            lat: Planning.location.latitude,
                            lng: Planning.location.longitude,
                        },
                        zoom: 10,
                        mapTypeControl: false,
                        fullscreenControl: false
                    }
                );
            } else {
                props._global_map_ref.current = new window.google.maps.Map(
                    document.getElementById("global_google_map_holder"),
                    _default_map_options
                );

            }

            // Check if _global_map_ref.current is a valid Google Map instance
            if (props._global_map_ref.current && typeof props._global_map_ref.current.getCenter === 'function') {
                console.log("This is a Google Map instance.");
                console.log("Map Center: ", props._global_map_ref.current.getCenter());
                console.log("Map Zoom: ", props._global_map_ref.current.getZoom());
            } else {
                console.error("props._global_map_ref.current is not a valid Google Map instance.");
            }

            if (props._global_map_ref?.current) {

                window.google.maps.event.addListenerOnce(props._global_map_ref.current, "idle", function() {
                    // Map has finished loading and is now idle
                    // Perform any desired actions here
                    console.log("-------------------- props._global_map_ref.current loaded");

                    console.log("##### props._global_map_ref.current : "+props._global_map_ref.current);

                    if (props._global_map_ref?.current) {
                        props._global_map_ref.current.addListener('click', (event) => {
                            console.log("-------------------- props._global_map_ref clickeddddddd");
                        });

                        const ClickEventHandlerGlobalMap = function (map, origin) {
                            this.origin = origin;
                            this.map = map;
                            this.placesService = new window.google.maps.places.PlacesService(map);
                            this.infowindow = new window.google.maps.InfoWindow();

                            // Listen for clicks on the map.
                            this.map.addListener('click', this.handleClickGlobalMap.bind(this));
                        };

                        ClickEventHandlerGlobalMap.prototype.handleClickGlobalMap = function (place) {
                            console.log("handleClickGlobalMap");
                            console.log('You clicked on: ' + JSON.stringify(place));
                            console.log('You clicked on: ' + place.latLng);

                            // If the place has a placeId, use it.
                            if (place.placeId) {
                                console.log('You clicked on place:' + place.placeId);

                                // Calling e.stop() on the place prplaces the default info window from
                                // showing.
                                // If you call stop here when there is no placeId you will prplace some
                                // other map click place handlers from receiving the place.
                                place.stop();
                                this.getPlaceInformationGlobalMap(place.placeId);

                            } else {
                                //get place details using coordinates
                                console.log('You clicked on residence');
                                this.getResidenceInformationGlobalMap(place.latLng);
                            }
                        };

                        ClickEventHandlerGlobalMap.prototype.getPlaceInformationGlobalMap = function (_google_business_id) {

                            const me = this;

                            this.placesService.getDetails({placeId: _google_business_id}, function (place, status) {

                                if (status === 'OK') {

                                    setViewPlaceInfoPlace(place);

                                    console.log("the international phone number : " + place.international_phone_number);

                                    console.log("the website url : " + place.website);

                                    let _address_line = "";
                                    let _address_city = "";
                                    let _address_state = "";
                                    let _address_zip = "";
                                    let _address_country = "";


                                    let _route_exists = false;
                                    let _country_code = "";
                                    for (const component of place.address_components) {
                                        if (component.types.includes("route")) {
                                            _route_exists = true;
                                        } else if (component.types.includes("country")) {
                                            _country_code = component.short_name;
                                        }
                                    }

                                    place.address_components.forEach((component) => {
                                        if (component.types.includes("street_number")) {
                                            _address_line = component.long_name;
                                        } else if (component.types.includes("route")) {
                                            _address_line += " " + component.long_name;
                                        } else if (!_route_exists && component.types.includes("administrative_area_level_2")) {
                                            _address_line += " " + component.long_name;
                                        } else if (component.types.includes("locality") || component.types.includes("postal_town")) {
                                            _address_city = component.long_name;
                                        } else if (component.types.includes("administrative_area_level_1") && _country_code === "US") {
                                            _address_state = component.short_name;
                                        } else if (component.types.includes("postal_code")) {
                                            _address_zip = component.long_name;
                                        } else if (component.types.includes("country")) {
                                            if (_country_code !== "US") {
                                                _address_state = component.long_name;
                                            }
                                            _address_country = component.short_name;
                                        }
                                    });

                                    if (_address_line === "") {
                                        //try getting it from the formatted address
                                        try {
                                            const _f_address_pieces = place.formatted_address.split(',');
                                            if (_f_address_pieces[0] !== "") {
                                                _address_line = _f_address_pieces[0].trim();
                                            }
                                        } catch (e) {
                                        }
                                    }


                                    console.log("the _address_line : " + _address_line);
                                    console.log("the _address_city : " + _address_city);
                                    console.log("the _address_state : " + _address_state);
                                    console.log("the _address_zip: " + _address_zip);
                                    console.log("the _address_country: " + _address_country);

                                    setViewPlaceInfoWindow(true);

                                } else {
                                    //no results for this PlaceId to use


                                }
                            });
                        };

                        ClickEventHandlerGlobalMap.prototype.getResidenceInformationGlobalMap = function (_latLng) {

                            //get the entire details about the location
                            const me = this;


                            const geocoder = new window.google.maps.Geocoder();
                            geocoder.geocode(
                                {
                                    'latLng': _latLng
                                },

                                function (place, status) {

                                    console.log("status 1: " + status)
                                    //            console.log("The returned place : " + JSON.stringify(place, null, '\t'));

                                    if (status === 'OK' && place.length > 0) {

                                        console.log("The place[0] : " + JSON.stringify(place[0], null, '\t'));

                                        setViewPlaceInfoPlace(place[0]);

                                        //the coordinates
                                        const _geometry = place[0].geometry;
                                        console.log("_geometry : " + JSON.stringify(_geometry, null, '\t'));
                                        console.log("latitude : " + _geometry.location.lat());
                                        console.log("longitude : " + _geometry.location.lng());

                                        //just look at the first address

                                        let _address_line = "";
                                        let _address_city = "";
                                        let _address_state = "";
                                        let _address_zip = "";
                                        let _address_country = "";

                                        console.log("_address_line: " + _address_line);
                                        console.log("_address_city: " + _address_city);
                                        console.log("_address_state: " + _address_state);
                                        console.log("_address_zip: " + _address_zip);

                                        let _route_exists = false;
                                        let _country_code = "";
                                        for (const component of place[0].address_components) {
                                            if (component.types.includes("route")) {
                                                _route_exists = true;
                                            } else if (component.types.includes("country")) {
                                                _country_code = component.short_name;
                                            }
                                        }

                                        place[0].address_components.forEach((component) => {
                                            if (component.types.includes("street_number")) {
                                                _address_line = component.long_name;
                                            } else if (component.types.includes("route")) {
                                                _address_line += " " + component.long_name;
                                            } else if (!_route_exists && component.types.includes("administrative_area_level_2")) {
                                                _address_line += " " + component.long_name;
                                            } else if (component.types.includes("locality") || component.types.includes("postal_town")) {
                                                _address_city = component.long_name;
                                            } else if (component.types.includes("administrative_area_level_1") && _country_code === "US") {
                                                _address_state = component.short_name;
                                            } else if (component.types.includes("postal_code")) {
                                                _address_zip = component.long_name;
                                            } else if (component.types.includes("country")) {
                                                if (_country_code !== "US") {
                                                    _address_state = component.long_name;
                                                }
                                                _address_country = component.short_name;
                                            }
                                        });

                                        if (_address_line === "") {
                                            //try getting it from the formatted address
                                            try {
                                                const _f_address_pieces = place[0].formatted_address.split(',');
                                                if (_f_address_pieces[0] !== "") {
                                                    _address_line = _f_address_pieces[0].trim();
                                                }
                                            } catch (e) {
                                            }
                                        }


                                        //now show the content window
//                            me.infowindow.close();
//                            me.infowindow.setPosition(_geometry.location);

                                        //add the info window
//                            me.infowindow.setContent(
//                                `<div class="planning-google-map-marker-info-window-content">
//                                    <h3>${place[0].name === undefined ? '' : place[0].name}</h3>
//                                    <h3>${place[0].formatted_address}</h3>
//                                    </div>`
//                            );
//                            me.infowindow.open(me.map);
//                            openInfoWindow = me.infowindow;

                                        setViewPlaceInfoWindow(true);

                                    }
                                }
                            );


                        };

                        ClickEventHandlerGlobalMap.prototype.showCustomInfoWindow = function (
                            latLng,
                            offsetX,
                            offsetY,
                            content
                        ) {
                            console.log("showCustomInfoWindow");
                            // Calculate the position to display the InfoWindow.
                            const projection = this.map.getProjection();
                            const point = projection.fromLatLngToPoint(latLng);
                            const offsetPoint = new window.google.maps.Point(point.x + offsetX, point.y + offsetY);
                            const offsetLatLng = projection.fromPointToLatLng(offsetPoint);

                            // Create and open the custom InfoWindow.
                            this.infowindow.setContent(content);
                            this.infowindow.setPosition(offsetLatLng);
                            this.infowindow.open(this.map);

                            // Optionally, you can close the InfoWindow on map click or other events.
                            this.map.addListener('click', () => {
                                console.log("clicked!!");
                                this.infowindow.close();
                            });
                        };

                        new ClickEventHandlerGlobalMap(props._global_map_ref.current, !_planningExists ? _default_map_options : {
                            mapId: "6c79697cd5555167",
                            center: {
                                lat: Planning.location.latitude,
                                lng: Planning.location.longitude,
                            },
                            zoom: 10
                        });

                        _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);

                        const autocomplete = new window.google.maps.places.Autocomplete(inputRefAddress.current);
                        autocomplete.addListener('place_changed', () => {
                            const place = autocomplete.getPlace();
                            setViewPlaceInfoPlace(place);
                            console.log(JSON.stringify(place, null, '\t'));
                            console.log(place.formatted_address);

                            const _geometry = place.geometry;

                            let _address_line = "";
                            let _address_city = "";
                            let _address_state = "";
                            let _address_zip = "";
                            let _address_country = "";

                            let _route_exists = false;
                            let _country_code = "";
                            for (const component of place.address_components) {
                                if (component.types.includes("route")) {
                                    _route_exists = true;
                                } else if (component.types.includes("country")) {
                                    _country_code = component.short_name;
                                }
                            }

                            place.address_components.forEach((component) => {
                                if (component.types.includes("street_number")) {
                                    _address_line = component.long_name;
                                } else if (component.types.includes("route")) {
                                    _address_line += " " + component.long_name;
                                } else if (!_route_exists && component.types.includes("administrative_area_level_2")) {
                                    _address_line += " " + component.long_name;
                                } else if (component.types.includes("locality") || component.types.includes("postal_town")) {
                                    _address_city = component.long_name;
                                } else if (component.types.includes("administrative_area_level_1") && _country_code === "US") {
                                    _address_state = component.short_name;
                                } else if (component.types.includes("postal_code")) {
                                    _address_zip = component.long_name;
                                } else if (component.types.includes("country")) {
                                    if (_country_code !== "US") {
                                        _address_state = component.long_name;
                                    }
                                    _address_country = component.short_name;
                                }
                            });

                            if (_address_line === "") {
                                //try getting it from the formatted address
                                try {
                                    const _f_address_pieces = place.formatted_address.split(',');
                                    if (_f_address_pieces[0] !== "") {
                                        _address_line = _f_address_pieces[0].trim();
                                    }
                                } catch (e) {
                                }
                            }

                            console.log("the _address_line : " + _address_line);
                            console.log("the _address_city : " + _address_city);
                            console.log("the _address_state : " + _address_state);
                            console.log("the _address_zip: " + _address_zip);
                            console.log("the _address_country: " + _address_country);

                            //put a marker at the address
                            //add the marker to the layers
                            const markerContentDiv = document.createElement('div');
                            markerContentDiv.innerHTML = `
                  <div class="planning-google-map-marker-layer-item">
                    <i class="planning-google-map-marker-layer-item-icon icon-home3" />
                    <span class="planning-google-map-marker-layer-item-name">
                       ${place.name}
                    </span>
                    
                  </div>
                `;

                            const _markerData = {};
                            _markerData.title = place.name;
                            _markerData.google_place_id = place.place_id;

                            const _marker = new window.google.maps.marker.AdvancedMarkerView({
                                map: props._global_map_ref.current,
                                position: _geometry.location,
                                content: markerContentDiv,
                                title: place.name,
                            });
                            _marker.addListener("click", () => {
                                console.log("!!!! clicked on " + _marker.title);
                                props._global_map_ref.current.setZoom(15);
                                props._global_map_ref.current.setCenter(_marker.position);
                                setViewPlaceInfoWindow(true);
                            });
                            _markerData.marker = _marker;

                            addMarkerToLayer("Residences", _markerData);

                            props._global_map_ref.current.setCenter(_geometry.location);

                        });
                    }
                });

            } else {
                console.error("props._global_map_ref.current is null");
            }

        }
        
    }, [props._global_map_ref]);

    useEffect(() => {
        console.log("props.searchingForLocation : {"+props.searchingForLocation+"}");

        if (Object.keys(props.searchingForLocation)?.length > 0){
            //do the search
            const request = {
                query: props.searchingForLocation,
            };

            console.log("props.searchingForLocation request : "+JSON.stringify(request));

            const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
            _global_map_service.textSearch(request, (results, status) => {

                console.log("textSearch status : "+status);

                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    // Handle the search results (e.g. display them on a map or list)

                    console.log("number of results : "+results.length);

                    for (const _item of results) {

                        console.log("The returned place : " + JSON.stringify(_item, null, '\t'));
                        const _geometry = _item.geometry;

                        const icon_for_event_activity = document.createElement("div");
                        icon_for_event_activity.innerHTML = '<i class="icon-list-heart google-maps-advanced-marker-glyph-icon"></i>';

                        const pinViewCustomIdea = new window.google.maps.marker.PinView({
                            background: "#f09637",
                            borderColor: "#428ce2",
                            glyphColor: "#FFFFFF",
                            glyph: icon_for_event_activity,
                        });

                        new window.google.maps.marker.AdvancedMarkerView({
                            map: props._global_map_ref.current,
                            position: _geometry.location,
                            content: pinViewCustomIdea.element,
                            title: _item.name,
                        });

                        props._global_map_ref.current.setCenter(_geometry.location);

                    }

//                adjustGlobalMapMarkersBoundsZoom();

                } else {
                    console.log("no results for ")
                }
            });

        }


    }, [props.searchingForLocation]);

    useEffect(() => {
        console.log("Move to area : "+JSON.stringify(props.zoomInOnLocation, null, '\t'));

        console.log("latitude : "+props.zoomInOnLocation['latitude']);
        console.log("longitude : "+props.zoomInOnLocation['longitude']);

        if (Object.keys(props.zoomInOnLocation).length !== 0){
            try {

                props._global_map_ref.current.setCenter(
                    new window.google.maps.LatLng(props.zoomInOnLocation['latitude'], props.zoomInOnLocation['longitude'])
                );
                setZoomInOnLocation(new window.google.maps.LatLng(props.zoomInOnLocation['latitude'], props.zoomInOnLocation['longitude']));

                //add a Marker at the location

                new window.google.maps.marker.AdvancedMarkerView({
                    map: props._global_map_ref.current,
                    position: new window.google.maps.LatLng(props.zoomInOnLocation['latitude'], props.zoomInOnLocation['longitude']),
                });

            } catch (e){
                console.log("Move to error : "+e);
            }
        }

    }, [props.zoomInOnLocation]);

    useEffect(() => {
        console.log("Another ConciergeMapActions was triggered : "+ConciergeMapActions.length);

        for (let i = 0; i < ConciergeMapActions.length; i++) {
            const mapAction = ConciergeMapActions[i];

            console.log(JSON.stringify(mapAction, null, '\t'));

            if (mapAction.action === "planningConciergeMapSuggestionChangeResults"){

                if (mapAction.suggestion === "Outdoor Activities"){
                    for (const _item of mapAction.response_message_array){
                        console.log("the Outdoor Idea item : "+_item.business_name);
                        //put a pin on the map and number it

                        const request = {
                            query: _item.business_name +" located in "+props.searchingForLocation,
                            location: props._global_map_ref.current.getCenter(),
                            radius: 1500,
                            types: _item.activity
                        };

                        console.log("search request : "+JSON.stringify(request));

                        const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                        _global_map_service.textSearch(request, (results, status) => {

                            console.log("textSearch status : "+status);

                            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                                // Handle the search results (e.g. display them on a map or list)

                                const icon_for_event_activity = document.createElement("div");
                                icon_for_event_activity.innerHTML = '<i class="icon-list-heart google-maps-advanced-marker-glyph-icon"></i>';

                                const pinViewCustomIdea = new window.google.maps.marker.PinView({
                                    background: "#f09637",
                                    borderColor: "#428ce2",
                                    glyphColor: "#FFFFFF",
                                    glyph: icon_for_event_activity,
                                    scale: 1.5
                                });

                                for (const _item of results) {

                                    console.log("The returned place : " + JSON.stringify(_item, null, '\t'));
                                    const _geometry = _item.geometry;

                                    new window.google.maps.marker.AdvancedMarkerView({
                                        map: props._global_map_ref.current,
                                        position: _geometry.location,
                                        content: pinViewCustomIdea.element,
                                        title: _item.name,
                                    });

                                }

                                //rezoom the map
//                                adjustGlobalMapMarkersBoundsZoom();

                            } else {
                                console.log("no results for ")
                            }
                        });
                    }
                }

            }

            //remove the one item we just processed
            ConciergeMapActions.splice(i, 1);

        }

    }, [ConciergeMapActions]);

    useEffect(() => {
        // Create markers for each lodging when the component mounts

        if (Planning.lodging){
            eventLodgingMarkers.forEach((marker) => {
                marker.map = null; // Hide the marker on the map
            });

            // Clear the markers from the array
            setEventLodgingMarkers([]);

            const _shade_of_black = getRandomShadeOfBlack();

            const markers = Planning.lodging.map((_item) => {
//                console.log("Marker for lodging : "+JSON.stringify(_item, null, '\t'));
                const _markerData = {};
                _markerData.title = _item['name'];
                _markerData.google_place_id = _item['location'].google_place_id;

                const markerContent = document.createElement("div");
                markerContent.className = "search-results planning-google-map-marker-search-results-item";
                markerContent.style.backgroundColor = _shade_of_black
                markerContent.style.color = "white";
                markerContent.innerHTML = `<i class="planning-google-map-marker-layer-item-icon icon-bed" />`;

                const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                    map: props._global_map_ref.current,
                    position: {
                        lat:
                        _item['location'].latitude,
                        lng:
                        _item['location'].longitude,
                    },
                    content: markerContent,
                    title: _item['name'],
                });

                _marker.addListener("click", () => {
                    console.log("!!!! clicked on "+_marker.title);
                    props._global_map_ref.current.setZoom(15);
                    props._global_map_ref.current.setCenter(_marker.position);

                    //get info about the google place and then show in the info
                    const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                    _global_map_service.getDetails({placeId : _item['location'].google_place_id}, function(place, status) {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {

                            if (status === 'OK') {
                                setViewPlaceInfoPlace(place);
                                setViewPlaceInfoWindow(true);
                            }
                        }
                    });


                    setViewPlaceInfoWindow(true);
                });

                _markerData.marker = _marker;

                addMarkerToLayer("Lodging", _markerData);

                return _marker;
            });

            console.log("Lodging markers : "+markers);
            console.log("------------------------------");

            // Store the markers in the state variable
            setEventLodgingMarkers(markers);
        }

    }, [Planning.lodging]);

    useEffect(() => {
        // Create markers for each Activity when the component mounts

        if (Planning.activities){
            eventActivityMarkers.forEach((marker) => {
                marker.map = null; // Hide the marker on the map
            });

            // Clear the markers from the array
            setEventActivityMarkers([]);

            const _shade_of_black = getRandomShadeOfBlack();

            const markers = Planning.activities.map((_item) => {

                const _markerData = {};
                _markerData.title = _item['title'];
                _markerData.google_place_id = _item['location'].google_place_id;


                const markerContent = document.createElement("div");
                markerContent.className = "search-results planning-google-map-marker-search-results-item";
                markerContent.style.backgroundColor = _shade_of_black
                markerContent.style.color = "white";
                markerContent.innerHTML = `<i class="planning-google-map-marker-layer-item-icon icon-list-heart" />`;

                const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                    map: props._global_map_ref.current,
                    position: {
                        lat:
                        _item['location'].latitude,
                        lng:
                        _item['location'].longitude,
                    },
                    content: markerContent,
                    title: _item['title'],
                });

                _marker.addListener("click", () => {
                    console.log("!!!! clicked on Activity: "+JSON.stringify(_item, null, '\t'));
                    props._global_map_ref.current.setZoom(15);
                    props._global_map_ref.current.setCenter(_marker.position);

                    //get info about the google place and then show in the info
                    const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                    _global_map_service.getDetails({placeId : _item['location'].google_place_id}, function(place, status) {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            console.log("The returned place : " + JSON.stringify(place, null, '\t'));

                            if (status === 'OK') {
                                setViewPlaceInfoPlace(place);
                                setViewPlaceInfoWindow(true);
                            }
                        }
                    });

                    setViewPlaceInfoWindow(true);
                });

                _markerData.marker = _marker;

                addMarkerToLayer("Activities", _markerData);

                return _marker;
            });

            console.log("Activity markers : "+markers);
            console.log("------------------------------");

            // Store the markers in the state variable
            setEventActivityMarkers(markers);
        }

    }, [Planning.activities]);

    useEffect(() => {
        // Create markers for each Meal when the component mounts

        if (Planning.meals){
            eventMealMarkers.forEach((marker) => {
                marker.map = null; // Hide the marker on the map
            });

            // Clear the markers from the array
            setEventMealMarkers([]);

            const _shade_of_black = getRandomShadeOfBlack();

            const markers = Planning.meals.map((_item) => {
                const _markerData = {};
                _markerData.title = _item['location'].name;
                _markerData.google_place_id = _item['location'].google_place_id;


                const markerContent = document.createElement("div");
                markerContent.className = "search-results planning-google-map-marker-search-results-item";
                markerContent.style.backgroundColor = _shade_of_black;
                markerContent.style.color = "white";
                markerContent.innerHTML = `<i class="planning-google-map-marker-layer-item-icon icon-fork-knife2" />`;

                const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                    map: props._global_map_ref.current,
                    position: {
                        lat:
                        _item['location'].latitude,
                        lng:
                        _item['location'].longitude,
                    },
                    content: markerContent,
                    title: _item['location'].name,
                });

                _marker.addListener("click", () => {
                    console.log("!!!! clicked on "+_marker.title);
                    props._global_map_ref.current.setZoom(15);
                    props._global_map_ref.current.setCenter(_marker.position);

                    //get info about the google place and then show in the info
                    const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                    _global_map_service.getDetails({placeId : _item['location'].google_place_id}, function(place, status) {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            console.log("The returned place : " + JSON.stringify(place, null, '\t'));

                            if (status === 'OK') {
                                setViewPlaceInfoPlace(place);
                                setViewPlaceInfoWindow(true);
                            }
                        }
                    });


                    setViewPlaceInfoWindow(true);
                });

                _markerData.marker = _marker;

                addMarkerToLayer("Meals", _markerData);

                return _marker;
            });

            console.log("Meal markers : "+markers);
            console.log("------------------------------");

            // Store the markers in the state variable
            setEventMealMarkers(markers);
        }

    }, [Planning.meals]);

    useEffect(() => {
        // Create markers for each Idea when the component mounts

        if (Planning.ideas){
            eventIdeaMarkers.forEach((marker) => {
                marker.map = null; // Hide the marker on the map
            });

            // Clear the markers from the array
            setEventIdeaMarkers([]);

            const _shade_of_black = getRandomShadeOfBlack();

            const markers = Planning.ideas.map((_item) => {

                const _markerData = {};
                _markerData.title = _item['title'];
                _markerData.google_place_id = _item['location'].google_place_id;


                const markerContent = document.createElement("div");
                markerContent.className = "search-results planning-google-map-marker-search-results-item";
                markerContent.style.backgroundColor = _shade_of_black
                markerContent.style.color = "white";
                markerContent.innerHTML = `<i class="planning-google-map-marker-layer-item-icon icon-list-heart" />`;

                const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                    map: props._global_map_ref.current,
                    position: {
                        lat:
                        _item['location'].latitude,
                        lng:
                        _item['location'].longitude,
                    },
                    content: markerContent,
                    title: _item['title'],
                });

                _marker.addListener("click", () => {
                    console.log("!!!! clicked on Idea: "+JSON.stringify(_item, null, '\t'));
                    props._global_map_ref.current.setZoom(15);
                    props._global_map_ref.current.setCenter(_marker.position);

                    //get info about the google place and then show in the info
                    const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                    _global_map_service.getDetails({placeId : _item['location'].google_place_id}, function(place, status) {
                        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                            console.log("The returned place : " + JSON.stringify(place, null, '\t'));

                            if (status === 'OK') {
                                setViewPlaceInfoPlace(place);
                                setViewPlaceInfoWindow(true);
                            }
                        }
                    });

                    setViewPlaceInfoWindow(true);
                });

                _markerData.marker = _marker;

                addMarkerToLayer("Ideas", _markerData);

                return _marker;
            });

            console.log("Idea markers : "+markers);
            console.log("------------------------------");

            // Store the markers in the state variable
            setEventIdeaMarkers(markers);
        }

    }, [Planning.ideas]);

    useEffect(() => {
        // Create markers for each lodging when the component mounts

        suggestionLodgingMarkers.forEach((marker) => {
            marker.map = null; // Hide the marker on the map
        });

        // Clear the markers from the array
        setSuggestionLodgingMarkers([]);


        const markers = SuggestionsLodging.map((_item) => {

            const _item_check_in_date = dayjs(_item.check_in_date);
            const _item_check_out_date = dayjs(_item.check_out_date);
            const _item_num_nights = _item_check_out_date.diff(
                _item_check_in_date,
                'days'
            );

            const _item_price = _item['accommodation'].cheapest_rate_total_amount;
            const _item_per_night_rate = _item_price / _item_num_nights;

            const priceTag = document.createElement("div");
            try {
                const _star_rating = _item['accommodation']['ratings'][0].value;

                //set the background color of the marker
                switch (_star_rating) {
                    case 1:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-one-star";
                        break;
                    case 2:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-two-star";
                        break;
                    case 3:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-three-star";
                        break;
                    case 4:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-four-star";
                        break;
                    case 5:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-five-star";
                        break;
                    default:
                        priceTag.className = "price-tag planning-google-map-marker-pricing-one-star";
                }

                priceTag.textContent = _item_per_night_rate.toFixed(0) + " "+ _item['accommodation'].cheapest_rate_currency;

            } catch (e){
                //no rating so just default green
                priceTag.className = "price-tag planning-google-map-marker-pricing-one-star";
                priceTag.textContent = _item_per_night_rate.toFixed(0) + " "+ _item['accommodation'].cheapest_rate_currency;
            }

            const _marker =  new window.google.maps.marker.AdvancedMarkerView({
                map: null,
                position: {
                    lat:
                    _item['accommodation']['location']['geographic_coordinates']
                        .latitude,
                    lng:
                    _item['accommodation']['location']['geographic_coordinates']
                        .longitude,
                },
                content: priceTag,
                title: _item['accommodation'].name,
            });

            const infoWindow = new window.google.maps.InfoWindow({
                content: `<div class="planning-google-map-marker-info-window-content">
                              <h3>${_item['accommodation'].name}</h3>
                              <p>Price per night: $${_item_per_night_rate.toFixed(0)}</p>
                          </div>`
            });

            _marker.addListener("click", ({ domEvent, latLng }) => {
                console.log("!!!! clicked on "+_item['id']);
                const { target } = domEvent;
                console.log("lodging name : "+_item['id']);
                props.setLodgingSuggestionNameFromMap(_item['id']);
                if (!props.showEventItemConfig){
                    //if it's not showing then show it
                    props.setShowEventItemConfig(true);
                    navigate('/Planner/Lodging/Suggestions');
                }

                /*
                if (openInfoWindow != null) {
                    openInfoWindow.close();
                }
                infoWindow.open(_marker.map, _marker);
                openInfoWindow = infoWindow;
                 */
            });

            return _marker;
        });

        // Store the markers in the state variable
        setSuggestionLodgingMarkers(markers);

    }, [SuggestionsLodging]);

    useEffect(() => {
        // Update marker visibility based on filter criteria

        if (props.viewSuggestionLodgingMarkers) {
            suggestionLodgingMarkers.forEach((marker, index) => {

                const _item = SuggestionsLodging[index];

                const _item_check_in_date = dayjs(_item.check_in_date);
                const _item_check_out_date = dayjs(_item.check_out_date);
                const _item_num_nights = _item_check_out_date.diff(
                    _item_check_in_date,
                    'days'
                );

                const _item_price = _item['accommodation'].cheapest_rate_total_amount;
                const _item_per_night_rate = _item_price / _item_num_nights;

                // Check if the lodging meets the filter criteria
                const meetsFilterCriteria =
                    _item_per_night_rate >= props.viewSuggestionLodgingMarkersFilter[0] &&
                    _item_per_night_rate <= props.viewSuggestionLodgingMarkersFilter[1];

                // Set the marker visibility
                if (meetsFilterCriteria) {
                    marker.map = props._global_map_ref.current; // Show the marker
                } else {
                    marker.map = null; // Hide the marker
                }
            });
        } else {
            //turn them all off
            suggestionLodgingMarkers.forEach((marker, index) => {
                marker.map = null; // Hide the marker
            });
        }
    }, [props.viewSuggestionLodgingMarkers, props.viewSuggestionLodgingMarkersFilter]);

    useEffect(() => {
        //update the tour cities layer


    }, [props.tourSuggestionsTourCities]);

    useEffect(() => {
        console.log("setting the count to : "+mapMarkerLayers.length);
        setMapMarkerLayersCount(mapMarkerLayers.length);
    }, [mapMarkerLayers]);


    const handleSearchTypeChange = (type) => {
        setMapSearchType(type);
        setMapSearchText(''); // Clear the input when switching search types
    };
    const handleMapSearchInput = (event) => {
        const searchText = event.target.value;
        setMapSearchText(searchText);
    };

    function handleMapSearchOK() {
        // Handle searching based on searchType ('address' or 'business')
        if (mapSearchType === 'establishment') {
            // Perform a Google search for businesses using searchText

            if (mapSearchText.length > 0){
                //see what AI has to say about search
                //see if there's a location to go along with the query

                const _sendString = {};
                _sendString.action = "planningConciergeServiceMapQuery";
                _sendString.query = mapSearchText;
                _sendString.language = "en";

                dataArray.Websocket.send(JSON.stringify(_sendString) + "|^");


                const request = {
                    query: mapSearchText + (props.searchingForLocation !== "" ? " located in "+props.searchingForLocation : ''),
                    location: props._global_map_ref.current.getCenter(),
                    radius: 5000,
                };

                console.log("search request : "+JSON.stringify(request));

                const _global_map_service = new window.google.maps.places.PlacesService(props._global_map_ref.current);
                _global_map_service.textSearch(request, (results, status) => {

                    console.log("textSearch status : "+status);

                    if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                        // Handle the search results (e.g. display them on a map or list)

                        console.log("number of results : "+results.length);
                        setMapSearchTextResultCount(results.length);

                        const _shade_of_black = getRandomShadeOfBlack();
                        for (let _index = 0; _index < results.length; _index++) {

                            console.log("The returned place : " + JSON.stringify(results[_index], null, '\t'));
                            const _geometry = results[_index].geometry;

                            const markerContent = document.createElement("div");
                            markerContent.className = "search-results planning-google-map-marker-search-results-item";
                            markerContent.textContent = (+_index + 1).toString();
                            markerContent.style.backgroundColor = _shade_of_black
                            markerContent.style.color = "white";

                            const _item_marker = new window.google.maps.marker.AdvancedMarkerView({
                                map: props._global_map_ref.current,
                                position: _geometry.location,
                                content: markerContent,
                                title: results[_index].name,
                            });
                            _item_marker.addListener('click', ()=>{
                                console.log("!!!! clicked on "+_item_marker.title);

                                setViewPlaceInfoPlace(results[_index]);
                                setViewPlaceInfoWindow(true);
                            });

                            //add this to the layer with the search terms as the category
                            const _markerData = {};
                            _markerData.title = results[_index].name;
                            _markerData.google_place_id = results[_index].place_id;
                            _markerData.marker = _item_marker;

                            addMarkerToLayer(mapSearchText, _markerData);

                            props._global_map_ref.current.setCenter(_geometry.location);
                            props._global_map_ref.current.setZoom(11);

                            if (results.length !== 0 && _index === 0){

                                const _sendLocation = {
                                    'google_place_id': results[_index].place_id,
                                    'latitude': _geometry.location.lat(),
                                    'longitude': _geometry.location.lng()
                                };
                                const currentDate = dayjs();
                                const twoWeeksLater = currentDate.add(14, 'day');
                                const fiveDaysAfter = twoWeeksLater.add(5, 'day');

                                const _sendLodgingString = {};
                                _sendLodgingString.action = "planningConciergeSuggestionServiceLodging";

                                _sendLodgingString.location = _sendLocation;
                                _sendLodgingString.adults = Planning.attendees.length > 0 ? Planning.attendees.length : 2 ;
                                _sendLodgingString.rooms = 1;
                                _sendLodgingString.begin_date = Planning.begin_date && Planning.begin_date !== "" ? Planning.begin_date : twoWeeksLater;
                                _sendLodgingString.end_date = Planning.end_date && Planning.end_date !== "" ? Planning.end_date : fiveDaysAfter;

                                console.log("!!!! Sending out the lodging request : "+JSON.stringify(_sendLodgingString));
                                dataArray.Websocket.send(JSON.stringify(_sendLodgingString) + "|^");

                                const _sendToursString = {};
                                _sendToursString.action = "planningConciergeSuggestionServiceTours";

                                _sendToursString.location = _sendLocation;
                                _sendToursString.city = _sendLocation;
                                _sendToursString.state = _sendLocation;
                                _sendToursString.country = _sendLocation;
                                _sendToursString.begin_date = Planning.begin_date && Planning.begin_date !== "" ? Planning.begin_date : twoWeeksLater;
                                _sendToursString.end_date = Planning.end_date && Planning.end_date !== "" ? Planning.end_date : fiveDaysAfter;

                                console.log("!!!! Sending out the Tours request");
                                dataArray.Websocket.send(JSON.stringify(_sendToursString) + "|^");

                                const _sendUniqueString = {};
                                _sendUniqueString.action = "planningConciergeSuggestionServiceUnique";

                                _sendUniqueString.location = _sendLocation;
                                _sendUniqueString.begin_date = Planning.begin_date && Planning.begin_date !== "" ? Planning.begin_date : twoWeeksLater;
                                _sendUniqueString.end_date = Planning.end_date && Planning.end_date !== "" ? Planning.end_date : fiveDaysAfter;

                                console.log("!!!! Sending out the unique request");
                                dataArray.Websocket.send(JSON.stringify(_sendUniqueString) + "|^");

                            }

                        }


                    } else {
                        console.log("no results for ");
                        setMapSearchTextResultCount(0);
                    }
                });

                //clear the search box
                setMapSearchText("");
            }

        } else {
            console.log("not an establishment");
        }
    }

    function adjustGlobalMapMarkersBoundsZoom(){
        console.log("adjustGlobalMapMarkersBoundsZoom");

        const bounds = new window.google.maps.LatLngBounds();

        //add a little more padding
        const extendAmount = 0.1;
        bounds.extend(new window.google.maps.LatLng(
            bounds.getNorthEast().lat() + extendAmount,
            bounds.getNorthEast().lng() + extendAmount
        ));
        bounds.extend(new window.google.maps.LatLng(
            bounds.getSouthWest().lat() - extendAmount,
            bounds.getSouthWest().lng() - extendAmount
        ));

        // Fit the map to the bounds of the markers
        props._global_map_ref.current.fitBounds(bounds);
    }

    const handleButtonOpenLayersMenuClick = (event) => {
        setAnchorLayersEl(event.currentTarget);
    }
    const handleButtonLayersMenuClose = () => {
        setAnchorLayersEl(null);
    };
    const toggleLayerVisibility = (layerName) => {
        console.log("toggleLayerVisibility : "+layerName);

        setMapMarkerLayers((prevLayers) => {
            const updatedLayers = [...prevLayers];

            const layerIndex = updatedLayers.findIndex((layer) => layer.name === layerName);

            if (layerIndex !== -1) {
                // Toggle the visibility of the layer
                updatedLayers[layerIndex].visible = !updatedLayers[layerIndex].visible;

                // Toggle the map for all markers in the layer
                updatedLayers[layerIndex].markers.forEach((marker) => {
                    if (marker.marker) {
                        const newMap = updatedLayers[layerIndex].visible ? props._global_map_ref.current : null;
                        marker.marker.setMap(newMap);
                    }
                });
            }

            return updatedLayers;
        });
    };
    function layerMarker(layerName){
        console.log("layerMarker : "+layerName);

        let _icon = "icon-bell"
        switch (layerName){
            case "Activities":
                _icon="icon-list-heart";
                break;
            case "Ideas":
                _icon="icon-lamp-bright";
                break;
            case "Meals":
                _icon="icon-fork-knife2";
                break;
            case "Lodging":
                _icon="icon-bed";
                break;
            case "Shopping":
                _icon="icon-bag";
                break;
            case "Planning":
                _icon="icon-sophisticated";
                break;
            case "Residences":
                _icon="icon-home3";
                break;
            case "Tours":
                _icon="icon-lamp-bright";
                break;
            default:


        }


        const markerContentDiv = document.createElement('div');
        markerContentDiv.innerHTML = `
          <div class="planning-google-map-marker-layer-item">
            <i class="planning-google-map-marker-layer-item-icon ${_icon}" />
            <span class="planning-google-map-marker-layer-item-name">
               ${viewPlaceInfoPlace.name}
            </span>
          </div>
        `;

        const _geometry = viewPlaceInfoPlace.geometry;

        const _markerData = {};
        _markerData.title = viewPlaceInfoPlace.name;
        _markerData.google_place_id = viewPlaceInfoPlace.place_id;

        console.log("props._global_map_ref.current : "+props._global_map_ref.current);

        const _marker = new window.google.maps.marker.AdvancedMarkerView({
            map: props._global_map_ref.current,
            position: _geometry.location,
            content: markerContentDiv,
            title: viewPlaceInfoPlace.name,
        });
        _marker.addListener("click", () => {
            console.log("!!!! clicked on "+_marker.title);
            props._global_map_ref.current.setZoom(15);
            props._global_map_ref.current.setCenter(_marker.position);
            setViewPlaceInfoWindow(true);
        });
        _markerData.marker = _marker;

        console.log("returning markerData : "+_markerData);
        return _markerData;

    }

    const addMarkerToLayer = (layerName, markerData) => {
        console.log("addMarkerToLayer : "+layerName + " : "+markerData);

        setMapMarkerLayers((prevLayers) => {
            const updatedLayers = [...prevLayers];

            // Check if the layer already exists
            const existingLayerIndex = updatedLayers.findIndex((layer) => layer.name === layerName);

            if (existingLayerIndex !== -1) {
                // Layer already exists, see if this google_place_id exists before adding
                const layerItemIndex = updatedLayers[existingLayerIndex].markers.findIndex((item) => item.google_place_id === markerData.google_place_id);

                if (layerItemIndex === -1) {
                    //item doesn't exist in this layer so add it
                    updatedLayers[existingLayerIndex].markers.push(markerData);
                }

            } else {
                // Layer doesn't exist, create a new layer with the marker
                const newLayer = {
                    name: layerName,
                    type: 'markers',
                    visible: true,
                    markers: [markerData],
                };

                updatedLayers.push(newLayer);
            }

            console.log('Updated Layers:', updatedLayers);

            return updatedLayers;
        });
    }
    function handleMarkerDeleteClick(layerName, _place_id){
        console.log("removing marker from layer : "+layerName+" : "+_place_id);

        setMapMarkerLayers((prevLayers) => {
            const updatedLayers = [...prevLayers];
            console.log('Updated Layers:', updatedLayers);

            // Find the index of the layer with the specified name
            const layerIndex = updatedLayers.findIndex((layer) => layer.name === layerName);
            console.log("layerIndex : "+layerIndex);

            if (layerIndex !== -1) {
                // Layer exists, find the index of the marker with the specified Google Place ID
                const markerIndex = updatedLayers[layerIndex].markers.findIndex(
                    (marker) => marker.google_place_id === _place_id
                );
                console.log("markerIndex : "+markerIndex);
                if (markerIndex !== -1) {

                    const markerToRemove = updatedLayers[layerIndex].markers[markerIndex];

                    // Set the map to null to remove it from the Google Map
                    if (markerToRemove.marker && markerToRemove.marker.setMap) {
                        markerToRemove.marker.setMap(null);
                    }

                    // Remove the marker from the layer
                    updatedLayers[layerIndex].markers.splice(markerIndex, 1);

                    // Optionally, remove the layer if it has no markers left
                    if (updatedLayers[layerIndex].markers.length === 0) {
                        updatedLayers.splice(layerIndex, 1);
                    }
                }
            }

            return updatedLayers;
        });
    }

    const handleButtonOpenMenuClick = (event) => {
        console.log("handleButtonOpenMenuClick");
        if (anchorEl){
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleButtonCloseMenuClick = () => {
        console.log("handleButtonCloseMenuClick");
        setAnchorEl(null); // Close the menu
    };
    const handleButtonCloseClick = () => {
        console.log("handleButtonCloseClick");
        setAnchorEl(null); // Close the menu
        setViewPlaceInfoWindow(false);
    };

    const handleMenuItemClick = (option) => {
        // Handle the click on a menu item (e.g., perform an action)
        console.log(`Selected option: ${option}`);
        setAnchorEl(null); // Close the menu

        let _action = "";
        let _set_feature = "";
        switch (option) {
            case "Activities":
                _action = '/Planner/Activities';
                _set_feature = "Activities";
//                addMarkerToLayer('Activities', layerMarker('Activities'));
                break;
            case "Ideas":
                _set_feature = "Ideas";
                addMarkerToLayer('Ideas', layerMarker('Ideas'));
                break;
            case "Meals":
                _action = '/Planner/Meals';
                _set_feature = "Meals";
//                addMarkerToLayer('Meals', layerMarker('Meals'));
                break;
            case "Lodging":
                _action = '/Planner/Lodging';
                _set_feature = "Lodging";
//                addMarkerToLayer('Lodging', layerMarker('Lodging'));
                break;
            case "Shopping":
                _set_feature = "Shopping";
                addMarkerToLayer('Shopping', layerMarker('Shopping'));
                break;
            case "Tours":
                _set_feature = "Tours";
                addMarkerToLayer('Tours', layerMarker('Tours'));
                break;
            case "Planning":
                //add to the ConciergePlanning array
                const existingItems = props.conciergePlanningItemsFromMap;
                const newArray = [...existingItems, _.cloneDeep(viewPlaceInfoPlace)];
                props.setConciergePlanningItemsFromMap(newArray);

                //now add a marker to the Marker Layer
                addMarkerToLayer('Planning', layerMarker("Planning"));

                break;
            default:

        }

        if (option === "Lodging"){
            //get details about the Place (viewPlaceInfoPlace)

            //create the new Lodging
            const _new_lodging = new Lodging();
            _new_lodging.temp_id = dayjs().valueOf();

            _new_lodging.name = viewPlaceInfoPlace["name"];
            _new_lodging.phone = viewPlaceInfoPlace["formatted_phone_number"];

            //set the Place object from Google
            _new_lodging.Place = viewPlaceInfoPlace;

            //add the lodging location
            const _place_location = viewPlaceInfoPlace["geometry"];

            const _lodging_location = new PALocation();
            _lodging_location.google_place_id = viewPlaceInfoPlace["place_id"];
            _lodging_location.latitude = _place_location.location.lat();
            _lodging_location.longitude = _place_location.location.lng();


            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            if (Array.isArray(viewPlaceInfoPlace.address_components)) {

                for (const component of viewPlaceInfoPlace.address_components) {
                    if (component.types.includes("route")) {
                        _route_exists = true;
                    } else if (component.types.includes("country")) {
                        _country_code = component.short_name;
                    }
                }

                viewPlaceInfoPlace.address_components.forEach((component) => {
                    if (component.types.includes("street_number")){
                        _address_line = component.long_name;
                    } else if (component.types.includes("route")){
                        _address_line += " "+component.long_name;
                    } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                        _address_line += " "+component.long_name;
                    } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                        _address_city = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                        _address_state = component.short_name;
                    } else if (component.types.includes("postal_code")){
                        _address_zip = component.long_name;
                    } else if (component.types.includes("country")){
                        if (_country_code !== "US"){
                            _address_state = component.long_name;
                        }
                        _address_country = component.short_name;
                    }
                });

            } else {
                console.log("address_components is not iterable");
                /*
                    so lets see if we can break apart the formatted_address
                 */

                const components = viewPlaceInfoPlace.formatted_address.split(',').map(component => component.trim());

                // Initialize variables
                let address = '';
                let city = '';
                let state = '';
                let zip = '';
                let country = '';

                // Assuming the country is always the last component
                if (components.length > 0) {
                    _address_country = components.pop();
                }

                // Process remaining components
                if (components.length > 0) {
                    _address_line = components.shift();
                }

                if (components.length > 0) {
                    _address_city = components.shift();
                }

                if (components.length > 0) {
                    _address_state = components.shift();
                }

                // If the state contains a zip code, split them
                const stateZipMatch = _address_state.match(/^(.+?)\s*(\d{5})?$/);
                if (stateZipMatch) {
                    _address_state = stateZipMatch[1];
                    _address_zip = stateZipMatch[2] || '';
                }


            }


            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = viewPlaceInfoPlace.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);


            _lodging_location.name =  viewPlaceInfoPlace["name"];
            _lodging_location.formatted_address = viewPlaceInfoPlace["formatted_address"];
            _lodging_location.address = _address_line;
            _lodging_location.city = _address_city;
            _lodging_location.state = _address_state;
            _lodging_location.zip = _address_zip;
            _lodging_location.country = _address_country;

            _new_lodging.location = _lodging_location;

            const _photos_array = [];
            if (viewPlaceInfoPlace.photos && Array.isArray(viewPlaceInfoPlace.photos)) {
                for (const _photo of viewPlaceInfoPlace.photos) {
                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                }
            }
            _new_lodging.photos = _photos_array;

            setPlanningLodging(Planning["id"], [...Planning.lodging, _new_lodging]);

        } else if (option === "Activities"){
            setAnchorEl(null); // Close the menu

            //get details about the Place (viewPlaceInfoPlace)
            console.log("the Activities viewPlaceInfoPlace : "+JSON.stringify(viewPlaceInfoPlace, null, '\t'));

            //create the new Activity
            const _new_activity = new Activity();
            _new_activity.title = viewPlaceInfoPlace["name"]
            _new_activity.phone = viewPlaceInfoPlace["formatted_phone_number"]

            //set the Place object from Google
            _new_activity.Place = viewPlaceInfoPlace;

            //add the activity location
            const _place_location = viewPlaceInfoPlace["geometry"];

            const _activity_location = new PALocation();
            _activity_location.google_place_id = viewPlaceInfoPlace["place_id"];
            _activity_location.latitude = _place_location.location.lat();
            _activity_location.longitude = _place_location.location.lng();


            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            if (Array.isArray(viewPlaceInfoPlace.address_components)) {

                for (const component of viewPlaceInfoPlace.address_components){
                    if (component.types.includes("route")) {
                        _route_exists = true;
                    } else if (component.types.includes("country")){
                        _country_code = component.short_name;
                    }
                }

                viewPlaceInfoPlace.address_components.forEach((component) => {
                    if (component.types.includes("street_number")){
                        _address_line = component.long_name;
                    } else if (component.types.includes("route")){
                        _address_line += " "+component.long_name;
                    } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                        _address_line += " "+component.long_name;
                    } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                        _address_city = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                        _address_state = component.short_name;
                    } else if (component.types.includes("postal_code")){
                        _address_zip = component.long_name;
                    } else if (component.types.includes("country")){
                        if (_country_code !== "US"){
                            _address_state = component.long_name;
                        }
                        _address_country = component.short_name;
                    }
                });
            } else {
                console.log("address_components is not iterable");
                /*
                    so lets see if we can break apart the formatted_address
                 */

                const components = viewPlaceInfoPlace.formatted_address.split(',').map(component => component.trim());

                // Initialize variables
                let address = '';
                let city = '';
                let state = '';
                let zip = '';
                let country = '';

                // Assuming the country is always the last component
                if (components.length > 0) {
                    _address_country = components.pop();
                }

                // Process remaining components
                if (components.length > 0) {
                    _address_line = components.shift();
                }

                if (components.length > 0) {
                    _address_city = components.shift();
                }

                if (components.length > 0) {
                    _address_state = components.shift();
                }

                // If the state contains a zip code, split them
                const stateZipMatch = _address_state.match(/^(.+?)\s*(\d{5})?$/);
                if (stateZipMatch) {
                    _address_state = stateZipMatch[1];
                    _address_zip = stateZipMatch[2] || '';
                }


            }

            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = viewPlaceInfoPlace.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);


            _activity_location.name =  viewPlaceInfoPlace["name"];
            _activity_location.formatted_address = viewPlaceInfoPlace["formatted_address"];
            _activity_location.address = _address_line;
            _activity_location.city = _address_city;
            _activity_location.state = _address_state;
            _activity_location.zip = _address_zip;
            _activity_location.country = _address_country;

            _new_activity.location = _activity_location;

            const _photos_array = [];
            if (viewPlaceInfoPlace.photos && Array.isArray(viewPlaceInfoPlace.photos)) {
                for (const _photo of viewPlaceInfoPlace.photos) {
                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                }
            }
            _new_activity.photos = _photos_array;

            setPlanningActivities(Planning["id"], [...Planning.activities, _new_activity]);

        } else if (option === "Meals"){
            setAnchorEl(null); // Close the menu

            //get details about the Place (viewPlaceInfoPlace)
//            console.log("the Meal viewPlaceInfoPlace : "+JSON.stringify(viewPlaceInfoPlace, null, '\t'));

            //create the new Meal
            const _new_meal = new Meal();
            _new_meal.title = "";

            _new_meal.phone = viewPlaceInfoPlace["formatted_phone_number"]

            //set the Place object from Google
            _new_meal.Place = viewPlaceInfoPlace;

            //add the meal location
            const _place_location = viewPlaceInfoPlace["geometry"];

            const _meal_location = new PALocation();
            _meal_location.google_place_id = viewPlaceInfoPlace["place_id"];
            _meal_location.latitude = _place_location.location.lat();
            _meal_location.longitude = _place_location.location.lng();


            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            if (Array.isArray(viewPlaceInfoPlace?.address_components)) {
                for (const component of viewPlaceInfoPlace.address_components) {
                    if (component.types.includes("route")) {
                        _route_exists = true;
                    } else if (component.types.includes("country")) {
                        _country_code = component.short_name;
                    }
                }
            } else {
                console.error('address_components is not iterable or not an array');
            }

            if (Array.isArray(viewPlaceInfoPlace?.address_components)) {
                viewPlaceInfoPlace.address_components.forEach((component) => {
                    if (component.types.includes("street_number")){
                        _address_line = component.long_name;
                    } else if (component.types.includes("route")){
                        _address_line += " "+component.long_name;
                    } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                        _address_line += " "+component.long_name;
                    } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                        _address_city = component.long_name;
                    } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                        _address_state = component.short_name;
                    } else if (component.types.includes("postal_code")){
                        _address_zip = component.long_name;
                    } else if (component.types.includes("country")){
                        if (_country_code !== "US"){
                            _address_state = component.long_name;
                        }
                        _address_country = component.short_name;
                    }
                });
            }

            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = viewPlaceInfoPlace.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);


            _meal_location.name =  viewPlaceInfoPlace["name"];
            _meal_location.formatted_address = viewPlaceInfoPlace["formatted_address"];
            _meal_location.address = _address_line;
            _meal_location.city = _address_city;
            _meal_location.state = _address_state;
            _meal_location.zip = _address_zip;
            _meal_location.country = _address_country;

            _new_meal.location = _meal_location;


            const _photos_array = [];
            if (viewPlaceInfoPlace.photos && Array.isArray(viewPlaceInfoPlace.photos)) {
                for (const _photo of viewPlaceInfoPlace.photos) {
                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                }
            }
            _new_meal.photos = _photos_array;

            setPlanningMeals(Planning["id"], [...Planning.meals, _new_meal]);

        } else if (option === "Ideas"){
            setAnchorEl(null); // Close the menu

            //get details about the Place (viewPlaceInfoPlace)
            console.log("the Idea viewPlaceInfoPlace : "+JSON.stringify(viewPlaceInfoPlace, null, '\t'));

            //create the new Idea
            const _new_activity = new Idea();
            _new_activity.title = viewPlaceInfoPlace["name"]
            _new_activity.phone = viewPlaceInfoPlace["formatted_phone_number"]

            //set the Place object from Google
            _new_activity.Place = viewPlaceInfoPlace;

            //add the activity location
            const _place_location = viewPlaceInfoPlace["geometry"];

            const _activity_location = new PALocation();
            _activity_location.google_place_id = viewPlaceInfoPlace["place_id"];
            _activity_location.latitude = _place_location.location.lat();
            _activity_location.longitude = _place_location.location.lng();


            let _address_line = "";
            let _address_city = "";
            let _address_state = "";
            let _address_zip = "";
            let _address_country = "";

            let _route_exists = false;
            let _country_code = "";
            for (const component of viewPlaceInfoPlace.address_components){
                if (component.types.includes("route")) {
                    _route_exists = true;
                } else if (component.types.includes("country")){
                    _country_code = component.short_name;
                }
            }

            viewPlaceInfoPlace.address_components.forEach((component) => {
                if (component.types.includes("street_number")){
                    _address_line = component.long_name;
                } else if (component.types.includes("route")){
                    _address_line += " "+component.long_name;
                } else if (!_route_exists && component.types.includes("administrative_area_level_2")){
                    _address_line += " "+component.long_name;
                } else if (component.types.includes("locality") || component.types.includes("postal_town")){
                    _address_city = component.long_name;
                } else if (component.types.includes("administrative_area_level_1") && _country_code === "US"){
                    _address_state = component.short_name;
                } else if (component.types.includes("postal_code")){
                    _address_zip = component.long_name;
                } else if (component.types.includes("country")){
                    if (_country_code !== "US"){
                        _address_state = component.long_name;
                    }
                    _address_country = component.short_name;
                }
            });

            if (_address_line === ""){
                //try getting it from the formatted address
                try {
                    const _f_address_pieces = viewPlaceInfoPlace.formatted_address.split(',');
                    if (_f_address_pieces[0] !== ""){
                        _address_line = _f_address_pieces[0].trim();
                    }
                } catch (e){}
            }

            console.log("the _address_line : "+_address_line);
            console.log("the _address_city : "+_address_city);
            console.log("the _address_state : "+_address_state);
            console.log("the _address_zip: "+_address_zip);
            console.log("the _address_country: "+_address_country);


            _activity_location.name =  viewPlaceInfoPlace["name"];
            _activity_location.formatted_address = viewPlaceInfoPlace["formatted_address"];
            _activity_location.address = _address_line;
            _activity_location.city = _address_city;
            _activity_location.state = _address_state;
            _activity_location.zip = _address_zip;
            _activity_location.country = _address_country;

            _new_activity.location = _activity_location;

            const _photos_array = [];
            if (viewPlaceInfoPlace.photos && Array.isArray(viewPlaceInfoPlace.photos)) {
                for (const _photo of viewPlaceInfoPlace.photos) {
                    _photos_array.push(_photo.getUrl({maxWidth: 175, maxHeight: 175}));
                }
            }
            _new_activity.photos = _photos_array;

            setPlanningActivities(Planning["id"], [...Planning.activities, _new_activity]);
        }

        props.setCurrentFeature(_set_feature);

        navigate(_action);
        props.setShowEventItemConfig(true);


    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleMapSearchOK();
        }
    };

    function handleMarkerLayerDeleteClick(_layerName){
        console.log("delete the whole layer : "+_layerName);

        setMapMarkerLayers((prevLayers) => {
            const updatedLayers = prevLayers.filter((layer) => layer.name !== _layerName);

            // Nullify the marker map if the removed layer contains markers
            const layerToRemove = prevLayers.find((layer) => layer.name === _layerName);
            if (layerToRemove && layerToRemove.markers.length > 0) {
                // Perform any necessary cleanup for the markers in the removed layer
                layerToRemove.markers.forEach((marker) => {
                    // Nullify or clean up the marker as needed
                    if (marker.marker) {
                        marker.marker.setMap(null);
                    }
                });
                layerToRemove.markers = [];
            }

            console.log('Updated Layers after removing layer:', updatedLayers);

            return updatedLayers;
        });

    }

    function getRandomShadeOfBlack() {
        // Generate a random value for saturation and lightness
        const randomSaturation = Math.floor(Math.random() * 20) + 80; // Random value between 80 and 100
        const randomLightness = Math.floor(Math.random() * 20) + 10; // Random value between 10 and 30

        // Convert HSL values to a CSS color string
        return `hsl(0, ${randomSaturation}%, ${randomLightness}%)`;
    }


    useEffect(() => {
        console.log("----------------------------------- props.clearEvent");
        //clear all map Markers

        setMapMarkerLayers((prevLayers) => {
            const updatedLayers = [...prevLayers];

            //go through each layer and delete all the Markers
            updatedLayers.forEach((_layer) => {
                _layer.markers.forEach((marker) => {
                    if (marker.marker) {
                        marker.marker.setMap(null);
                    }
                })
            });

            console.log("----------------------------------- just cleared all the markers");
            return [];
        });

    },[props.clearEvent]);

    const [column1Width, setColumn1Width] = useState(0);
    const column1Ref = useRef(null);
    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                // Identify the column being observed and update the corresponding state
                if (entry.target === column1Ref.current) {
                    setColumn1Width(entry.contentRect.width);
                }

                // You can perform additional actions here
                console.log('!!!!!!!!!!!!!!!!!! Column size changed:', entry.target, entry.contentRect.width);
            }
        });

        if (column1Ref.current) observer.observe(column1Ref.current);

        return () => {
            if (column1Ref.current) observer.unobserve(column1Ref.current);
        };
    }, []);





    return (

        <div>

            {/* add the search input box */}
            <Box
                className="planning-map-area-search-inputbox-holder"
                sx={{
                    display: column1Width < 350 ? 'none' : 'block'
                }}
            >
                <Paper
                    component="form"
                    sx={{
                        p: '2px 4px',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%', // Full width
                        maxWidth: 400, // Max width of 400px
                    }}
                >
                    <Badge badgeContent={mapSearchTextResultCount} color="primary">
                        <IconButton
                            type="button"
                            sx={{
                                p: '10px',
                                backgroundColor: mapSearchType === "establishment" ? 'rgba(198, 226, 255, 0.8)' : '',
                            }}
                            aria-label="search using type"
                            onClick={() => handleSearchTypeChange('establishment')}
                        >
                            <i className="icon-store" title="Search for establishment types"/>
                        </IconButton>
                    </Badge>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton
                        type="button"
                        sx={{
                            p: '10px',
                            backgroundColor: mapSearchType === "address" ? 'rgba(198, 226, 255, 0.8)' : '',
                        }}
                        aria-label="search using address"
                        onClick={() => handleSearchTypeChange('address')}
                    >
                        <i className="icon-home3" title="Search for actual address"/>
                    </IconButton>
                    <InputBase
                        sx={{
                            display: mapSearchType === 'establishment' ? 'block' : 'none',
                            ml: 1,
                            flex: 1
                        }}
                        placeholder='Search Google Maps'
                        inputProps={{ 'aria-label': 'search google maps' }}
                        value={mapSearchText}
                        onChange={handleMapSearchInput}
                        onKeyDown={handleKeyDown}
                        inputRef={inputRefEstablishment}
                    />
                    <InputBase
                        sx={{
                            display: mapSearchType === 'address' ? 'block' : 'none',
                            ml: 1,
                            flex: 1
                        }}
                        placeholder={mapSearchType === "establishment" ? 'Search Google Maps' : 'Search Physical Address'}
                        inputProps={{ 'aria-label': 'search google maps' }}
                        value={mapSearchText}
                        onChange={handleMapSearchInput}
                        inputRef={inputRefAddress}
                    />
                    <IconButton
                        type="button"
                        color="primary"
                        sx={{ p: '10px' }}
                        aria-label="search"
                        onClick={handleMapSearchOK}
                    >
                        <i className="icon-search" title="Search"/>
                    </IconButton>
                </Paper>
            </Box>

            {/* add the layers icon */}
            <div className="planning-map-area-layers-icon-holder">
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'}}
                >
                    <Badge
                        badgeContent={mapMarkerLayersCount}
                        color="badge"
                    >
                        <IconButton
                            type="button"
                            sx={{
                                p: '10px',
                            }}
                            aria-label="map layers for Markers"
                            onClick={handleButtonOpenLayersMenuClick}
                        >
                            <i className="icon-layers" title="Marker Layers"/>
                        </IconButton>
                    </Badge>
                    <Menu
                        id="split-button-menu"
                        anchorEl={anchorLayersEl}
                        open={Boolean(anchorLayersEl)}
                        onClose={handleButtonLayersMenuClose}

                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                    >
                        <MenuList dense
                            sx={{cursor:'default'}}
                        >
                            {
                                Object.keys(mapMarkerLayers).length > 0 ? (
                                    mapMarkerLayers.map((layer, index) => (
                                            <div key={index}>
                                                <ListItem
                                                    key={index}
                                                    sx={{cursor:'default'}}
                                                >
                                                    <ListItemIcon>{layer['markers'].length}</ListItemIcon>
                                                    <ListItemText>{layer.name}</ListItemText>
                                                    <Checkbox
                                                        edge="end"
                                                        checked={layer.visible}
                                                        inputProps={{ 'aria-labelledby': "Toggle Layer" }}
                                                        onClick={() => toggleLayerVisibility(layer.name)}
                                                    />
                                                    <i
                                                        style={{ color: 'red', marginLeft: '10px', cursor:'pointer' }}
                                                        className="icon-trash"
                                                        onClick={()=>handleMarkerLayerDeleteClick(layer.name)}
                                                    />
                                                    {
                                                        layer['markers'].length > 5 ? (
                                                            <i
                                                                style={{ marginLeft: '10px', cursor:'pointer' }}
                                                                className="icon-chevron-down"
                                                                onClick={()=>toggleLayerVisibility(layer.name)}
                                                            ></i>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                </ListItem>
                                                {/* show the individual items for this layer */}
                                                {
                                                    layer['markers'].length === 1 || layer['markers'].length > 5 ? (
                                                        <></>
                                                    ) : (
                                                        layer['markers'].map((markerData, index) => {
//                                                            console.log("The marker : "+[markerData]);
//                                                            console.log("The marker : "+markerData.google_place_id);
//                                                            console.log("The marker : "+markerData.title);
                                                            return (
                                                                <MenuList
                                                                    key={markerData.google_place_id}
                                                                    dense
                                                                >
                                                                    <ListItem
                                                                        sx={{cursor:'default'}}
                                                                    >
                                                                        <ListItemText inset>
                                                                            <Typography variant="inherit" noWrap>
                                                                                {markerData.title}
                                                                            </Typography>
                                                                        </ListItemText>
                                                                        <i
                                                                            style={{ color: 'red', marginLeft: '10px', cursor:'pointer' }}
                                                                            className="icon-trash"
                                                                            onClick={()=>handleMarkerDeleteClick(layer.name, markerData.google_place_id)}
                                                                        />
                                                                    </ListItem>
                                                                </MenuList>
                                                            )

                                                        })
                                                    )


                                                }

                                            </div>
                                        ))
                                ):(
                                    <MenuItem>
                                        <ListItemText>No Layers</ListItemText>
                                    </MenuItem>
                                )

                            }
                        </MenuList>
                    </Menu>
                </Paper>
            </div>

            {/* add the map */}
            <div
                ref={column1Ref}
                className="planning-map-area-google-map-holder"
                id="global_google_map_holder"
                style={{ width: '100%', height: 'calc(100vh - 265px)' }}
            />

            {/* add the place info panel */}
            <div
                className="planning-map-area-place-info-container"
                style={{display: viewPlaceInfoWindow ? 'block' : 'none'}}
            >
                <Paper
                    sx={{ p: '10px', display: 'flex', alignItems: 'center' }}
                >
                    {
                        viewPlaceInfoPlace && viewPlaceInfoWindow ? (
                            <div className="planning-map-area-place-info-holder" >
                                {/* add the header */}
                                <div className="planning-map-area-place-info-name"><strong>{viewPlaceInfoPlace.name}</strong></div>
                                {/* add the description */}

                                {/* add the Chip to add to a particular area */}
                                <div className="planning-map-area-place-info-button-holder">
                                    <Chip
                                        sx={cancelChipStyle}
                                        label="Close"
                                        size="small"
                                        onClick={handleButtonCloseClick}
                                    />
                                    <Chip
                                        sx={updateChipStyle}
                                        label="Add"
                                        size="small"
                                        onClick={handleButtonOpenMenuClick}
                                    />
                                    <Menu
                                        id="split-button-menu"
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleButtonCloseMenuClick}

                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

                                    >
                                        <MenuList dense >
                                            <MenuItem onClick={() => handleMenuItemClick('Activities')}>Add to Activities</MenuItem>
                                            <MenuItem onClick={() => handleMenuItemClick('Ideas')}>Add to Ideas</MenuItem>
                                            {
                                                viewPlaceInfoPlace.types.includes('lodging')
                                                || viewPlaceInfoPlace.types.includes('premise')
                                                || viewPlaceInfoPlace.types.includes('campground')
                                                || viewPlaceInfoPlace.types.includes('park') ? (
                                                    <MenuItem onClick={() => handleMenuItemClick('Lodging')}>Add to Lodging</MenuItem>
                                                ) : null
                                            }
                                            {
                                                viewPlaceInfoPlace.types.includes('food')
                                                || viewPlaceInfoPlace.types.includes('bar') ? (
                                                    <MenuItem onClick={() => handleMenuItemClick('Meals')}>Add to Meals</MenuItem>
                                                ) : null
                                            }
                                            {
                                                viewPlaceInfoPlace.types.includes('store') ? (
                                                    <MenuItem onClick={() => handleMenuItemClick('Shopping')}>Add to Shopping</MenuItem>
                                                ) : null
                                            }
                                            <MenuItem onClick={() => handleMenuItemClick('Planning')}>Add to Concierge Planning</MenuItem>
                                        </MenuList>

                                    </Menu>
                                </div>

                                {/* add the address */}
                                <div className="planning-map-area-place-info-address">{viewPlaceInfoPlace.formatted_address}</div>

                                {/* add any images */}
                                {
                                    viewPlaceInfoPlace.photos && viewPlaceInfoPlace.photos.length > 0 ? (
                                        <div style={{ width: '100%', display: 'flex', overflowX: 'scroll' }}>
                                            {
                                                viewPlaceInfoPlace['photos'].map((_photo, index) => (
                                                    <CardMedia
                                                        key={index}
                                                        component="img"
                                                        sx={{marginRight: '10px', marginBottom: '5px', borderRadius: '5px'}}
                                                        alt={`Attribution ${index + 1}`}
                                                        image={_photo.getUrl({maxWidth: 150, maxHeight: 150})} // Use the attribution as the image source
                                                    />
                                                ))
                                            }
                                        </div>
                                    ):(
                                        <></>
                                    )
                                }

                                {/* add the reviews */}
                                <div className="planning-map-area-place-info-rating-holder">
                                    {
                                        viewPlaceInfoPlace.rating ? (
                                            <Rating
                                                sx={{flex: '1'}}
                                                name="read-only"
                                                value={viewPlaceInfoPlace.rating}
                                                precision={0.1}
                                                readOnly />
                                        ) : null
                                    }
                                    {
                                        viewPlaceInfoPlace.user_ratings_total ? (
                                            <Typography
                                                sx={{
                                                    fontSize:'12px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                                component="legend"
                                            >
                                                {viewPlaceInfoPlace.user_ratings_total} review{viewPlaceInfoPlace.user_ratings_total===1?'':'s'}
                                            </Typography>
                                        ) : null
                                    }
                                </div>
                                {/* add the price level */}

                                {/* add the types chips */}
                                {
                                    viewPlaceInfoPlace.types && viewPlaceInfoPlace.types.length > 0 ? (
                                        <div className="planning-map-area-place-info-type-holder">
                                            {viewPlaceInfoPlace.types.map((item, index) => (
                                                item !== 'point_of_interest' && item !== 'establishment' ? (
                                                    <Chip
                                                        key={index}
                                                        label={item}
                                                        size='small'
                                                    />
                                                ) : (
                                                    <div key={index}></div>
                                                )

                                            ))
                                            }
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                }
                                {/* add the hours */}


                                {/* add the website */}
                                {
                                    viewPlaceInfoPlace.website && viewPlaceInfoPlace.website !== "" ? (
                                        <span
                                            className="planning-map-area-place-info-website-holder"
                                            onClick={() => {window.open(viewPlaceInfoPlace.website, "_blank")}}
                                        >
                                            Website
                                        </span>
                                    ) : (
                                        <></>
                                    )
                                }
                            </div>
                        ) : (
                            <></>
                        )

                    }
                </Paper>
            </div>

        </div>
    )

}

export default GoogleMap;



