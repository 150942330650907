
import React, {useEffect, useState} from 'react';
import {TextField, Button, Typography, FormControlLabel, Switch} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {_airports_list} from "../../../sampleData/airline_data";
import Box from "@mui/material/Box";

const FlightInput = ({ onAirportChange, onSearch }) => {

    const [departure, setDeparture] = useState(null);
    const [destination, setDestination] = useState(null);
    const [date, setDate] = useState('2024-10-15');
    const [maxConnections, setMaxConnections] = useState(1);
    const [filteredAirportsList, setFilteredAirportsList] = useState([]);
    const [useTestData, setUseTestData] = useState(true);


    // Filter and reduce _airports_list only once or when it changes
    useEffect(() => {
        const filteredList = _airports_list
            .filter(airport => airport.iata && airport.iata.trim() !== '' && airport.name && airport.name.trim() !== '')
            .reduce((acc, current) => {
                if (!acc.some(item => item.iata === current.iata)) {
                    acc.push(current); // Add to the accumulator if no duplicates
                }
                return acc;
            }, []);
        setFilteredAirportsList(filteredList);
    }, [_airports_list]); // Runs only when airportsList changes

    // Function to handle changes in departure airport
    const handleDepartureChange = (event, newValue) => {
        setDeparture(newValue);
        onAirportChange({ departure: newValue, destination });
    };

    // Function to handle changes in destination airport
    const handleDestinationChange = (event, newValue) => {
        setDestination(newValue);
        onAirportChange({ departure, destination: newValue });
    };

    const handleSubmit = () => {
        if (departure && destination) {
            onSearch({ departure, destination, date, maxConnections, useTestData });
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Typography variant="h7">Search</Typography>

                {/* Toggle Switch for Test Data */}
                <FormControlLabel
                    control={<Switch checked={useTestData} onChange={(e) => setUseTestData(e.target.checked)} />}
                    label="Fake Data"
                />
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'nowrap' }}>
                {/* Autocomplete for Departure */}
                <Autocomplete
                    options={filteredAirportsList}
                    getOptionLabel={(option) => `${option.iata} - ${option.name}`}
                    value={departure}
                    onChange={handleDepartureChange}
                    renderInput={(params) => <TextField {...params} label="Departure" />}
                    key={(option) => 'dep-' + option.iata + '-' + option.name}
                    sx={{ flexGrow: 1, minWidth: '200px' }}  // Make the Autocomplete take up most of the space
                />

                {/* Autocomplete for Destination */}
                <Autocomplete
                    options={filteredAirportsList}
                    getOptionLabel={(option) => `${option.iata} - ${option.name}`}
                    value={destination}
                    onChange={handleDestinationChange}
                    renderInput={(params) => <TextField {...params} label="Destination" />}
                    key={(option) => 'des-' + option.iata + '-' + option.name}
                    sx={{ flexGrow: 1, minWidth: '200px' }}  // Make the Autocomplete take up most of the space
                />

                {/* Date TextField */}
                <TextField
                    label="Date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '150px', flexShrink: 0 }}  // Compact width, prevent shrinking
                />

                {/* Max Connections TextField */}
                <TextField
                    label="Stops"
                    type="number"
                    value={maxConnections}
                    inputProps={{ min: 0, max: 2 }}
                    onChange={(e) => setMaxConnections(e.target.value)}
                    sx={{ width: '60px', flexShrink: 0 }}  // Compact width, prevent shrinking
                />

                {/* Search Button */}
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ height: '56px', flexShrink: 0 }}  // Align button with text fields, prevent shrinking
                >
                    Search
                </Button>
            </Box>
        </div>
    );
};

export default FlightInput;
