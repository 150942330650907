
import React, {useEffect, useState} from 'react';
import {
    Checkbox,
    Typography,
    CardActions,
    CardContent,
    Card,
    Grid,
    Chip,
    Menu,
    MenuList, MenuItem
} from '@mui/material';
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    timelineItemClasses,
    TimelineSeparator
} from "@mui/lab";
import {menuChipStyle} from "../../ButtonStyle/MenuButton";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(duration);
dayjs.extend(timezone);

const calculateTotalDurationWithLayovers = (flight) => {
    let totalDurationMinutes = 0;

    flight.slices.forEach((slice) => {
        slice.segments.forEach((segment, index) => {
            // Add in-flight duration of the current segment
            const segmentDurationMinutes = parseDurationISO8601(segment.duration);
            totalDurationMinutes += segmentDurationMinutes;

            // If there's a next segment, calculate the layover time between this segment and the next one
            if (index < slice.segments.length - 1) {
                const nextSegment = slice.segments[index + 1];
                const arrivalTime = dayjs(segment.arriving_at);
                const nextDepartureTime = dayjs(nextSegment.departing_at);

                // Calculate the layover time between the segments
                const layoverDurationMinutes = nextDepartureTime.diff(arrivalTime, 'minutes');
                totalDurationMinutes += layoverDurationMinutes;
            }
        });
    });

    // Convert the total duration minutes to a duration object
    const totalDuration = dayjs.duration(totalDurationMinutes, 'minutes');

    return formatDuration(totalDuration);
};


// Helper to calculate In-Flight Time using dayjs
const calculateInFlightTime = (flight) => {
    let totalInFlightMinutes = 0;

    flight.slices.forEach((slice) => {
        slice.segments.forEach((segment) => {
            // Parse ISO 8601 duration from the segment's duration field
            const segmentDuration = dayjs.duration(segment.duration); // Now using the provided duration
            totalInFlightMinutes += segmentDuration.asMinutes(); // Get duration in minutes
        });
    });

    // Convert total in-flight minutes to a duration object
    const inFlightDuration = dayjs.duration(totalInFlightMinutes, 'minutes');

    return formatDuration(inFlightDuration);
};

// Helper to parse ISO 8601 duration (e.g., PT1H30M)
const parseDurationISO8601 = (isoDuration) => {
    const parsedDuration = dayjs.duration(isoDuration);
    return parsedDuration.asMinutes();
};

// Helper to format the duration (in hours and minutes)
const formatDuration = (duration) => {
    const hours = duration.hours();
    const minutes = duration.minutes();

    return `${hours ? `${hours} hr${hours > 1 ? 's' : ''} ` : ''}${minutes} min`;
};

// Helper function to calculate the difference between two timestamps and return a human-readable format
const calculateLayover = (arrivalTime, nextDepartureTime) => {
    const diffMs = new Date(nextDepartureTime) - new Date(arrivalTime); // Difference in milliseconds
    const diffHours = Math.floor(diffMs / (1000 * 60 * 60)); // Convert to hours
    const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
    return `${diffHours > 0 ? `${diffHours} hr${diffHours > 1 ? 's' : ''}` : ''} ${diffMinutes > 0 ? `${diffMinutes} min${diffMinutes > 1 ? 's' : ''}` : ''}`.trim();
};

// Helper function to check if two dates are on the same day
const isSameDay = (date1, date2) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.toLocaleDateString() === d2.toLocaleDateString();
};

// Helper function to format time without seconds and with AM/PM
const formatTime = (timeString) => {
    return new Date(timeString).toLocaleTimeString([], {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    });
};

// Helper to check if the timezone changes between origin and destination
const checkTimezoneChange = (origin, destination) => {
    const originTimeZone = origin.time_zone;
    const destinationTimeZone = destination.time_zone;

    return originTimeZone !== destinationTimeZone;
};

const getTimezoneOffset = (timeZone) => {
    const now = new Date();
    const localTime = now.toLocaleString('en-US', { timeZone });
    const timeZoneDate = new Date(localTime);
    const diff = (timeZoneDate.getTime() - now.getTime()) / (1000 * 60 * 60);
    return Math.round(diff);
};

// Helper to format the timezone change notice
const formatTimezoneChangeNotice = (origin, destination) => {
    const originOffset = getTimezoneOffset(origin.time_zone);
    const destinationOffset = getTimezoneOffset(destination.time_zone);

    const diffHours = destinationOffset - originOffset;

    if (diffHours > 0) {
        return (
            <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                TZ change +{diffHours} hr{diffHours > 1 ? 's' : ''}
            </Typography>
        );
    } else if (diffHours < 0) {
        return (
            <Typography variant="body2" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                TZ change -{Math.abs(diffHours)} hr{Math.abs(diffHours) > 1 ? 's' : ''}
            </Typography>
        );
    }

    return null;
};





const FlightSelection = ({ flights, selectedFlights, onSelectFlight }) => {
    const [selectedCarrier, setSelectedCarrier] = useState('all');
    const [sortType, setSortType] = useState(null);
    const [filteredFlights, setFilteredFlights] = useState(flights);

    // Effect to reset filteredFlights and selectedCarrier when flights change
    useEffect(() => {
        setFilteredFlights(flights);
        setSelectedCarrier('all'); // Reset carrier selection to 'all'
    }, [flights]);

    // Function to handle logo click and filter flights
    const handleCarrierSelect = (carrier) => {
        setSelectedCarrier(carrier);
        if (carrier === 'all') {
            setFilteredFlights(flights); // Show all flights
        } else {
            const filtered = flights.filter((flight) => flight.owner.iata_code === carrier);
            setFilteredFlights(filtered);
        }

        handleFlightSelectionListMenuClose();
    };

    // Sorting logic based on the selected sortType
    const sortedFlights = [...filteredFlights].sort((a, b) => {
        if (sortType === 'price') {
            return parseFloat(a.total_amount) - parseFloat(b.total_amount);
        }
        if (sortType === 'duration') {
            const durationA = a.slices.reduce((total, slice) => total + parseISO8601Duration(slice.duration), 0);
            const durationB = b.slices.reduce((total, slice) => total + parseISO8601Duration(slice.duration), 0);
            return durationA - durationB;
        }
        if (sortType === 'segments') {
            return a.slices.length - b.slices.length;
        }
        return 0;
    });

    // Function to convert ISO 8601 duration (e.g., "PT1H30M") to minutes
    const parseISO8601Duration = (duration) => {
        const match = duration.match(/P(?:(\d+)D)?T(?:(\d+)H)?(?:(\d+)M)?/);
        const days = parseInt(match[1] || '0', 10);
        const hours = parseInt(match[2] || '0', 10);
        const minutes = parseInt(match[3] || '0', 10);
        return days * 24 * 60 + hours * 60 + minutes;
    };

    // Get unique carriers and count the number of flights per carrier
    const carrierCounts = flights.reduce((acc, flight) => {
        const carrierCode = flight.owner.iata_code;
        if (!acc[carrierCode]) {
            acc[carrierCode] = { name: flight.owner.name, logo: flight.owner.logo_lockup_url || flight.owner.logo_symbol_url, count: 0 };
        }
        acc[carrierCode].count += 1;
        return acc;
    }, {});

    const handleFlightToggle = (flightId) => {
        onSelectFlight(flightId);
    };

    // Check if a flight is selected based on flight id
    const isFlightSelected = (flightId) => {
        return selectedFlights.some((flight) => flight.id === flightId);
    };


    const [anchorFlightSelectionListMenu, setAnchorFlightSelectionListMenu] = useState(null);
    const handleFlightSelectionListMenuOpenMenu = (event) => {
        setAnchorFlightSelectionListMenu(event.currentTarget);
    };
    const handleFlightSelectionListMenuClose = () => {
        setAnchorFlightSelectionListMenu(null);
    };
    function handlePromptChangeClose() {
        setAnchorFlightSelectionListMenu(null);
    }
    const handleFlightSelectionListMenuClick = (event) => {
        const target = event.currentTarget;
        const dataId = target.getAttribute('data-id');

        setAnchorFlightSelectionListMenu(event.currentTarget);

    };



    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column'}}>
            {/* Sticky Header */}
            <div style={{
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                zIndex: 1,
                padding: '5px 10px',   // Reduce padding for the header
                height: 'auto',        // Let the content define the height
            }}>

                {/* Flex container for the header */}
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {/* Left-aligned available flights text */}
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h7>Available Flights ({filteredFlights.length})</h7>
                    </div>

                    {/* Right-aligned chip */}
                    <div>
                        <Chip
                            sx={menuChipStyle}
                            label={<i className="icon-menu2" style={{fontSize: '16px'}}/>}
                            size="small"
                            onClick={handleFlightSelectionListMenuOpenMenu}
                        />
                        <Menu
                            sx={{width: 320, maxWidth: '100%'}}
                            anchorEl={anchorFlightSelectionListMenu}
                            id="library-menu"
                            open={Boolean(anchorFlightSelectionListMenu)}
                            onClose={handleFlightSelectionListMenuClose}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuList dense sx={{width: 320, maxWidth: '100%'}}>

                                <MenuItem onClick={() => handleCarrierSelect('all')}>
                                    <ListItemText>All</ListItemText>
                                    {/* Right-aligned content: Carrier Count */}
                                    <Badge badgeContent={flights.length} color="primary" />
                                </MenuItem>

                                {/* Vertical list of carrier logos */}
                                <MenuItem>
                                    <Typography variant="button">Filter</Typography>
                                </MenuItem>

                                {Object.entries(carrierCounts).map(([carrierCode, carrier]) => (
                                    <MenuItem
                                        key={carrierCode}
                                        onClick={() => handleCarrierSelect(carrierCode)}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            {/* Left-aligned content: Logo and Carrier Name */}
                                            <Box display="flex" alignItems="center">
                                                <img
                                                    src={carrier.logo}
                                                    alt={`${carrier.name} logo`}
                                                    style={{ width: '50px', height: 'auto', maxHeight: '30px', marginRight: '10px' }}
                                                />
                                                <Typography variant="body2">{carrier.name}</Typography>
                                            </Box>

                                            {/* Right-aligned content: Carrier Count */}
                                            <Badge badgeContent={carrier.count} color="primary" />
                                        </Box>
                                    </MenuItem>
                                ))}

                                <MenuItem>
                                    <Typography variant="button">Sort By</Typography>
                                </MenuItem>

                                {/* Sorting Options */}
                                <MenuItem onClick={() => setSortType('price')}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <i className="icon-wallet" style={{ marginRight: '10px' }} />
                                        <Typography variant="body2">Price</Typography>
                                    </Box>
                                </MenuItem>

                                <MenuItem onClick={() => setSortType('duration')}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <i className="icon-clock" style={{ marginRight: '10px' }} />
                                        <Typography variant="body2">Time</Typography>
                                    </Box>
                                </MenuItem>

                                <MenuItem onClick={() => setSortType('segments')}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <i className="icon-airplane" style={{ marginRight: '10px' }} />
                                        <Typography variant="body2">Stops</Typography>
                                    </Box>
                                </MenuItem>

                                <MenuItem onClick={handlePromptChangeClose}>
                                    <ListItemText>Close</ListItemText>
                                </MenuItem>

                            </MenuList>
                        </Menu>
                    </div>
                </div>
            </div>

            {/* Scrollable List of Flights as Cards */}
            <div style={{ flex: 1, overflowY: 'auto' }}>
                <Grid container spacing={2}>
                    {sortedFlights.length ? (
                        sortedFlights.map((flight) => {
                            const carrierLogo = carrierCounts[flight.owner.iata_code]?.logo;

                            return (
                                <Grid item xs={12} key={flight.id}>
                                    <Card
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: selectedFlights.some(selectedFlight => selectedFlight.id === flight.id) ? 'beige' : 'white'
                                        }}
                                    >
                                        <CardContent>
                                            {/* Flight Logo and Duration */}
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Box display="flex" alignItems="center">
                                                    {carrierLogo && (
                                                        <img
                                                            src={carrierLogo}
                                                            alt={`${flight.owner.name} logo`}
                                                            style={{  width: '100px', maxHeight: '30px', maxWidth: '100px', height: 'auto', marginRight: '10px' }}
                                                        />
                                                    )}
                                                </Box>

                                                {/* Stacking In-Flight time and Total Duration */}
                                                <Box sx={{ textAlign: 'right' }}>
                                                    {
                                                        calculateInFlightTime(flight) !== calculateTotalDurationWithLayovers(flight) ? (
                                                            <>
                                                                {/* In-Flight Time */}
                                                                <Typography variant="body2" color="textSecondary">
                                                                    In Flight: {calculateInFlightTime(flight)}
                                                                </Typography>

                                                                {/* Total Duration */}
                                                                <Typography variant="body2" color="textSecondary">
                                                                    Total: {calculateTotalDurationWithLayovers(flight)}
                                                                </Typography>
                                                            </>
                                                        ) : (
                                                            // Only show Total Duration if both values are equal
                                                            <Typography variant="body2" color="textSecondary">
                                                                Flight: {calculateTotalDurationWithLayovers(flight)}
                                                            </Typography>
                                                        )
                                                    }
                                                    <Typography variant="body2" color="textSecondary">
                                                        # {flight.slices[0]?.segments[0]?.marketing_carrier_flight_number}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {/* Timeline for Segment Details */}
                                            <Timeline
                                                sx={{
                                                    paddingTop: 0,
                                                    paddingBottom: 0,
                                                    margin: 0,
                                                    [`& .${timelineItemClasses.root}:before`]: {
                                                        flex: 0,
                                                        padding: 0,
                                                    },
                                                }}
                                            >
                                                {
                                                    flight.slices.map((slice, index) => (
                                                        <Box key={index} marginTop={2}>
                                                            {
                                                                slice.segments.map((segment, segmentIndex) => {
                                                                    const departDate = new Date(segment.departing_at);
                                                                    const arriveDate = new Date(segment.arriving_at);

                                                                    // Check if the day changes or if there is a timezone difference that affects the date
                                                                    const departDayChange = departDate.toLocaleDateString() !== arriveDate.toLocaleDateString();
                                                                    // Format dates to "3 Nov" format
                                                                    const formatDate = (date) => `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
                                                                    const formatDay = (dateString) => {
                                                                        const date = new Date(dateString);
                                                                        return date.getDate() + ' ' + date.toLocaleString('default', { month: 'short' });
                                                                    };

                                                                    const prevSegment = slice.segments[segmentIndex - 1];
                                                                    const isDateChanged = prevSegment && !isSameDay(prevSegment.arriving_at, segment.departing_at);


                                                                    return (
                                                                        <>
                                                                            <TimelineItem>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="info">
                                                                                        <i className="icon-airplane" style={{ fontSize: '1rem' }}/>
                                                                                    </TimelineDot>
                                                                                    <TimelineConnector />
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    {/* Carrier Change Notice */}
                                                                                    {
                                                                                        segmentIndex > 0 && segment.marketing_carrier.name !== slice.segments[segmentIndex - 1].marketing_carrier.name && (
                                                                                            <Typography variant="caption" color="textSecondary" sx={{ fontStyle: 'italic' }}>
                                                                                                Carrier change: {segment.marketing_carrier.name}
                                                                                            </Typography>
                                                                                        )
                                                                                    }

                                                                                    <Typography variant="body2" sx={{ paddingTop: '10px' }}>
                                                                                        {segment.origin.iata_code} - {formatTime(segment.departing_at)}
                                                                                    </Typography>

                                                                                    {
                                                                                        departDayChange && (
                                                                                            <Typography variant="caption" color="textSecondary">
                                                                                                {formatDate(departDate)}
                                                                                            </Typography>
                                                                                        )
                                                                                    }
                                                                                    {/* Optional Date Change Notice */}
                                                                                    {
                                                                                        isDateChanged && (
                                                                                            <Typography variant="caption" color="textSecondary">
                                                                                                {formatDay(segment.departing_at)}
                                                                                            </Typography>
                                                                                        )
                                                                                    }

                                                                                    {/* Timezone Change Notice */}
                                                                                    {
                                                                                        checkTimezoneChange(segment.origin, segment.destination) &&
                                                                                            formatTimezoneChangeNotice(segment.origin, segment.destination)
                                                                                    }

                                                                                    {/* Layover Notice */}
                                                                                    {
                                                                                        slice.segments.length > 1 && (
                                                                                            <Typography variant="body2" color="primary">
                                                                                                {calculateLayover(segment.departing_at, segment.arriving_at)}
                                                                                            </Typography>
                                                                                        )
                                                                                    }

                                                                                </TimelineContent>
                                                                            </TimelineItem>

                                                                            <TimelineItem>
                                                                                <TimelineSeparator>
                                                                                    <TimelineDot color="info">
                                                                                        <i className="icon-airplane"
                                                                                           style={{
                                                                                               fontSize: '1rem',
                                                                                               transform: 'rotate(90deg)'
                                                                                           }}/>
                                                                                    </TimelineDot>
                                                                                </TimelineSeparator>
                                                                                <TimelineContent>
                                                                                    {/* Show arrival time with date if the day changes */}
                                                                                    <Typography variant="body2" sx={{ paddingTop: '10px' }}>
                                                                                        {segment.destination.iata_code} - {formatTime(segment.arriving_at)}
                                                                                    </Typography>
                                                                                    {
                                                                                        departDayChange && (
                                                                                            <Typography variant="caption" color="textSecondary">
                                                                                                {formatDate(arriveDate)}&nbsp;&nbsp;
                                                                                            </Typography>
                                                                                        )
                                                                                    }
                                                                                    {
                                                                                        segment.destination.iata_code !== slice.destination.iata_code && (
                                                                                            <Typography variant="caption" color="textSecondary">
                                                                                                ({segment.destination.name.split(' ')[0]})
                                                                                            </Typography>
                                                                                        )
                                                                                    }

                                                                                </TimelineContent>
                                                                            </TimelineItem>

                                                                            {/* Layover Information */}
                                                                            {segmentIndex < slice.segments.length - 1 && (
                                                                                <Typography variant="body2" color="red">
                                                                                    Layover: {calculateLayover(segment.arriving_at, slice.segments[segmentIndex + 1].departing_at)}
                                                                                </Typography>
                                                                            )}
                                                                        </>
                                                                    );
                                                                })
                                                            }
                                                        </Box>
                                                    ))
                                                }
                                            </Timeline>

                                        </CardContent>

                                        <CardActions>
                                            {/* Left-aligned content */}
                                            <Box display="flex" flexGrow={1} alignItems="center">
                                                <Checkbox
                                                    checked={isFlightSelected(flight.id)}
                                                    onChange={() => handleFlightToggle(flight.id)}
                                                />
                                                <Typography variant="button">
                                                    Map It
                                                </Typography>
                                            </Box>

                                            {/* Right-aligned content */}
                                            <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                                                <Typography variant="h6" align="right">
                                                    {`$${flight.total_amount}`}
                                                </Typography>
                                            </Box>
                                        </CardActions>

                                    </Card>
                                </Grid>
                            );
                        })
                    ) : (
                        <Typography>No flights available.</Typography>
                    )}
                </Grid>
            </div>
        </div>
    );

};


export default FlightSelection;
